import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchAcheminement: (payload, uuid) =>
    superFetch({
      url: 'listeacheminement',
      method: 'POST',
      body: payload.join(';'),
      uuid,
    }),
}

// Actions
const FETCH_ACHEMINEMENT_REQUEST = 'esite/acheminement/FETCH_ACHEMINEMENT_REQUEST'
const FETCH_ACHEMINEMENT_SUCCESS = 'esite/acheminement/FETCH_ACHEMINEMENT_SUCCESS'
const FETCH_ACHEMINEMENT_FAILURE = 'esite/acheminement/FETCH_ACHEMINEMENT_FAILURE'

// Reducer
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACHEMINEMENT_REQUEST:
      return {
        uuid: action.uuid,
      }
    case FETCH_ACHEMINEMENT_SUCCESS:
      return action.payload
    default:
      return state
  }
}

// Selectors
export const getAcheminement = (state) => state.acheminement
export const getAcheminementUuid = (state) => state.acheminement.uuid

// Thunks
export const fetchAcheminement = (payload) => async (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_ACHEMINEMENT_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchAcheminement(payload, uuid)
    if (response && response.headers.get('X-REQUEST-ID') !== getAcheminementUuid(getState())) return
    dispatch({
      type: FETCH_ACHEMINEMENT_SUCCESS,
      payload: await response.json(),
    })
  } catch (error) {
    dispatch({
      type: FETCH_ACHEMINEMENT_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}
