import React from 'react'
import { Table } from 'reactstrap'

export const CustomTable = ({ children, ...props }) => {
  return (
    <Table
      striped
      bordered
      hover
      size="sm"
      className="shadow-sm"
      css={{
        margin: 0,
        color: '#495057',
        tableLayout: 'fixed',
        thead: {
          textAlign: 'center',
        },
        tbody: {
          td: {
            textAlign: 'right',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
        'th, td': {
          padding: '0.4rem 0.5rem',
        },
      }}
      {...props}
    >
      {children}
    </Table>
  )
}
