import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import EnergySignatureGraph from './EnergySignatureGraph'
import EnergySignatureOptions from './EnergySignatureOptions'

const EnergySignature = ({ title }) => {
  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="10" className="graph-container">
          <EnergySignatureGraph />
        </Col>
        <Col xs="2">
          <EnergySignatureOptions />
        </Col>
      </Row>
    </Container>
  )
}

export default EnergySignature
