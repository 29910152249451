import {
  getPeesIds,
  getSitesIds,
  getUorsIds,
  getRootUor,
  fetchOrganisationSite,
  getOrganisationRequestValid,
} from './organisation'

// Actions
const OPEN_SEARCH_PANEL = 'esite/searchPanel/OPEN_SEARCH_PANEL'
const CLOSE_SEARCH_PANEL = 'esite/searchPanel/CLOSE_SEARCH_PANEL'
const TOGGLE_SEARCH_PANEL = 'esite/searchPanel/TOGGLE_SEARCH_PANEL'
const TOGGLE_FILTER_TYPE = 'esite/searchPanel/TOGGLE_FILTER_TYPE'
const COUNTRY_FILTER_CHANGE = 'esite/searchPanel/COUNTRY_FILTER_CHANGE'
const FILTER_INPUT_CHANGE = 'esite/searchPanel/FILTER_INPUT_CHANGE'
const TOGGLE_UOR = 'esite/searchPanel/TOGGLE_UOR'
const TOGGLE_TAG_FILTER = 'esite/searchPanel/TOGGLE_TAG_FILTER'

// Reducer
const initialState = {
  filterType: 'all',
  countryFilter: 'all',
  openUors: {},
  tagFilters: [],
}
export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SEARCH_PANEL:
      return {
        ...state,
        open: true,
      }
    case CLOSE_SEARCH_PANEL:
      return {
        ...state,
        filterInput: '',
        open: false,
      }
    case TOGGLE_SEARCH_PANEL:
      return {
        ...state,
        open: !state.open,
      }
    case TOGGLE_FILTER_TYPE:
      return {
        ...state,
        filterType: action.filterType,
      }
    case COUNTRY_FILTER_CHANGE:
      return {
        ...state,
        countryFilter: action.countryFilter,
      }
    case FILTER_INPUT_CHANGE:
      return {
        ...state,
        filterInput: action.filterInput,
      }
    case TOGGLE_UOR:
      const isOpen = state.openUors[action.uorId]
      return {
        ...state,
        openUors: {
          ...state.openUors,
          [action.uorId]: typeof isOpen === 'undefined' ? !action.isRoot : !isOpen,
        },
      }
    case TOGGLE_TAG_FILTER: {
      return {
        ...state,
        tagFilters:
          state.tagFilters.findIndex((tag) => tag.id === action.id) === -1
            ? [...state.tagFilters, { id: action.id, nom: action.nom }]
            : state.tagFilters.filter((tag) => tag.id !== action.id),
      }
    }
    default:
      return state
  }
}

// Selectors
export const getTagFilters = (state) => state.searchPanel.tagFilters
export const getSearchPanelOpen = (state) => state.searchPanel.open || false
export const getFilterType = (state) => state.searchPanel.filterType || 'all'
export const getCountryFilter = (state) => state.searchPanel.countryFilter || 'all'

export const getPeeIsOfFilterType = (state, peeId) =>
  (state.searchPanel.filterType === 'all' ||
    state.searchPanel.filterType ===
      state.organisation.organisationEntities.pees[peeId].PeeNrjId) &&
  (state.searchPanel.countryFilter === 'all' ||
    state.searchPanel.countryFilter === state.organisation.organisationEntities.pees[peeId].IdPays)

export const getSiteHasPeeOfFilterType = (state, siteId) =>
  (state.searchPanel.filterType === 'all' && state.searchPanel.countryFilter === 'all') ||
  (state.organisation.organisationEntities.sites &&
    state.organisation.organisationEntities.sites[siteId] &&
    state.organisation.organisationEntities.sites[siteId].SitPees &&
    state.organisation.organisationEntities.sites[siteId].SitPees.filter((peeId) =>
      getPeeIsOfFilterType(state, peeId),
    ).length > 0) ||
  false

export const getSiteIsOfFilterType = (state, siteId) =>
  (state.searchPanel.filterType === 'all' && state.searchPanel.countryFilter === 'all') ||
  (state.organisation.organisationEntities.sites &&
    state.organisation.organisationEntities.sites[siteId] &&
    state.organisation.organisationEntities.sites[siteId].SphAdresse &&
    state.organisation.organisationEntities.sites[siteId].SphAdresse.AdrPaysId ===
      state.searchPanel.countryFilter) ||
  false

export const getUorHasPeeOfFilterType = (state, uorId) => {
  const uor = state.organisation.organisationEntities.uors[uorId]
  const sites =
    (uor &&
      uor.UorSites &&
      uor.UorSites.filter((siteId) => getSiteHasPeeOfFilterType(state, siteId)).length > 0) ||
    false
  const children =
    (uor &&
      uor.UorEnfants &&
      uor.UorEnfants.filter((siteId) => getUorHasPeeOfFilterType(state, siteId)).length > 0) ||
    false
  return (
    (state.searchPanel.filterType === 'all' && state.searchPanel.countryFilter === 'all') ||
    sites ||
    children
  )
}

export const getUorHasSiteOfFilterType = (state, uorId) => {
  const uor = state.organisation.organisationEntities.uors[uorId]
  const sites =
    (uor &&
      uor.UorSites &&
      uor.UorSites.filter((siteId) => getSiteIsOfFilterType(state, siteId)).length > 0) ||
    false
  const children =
    (uor &&
      uor.UorEnfants &&
      uor.UorEnfants.filter((siteId) => getUorHasSiteOfFilterType(state, siteId)).length > 0) ||
    false
  return (
    (state.searchPanel.filterType === 'all' && state.searchPanel.countryFilter === 'all') ||
    sites ||
    children
  )
}

export const getFilterInput = (state) => state.searchPanel.filterInput || ''

export const getFilteredPees = (state) => {
  const peesIds = getPeesIds(state)
  return peesIds.filter(
    (peeId) =>
      getPeeIsOfFilterType(state, peeId) &&
      state.organisation.organisationEntities.pees[peeId].PeeClef.toLowerCase().indexOf(
        state.searchPanel.filterInput.toLowerCase(),
      ) > -1,
  )
}

export const getFilteredSites = (state) => {
  const sitesIds = getSitesIds(state)
  return sitesIds.filter(
    (siteId) =>
      getSiteHasPeeOfFilterType(state, siteId) &&
      state.organisation.organisationEntities.sites[siteId].SphNom.toLowerCase().indexOf(
        state.searchPanel.filterInput.toLowerCase(),
      ) > -1,
  )
}

export const getFilteredUors = (state) => {
  const uorsIds = getUorsIds(state)
  return uorsIds.filter(
    (uorId) =>
      getUorHasPeeOfFilterType(state, uorId) &&
      state.organisation.organisationEntities.uors[uorId].UorNom.toLowerCase().indexOf(
        state.searchPanel.filterInput.toLowerCase(),
      ) > -1,
  )
}

export const isUorOpen = (state, uorId) => {
  const isOpen = state.searchPanel.openUors[uorId]
  const isRoot = getRootUor(state) === uorId
  if (typeof isOpen === 'undefined') return isRoot
  return isOpen
}

// Action Creators
export const toggleFilterType = (filterType) => ({
  type: TOGGLE_FILTER_TYPE,
  filterType,
})
export const countryFilterChange = (countryFilter) => ({
  type: COUNTRY_FILTER_CHANGE,
  countryFilter,
})
export const filterInputChange = (filterInput) => ({
  type: FILTER_INPUT_CHANGE,
  filterInput,
})

export const toggleTagFilter = (id, nom) => ({ type: TOGGLE_TAG_FILTER, id, nom })

// Thunks
export const toggleUor = (uorId) => (dispatch, getState) => {
  const isRoot = getRootUor(getState()) === uorId

  dispatch({ type: TOGGLE_UOR, uorId, isRoot })
}

export const closeSearchPanel = () => (dispatch) => {
  dispatch({
    type: CLOSE_SEARCH_PANEL,
  })
  document.body.classList.remove('no-scroll')
}

export const openSearchPanel = () => (dispatch) => {
  dispatch({
    type: OPEN_SEARCH_PANEL,
  })
  document.body.classList.add('no-scroll')
}

export const toggleSearchPanel = (dispatch, getState) => {
  const state = getState()
  const open = getSearchPanelOpen(state)

  if (!open) {
    const valid = getOrganisationRequestValid(state)
    if (!valid) dispatch(fetchOrganisationSite())
  }

  dispatch({
    type: TOGGLE_SEARCH_PANEL,
  })

  document.body.classList.toggle('no-scroll')
}
