import moment from 'moment'

import { getColor } from 'helpers/colors'
import { decimalFormat } from 'helpers/formatters'
import { formatCSV } from 'helpers/csv'
import downloadFile from 'helpers/downloadFile'

const COLOR_INDEX = 0
const ROW_HEIGHT = 25

const createCSV = (data) => {
  if (!data || data.length === 0) return []
  const dates = data[0].graphMensuel.series[0].data.map(({ x }) => moment(x).format('MMMM YYYY'))

  const header = ['Site', ...dates]

  const body = data.map(({ nomEntite, graphMensuel }) => [
    nomEntite,
    ...graphMensuel.series[0].data.map(({ y }) => decimalFormat(y)),
  ])

  return formatCSV([header, ...body])
}

const createOptions = (graph, startDate, endDate, uor) => {
  if (!graph || !graph.graphMensuel || graph.graphMensuel.length === 0)
    return { title: { text: null } }

  const slice = graph.graphMensuel

  const categories = slice.map((entite) => entite.nomEntite)

  const monthCount = moment(endDate, 'YYYY-MM-DD').diff(
    moment(startDate, 'YYYY-MM-DD'),
    'months',
    true,
  )
  const labelFormat =
    monthCount < 16 ? 'MMM YY' : monthCount < 20 ? 'MM/YY' : monthCount < 28 ? 'MM' : ' '

  const series = slice.map((entity, index) => ({
    data: entity.graphMensuel.series[0].data.reduce((acc, { x, y }) => {
      if (
        acc.length > 0 &&
        ((acc[acc.length - 1].value < 0.05 && y < 0.05) ||
          (acc[acc.length - 1].value > 0.95 && y > 0.95) ||
          (acc[acc.length - 1].value > 0.05 &&
            acc[acc.length - 1].value < 0.95 &&
            y > 0.05 &&
            y < 0.95))
      ) {
        const x2 = acc[acc.length - 1].x2
        acc[acc.length - 1].x2 = Number(
          moment(x2).add(1, 'M').endOf('month').subtract(3, 'd').format('x'),
        )
        acc[acc.length - 1].value = (acc[acc.length - 1].value * acc.length + y) / (acc.length + 1)
      } else {
        acc.push({
          x,
          x2: Number(moment(x).endOf('month').subtract(3, 'd').format('x')),
          y: index,
          value: y,
          color:
            y < 0.05
              ? '#FFF'
              : y > 0.95
              ? getColor(COLOR_INDEX, 0.7, { uor })
              : getColor(COLOR_INDEX, 0.3, { uor }),
          borderColor: y < 0.05 ? '#FFF' : getColor(COLOR_INDEX, 1, { uor }),
        })
      }
      return acc
    }, []),
  }))

  const height = slice.length * (ROW_HEIGHT + 4)

  return {
    chart: {
      type: 'xrange',
      ...(height < 400
        ? { height: height + 50 }
        : {
            scrollablePlotArea: {
              minHeight: height,
              opacity: 1,
            },
          }),
    },
    title: {
      text: null,
    },
    xAxis: {
      type: 'datetime',
      min: Number(moment(startDate, 'YYYY-MM-DD').format('x')),
      max: Number(moment(endDate, 'YYYY-MM-DD').endOf('month').format('x')),
      allowDecimals: false,
      tickInterval: 24 * 3600 * 1000 * 30,
      labels: {
        align: 'left',
        x: 10,
        rotation: 0,
        formatter: function () {
          return moment(this.value).format(labelFormat)
        },
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
    },
    yAxis: {
      title: {
        text: '',
      },
      categories,
      reversed: true,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      label: {
        step: 1,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      formatter: function () {
        const date1 = moment(this.point.x).format('MMM YYYY')
        const date2 = moment(this.point.x2).format('MMM YYYY')
        const period = date1 === date2 ? date1 : `${date1} - ${date2}`
        return `${period} : <b>${Math.floor(this.point.value * 100) / 100}</b>`
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
      xrange: {
        borderRadius: 0,
        borderWidth: 1,
        pointWidth: 20,
        dataLabels: {
          enabled: false,
        },
      },
    },
    series,
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            {
              textKey: 'downloadCSV',
              onclick: function () {
                downloadFile('chart.csv', createCSV(slice), {
                  type: 'text/csv',
                })
              },
            },
          ],
        },
      },
    },
  }
}

export default createOptions
