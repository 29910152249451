import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

export const ErrorFallback = ({ resetErrorBoundary }) => {
  const { t } = useTranslation()

  return (
    <div role="alert" style={{ maxWidth: '24rem' }}>
      <div className="text-danger mb-2">{t('global.error')}</div>

      <Button color="primary" size="sm" onClick={resetErrorBoundary}>
        {t('global.tryAgain')}
      </Button>
    </div>
  )
}
