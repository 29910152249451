import * as env from '../helpers/env'

let token = null
export const setToken = (newToken) => (token = newToken)
let userPlatform = 'MUT'
export const setPlatform = (newPlatform) => (userPlatform = newPlatform)

/**
 * Wrapper d'appel HTTP pour gérer le cas de la déconnexion en 401 lors de l'expiration du cookie de refresh.
 * Le content-type est 'application/json'
 * Pour l'envoie de cookie le header 'credentials' est à 'include',
 *
 * @param {string} url Chemin relatif de l'API. Cf. <code>apiUrl</code> - Mandatory
 * @param {string} method Chemin relatif de l'API. Cf. <code>apiUrl</code> - Optional - Default 'GET'
 * @param {object} body Objet à serialiser dans le body - Optional - Default null
 * @param {string} uuid ID unique de la request - Optional - Default null
 * @param {string} contentType content type de la request - Optional - Default 'application/json'
 * @param {boolean} stringify Objet à serialiser dans le body - Optional - Default false
 * @param {string} api
 */
export const superFetch = async ({
  url,
  method = 'GET',
  body = null,
  uuid = null,
  contentType = 'application/json',
  stringify = true,
  platform,
  api,
  abortable = false,
}) => {
  try {
    if (!url) throw new Error('Url est obligatoire pour le fetch wrapper')

    const controller =
      typeof window.AbortController === 'undefined' ? null : new window.AbortController()

    let headers = new Headers()
    if (contentType) headers.append('Content-Type', contentType)

    if (uuid) headers.append('X-REQUEST-ID', uuid)

    if (token) headers.append('Authorization', `Bearer ${token}`)

    let request = null

    if (!platform) {
      platform = userPlatform
    }

    const baseUrl = api === 'invoice' ? env.apiUrlInvoice : env.apiUrl(platform)

    switch (method) {
      case 'POST':
      case 'PATCH':
      case 'PUT': {
        const options = {
          credentials: 'include',
          method: method,
          body: stringify === true ? JSON.stringify(body) : body,
          headers,
        }
        if (controller !== null) {
          options.signal = controller.signal
        }
        request = fetch(`${baseUrl}${url}`, options)
        break
      }
      default: {
        const options = {
          credentials: 'include',
          method: method,
          headers,
        }
        if (controller !== null) {
          options.signal = controller.signal
        }
        request = fetch(`${baseUrl}${url}`, options)
      }
    }

    return !abortable || controller === null
      ? request
      : {
          request,
          abort: controller.abort.bind(controller),
        }
  } catch (e) {
    throw e
  }
}
