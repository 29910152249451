import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'

import { getFilterInput, countryFilterChange, filterInputChange } from 'store/searchPanel'
import { fetchCountries, getCountries } from 'store/countries'
import { TagFilters } from './TagFilters'

const Filtres = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const filterInput = useSelector(getFilterInput)
  const countries = useSelector(getCountries)

  useEffect(() => {
    dispatch(fetchCountries)
  }, [dispatch])

  return (
    <div>
      <Row>
        <Col>
          <FormGroup className="text-input">
            <Input
              className={filterInput === '' ? '' : 'has-value'}
              id="searchFilterText"
              onChange={(e) => dispatch(filterInputChange(e.target.value))}
            />
            <Label for="searchFilterText">{t('panel.placeholder')}</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <Label id="country-label" for="country-select">
              {t('panel.country')}
            </Label>

            <Input
              type="select"
              name="select"
              id="country-select"
              onChange={(e) =>
                dispatch(
                  countryFilterChange(
                    e.target.value === 'all' ? e.target.value : parseInt(e.target.value, 10),
                  ),
                )
              }
            >
              <option value="all">{t('panel.noCountrySelection')}</option>

              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.libelle}
                </option>
              ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>

      <TagFilters />
    </div>
  )
}

export default Filtres
