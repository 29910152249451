import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchCountries: () =>
    superFetch({
      url: 'pays',
      uuid: v4(),
    }),
}

// Actions
const FETCH_COUNTRY_LIST_REQUEST = 'esite/countries/FETCH_COUNTRY_LIST_REQUEST'
const FETCH_COUNTRY_LIST_SUCCESS = 'esite/countries/FETCH_COUNTRY_LIST_SUCCESS'
const FETCH_COUNTRY_LIST_FAILURE = 'esite/countries/FETCH_COUNTRY_LIST_FAILURE'

// Reducer
export default (state = [], action) => {
  switch (action.type) {
    case FETCH_COUNTRY_LIST_REQUEST:
      return []
    case FETCH_COUNTRY_LIST_SUCCESS:
      return action.countries
    default:
      return state
  }
}

// Thunks
export const fetchCountries = (dispatch) => {
  dispatch({
    type: FETCH_COUNTRY_LIST_REQUEST,
  })

  return api.fetchCountries().then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_COUNTRY_LIST_SUCCESS,
            countries: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_COUNTRY_LIST_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_COUNTRY_LIST_FAILURE,
        message: error.Message || 'Something went wrong.',
      })
    },
  )
}

// Selectors
export const getCountries = (state) => state.countries
