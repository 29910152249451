import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'
import sum from 'lodash/sum'

import { usePuissancesMensuel } from './useGestion'
import { noDecimalFormat } from 'helpers/formatters'
import { CustomTable } from './CustomTable'
import { CSVButton } from './CSVButton'

const itemProps = [
  'PuissancePointe',
  'PuissanceHP',
  'PuissanceHC',
  'ConsoPointe',
  'ConsoHP',
  'ConsoHC',
]

const columns = ['PTE', 'HP', 'HC', 'PTE', 'HP', 'HC', 'Total']

export const TablePuissances = () => {
  const { t } = useTranslation()
  const data = usePuissancesMensuel()

  const rows = React.useMemo(() => {
    const mainRows = data.map((item) => [
      upperFirst(moment(item.Date).format('MMM YY')),
      ...itemProps.map((prop) => noDecimalFormat(item[prop])),
      noDecimalFormat(
        item.ConsoPointe + item.ConsoHP + item.ConsoHC ||
          0 + item.ConsoHP + item.ConsoHC ||
          0 + item.ConsoHC ||
          0,
      ),
    ])

    const totals = itemProps.map((prop) => {
      const values = data.map((item) => item[prop])
      return prop.startsWith('Puissance') ? Math.max(...values) : sum(values)
    })

    const totalRow = [
      'Total',
      ...totals.map((value) => noDecimalFormat(value)),
      noDecimalFormat(sum(totals.slice(3, 6))),
    ]

    return [...mainRows, totalRow]
  }, [data])

  const getCsvData = React.useCallback(
    () => [['', ...columns], ...rows.map((row) => [`"=""${row[0]}"""`, ...row.slice(1)])],
    [rows],
  )

  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            <th rowSpan="2">&nbsp;</th>
            <th colSpan="3">{t('gestion.pMax')}</th>
            <th colSpan="4">{t('gestion.consoActive')}</th>
          </tr>

          <tr>
            {columns.map((c, i) => (
              <th key={i}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((item, j) => (
            <tr key={j}>
              <th style={{ width: 100 }}>{item[0]}</th>

              {item.slice(1).map((value, i) => (
                <td key={i}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </CustomTable>

      <CSVButton filename={`ESITE - ${t('gestion.pMensuel')}.csv`} getData={getCsvData} />
    </>
  )
}
