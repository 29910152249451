import React from 'react'
import { withRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Site from '../site/Site'
import GlobalConsumption from '../consumption/GlobalConsumption'
import DetailedConsumption from '../consumption/DetailedConsumption'
import ActionPlan from '../actionPlan/Alerts'
import Performance from '../performance/Performance'
import { Gestion } from '../gestion/Gestion'

import Admin from '../admin/Admin'
import { isAdmin } from '../../store/admin'

const Views = (props) => {
  return (
    <Switch>
      <Route path="/site/:id" component={Site} />
      <Route path="/site" component={Site} />
      <Route path="/global-consumption" component={GlobalConsumption} />
      <Route path="/detailed-consumption" component={DetailedConsumption} />
      <Route path="/action-plan" component={ActionPlan} />
      <Route path="/performance" component={Performance} />
      <Route path="/gestion" component={Gestion} />

      {props.isAdmin && <Route path="/admin/" component={Admin} />}
    </Switch>
  )
}

const mapState = (state) => ({
  isAdmin: isAdmin(state),
})

export default compose(withRouter, connect(mapState))(Views)
