import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { withRouter, Route, Switch, Redirect } from 'react-router-dom'

import * as env from 'helpers/env'
import { customerTheme } from 'helpers/themes'
import Template from 'components/template/Template'
import { Logout, Callback, AccessDenied, SilentRedirectPage, userManager, Version } from 'commons'

const Root = ({ location }) => {
  const isLoadingUser = useSelector((state) => state.oidc.isLoadingUser)
  const user = useSelector((state) => state.oidc.user)
  const theme = useSelector(customerTheme)
  const [isRedirecting, setIsRedirecting] = useState(false)

  useEffect(() => {
    if (
      location.pathname &&
      !location.pathname.startsWith('/callback') &&
      !location.pathname.startsWith('/silent_renew')
    ) {
      if ((!user || (!user && user.expired === true)) && isLoadingUser === false) {
        if (isRedirecting === false) {
          setIsRedirecting(true)
          userManager.signinRedirect()
        }
      }
    }
  }, [isLoadingUser, isRedirecting, location.pathname, user])

  const isAllowed = () => {
    if (user && user.profile && user.profile.role) {
      if (Array.isArray(user.profile.role)) {
        return user.profile.role.some((role) => env.requiredRoles.includes(role))
      } else {
        return env.requiredRoles.includes(user.profile.role)
      }
    }
    return false
  }

  return (
    <div className={theme}>
      {!!(user && !user.expired) ? (
        isAllowed() ? (
          <Switch>
            <Route exact path="/silent_renew" component={SilentRedirectPage} />
            <Route exact path="/" render={() => <Redirect to="/site" push />} />
            <Route path="/logout" component={Logout} />
            <Route path="/callback" component={Callback} />
            <Route path="/:path" component={Template} />
          </Switch>
        ) : (
          <Switch>
            <Route path="/" component={AccessDenied} />
          </Switch>
        )
      ) : (
        <Switch>
          <Route exact path="/silent_renew" component={SilentRedirectPage} />
          <Route path="/" component={Callback} />
        </Switch>
      )}

      {location.pathname !== '/site' && <Version />}
    </div>
  )
}

export default withRouter(Root)
