import React from 'react'
import { Button } from 'reactstrap'
import { IoMdDownload as ExportIcon } from 'react-icons/io'

import downloadFile from 'helpers/downloadFile'

export const CSVButton = ({ filename = 'export.csv', getData = () => [], ...props }) => {
  const handleClick = React.useCallback(() => {
    const formatted = getData()
      .map((line) => line.map((cell) => `${cell}`).join(';'))
      .join('\n')
    downloadFile(filename, formatted, { type: 'text/csv' })
  }, [filename, getData])

  return (
    <Button
      size="sm"
      color="primary"
      onClick={handleClick}
      css={{ position: 'absolute', top: '1.5rem', right: '1.5rem', fontWeight: 'bold' }}
      {...props}
    >
      <ExportIcon size="1.1em" className="mr-1" />
      <span>CSV</span>
    </Button>
  )
}
