import React from 'react'
import { Spinner } from 'reactstrap'

import styles from './Loader.module.scss'

const Loader = ({ size = 'normal', style }) => (
  <div className={styles.container} style={style}>
    <div className="-loading -active">
      <div className="-loading-inner">
        <Spinner
          style={{
            width: size === 'sm' ? '20px' : '30px',
            height: size === 'sm' ? '20px' : '30px',
            borderWidth: size === 'sm' ? '3px' : '5px',
            color: '#926A14CC',
          }}
        />
      </div>
    </div>
  </div>
)

const withLoader = (BaseComponent) => (props) =>
  props.isLoading ? <Loader /> : <BaseComponent {...props} />

export { Loader, withLoader }
