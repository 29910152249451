import React from 'react'
import { connect } from 'react-redux'
import { FormGroup, Label } from 'reactstrap'
import Highlighter from 'react-highlight-words'

import { getUor } from '../../store/organisation'
import {
  getUorHasSiteOfFilterType,
  getFilterInput,
  getFilterType,
  getCountryFilter,
  toggleUor,
  isUorOpen,
} from '../../store/searchPanel'
import {
  toggleUorInSelection,
  getIsUorSelected,
  getIsUorIndeterminate,
} from '../../store/currentSelection'
import Site from './Site'

const Uor = ({
  uorId,
  uor,
  isUorSelected,
  isUorIndeterminate,
  toggleUorInSelection,
  dispatch,
  uorHasSiteOfFilterType,
  filterInput,
  level = 0,
  isOpen,
  tagFilters,
}) => {
  const shouldDisplayChildren = isOpen && !filterInput && uor

  /**
   * This method will display the children of a root node.
   * Based on the type of the child node, a different renderer
   * method will be provided.
   */
  function displayChildren(children, rederer) {
    return (
      shouldDisplayChildren &&
      children &&
      children.length && <ul>{children.map((id) => rederer(id))}</ul>
    )
  }

  const filterTagNames = tagFilters.map((tag) => tag.nom)
  const uorTagNames = uor.Tags?.split('|') ?? []
  const shouldDisplay =
    filterTagNames.length === 0 || uorTagNames.some((s) => filterTagNames.includes(s))
  if (!shouldDisplay) return null

  return (
    <span>
      {uorHasSiteOfFilterType ? (
        <li>
          <FormGroup check>
            <Label
              check
              className={
                isUorIndeterminate ? 'indeterminate' : isUorSelected ? 'checked' : undefined
              }
            >
              <input
                className="form-check-input"
                type="checkbox"
                value={uorId}
                checked={isUorSelected}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = isUorIndeterminate
                  }
                }}
                onChange={(e) => dispatch(toggleUorInSelection)}
              />
              <span className="left-pad">
                {filterInput ? (
                  <Highlighter
                    highlightClassName="bg-primary"
                    searchWords={[filterInput]}
                    textToHighlight={uor.UorNom}
                  />
                ) : (
                  <button
                    className="reset"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      dispatch(toggleUor(uorId))
                    }}
                  >
                    <i className={isOpen ? 'icon-smallarrow-down' : 'icon-smallarrow-right'} />
                    {uor.UorNom}
                  </button>
                )}
              </span>
            </Label>
          </FormGroup>

          {displayChildren(uor.UorEnfants, (id) => (
            <UorWrapper key={id} uorId={id} level={level + 1} tagFilters={tagFilters} />
          ))}
          {displayChildren(uor.UorSites, (id) => (
            <Site key={id} siteId={id} level={level + 1} opened={false} tagFilters={tagFilters} />
          ))}
        </li>
      ) : null}
    </span>
  )
}

const mapState = (state, { uorId }) => ({
  uor: getUor(state, uorId),
  isUorSelected: getIsUorSelected(state, uorId),
  isUorIndeterminate: getIsUorIndeterminate(state, uorId),
  toggleUorInSelection: toggleUorInSelection(
    state,
    uorId,
    getFilterType(state),
    getCountryFilter(state),
  ),
  uorHasSiteOfFilterType: getUorHasSiteOfFilterType(state, uorId),
  filterInput: getFilterInput(state),
  isOpen: isUorOpen(state, uorId),
})

const UorWrapper = connect(mapState)(Uor)
export default UorWrapper
