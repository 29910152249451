import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import get from 'lodash/get'

import { request } from 'helpers/request'
import { getPeeId } from 'store/gestion'

export const useAcheminement = () => {
  const peeId = useSelector(getPeeId)

  const { data } = useQuery({
    queryKey: ['acheminement', { peeId }],
    queryFn: () => request({ url: `listeacheminement`, method: 'POST', body: peeId }),
  })

  return get(data, '0.AchListeFta', [])
}
