import { createStore, applyMiddleware, compose } from 'redux'
import { loadUser } from 'redux-oidc'
import thunk from 'redux-thunk'
import { userManager, accessTokenMiddleware } from 'commons'
import ReactGA from 'react-ga'
import get from 'lodash/get'

import rootReducer from './store'
import { persist } from 'persist'

const getName = (state) => get(state, 'oidc.user.profile.name', null)
const getUor = (state) => get(state, 'oidc.user.profile.uor', null)

const configureStore = () => {
  const middleware = [thunk, accessTokenMiddleware]

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

  persist(store)
  loadUser(store, userManager)

  let prevUserId = null
  store.subscribe(() => {
    const state = store.getState()
    const name = getName(state)
    const uor = getUor(state)
    const isCallback = window.location.pathname === '/callback'

    // Set Google Analytics userId when available
    const userId = !name || !uor ? null : `${name}_${uor}`
    if (!prevUserId && prevUserId !== userId && !isCallback) {
      prevUserId = userId
      ReactGA.set({ userId })
    }
  })

  return store
}

export default configureStore
