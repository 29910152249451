import React from 'react'
import { Spinner } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import { Highcharts, Highstock } from 'helpers/highcharts'
import Error from 'components/common/Error'
import styles from './Chart.module.scss'

const LoadingLayer = () => (
  <div className={styles.loadingLayer}>
    <Spinner className={styles.spinner} />
  </div>
)

const Chart = ({
  id,
  minHeight = 400,
  error = null,
  isLoading = false,
  isEmpty = false,
  highstock = false,
  ...props
}) => {
  const { options, ...hcProps } = props

  const { t } = useTranslation()

  const hcOptions = {
    time: {
      useUTC: true,
      getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
    },
    boost: {
      enabled: true,
    },
    ...options,
    exporting: {
      ...options?.exporting,
      width: 2000,
    },
  }

  if (error) return <Error />
  if (isEmpty && !isLoading) return t('global.noData')

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!isEmpty && (
        <HighchartsReact
          highcharts={highstock ? Highstock : Highcharts}
          constructorType={highstock ? 'stockChart' : 'chart'}
          options={hcOptions}
          {...hcProps}
        />
      )}
      {isLoading && <LoadingLayer />}
    </div>
  )
}

export default Chart
