import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Input } from 'reactstrap'

import styles from './DateRange.module.scss'
import { getAvailableNrj } from 'store/organisation'
import { getEnergy, updateEnergy } from 'store/dateRange'

export const NrjSelect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const value = useSelector(getEnergy)
  const available = useSelector(getAvailableNrj)
  if (available.length < 2) return null

  return (
    <div className="dt-label">
      <span>{t('consumption.daterange.energy')}</span>
      <div className={styles.radio}>
        {available.includes(1) && (
          <label className={classnames({ [styles.checked]: value === '1' })}>
            <Input type="checkbox" onChange={() => dispatch(updateEnergy('1'))} />
            <span>{t('consumption.daterange.gas')}</span>
          </label>
        )}

        {available.includes(2) && (
          <label className={classnames({ [styles.checked]: value === '2' })}>
            <Input type="checkbox" onChange={() => dispatch(updateEnergy('2'))} />
            <span>{t('consumption.daterange.electricity')}</span>
          </label>
        )}

        {available.includes(8) && (
          <label className={classnames({ [styles.checked]: value === '8' })}>
            <Input type="checkbox" onChange={() => dispatch(updateEnergy('8'))} />
            <span>
              <i className="icon-tint" />
              {t('consumption.daterange.water')}
            </span>
          </label>
        )}
      </div>
    </div>
  )
}
