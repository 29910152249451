import { getUor } from 'store'

import logoVinci from 'assets/images/logo_vinci.svg'
import logoStef from 'assets/images/logo_stef.png'
import logoPomona from 'assets/images/logo_pomona.png'

export const mainLogo = (state) => {
  switch (getUor(state)) {
    case '735':
      return logoVinci
    case '620':
      return logoStef
    case '1254':
      return logoPomona
    default:
      return null
  }
}
