import React from 'react'
import { ReactComponent as Logo } from './eleneo.svg'

export const LogoEleneo = ({ colorful = false, color, fill, ...props }) => {
  return (
    <span {...props}>
      <Logo
        style={{
          display: 'inline',
          verticalAlign: 'baseline',
          color: color ? color : colorful ? '#212159' : 'currentColor',
          fill: fill ? fill : colorful ? '#946c14' : 'currentColor',
          height: '1em',
          width: `calc(1em * 3.6)`,
        }}
      />
    </span>
  )
}
