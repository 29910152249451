import moment from 'moment'
import uniq from 'lodash/uniq'

import { decimalFormat, shortDateFormat } from 'helpers/formatters'
import { formatCSV } from 'helpers/csv'
import downloadFile from 'helpers/downloadFile'

const createCSV = (data, hours, days) => {
  const header = ['', ...hours]

  const body = data.reduce(
    (acc, [h, d, v]) => {
      acc[d][h + 1] = v === null ? '' : decimalFormat(v)
      return acc
    },
    days.map((d) => [d]),
  )

  return formatCSV([header, ...body])
}

const createOptions = (graph, unite) => {
  if (!graph || graph.length === 0) return { title: { text: null } }

  const series = graph[0].heatMap

  const daysIndexes = uniq(
    series.map(({ x }) => moment(x).startOf('day').format(shortDateFormat())),
  ).reduce((acc, x, i) => ({ ...acc, [x]: i }), {})

  const days = Object.keys(daysIndexes)

  const hours = [...Array(24).keys()].map((x) => `${x}h-${x + 1}h`)

  const data = series.map(({ x, value }) => [
    daysIndexes[moment(x).startOf('day').format(shortDateFormat())],
    Number(moment(x).format('HH')),
    value,
  ])

  const values = data.map((p) => p[2]).filter((x) => x !== null)
  const min = Math.min(...values)
  const max = Math.max(...values)

  const isShortPeriod = data.length < 62 * 24
  const isLongPeriod = data.length > 365 * 24

  return {
    chart: {
      type: 'heatmap',
      plotBorderWidth: 0,
      plotBorderColor: '#ffffff00',
      height: 600,
    },
    boost: {
      useGPUTranslations: true,
    },
    title: {
      text: null,
    },
    yAxis: {
      categories: hours,
      title: null,
      gridLineWidth: 0,
      gridLineColor: '#ffffff00',
      labels: {
        step: 1,
      },
    },
    xAxis: {
      categories: days,
      title: null,
      gridLineWidth: 0,
      gridLineColor: '#ffffff00',
      labels: {
        step: isShortPeriod ? 2 : 1,
        rotation: isShortPeriod || isLongPeriod ? -45 : 0,
        formatter: function () {
          const isFirstDay = String(this.value).startsWith('01/')
          return isShortPeriod || isFirstDay ? this.value : ''
        },
      },
    },
    colorAxis: {
      stops: [
        [0, '#515BB3'],
        [0.5, '#FFD223'],
        [0.9, '#B9525A'],
      ],
      min,
      max,
    },
    legend: {
      align: 'center',
      layout: 'horizontal',
    },
    tooltip: {
      formatter: function () {
        const { value } = this.point
        if (value === null) return false
        return `${decimalFormat(value)} ${unite}`
      },
    },
    series: [
      {
        name: '',
        borderWidth: 0,
        gridLineColor: '#ffffff00',
        data,
        turboThreshold: 0,
        colorByPoint: true,
      },
    ],
    exporting: {
      enabled: true,
      sourceWidth: 800,
      sourceHeight: days.length * 16,
      buttons: {
        contextButton: {
          menuItems: [
            {
              textKey: 'downloadJPEG',
              onclick: function () {
                this.exportChart({ type: 'image/jpeg' })
              },
            },
            {
              textKey: 'downloadPDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' })
              },
            },
            {
              textKey: 'downloadCSV',
              onclick: function () {
                downloadFile('heatmap.csv', createCSV(data, hours, days, unite), {
                  type: 'text/csv',
                })
              },
            },
          ],
        },
      },
    },
  }
}

export default createOptions
