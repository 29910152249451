import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { compose, lifecycle, withHandlers } from 'recompose'

import { getCurrentSitesIds } from 'store/currentSelection'
import { getLongStartDate, getLongEndDate, getEnergy } from 'store/dateRange'
import { fetchQualiteConsoGraph } from 'store/consumption'
import QualiteConsoGraph from './QualiteConsoGraph'

const QualiteConso = ({ title, startDate, endDate }) => (
  <Container className="box">
    <Row>
      <Col>
        <h3>{title}</h3>
      </Col>
    </Row>
    <Row>
      <Col className="graph-container">
        <QualiteConsoGraph startDate={startDate} endDate={endDate} />
      </Col>
    </Row>
  </Container>
)

const mapStateToProps = (state) => ({
  siteIds: getCurrentSitesIds(state),
  startDate: getLongStartDate(state),
  endDate: getLongEndDate(state),
  energy: getEnergy(state),
})

const mapDispatchToProps = {
  fetchQualiteConsoGraph,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    fetchGraph: (props) => () => {
      const payload = {
        debut: props.startDate,
        fin: props.endDate,
        ids: props.siteIds,
        typeIds: props.agregation === '0' ? 'PEE' : 'SIT',
        nrjs: [props.energy],
        typesDonnees: ['Mesure'],
        unite: 11,
        agregation: '1', // 0 = Compteur
        pas: '5',
      }
      props.fetchQualiteConsoGraph(payload)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        this.props.fetchGraph()
      }
    },
  }),
)(QualiteConso)

export { QualiteConso }
