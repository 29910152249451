import React, { Suspense } from 'react'

import { ErrorBoundary } from 'commons'
import { Loader } from 'components/common/Loader'

export const Section = ({ title, children, ...props }) => {
  return (
    <div {...props}>
      <section className="shadow-sm bg-white p-4 h-100 position-relative">
        {title && <h3 className="mb-4">{title}</h3>}

        <ErrorBoundary>
          <Suspense fallback={<Loader style={{ height: 150 }} />}>{children}</Suspense>
        </ErrorBoundary>
      </section>
    </div>
  )
}
