import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getPeeId } from 'store/gestion'
import { Section } from './Section'
import { Options } from './Options'
import { PeeFta } from './PeeFta'
import { TableTotal } from './TableTotal'
import { TablePuissances } from './TablePuissances'
import { TableDepenses } from './TableDepenses'

export const Gestion = () => {
  const { t } = useTranslation()
  const peeId = useSelector(getPeeId)

  return (
    <div
      css={{
        '.options': {
          position: 'sticky',
          top: 76,
          zIndex: 20,
          margin: '0 -15px',
          padding: 30,
        },
        '.sections1': {
          display: 'grid',
          gridGap: 30,
          gridTemplateColumns: '1fr 1fr',
          gridTemplateAreas: `
            'fta total'
          `,
        },
        '.sections2': {
          display: 'grid',
          gridGap: 30,
          gridTemplateColumns: '2.1fr 1fr',
          gridTemplateAreas: `
            'puissances depenses'
          `,
        },
      }}
    >
      <div className="options shadow-sm bg-white">
        <Options />
      </div>

      {peeId !== null && (
        <>
          <div className="sections1 px-3 py-4">
            <Section css={{ gridArea: 'fta' }} title={t('gestion.fta')}>
              <PeeFta />
            </Section>

            <Section css={{ gridArea: 'total' }} title={t('gestion.pTotal')}>
              <TableTotal />
            </Section>
          </div>

          <div className="sections2 px-3 pb-4">
            <Section css={{ gridArea: 'puissances' }} title={t('gestion.pMensuel')}>
              <TablePuissances />

              <div css={{ marginTop: '2rem' }}>
                <Link to="/detailed-consumption">{t('topNav.detailedConsumption')}</Link>
              </div>
            </Section>

            <Section css={{ gridArea: 'depenses' }} title={t('gestion.depenses')}>
              <TableDepenses />
            </Section>
          </div>
        </>
      )}
    </div>
  )
}
