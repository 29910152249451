import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withHandlers, lifecycle, withState } from 'recompose'
import moment from 'moment'

import { dateFormat } from 'helpers/formatters'

import { fetchSignatureGraph, getSignatureIsFetching } from 'store/performance'
import {
  getCurrentSitesIds,
  getCurrentSitesByUor,
  getFilteredPeesIds,
  getFilteredPeesBySite,
} from 'store/currentSelection'

import { getVue, getSite, getPee, getShortEndDate, getEnergy } from 'store/dateRange'

import InputDatepicker from 'components/common/InputDatepicker'

import styles from './EnergySignature.module.scss'

const Date = ({ isLoading, date, setDate, title }) => {
  return (
    <div className={styles.container} style={{ width: 110 }}>
      <div className={styles.title}>{title}</div>
      <InputDatepicker
        inputProps={{ icon: false, disabled: isLoading }}
        value={moment(date, 'YYYY-MM-DD').format(dateFormat())}
        onChange={(date) => setDate(date.format('YYYY-MM-DD'))}
      />
    </div>
  )
}

const EnergySignatureOptions = ({
  isLoading,
  customStart,
  customEnd,
  setCustomStart,
  setCustomEnd,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Date
        title={t('consumption.daterange.startDate')}
        isLoading={isLoading}
        date={customStart}
        setDate={setCustomStart}
      />

      <Date
        title={t('consumption.daterange.endDate')}
        isLoading={isLoading}
        date={customEnd}
        setDate={setCustomEnd}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const agregation = getVue(state)
  return {
    siteIds: getCurrentSitesIds(state),
    sitesByUor: getCurrentSitesByUor(state),
    peeIds: getFilteredPeesIds(state),
    peesBySite: getFilteredPeesBySite(state),
    endDate: getShortEndDate(state),
    energy: getEnergy(state),
    isLoading: getSignatureIsFetching(state),
    entity1: agregation === '0' ? getPee(state) : getSite(state),
    agregation,
  }
}

const mapDispatchToProps = {
  fetchSignatureGraph,
}

const createPayload = (props) => ({
  debut: props.customStart,
  fin: props.customEnd,
  ids: !!props.entity1 ? [props.entity1] : [],
  typeIds: props.agregation === '0' ? 'PEE' : 'SIT',
  nrjs: [props.energy],
  unite: 2, // MWh
  agregation: props.agregation,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('customStart', 'setCustomStart', (props) =>
    moment(props.endDate, 'YYYY-MM-DD').subtract(1, 'years').format('YYYY-MM-DD'),
  ),
  withState('customEnd', 'setCustomEnd', (props) => props.endDate),
  withHandlers({
    fetchGraph: (props) => () => {
      const payload = createPayload(props)
      props.fetchSignatureGraph(payload)
    },
  }),
  lifecycle({
    componentDidMount() {
      if (createPayload(this.props).ids.length > 0) this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (
        JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props)) &&
        prevProps.agregation === this.props.agregation &&
        prevProps.energy === this.props.energy &&
        createPayload(this.props).ids.length > 0
      ) {
        this.props.fetchGraph()
      }
    },
  }),
)(EnergySignatureOptions)
