import React, { Suspense, useState, useEffect, useMemo } from 'react'
import { Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'

import { Loader } from 'components/common/Loader'
import { ErrorFallback } from 'components/common/ErrorFallback'
import { Tag } from 'components/common/Tag'
import { getSiteId } from 'store/site'
import { useTags, useSiteTags, useCreateSiteTag, useDeleteSiteTag } from 'queries/tags'
import { useNotifyError } from 'hooks/notify'

export const Tags = () => {
  const siteId = useSelector(getSiteId)
  if (siteId === null) return null
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loader style={{ height: 80 }} />}>
        <TagsForm siteId={siteId} />
      </Suspense>
    </ErrorBoundary>
  )
}

const TagsForm = ({ siteId }) => {
  const { t } = useTranslation()
  const notifyError = useNotifyError()
  const [tagId, setTagId] = useState(null)
  const { data: siteTags } = useSiteTags(siteId)
  const { data: allTags } = useTags()
  const { mutateAsync: createTag, status: createStatus } = useCreateSiteTag(siteId)
  const { mutateAsync: deleteTag, status: deleteStatus } = useDeleteSiteTag(siteId)

  const remainingTags = useMemo(() => {
    const siteTagsIds = siteTags.map((tag) => tag.Id)
    return allTags.filter((tag) => !siteTagsIds.includes(tag.Id))
  }, [allTags, siteTags])

  useEffect(() => {
    if (remainingTags.some((tag) => tag.Id === tagId)) return
    if (remainingTags.length > 0) setTagId(remainingTags[0].Id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remainingTags])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (tagId === null) return
      const tag = remainingTags.find((tag) => tag.Id === tagId)
      if (!tag) return
      createTag(tag)
    } catch (error) {
      console.error(error)
      notifyError()
    }
  }

  const handleDelete = async (id) => {
    try {
      deleteTag(id)
    } catch (error) {
      console.error(error)
      notifyError()
    }
  }

  const deleteDisabled = deleteStatus === 'loading' || createStatus === 'loading'
  const submitDisabled = deleteDisabled || tagId === null

  if (siteTags.length === 0 && remainingTags.length === 0) return null

  return (
    <Row>
      <Col>
        <h4>Tags</h4>

        {siteTags.length > 0 && (
          <div className="d-flex mt-4" style={{ fontSize: 20 }}>
            {siteTags.map((tag) => (
              <Tag
                key={tag.Id}
                className="mr-3"
                name={tag.Nom}
                disabled={true}
                deleteDisabled={deleteDisabled}
                onClose={() => handleDelete(tag.Id)}
              />
            ))}
          </div>
        )}

        {remainingTags.length > 0 && (
          <form onSubmit={handleSubmit} className="form-inline mt-4">
            <label>
              <span>{t('tags.addLabel')}</span>
              <select
                className="form-control form-control-sm mx-3"
                value={tagId ?? ''}
                onChange={(e) => setTagId(Number(e.target.value))}
              >
                {remainingTags.map((tag) => (
                  <option key={tag.Id} value={tag.Id}>
                    {tag.Nom}
                  </option>
                ))}
              </select>
            </label>

            <button type="submit" className="btn btn-primary btn-sm" disabled={submitDisabled}>
              <i className="icon-android-add mr-2" />
              <span>{t('tags.addButton')}</span>
            </button>
          </form>
        )}
      </Col>
    </Row>
  )
}
