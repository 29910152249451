import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import classnames from 'classnames'
import { getUor } from 'store'

import styles from './ProgressKpi.module.scss'
import { Loader } from '../../common/Loader'
import TopFlopCardKpi from '../TopFlopCardKpi'
import TopFlopTableKpi from '../TopFlopTableKpi'
import { decimalFormat, noDecimalFormat } from '../../../helpers/formatters'
import CSVButton from '../../common/CSVButton'
import Error from '../../common/Error'
import ProgressKpiGraph from './ProgressKpiGraph'
import PerformanceKPI from '../PerformanceKPI'

const ProgressContent = ({ data, error, name, kpi, uor }) => {
  const { t } = useTranslation()

  if (error) return <Error />
  if (!data || (data.Top.length === 0 && data.Flop.length === 0)) return t('global.noData')

  const csvData =
    error || !data || data.Top.length === 0
      ? []
      : [
          [
            t('performance.entite'),
            data.Top[0].LibelleValeur,
            data.Top[0].LibelleValeurKpi,
            data.Top[0].LibelleValeurPerf,
          ],
          ...data.Top.map((item) => [
            item.SitNom,
            noDecimalFormat(item.Valeur),
            noDecimalFormat(item.ValeurKpi),
            decimalFormat(item.ValeurPerf),
          ]),
          ...data.Flop.map((item) => [
            item.SitNom,
            noDecimalFormat(item.Valeur),
            noDecimalFormat(item.ValeurKpi),
            decimalFormat(item.ValeurPerf),
          ]).reverse(),
        ]

  const graphData =
    error || !data || data.Top.length === 0
      ? []
      : [
          ...data.Top.map((item) => ({
            x: item.ValeurKpi,
            y: item.Valeur,
            name: item.SitNom,
          })),
          ...data.Flop.map((item) => ({
            x: item.ValeurKpi,
            y: item.Valeur,
            name: item.SitNom,
          })).reverse(),
        ]

  const xUnit = data.Top[0].LibelleValeurKpi
  const yUnit = data.Top[0].LibelleValeur

  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-between align-items-end">
          <h3>{t('performance.top')}</h3>
          {!error && data && data.Top.length > 0 && (
            <CSVButton
              data={csvData}
              filename={`topflop-${data.Type}-${name}.csv`}
              size="md"
              color="primary"
              style={{ fontSize: 13, marginBottom: 20 }}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col style={{ maxWidth: '315px' }}>
          <TopFlopCardKpi type="top" data={data.Top[0]} />
        </Col>
        <Col>
          <TopFlopTableKpi type="top" data={data.Top.slice(1)} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h3>{t('performance.flop')}</h3>
        </Col>
      </Row>
      <Row className="mb-0">
        <Col style={{ maxWidth: '315px' }}>
          <TopFlopCardKpi type="flop" data={data.Flop[0]} />
        </Col>
        <Col>
          <TopFlopTableKpi type="flop" data={data.Flop.slice(1)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ProgressKpiGraph data={graphData} xUnit={xUnit} yUnit={yUnit} uor={uor} />
        </Col>
      </Row>
      <Row>
        <Col>
          <PerformanceKPI kpi={kpi} />
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => ({
  uor: getUor(state),
})

const ProgressKpi = (props) => (
  <Container className={classnames('box', styles.progress)}>
    {props.isLoading ? <Loader style={{ height: '300px' }} /> : <ProgressContent {...props} />}
  </Container>
)

export default connect(mapStateToProps)(ProgressKpi)
