import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'

const Security = ({ changePassword, logout }) => {
  const { t } = useTranslation()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

  return (
    <>
      <Row className="form-inline panel-header radio-input-btn">
        <Col className="d-flex">
          <div className="ml-auto">
            <Button color="danger" onClick={logout}>
              <i className="icon-power" />
              {t('user.logout')}
            </Button>
          </div>
        </Col>
      </Row>

      <Form
        className="user-infos user-security"
        onSubmit={(e) => {
          e.preventDefault()
          changePassword({
            Password: currentPassword,
            NewPassword: newPassword,
            NewPasswordConfirm: newPasswordConfirmation,
          })
        }}
      >
        <Row className="panel-flex">
          <Col>
            <FormGroup className="text-input">
              <Input
                id="currentPassword"
                value={currentPassword}
                type="password"
                onChange={(e) => setCurrentPassword(e.target.value)}
                className={currentPassword === '' ? '' : 'has-value'}
              />
              <Label for="currentPassword">{t('user.security.currentPassword')}</Label>
            </FormGroup>

            <FormGroup className="text-input">
              <Input
                id="newPassword"
                value={newPassword}
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                className={newPassword === '' ? '' : 'has-value'}
              />
              <Label for="newPassword">{t('user.security.newPassword')}</Label>
            </FormGroup>

            <FormGroup className="text-input">
              <Input
                id="newPasswordConfirm"
                value={newPasswordConfirmation}
                type="password"
                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                className={newPasswordConfirmation === '' ? '' : 'has-value'}
              />
              <Label for="newPasswordConfirm">{t('user.security.newPasswordConfirmation')}</Label>
            </FormGroup>
          </Col>
        </Row>

        <Row className="panel-footer">
          <Col className="d-flex">
            <div className="ml-auto">
              <div className="push-right">
                <Button
                  type="submit"
                  color="primary"
                  disabled={
                    currentPassword === '' || newPassword === '' || newPasswordConfirmation === ''
                  }
                >
                  {t('user.userInfo.save')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Security
