import React from 'react'

import styles from './Synthesis.module.scss'
import { decimalFormat } from '../../../helpers/formatters'

const SynthesisItem = ({ title, value1, value2, max, className }) => (
  <div className={styles.item}>
    <div className={styles.title}>{title}</div>

    <div
      className={`${styles.gauge} ${className}`}
      style={{
        width: max ? (value1 * 155) / max : value1,
      }}
    >
      {value1}
    </div>

    <div className={styles.value}>{decimalFormat(value2)} €</div>
  </div>
)

export default SynthesisItem
export { SynthesisItem }
