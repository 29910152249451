import React, { Suspense } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, FormGroup, Label } from 'reactstrap'
import { ErrorBoundary } from 'react-error-boundary'

import { Loader } from 'components/common/Loader'
import { ErrorFallback } from 'components/common/ErrorFallback'
import { Tag } from 'components/common/Tag'
import { useTags } from 'queries/tags'
import { getTagFilters, toggleTagFilter } from 'store/searchPanel'

export const TagFilters = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense
        fallback={
          <Container>
            <Loader style={{ height: 50 }} />
          </Container>
        }
      >
        <TagFiltersForm />
      </Suspense>
    </ErrorBoundary>
  )
}

const Container = ({ children }) => (
  <Row className="mt-4">
    <Col>{children}</Col>
  </Row>
)

const TagFiltersForm = () => {
  const dispatch = useDispatch()
  const tagFilters = useSelector(getTagFilters)

  const { data: tags } = useTags()
  if (tags.length === 0) return null

  return (
    <Container>
      <FormGroup>
        <Label>Tags</Label>
        <div className="d-flex flex-wrap mt-2" style={{ fontSize: 20 }}>
          {tags.map((tag) => (
            <Tag
              key={tag.Id}
              className="mr-2 mb-2"
              name={tag.Nom}
              disabled={false}
              active={tagFilters.some(({ id }) => id === tag.Id)}
              onClick={() => dispatch(toggleTagFilter(tag.Id, tag.Nom))}
            />
          ))}
        </div>
      </FormGroup>
    </Container>
  )
}
