import React from 'react'
import { connect } from 'react-redux'
import { compose, shouldUpdate, withProps, withState, withHandlers, lifecycle } from 'recompose'
import Isotope from 'isotope-layout'
import classnames from 'classnames'

import styles from './ActionCards.module.scss'
import ActionCard from '../ActionCard'
import { getActions, updateAction, getSort, getFilter } from '../../../store/alerts'
import { withLoader } from '../../common/Loader'
import ActionPopover from '../ActionPopover'

let iso = null

const Item = compose(
  connect(null, { updateAction }),
  withState('popoverOpen', 'setPopoverOpen', ''),
  withHandlers({
    togglePopover:
      ({ popoverOpen, setPopoverOpen }) =>
      (id) => {
        setPopoverOpen(popoverOpen === id ? '' : id)
      },
  }),
)(({ action, popoverOpen, togglePopover, updateAction }) => (
  <div
    className={classnames(styles.gridItem, `filter-status-${action.StatutId}`)}
    data-sort-nrj={action.CompteurNrj}
    data-sort-date={action.DateRemontee}
    data-sort-gain={action.Gain}
    data-sort-status={action.StatutId}
  >
    <ActionCard
      {...action}
      toggle={() => togglePopover(action.Id)}
      btnEditId={`card_popover_${action.Id}`}
      isPopoverOpen={popoverOpen === action.Id}
    />
    <ActionPopover
      placement="auto"
      action={action}
      target={`card_popover_${action.Id}`}
      isOpen={popoverOpen === action.Id}
      toggle={() => togglePopover(action.Id)}
      onSave={updateAction}
    />
  </div>
))

const ActionCards = ({ actions }) => (
  <div className={styles.grid}>
    {actions.map((action) => (
      <Item key={action.Id} action={action} />
    ))}
  </div>
)

const mapStateToProps = (state) => ({
  actions: getActions(state),
  sort: getSort(state),
  filter: getFilter(state),
})

export default compose(
  connect(mapStateToProps),
  shouldUpdate((props, nextProps) => {
    if (props.sort !== nextProps.sort) {
      const sortAscending = nextProps.sort > 0
      const sortBy = ['nrj', 'gain', 'status', 'type', 'date'][Math.abs(nextProps.sort) - 1]
      iso.arrange({ sortBy, sortAscending })
    }

    if (props.filter !== nextProps.filter) {
      iso.arrange({
        filter: `.filter-status-${nextProps.filter}`,
      })
    }
    return false
  }),
  withProps((props) => ({ isLoading: !props.actions })),
  lifecycle({
    componentDidMount() {
      iso = new Isotope(`.${styles.grid}`, {
        itemSelector: `.${styles.gridItem}`,
        layoutMode: 'masonry',
        sortBy: ['nrj', 'gain', 'status', 'type', 'date'][Math.abs(this.props.sort) - 1],
        sortAscending: this.props.sort > 0,
        getSortData: {
          nrj: '[data-sort-nrj]',
          gain: '[data-sort-gain]',
          status: '[data-sort-status]',
          type: '[data-sort-type]',
          date: '[data-sort-date]',
        },
        filter: '*',
      })
    },
  }),
  withLoader,
)(ActionCards)

export { ActionCards }
