import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import { compose, lifecycle, withHandlers, withProps } from 'recompose'
import classnames from 'classnames'
import throttle from 'lodash/throttle'

import { getGlobalActiveTab, setGlobalActiveTab } from 'store/consumption'
import { getEnergy } from 'store/dateRange'

import styles from './GlobalConsumption.module.scss'
import DateRange from '../../common/DateRange'
import MonthlyConsumption from '../MonthlyConsumption'
import PosteConsumption from '../PosteConsumption'
import QualiteConso from '../QualiteConso'
import { AggregatedLoadCurve as LoadCurve } from '../LoadCurve'
import { getToFocus, focus } from 'store/currentSelection'
import withEmpty from 'components/common/Empty'

let isUserScroll = true

const scrollToRef = (ref) => {
  isUserScroll = false

  window.scrollTo({
    top:
      ref && ref.current
        ? ref.current.getBoundingClientRect().top + window.scrollY - 120 - 50 - 76
        : 76,
    behavior: 'smooth',
  })

  setTimeout(() => {
    isUserScroll = true
  }, 1000)
}

const NavBar = ({ activeTab, toggleTab, tabs }) => (
  <Nav className="sub-nav-tab">
    {tabs.map(({ title, ref }, index) => (
      <NavItem key={index}>
        <NavLink
          className={classnames({
            active: activeTab === index,
          })}
          onClick={() => {
            if (toggleTab) toggleTab(index)
            scrollToRef(ref)
          }}
        >
          <span>{title}</span>
        </NavLink>
      </NavItem>
    ))}
  </Nav>
)

const GlobalConsumption = ({ tabs2, activeTab2, setGlobalActiveTab, toFocus, focus }) => {
  React.useEffect(() => {
    if (toFocus !== null) {
      focus(toFocus)
    }
  }, [toFocus, focus])

  if (toFocus !== null) return null

  return (
    <>
      <Row className={styles.header2}>
        <Col>
          <Row className={styles.periodSelector}>
            <Col className="ml-3 mr-3">
              <DateRange short={false} showAgregation={false} showCurveType={true} isGlobal={true} />
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <NavBar tabs={tabs2} activeTab={activeTab2} toggleTab={setGlobalActiveTab} />
            </Col>
          </Row>
        </Col>
      </Row>
      {tabs2.map(({ Graph, ref, title }, index) => (
        <Row key={index}>
          <Col className="ml-3 mr-3">
            <div ref={ref} />
            <Graph title={title} />
          </Col>
        </Row>
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  activeTab2: getGlobalActiveTab(state),
  toFocus: getToFocus(state),
  energy: getEnergy(state),
})

const mapDispatchToProps = {
  setGlobalActiveTab,
  focus,
}

export default compose(
  withEmpty,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withProps(({ t, energy }) => ({
    tabs2: [
      {
        title: t('consumption.title.monthlyConsumption'),
        Graph: MonthlyConsumption,
        ref: React.createRef(),
      },
      {
        title: t('consumption.title.loadCurve'),
        Graph: LoadCurve,
        props: {},
        ref: React.createRef(),
      },
      ...(energy === '2'
        ? [
            {
              title: t('consumption.title.posteConsumption'),
              Graph: PosteConsumption,
              ref: React.createRef(),
            },
          ]
        : []),
      {
        title: t('consumption.title.dataAvailability'),
        Graph: QualiteConso,
        ref: React.createRef(),
      },
    ],
  })),
  withHandlers({
    onScroll: (props) =>
      throttle(() => {
        if (!isUserScroll) return false
        const index = props.tabs2
          .map(({ ref }) =>
            ref && ref.current ? ref.current.getBoundingClientRect().top + window.scrollY : 76,
          )
          .findIndex((top) => top > window.scrollY)
        props.setGlobalActiveTab(index)
      }, 400),
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('scroll', this.props.onScroll)
    },
    componentWillUnmount() {
      window.removeEventListener('scroll', this.props.onScroll)
    },
  }),
)(GlobalConsumption)
