import React, { Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { ErrorBoundary } from 'commons'
import { setSelectedPee, getSelectedPee, getPostesUnit, setPostesUnit } from 'store/consumption'
import { Loader } from 'components/common/Loader'
import { PeeTreeSelect } from 'components/common/PeeTreeSelect'
import ButtonGroup from 'components/common/ButtonGroup'
import { BarChart } from './BarChart'
import { ColChart } from './ColChart'

const PosteConsumption = ({ title }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const peeId = useSelector(getSelectedPee)
  const postesUnit = useSelector(getPostesUnit)

  return (
    <Container className="box">
      <ErrorBoundary>
        <Row>
          <Col>
            <div css={{ display: 'flex', '>*:not(:last-child)': { marginRight: '1rem' } }}>
              <div css={{ width: 550 }}>
                <Suspense
                  fallback={
                    <Loader size="sm" style={{ height: 32, justifyContent: 'flex-start' }} />
                  }
                >
                  <PeeTreeSelect
                    value={peeId}
                    onChange={(value) => dispatch(setSelectedPee(value))}
                    filtered
                  />
                </Suspense>
              </div>

              <ButtonGroup
                inline
                title={t('graph.unit')}
                value={postesUnit}
                inputs={[
                  { label: 'kWh', value: '11' },
                  { label: 'MWh', value: '12' },
                  { label: 'GWh', value: '13' },
                ]}
                onChange={(value) => dispatch(setPostesUnit(value))}
              />
            </div>
          </Col>
        </Row>

        {peeId && (
          <Row className="mt-4">
            <Col xs="6">
              <Row>
                <Col>
                  <h3 className="text-center">{t('consumption.title.barChart')}</h3>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div css={{ minHeight: 150, display: 'flex', alignItems: 'center' }}>
                    <Suspense fallback={<Loader />}>
                      <BarChart peeId={peeId} />
                    </Suspense>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs="6">
              <Row>
                <Col>
                  <h3 className="text-center">{t('consumption.title.colChart')}</h3>
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <div css={{ minHeight: 150, display: 'flex', alignItems: 'center' }}>
                    <Suspense fallback={<Loader />}>
                      <ColChart peeId={peeId} />
                    </Suspense>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ErrorBoundary>
    </Container>
  )
}

export default PosteConsumption
