import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'

import SidebarRoutes from './SidebarRoutes'
import { mainLogo } from 'helpers/logo'
import { LogoEleneo } from 'components/common/LogoEleneo'
import * as user from 'commons'

const Sidebar = (props) => {
  const { t } = useTranslation()
  const { langugage } = props
  return (
    <Row>
      <Col className="sidebar p-0">
        <Row>
          <Col className="px-0">
            {props.logo ? (
              <NavLink to="/" className="logo">
                <img src={props.logo} alt={t('sidebar.logoAlt')} className="img-fluid" />
              </NavLink>
            ) : (
              <div
                css={{
                  padding: '2rem 1rem 1rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <NavLink
                    to="/"
                    css={{
                      fontFamily: 'Merlo',
                      color: '#f8f9fa',
                      fontSize: '3.6rem',
                      lineHeight: 1,
                      marginBottom: '.2rem',
                      '&:hover': {
                        textDecoration: 'none',
                        color: '#f8f9fa',
                      },
                    }}
                  >
                    <span>eSite</span>
                  </NavLink>

                  <div>
                    <span
                      css={{
                        color: '#f8f9fa',
                        fontSize: '.7rem',
                        lineHeight: 1,
                        margin: '0 0.4rem 0 0.9rem',
                      }}
                    >
                      by
                    </span>

                    <a href="https://www.eleneo.fr/" target="_blank" rel="noopener noreferrer">
                      <LogoEleneo color="#f8f9fa" fill="#adb5bd" style={{ fontSize: '1.2rem' }} />
                    </a>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col css={{ textAlign: 'center', fontSize: 12 }}>
            <a target="_blank" rel="noreferrer" href={ langugage === "fr" ? "/ELENEO - Guide utilisateurs E-SITE.pdf" : "/ELENEO - Guide utilisateurs E-SITE V4 - EN.pdf"}>
              <span css={{ color: '#f8f9fa' }}>
                  {t('global.userGuide')}
              </span>
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <SidebarRoutes />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
export default connect((state) => ({ logo: mainLogo(state), langugage: user.lngIso(state), }))(Sidebar)
