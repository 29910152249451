import React from 'react'
import { connect } from 'react-redux'

import L, { Icon, Point } from 'leaflet'
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet'

import styles from './Map.module.scss'
import markerIconBlueNavy from '../../../assets/images/marker-icon-blueNavy.svg'
import markerIconBlue from '../../../assets/images/marker-icon-blue.svg'
import markerIconGrey from '../../../assets/images/marker-icon-grey.svg'
import Popup from '../Popup'
import List from '../List'
import { getPositionedSites } from '../../../store/currentSelection'
import { getUor } from 'store'

const TILES_URL =
  'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png'

const PARIS = [48.866667, 2.333333]
const ZERO_SITE_ZOOM = 5
const ONE_SITE_ZOOM = 13

const computeBounds = (sites) => L.latLngBounds(sites.map((site) => site.position))

const isVisible = (bounds) => (site) =>
  bounds.contains(L.latLng(site.position[0], site.position[1]))

const boundsOptions = {
  paddingTopLeft: [0, 0],
  paddingBottomRight: [200, 0],
}

const ICON_WIDTH = 26
const ICON_HEIGHT = 38

const selectedIcon = (uor) =>
  new Icon({
    // iconUrl: uor === '1254' ? markerIconRed : markerIconBlue,
    iconUrl: uor === '620' ? markerIconBlueNavy : markerIconBlue,
    iconSize: new Point(ICON_WIDTH, ICON_HEIGHT),
    iconAnchor: new Point(ICON_WIDTH / 2, ICON_HEIGHT),
    popupAnchor: new Point(0, 200),
    className: styles.icon,
  })

const unselectedIcon = new Icon({
  iconUrl: markerIconGrey,
  iconSize: new Point(ICON_WIDTH, ICON_HEIGHT),
  iconAnchor: new Point(ICON_WIDTH / 2, ICON_HEIGHT),
  popupAnchor: new Point(0, 200),
  className: styles.icon,
})

const mapProps = (sites) =>
  sites.length === 0
    ? { center: PARIS, zoom: ZERO_SITE_ZOOM }
    : sites.length === 1
    ? { center: sites[0].position, zoom: ONE_SITE_ZOOM }
    : { bounds: computeBounds(sites) }

const Map = ({ sites, uor, showList }) => {
  const mapRef = React.useRef()

  const [visibleSites, setVisibleSites] = React.useState(sites.map((site) => site.id))

  const updateVisibleSites = () => {
    const bounds = mapRef.current.leafletElement.getBounds()
    const visibles = sites.filter(isVisible(bounds)).map((site) => site.id)
    setVisibleSites(visibles)
  }

  return (
    <>
      <LeafletMap
        {...mapProps(sites)}
        boundsOptions={boundsOptions}
        ref={mapRef}
        onMoveend={updateVisibleSites}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
          url={TILES_URL}
        />
        {sites.map(({ id, name, isSelected, position }, index) => (
          <Marker
            key={id}
            position={position}
            icon={isSelected ? selectedIcon(uor) : unselectedIcon}
          >
            <Popup id={id} name={name} address="" isSelected={isSelected} />
          </Marker>
        ))}
      </LeafletMap>
      <List
        className={showList ? styles.openedList : styles.closedList}
        visibleSites={visibleSites}
        sites={sites}
      />
    </>
  )
}

const mapState = (state) => ({
  sites: getPositionedSites(state),
  uor: getUor(state),
})

export default connect(mapState)(Map)

export { Map }
