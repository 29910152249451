import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { compose, withHandlers, lifecycle } from 'recompose'

import styles from './Alerts.module.scss'
import Synthesis from '../Synthesis'
import ActionBar from '../ActionBar'
import ActionCards from '../ActionCards'
import ActionTable from '../ActionTable'
import { Loader } from '../../common/Loader'

import { fetchActions, fetchModels, getFetching, getLayout } from 'store/alerts'
import { getCurrentSitesIds, unfocus, getFocus } from 'store/currentSelection'
import withEmpty from 'components/common/Empty'

const ActionPlan = ({ fetching, layout, focus, unfocus }) => {
  React.useEffect(() => {
    if (focus !== null) {
      unfocus()
    }
  }, [focus, unfocus])

  if (focus !== null) return null

  return (
    <>
      <Row className="mt-4 ml-2 mr-2">
        <Col>
          <Container className="box" style={{ height: '200px', minHeight: 0 }}>
            <Row style={{ height: '100%' }}>
              <Col className={styles.synthesis}>{fetching ? <Loader /> : <Synthesis />}</Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <ActionBar />

      <Row className="ml-2 mr-2" style={{ minHeight: '200px' }}>
        <Col>{fetching ? <Loader /> : layout === 'cards' ? <ActionCards /> : <ActionTable />}</Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => ({
  layout: getLayout(state),
  siteIds: getCurrentSitesIds(state),
  fetching: getFetching(state),
  focus: getFocus(state),
})

const mapDispatchToProps = {
  fetchActions,
  fetchModels,
  unfocus,
}

const createPayload = (props) => props.siteIds

export default compose(
  withEmpty,
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    fetchData: (props) => () => {
      props.fetchActions(createPayload(props))
      props.fetchModels()
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchData()
    },
    componentDidUpdate(prevProps) {
      if (JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props))) {
        this.props.fetchData()
      }
    },
  }),
)(ActionPlan)

export { ActionPlan }
