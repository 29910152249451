import React from 'react'

import { QueryBoundary } from 'components/base/QueryBoundary'
import { CalendarForm } from './CalendarForm'
import { PMaxForm } from './PMaxForm'

export const OpeningCalendar = () => {
  return (
    <div className="box d-flex space-x-8">
      <QueryBoundary>
        <CalendarForm />
      </QueryBoundary>

      <QueryBoundary>
        <PMaxForm />
      </QueryBoundary>
    </div>
  )
}
