import React from 'react'
import { useQuery } from 'react-query'

import { fetchVersion } from 'commons/api/version'
import { version } from '../../../../package.json'

const Version = () => {
  return (
    <div
      css={{
        textAlign: 'right',
        padding: '0.4rem 0.8rem',
        fontSize: '0.8rem',
        color: 'var(--gray-dark)',
        background: 'var(--alt-white)',
      }}
    >
      <BackVersion />
      {version}
    </div>
  )
}

const BackVersion = () => {
  const { data: backVersion } = useQuery({
    queryKey: ['version'],
    queryFn: async () => {
      const resp = await fetchVersion()
      const data = await resp.json()
      return data.version
    },
    staleTime: Infinity,
    useErrorBoundary: false,
    suspense: false,
  })

  return <span>{backVersion ?? '\u2013'} / </span>
}

export default Version
