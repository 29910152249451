import { combineReducers } from 'redux'
import { apiConf as api } from 'commons'
import v4 from 'uuid/v4'

const isErrorStatus = (code) => code < 200 || code >= 300

// Actions
const SET_ACTIVE_TAB = 'esite/performance/SET_ACTIVE_TAB'
const SET_TOPFLOP_KPI_SELECTION = 'esite/performance/SET_TOPFLOP_KPI_SELECTION'
const SET_KPI_SELECTION = 'esite/performance/SET_KPI_SELECTION'
const SET_KPI_TYPE = 'esite/performance/SET_KPI_TYPE'
const SET_KPI_COMPARE = 'esite/performance/SET_KPI_COMPARE'
const SET_KPI_UNIT = 'esite/performance/SET_KPI_UNIT'
const FETCH_TOPFLOP_REQUEST = 'esite/performance/FETCH_TOPFLOP_REQUEST'
const FETCH_TOPFLOP_SUCCESS = 'esite/performance/FETCH_TOPFLOP_SUCCESS'
const FETCH_TOPFLOP_FAILURE = 'esite/performance/FETCH_TOPFLOP_FAILURE'
const FETCH_TOPFLOP_KPI_REQUEST = 'esite/performance/FETCH_TOPFLOP_KPI_REQUEST'
const FETCH_TOPFLOP_KPI_SUCCESS = 'esite/performance/FETCH_TOPFLOP_KPI_SUCCESS'
const FETCH_TOPFLOP_KPI_FAILURE = 'esite/performance/FETCH_TOPFLOP_KPI_FAILURE'
const FETCH_SIGNATURE_REQUEST = 'esite/performance/FETCH_SIGNATURE_REQUEST'
const FETCH_SIGNATURE_SUCCESS = 'esite/performance/FETCH_SIGNATURE_SUCCESS'
const FETCH_SIGNATURE_FAILURE = 'esite/performance/FETCH_SIGNATURE_FAILURE'
const FETCH_KPI_REQUEST = 'esite/performance/FETCH_KPI_REQUEST'
const FETCH_KPI_SUCCESS = 'esite/performance/FETCH_KPI_SUCCESS'
const FETCH_KPI_FAILURE = 'esite/performance/FETCH_KPI_FAILURE'
const FETCH_LABEL_REQUEST = 'esite/performance/FETCH_LABEL_REQUEST'
const FETCH_LABEL_SUCCESS = 'esite/performance/FETCH_LABEL_SUCCESS'
const FETCH_LABEL_FAILURE = 'esite/performance/FETCH_LABEL_FAILURE'

// Reducer
const activeTabReducer = (state = 0, action) =>
  action.type === SET_ACTIVE_TAB ? action.payload : state

const topFlopReducer = (
  state = {
    data: null,
    isFetching: false,
    error: false,
    uuid: null,
    kpi: 1,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOPFLOP_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_TOPFLOP_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: false,
        uuid: null,
      }
    case FETCH_TOPFLOP_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: true,
        uuid: null,
      }
    case SET_TOPFLOP_KPI_SELECTION:
      return {
        ...state,
        kpi: action.payload,
      }
    default:
      return state
  }
}

const topFlopKpiReducer = (
  state = {
    data: null,
    isFetching: false,
    error: false,
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_TOPFLOP_KPI_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_TOPFLOP_KPI_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: false,
        uuid: null,
      }
    case FETCH_TOPFLOP_KPI_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: true,
        uuid: null,
      }
    default:
      return state
  }
}

const signatureReducer = (
  state = {
    graph: null,
    isFetching: false,
    error: false,
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_SIGNATURE_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_SIGNATURE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        graph: action.payload,
        error: false,
        uuid: null,
      }
    case FETCH_SIGNATURE_FAILURE:
      return {
        ...state,
        isFetching: false,
        graph: null,
        error: true,
        uuid: null,
      }
    default:
      return state
  }
}

const kpiReducer = (
  state = {
    graph: null,
    isFetching: false,
    error: false,
    selection: 1,
    type: 'Normalise',
    compare: ['MesureN1'],
    unit: 11,
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_KPI_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_KPI_SUCCESS:
      return {
        ...state,
        isFetching: false,
        graph: action.payload,
        error: false,
        uuid: null,
      }
    case FETCH_KPI_FAILURE:
      return {
        ...state,
        isFetching: false,
        graph: null,
        error: true,
        uuid: null,
      }
    case SET_KPI_SELECTION:
      return { ...state, selection: action.payload }
    case SET_KPI_TYPE:
      return { ...state, type: action.payload }
    case SET_KPI_UNIT:
      return { ...state, unit: action.payload }
    case SET_KPI_COMPARE:
      return {
        ...state,
        compare: state.compare.includes(action.payload)
          ? state.compare.filter((x) => x !== action.payload)
          : state.compare.concat(action.payload),
      }
    default:
      return state
  }
}

const labelReducer = (
  state = {
    data: null,
    isFetching: false,
    error: false,
    uuid: null,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_LABEL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: false,
        uuid: action.uuid,
      }
    case FETCH_LABEL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: false,
        uuid: null,
      }
    case FETCH_LABEL_FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: true,
        uuid: null,
      }
    default:
      return state
  }
}

export default combineReducers({
  activeTab: activeTabReducer,
  topFlop: topFlopReducer,
  topFlopKpi: topFlopKpiReducer,
  signature: signatureReducer,
  kpi: kpiReducer,
  label: labelReducer,
})

// Selectors
export const getActiveTab = (state) => state.performance.activeTab

export const getTopFlopData = (state) => state.performance.topFlop.data
export const getTopFlopIsFetching = (state) => state.performance.topFlop.isFetching
export const getTopFlopError = (state) => state.performance.topFlop.error
export const getTopFlopKpi = (state) => state.performance.topFlop.kpi
export const getTopFlopUuid = (state) => state.performance.topFlop.uuid

export const getTopFlopKpiData = (state) => state.performance.topFlopKpi.data
export const getTopFlopKpiIsFetching = (state) => state.performance.topFlopKpi.isFetching
export const getTopFlopKpiError = (state) => state.performance.topFlopKpi.error
export const getTopFlopKpiUuid = (state) => state.performance.topFlopKpi.uuid

export const getSignatureGraph = (state) => state.performance.signature.graph
export const getSignatureIsFetching = (state) => state.performance.signature.isFetching
export const getSignatureError = (state) => state.performance.signature.error
export const getSignatureUuid = (state) => state.performance.signature.uuid

export const getKpiGraph = (state) => state.performance.kpi.graph
export const getKpiIsFetching = (state) => state.performance.kpi.isFetching
export const getKpiError = (state) => state.performance.kpi.error
export const getKpiSelection = (state) => state.performance.kpi.selection
export const getKpiType = (state) => state.performance.kpi.type
export const getKpiCompare = (state) => state.performance.kpi.compare
export const getKpiUnit = (state) => state.performance.kpi.unit
export const getKpiUuid = (state) => state.performance.kpi.uuid

export const getLabel = (state) => state.performance.label.data
export const getLabelIsFetching = (state) => state.performance.label.isFetching
export const getLabelError = (state) => state.performance.label.error
export const getLabelUuid = (state) => state.performance.label.uuid

// Action Creators
export const setActiveTab = (payload) => ({ type: SET_ACTIVE_TAB, payload })
export const setTopFlopKpi = (payload) => ({
  type: SET_TOPFLOP_KPI_SELECTION,
  payload,
})
export const setKpiSelection = (payload) => ({
  type: SET_KPI_SELECTION,
  payload,
})
export const setKpiType = (payload) => ({ type: SET_KPI_TYPE, payload })
export const setKpiUnit = (payload) => ({ type: SET_KPI_UNIT, payload })
export const setKpiCompare = (payload) => ({ type: SET_KPI_COMPARE, payload })

export const getKpiReferentiel = (state) =>
  state.activity.activityReferentiel.concat(state.patrimonial.patrimonialReferentiel)

// Thunks
const createFetchThunk =
  (url, requestAction, successAction, failureAction, uuidSelector) =>
  (payload) =>
  async (dispatch, getState) => {
    const uuid = v4()

    if (payload.sites && payload.sites.length === 0) {
      dispatch({
        type: successAction,
        payload: null,
      })
      return
    }

    dispatch({ type: requestAction, uuid })
    try {
      const response = await api.superFetch({
        url,
        method: 'POST',
        body: payload,
        uuid,
      })
      if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)

      const responseUuid = response.headers.get('X-REQUEST-ID')
      const lastUuid = uuidSelector(getState())
      if (responseUuid !== uuid || lastUuid !== uuid) return

      const result = await response.json()
      dispatch({ type: successAction, payload: result })
    } catch (error) {
      console.error(`fetch error: ${error}`)
      dispatch({ type: failureAction })
    }
  }

export const fetchTopFlop = createFetchThunk(
  'sites/indicateur/topflop/basique',
  FETCH_TOPFLOP_REQUEST,
  FETCH_TOPFLOP_SUCCESS,
  FETCH_TOPFLOP_FAILURE,
  getTopFlopUuid,
)
export const fetchTopFlopKpi = createFetchThunk(
  'sites/indicateur/topflop/kpi',
  FETCH_TOPFLOP_KPI_REQUEST,
  FETCH_TOPFLOP_KPI_SUCCESS,
  FETCH_TOPFLOP_KPI_FAILURE,
  getTopFlopKpiUuid,
)
export const fetchSignatureGraph = createFetchThunk(
  'sites/graph/signature',
  FETCH_SIGNATURE_REQUEST,
  FETCH_SIGNATURE_SUCCESS,
  FETCH_SIGNATURE_FAILURE,
  getSignatureUuid,
)
export const fetchKpiGraph = createFetchThunk(
  'sites/graph/consomensuelle/kpi',
  FETCH_KPI_REQUEST,
  FETCH_KPI_SUCCESS,
  FETCH_KPI_FAILURE,
  getKpiUuid,
)
export const fetchLabel = createFetchThunk(
  'sites/indicateur/etiquette',
  FETCH_LABEL_REQUEST,
  FETCH_LABEL_SUCCESS,
  FETCH_LABEL_FAILURE,
  getLabelUuid,
)
