import React from 'react'
import { connect } from 'react-redux'

import {
  getMonotoneGraph,
  getMonotoneGraphIsFetching,
  getMonotoneGraphError,
} from 'store/consumption'

import Chart from 'components/common/Chart'
import createOptions from './options'
import { getUor } from 'store'

const MonotoneGraph = ({ graph, error, isLoading, uor }) => {
  const options = React.useMemo(() => {
    return createOptions(graph, uor)
  }, [graph, uor])

  return (
    <Chart
      id="monotone"
      error={error}
      isEmpty={!graph || graph.length === 0}
      isLoading={isLoading}
      options={options}
    />
  )
}

const mapStateToProps = (state) => ({
  graph: getMonotoneGraph(state),
  isLoading: getMonotoneGraphIsFetching(state),
  error: getMonotoneGraphError(state),
  uor: getUor(state),
})

export default connect(mapStateToProps)(MonotoneGraph)
