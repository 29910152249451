import React from 'react'
import { connect } from 'react-redux'

import { getColor } from 'helpers/colors'
import { getPlotBandsLegend } from 'store/consumption'
import styles from './LoadCurve.module.scss'

const LoadCurveLegend = ({ plotBandsLegend }) => {
  if (!plotBandsLegend) return null

  return (
    <div className={styles.legend}>
      {plotBandsLegend.map(({ Id, Nom }) => (
        <div
          key={Id}
          className={styles.legendItem}
          style={{
            backgroundColor: getColor(Id * 3, 0.2),
          }}
        >
          {Nom}
        </div>
      ))}
    </div>
  )
}

const mapState = (state) => ({
  plotBandsLegend: getPlotBandsLegend(state),
})

export default connect(mapState)(LoadCurveLegend)
