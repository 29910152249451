import moment from 'moment'

const SET_PEE_ID = 'esite/gestion/SET_PEE'
const SET_START_DATE = 'esite/gestion/SET_START_DATE'
const SET_END_DATE = 'esite/gestion/SET_END_DATE'

export const setPeeId = (peeId) => ({ type: SET_PEE_ID, payload: peeId })
export const setStartDate = (date) => ({ type: SET_START_DATE, payload: date })
export const setEndDate = (date) => ({ type: SET_END_DATE, payload: date })

export const getPeeId = (state) => state.gestion.peeId
export const getStartDate = (state) => state.gestion.startDate
export const getEndDate = (state) => state.gestion.endDate

export default (
  state = {
    peeId: null,
    startDate: moment().subtract(1, 'year').startOf('year').format(),
    endDate: moment().subtract(1, 'year').endOf('year').startOf('month').format(),
  },
  action,
) => {
  switch (action.type) {
    case SET_PEE_ID:
      return { ...state, peeId: action.payload }
    case SET_START_DATE:
      return { ...state, startDate: action.payload }
    case SET_END_DATE:
      return { ...state, endDate: action.payload }
    default:
      return state
  }
}
