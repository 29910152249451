import React from 'react'
import { withRouter } from 'react-router-dom'
import SidebarTabs from './sidebarSelection/SidebarTabs'

const SidebarRoutes = ({ match }) => {
  switch (match.params.path) {
    case 'gestion':
      return null
    default:
      return <SidebarTabs />
  }
}

export default withRouter(SidebarRoutes)
