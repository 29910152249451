import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'

import UserInfosInput from './UserInfosInput'

const UserInfos = ({
  userInfo,
  fetchUserInfo,
  postUserForm,
  setUserPanelOpen,
  userPanelOpen,
  logout,
}) => {
  const { t } = useTranslation()

  const [formData, setFormData] = useState(null)

  useEffect(() => {
    setFormData(userInfo)
  }, [userInfo])

  useEffect(() => {
    fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = (e) => {
    e.preventDefault()
    postUserForm(formData)
    setUserPanelOpen(!userPanelOpen)
  }

  const cancel = (e) => {
    e.preventDefault()
    setUserPanelOpen(!userPanelOpen)
  }

  const onChangeOfCivility = (UsrCiviliteId, UserCivilite) => () => {
    setFormData({ ...formData, UsrCiviliteId, UserCivilite: 'Mme' })
  }

  const onChangeOfLan = (v) => () => {
    setFormData({ ...formData, LanId: v })
  }

  const onChangeOfFirstName = (e) => {
    setFormData({ ...formData, UsrNom: e.target.value })
  }

  const onChangeOfLastName = (e) => {
    setFormData({ ...formData, UsrPrenom: e.target.value })
  }

  const onChangeOfEmail = (e) => {
    setFormData({ ...formData, UsrEMail: e.target.value })
  }

  const onChangeOfAddress = (e) => {
    setFormData({
      ...formData,
      UsrAdresse: { ...formData.UsrAdresse, AdrAdresse: e.target.value },
    })
  }

  const onChangeOfAddressComp1 = (e) => {
    setFormData({
      ...formData,
      UsrAdresse: { ...formData.UsrAdresse, AdrCompl1: e.target.value },
    })
  }

  const onChangeOfAddressComp2 = (e) => {
    setFormData({
      ...formData,
      UsrAdresse: { ...formData.UsrAdresse, AdrCompl2: e.target.value },
    })
  }

  const onChangeOfPc = (e) => {
    setFormData({
      ...formData,
      UsrAdresse: { ...formData.UsrAdresse, AdrCodePostal: e.target.value },
    })
  }

  const onChangeOfCity = (e) => {
    setFormData({
      ...formData,
      UsrAdresse: { ...formData.UsrAdresse, AdrVille: e.target.value },
    })
  }

  const onChangeOfPhone = (e) => {
    setFormData({ ...formData, UsrNumTel: e.target.value })
  }

  const onChangeOfMobile = (e) => {
    setFormData({ ...formData, UsrNumMob: e.target.value })
  }

  return formData ? (
    <Form className="user-infos" onSubmit={submit}>
      <Row className="form-inline panel-header radio-input-btn">
        <Col className="d-flex">
          <FormGroup>
            <Input
              id="userLanFr"
              type="radio"
              name="userLan"
              defaultChecked={formData.LanId === 1}
              onChange={onChangeOfLan(1)}
              value="1"
            />
            <Label for="userLanFr" className="btn">
              FR
            </Label>
          </FormGroup>
          <FormGroup>
            <Input
              id="userLanEn"
              type="radio"
              name="userLan"
              defaultChecked={formData.LanId === 2}
              onChange={onChangeOfLan(2)}
              value="2"
            />
            <Label for="userLanEn" className="btn">
              EN
            </Label>
          </FormGroup>
          <div className="ml-auto">
            <Button color="danger" onClick={logout}>
              <i className="icon-power" />
              {t('user.logout')}
            </Button>
          </div>
        </Col>
      </Row>

      <Row className="panel-flex">
        <Col>
          <Row className="radio-input form-inline">
            <Col className="d-flex">
              <FormGroup>
                <Input
                  id="userM"
                  type="radio"
                  name="userGender"
                  defaultChecked={formData.UsrCiviliteId === 1}
                  onChange={onChangeOfCivility(1, t('user.userInfo.male'))}
                  value="0"
                />
                <Label for="userM">{t('user.userInfo.male')}</Label>
              </FormGroup>
              <FormGroup>
                <Input
                  id="userF"
                  type="radio"
                  name="userGender"
                  defaultChecked={formData.UsrCiviliteId === 2}
                  onChange={onChangeOfCivility(2, t('user.userInfo.female'))}
                  value="1"
                />
                <Label for="userF">{t('user.userInfo.female')}</Label>
              </FormGroup>
            </Col>
          </Row>

          <UserInfosInput
            id="userFirstname"
            value={formData.UsrNom}
            onChange={onChangeOfFirstName}
            label={t('user.userInfo.firstName')}
          />
          <UserInfosInput
            id="userLastname"
            value={formData.UsrPrenom}
            onChange={onChangeOfLastName}
            label={t('user.userInfo.lastName')}
          />
          <UserInfosInput
            id="userEmail"
            value={formData.UsrEMail}
            onChange={onChangeOfEmail}
            label={t('user.userInfo.email')}
            required
          />
          <UserInfosInput
            id="userAddress"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrAdresse}
            onChange={onChangeOfAddress}
            label={t('user.userInfo.address')}
          />
          <UserInfosInput
            id="userAddressComp1"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrCompl1}
            onChange={onChangeOfAddressComp1}
            label={t('user.userInfo.addressComp1')}
          />
          <UserInfosInput
            id="userAddressComp2"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrCompl2}
            onChange={onChangeOfAddressComp2}
            label={t('user.userInfo.addressComp2')}
          />
          <UserInfosInput
            id="userPc"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrCodePostal}
            onChange={onChangeOfPc}
            label={t('user.userInfo.pc')}
          />
          <UserInfosInput
            id="userCity"
            value={formData.UsrAdresse && formData.UsrAdresse.AdrVille}
            onChange={onChangeOfCity}
            label={t('user.userInfo.city')}
          />
          <UserInfosInput
            id="userPhone"
            value={formData.UsrNumTel}
            onChange={onChangeOfPhone}
            label={t('user.userInfo.phone')}
          />
          <UserInfosInput
            id="userMobile"
            value={formData.UsrNumMob}
            onChange={onChangeOfMobile}
            label={t('user.userInfo.mobile')}
          />
        </Col>
      </Row>

      <Row className="panel-footer">
        <Col className="d-flex">
          <div className="ml-auto">
            <div className="push-right">
              <Button type="button" color="link" onClick={cancel}>
                {t('user.userInfo.cancel')}
              </Button>
              <Button type="submit" color="primary">
                {t('user.userInfo.save')}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  ) : (
    <Row className="form-inline panel-header radio-input-btn">
      <Col className="d-flex">
        <div className="ml-auto">
          <Button color="danger" onClick={logout}>
            <i className="icon-power" />
            {t('user.logout')}
          </Button>
        </div>
      </Col>
    </Row>
  )
}

export default UserInfos
