import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getCountSelected } from 'store/currentSelection'
import styles from './Empty.module.scss'

const withEmpty = (BaseComponent) => (props) => {
  const { t } = useTranslation()
  const count = useSelector(getCountSelected)

  if (count === 0) return <div className={styles.container}>{t('global.emptySelection')}</div>

  return <BaseComponent {...props} />
}

export default withEmpty
