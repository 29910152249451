import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'

import styles from './Synthesis.module.scss'
import SynthesisItem from './SynthesisItem'
import { getActions } from '../../../store/alerts'

const nrjs = [
  { id: 1, title: 'action.energy.gas' },
  { id: 2, title: 'action.energy.electricity' },
  // { id: 8, title: 'action.energy.water' },
]

const statutIds = [
  { id: 1, title: 'action.state.new', className: 'synthesis-item-new' },
  { id: 2, title: 'action.state.inProgress', className: 'synthesis-item-inprogress' },
  { id: 3, title: 'action.state.resolved', className: 'synthesis-item-resolved' },
  { id: 4, title: 'action.state.abandoned', className: 'synthesis-item-abandoned' },
]

const Synthesis = ({ values, max }) => {
  const { t } = useTranslation()
  return (
    <div className={styles.container}>
      {nrjs.map(({ id, title: nrjTitle }, i) => (
        <div className={styles.nrjContainer} key={id}>
          <h4
            className={classnames(styles.energy, {
              [styles.gas]: id === 1,
              [styles.elec]: id === 2,
              [styles.water]: id === 8,
            })}
          >
            {t(nrjTitle)}
          </h4>
          {statutIds.map(({ title: statutTitle, colorIndex, id, className }, j) => (
            <SynthesisItem
              key={id}
              title={t(statutTitle)}
              value1={values[i][j].count}
              value2={values[i][j].value}
              colorIndex={colorIndex}
              max={max}
              className={className}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

const mapStateToProps = (state) => {
  const actions = statutIds.map(({ id }) =>
    getActions(state).filter((action) => action.StatutId === id),
  )

  const values = nrjs.map(({ id }) =>
    actions.map((statutActions) => {
      const filtered = statutActions.filter((action) => action.CompteurNrj === id)
      return {
        count: filtered.length,
        value: filtered.reduce((acc, x) => acc + x.Gain, 0),
      }
    }),
  )

  const max = Math.max(
    ...values.map((arr) => arr.reduce((acc, x) => (x.count > acc ? x.count : acc), 0)),
  )

  return { values, max }
}

export default compose(connect(mapStateToProps))(Synthesis)
