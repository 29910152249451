import React, { Suspense, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import { Dialog } from '@reach/dialog'
import { Button } from 'reactstrap'

import { Loader } from 'components/common/Loader'
import { ErrorFallback } from 'components/common/ErrorFallback'
import { Tag } from 'components/common/Tag'
import { useTags, useCreateTag, useDeleteTag } from 'queries/tags'
import { useNotifyError } from 'hooks/notify'

export const AdminTags = () => {
  return (
    <div className="section">
      <h3>Tags</h3>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<Loader style={{ height: 150 }} />}>
          <AdminTagsForm />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

const AdminTagsForm = () => {
  const { t } = useTranslation()
  const notifyError = useNotifyError()
  const [tag, setTag] = useState('')
  const { data: tags } = useTags()
  const { mutateAsync: createTag, status: createStatus } = useCreateTag()
  const { mutateAsync: deleteTag, status: deleteStatus } = useDeleteTag()

  const [dialogId, setDialogId] = useState(null)
  const openDialog = (id) => setDialogId(id)
  const closeDialog = () => setDialogId(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      e.target.tag.focus()
      await createTag(tag.trim())
      setTag('')
    } catch (error) {
      console.error(error)
      notifyError()
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteTag(id)
      closeDialog()
    } catch (error) {
      console.error(error)
      notifyError()
    }
  }

  const deleteDisabled = deleteStatus === 'loading' || createStatus === 'loading'

  const submitDisabled =
    deleteDisabled || tag.trim().length === 0 || tags.some(({ Nom }) => Nom === tag.trim())

  const tagName = tags.find((tag) => tag.Id === dialogId)?.Nom ?? ''

  return (
    <>
      <div>
        <form onSubmit={handleSubmit} className="form-inline">
          <label>
            <span>{t('tags.addLabel')}</span>
            <input
              name="tag"
              className="form-control form-control-sm m-3"
              autoComplete="off"
              minLength={1}
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
          </label>

          <button type="submit" className="btn btn-primary btn-sm" disabled={submitDisabled}>
            <i className="icon-android-add mr-2" />
            <span>{t('tags.addButton')}</span>
          </button>
        </form>

        <div className="d-flex flex-wrap mt-4" style={{ fontSize: 20 }}>
          {tags.map((tag) => (
            <Tag
              key={tag.Id}
              className="mr-2 mb-2"
              name={tag.Nom}
              disabled={true}
              deleteDisabled={deleteDisabled}
              onClose={() => openDialog(tag.Id)}
            />
          ))}
        </div>
      </div>

      <Dialog
        isOpen={dialogId !== null}
        onDismiss={closeDialog}
        className="rounded"
        style={{ position: 'relative' }}
        aria-label="Confirmation"
      >
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeDialog}
          style={{ outline: 'none', position: 'absolute', right: 0, top: 0, margin: '1rem' }}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <p>
          <Trans i18nKey="tags.deleteMessage">
            Êtes vous sûr de vouloir supprimer le tag <strong>{{ tagName }}</strong> ?
          </Trans>
        </p>

        <Button color="danger" onClick={() => handleDelete(dialogId)}>
          {t('tags.deleteTitle')}
        </Button>
      </Dialog>
    </>
  )
}
