import React from 'react'
import { Button } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { dateFormat, decimalFormat } from '../../../helpers/formatters'

import styles from './ActionCard.module.scss'

const ActionCard = ({
  DateRemontee,
  CompteurNrj,
  Titre,
  ModelTitre,
  Commentaire,
  Gain,
  StatutId,
  TypeRemontee,
  btnEditId,
  toggle,
  isPopoverOpen,
  SiteNom,
  CompteurClef,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.content}>
        <Button
          id={btnEditId}
          className={classnames(styles.btnEdit, {
            [styles.active]: isPopoverOpen,
          })}
          onClick={toggle}
        >
          <i className="icon-ico-edit" />
        </Button>

        <div
          className={classnames(styles.energy, {
            [styles.gas]: CompteurNrj === 1,
            [styles.elec]: CompteurNrj === 2,
            [styles.water]: CompteurNrj === 8,
          })}
        >
          {CompteurNrj === 1
            ? t('action.energy.gas')
            : CompteurNrj === 2
            ? t('action.energy.electricity')
            : CompteurNrj === 8
            ? t('action.energy.water')
            : ''}
        </div>
        <div className={styles.alertModel}>{ModelTitre}</div>
        <div className={styles.cpt}>
          <span>{SiteNom}</span>
          <span>{CompteurClef}</span>
        </div>
        <div>
          <span className={styles.alert}>{Titre}</span>
        </div>
        <div className={styles.comment}>{Commentaire}</div>
        <div className={styles.gain}>
          {t('action.table.gain')}&nbsp;:&nbsp;
          <span className={styles.gainValue}>{decimalFormat(Gain)} €</span>
        </div>
        <div>
          <span className={styles.date}>
            {moment(DateRemontee).isValid() ? moment(DateRemontee).format(dateFormat()) : ''}
          </span>
        </div>
      </div>
      <div className={styles.footer}>
        <span
          className={classnames(styles.state, {
            'state-item-new': StatutId === 1,
            'state-item-inprogress': StatutId === 2,
            'state-item-resolved': StatutId === 3,
            'state-item-abandoned': StatutId === 4,
          })}
        >
          {StatutId === 1
            ? t('action.state.new')
            : StatutId === 2
            ? t('action.state.inProgress')
            : StatutId === 3
            ? t('action.state.resolved')
            : StatutId === 4
            ? t('action.state.abandoned')
            : ''}
        </span>
        <span className={styles.type}>
          {TypeRemontee === 0 ? t('action.type.manual') : TypeRemontee === 1 ? 'ESITE' : ''}
        </span>
      </div>
    </>
  )
}
export default ActionCard
