import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Datetime from 'react-datetime'
import moment from 'moment'

import { dateFormat, dateFormatMonths } from '../../../helpers/formatters'
import { lngIso } from 'commons'
import { updateLongEndDate, getLongDateRange } from '../../../store/dateRange'

const DateRangeLongEndDate = ({ dispatch, dateRange, location, lng }) => {
  const format = location.pathname === '/market-informations' ? dateFormat() : dateFormatMonths()

  function onChange(date) {
    const isValid = moment(date, format, true).isValid()
    if (!isValid) return

    const endOfMonth = moment(date).startOf('month').add(1, 'months').subtract(1, 'days')

    dispatch(updateLongEndDate(endOfMonth))
  }
  return (
    <Datetime
      id="endDate"
      locale={lng}
      closeOnSelect
      timeFormat={false}
      dateFormat={format}
      viewMode={location.pathname === '/market-informations' ? 'days' : 'months'}
      value={moment(dateRange.endDate).startOf('month').toDate()}
      onChange={onChange}
    />
  )
}

const mapStateToProps = (state) => ({
  dateRange: getLongDateRange(state),
  lng: lngIso(state),
})

export default withRouter(connect(mapStateToProps)(DateRangeLongEndDate))
