import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { Highcharts } from 'helpers/highcharts'
import { decimalFormat } from 'helpers/formatters'
import { request } from 'helpers/request'
import { getPostesUnit } from 'store/consumption'
import { getLongStartDate, getLongEndDate } from 'store/dateRange'
import { uorIdMst as getUorIdMst, lanId as getLanId } from 'commons'
import { getColor } from 'helpers/colors'
import { getUor } from 'store'

const createOptions = (data, uor) => {
  if (data.series) {
    const series = [
      {
        name: data.series[0].name,
        data: data.series[0].data.map((i, index) => ({
          y: i.y,
          color: getColor(index, 0.4, { alt: true, uor }),
          borderColor: getColor(index, 1, { alt: true, uor }),
        })),
      },
    ]

    return {
      ...data,
      series,
      chart: {
        type: 'bar',
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      title: {
        text: '',
      },
      tooltip: {
        formatter: function () {
          return `<span style="color:${this.point.color}">\u25CF</span> ${
            this.x
          }: <b>${decimalFormat(this.point.y)}</b><br/>`
        },
      },
      yAxis: {
        title: '',
      },
      legend: {
        enabled: false,
      },
    }
  } else {
    return {}
  }
}

const useNonTemporalGraph = (peeId) => {
  const dateDebut = useSelector(getLongStartDate)
  const dateFin = useSelector(getLongEndDate)
  const types = 'Facture'
  const unite = useSelector(getPostesUnit)
  const uorIdMst = useSelector(getUorIdMst)
  const lanId = useSelector(getLanId)

  const { data } = useQuery({
    queryKey: ['nonTemporalGraph', { peeId, dateDebut, dateFin, types, unite, uorIdMst, lanId }],
    queryFn: () =>
      request({
        url: `graphiquenontemporel?date_debut=${dateDebut}&date_fin=${dateFin}&mesures=VolumeHRS&type_donnee=${types}&unite=${unite}&devise=21&uorIdMst=${uorIdMst}&lanId=${lanId}`,
        method: 'POST',
        body: peeId,
      }),
  })

  return data
}

export const BarChart = ({ peeId }) => {
  const data = useNonTemporalGraph(peeId)
  const uor = useSelector(getUor)
  if (!data) return null
  const options = createOptions(data, uor)
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
