import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'

import { getSite } from 'store/dateRange'

import { StationMeteo } from './StationMeteo'
import { Evolution } from './Evolution'

const Temperatures = ({ title }) => {
  const siteId = useSelector(getSite)

  if (!siteId) return null

  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="8">
          <Evolution siteId={siteId} />
        </Col>

        <Col xs="4">
          <StationMeteo siteId={siteId} />
        </Col>
      </Row>
    </Container>
  )
}

export default Temperatures
