import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup } from 'reactstrap'

import { emptySelection, selectAll } from '../../store/currentSelection'

const SelectButtons = ({ dispatch, rootUor, emptySelection, selectAll }) => {
  const { t } = useTranslation()
  return (
    <ButtonGroup>
      <Button
        color="link"
        onClick={(e) => {
          e.preventDefault()
          dispatch(selectAll)
        }}
      >
        {t('panel.selectAll')}
      </Button>
      <Button
        color="link"
        onClick={(e) => {
          e.preventDefault()
          dispatch(emptySelection)
        }}
      >
        {t('panel.unselectAll')}
      </Button>
    </ButtonGroup>
  )
}

const mapStateToProps = (state, { rootUor }) => ({
  emptySelection: emptySelection(),
  selectAll: selectAll(
    state,
    rootUor,
    state.searchPanel.filterType,
    state.searchPanel.countryFilter,
  ),
})

// SelectButtons.propTypes = {
//   dispatch: PropTypes.func.isRequired,
//   rootUor: PropTypes.number.isRequired,
//   emptySelection: PropTypes.func.isRequired,
//   selectAll: PropTypes.func.isRequired,
// };

export default connect(mapStateToProps)(SelectButtons)
