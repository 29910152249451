import { setToken, setPlatform } from '../api/apiConf'

function createAccessTokenMiddleware() {
  return ({ getState }) =>
    (next) =>
    (action) => {
      const state = getState()
      const token =
        state.oidc && state.oidc.user && state.oidc.user !== null && state.oidc.user.access_token
      const platform =
        state.oidc && state.oidc.user && state.oidc.user !== null && state.oidc.user.profile.pla

      // if (action.type === LOADING_USER && token) {
      if (token) {
        setToken(token)
      }
      if (platform) {
        setPlatform(platform)
      }

      return next(action)
    }
}

const accessTokenMiddleware = createAccessTokenMiddleware()
export default accessTokenMiddleware
