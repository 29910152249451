export const colors = [
  [33, 33, 90],
  [146, 106, 20],
  [81, 91, 179],
  [185, 82, 90],
  [237, 114, 80],
  [100, 88, 146],
  [243, 152, 75],
]

export const colorsAlt = [
  [33, 33, 90],
  [146, 106, 20],
  [81, 91, 179],
  [185, 82, 90],
  [237, 114, 80],
  [100, 88, 146],
  [243, 152, 75],
]

export const colorsPomona = [
  [0, 92, 131],
  [233, 118, 91],
  [127, 127, 127],
  [83, 145, 172],
]

export const colorsStef = [
  [5, 5, 175], // Navy Bleu
  [0, 139, 210], // Azur Cyan
  [154, 251, 218], // Lagoon Mint
  [40, 224, 173], // Golden Lemon
  [242, 229, 30], // Iced Sea
]

export const colorsHighContrastStef = [
  '#0505AF', // Navy Bleu
  '#008BD2', // Azur Cyan
  '#F2E500', // Golden Lemon
  '#28E0AD', // Lagoon Mint
  '#9AFBDA', // Iced Sea
  '#FB923C', // orange 400
  '#A3E635', // lime 400
  '#F472B6', // pink 400
  '#FCA5A5', // red 300
  '#4F46E5', // indigo 600
  '#A16207', // yellow 700
]

// https://tailwindcss.com/docs/customizing-colors
export const colorsHighContrast = [
  '#60A5FA', // blue 400
  '#EF4444', // red 500
  '#34D399', // emerald 400
  '#FACC15', // yellow 400
  '#A78BFA', // violet 400
  '#FB923C', // orange 400
  '#A3E635', // lime 400
  '#F472B6', // pink 400
  '#FCA5A5', // red 300
  '#4F46E5', // indigo 600
  '#A16207', // yellow 700
]

const getScheme = (uor, alt) => {
  var scheme = colors
  if (alt) scheme = colorsAlt
  if (uor === '620') scheme = colorsStef
  if (uor === '1254') scheme = colorsPomona
  return scheme
}

export const getColor = (index, alpha = 1, { alt = false, uor = '' } = {}) => {
  const scheme = getScheme(uor, alt)
  const [r, g, b] = scheme[index % scheme.length]
  return `rgba(${r},${g},${b},${alpha})`
}

export const getContrastedColor = (index, uor = '') => {
  if (uor === '620') return colorsHighContrastStef[index % colorsHighContrastStef.length]
  else return colorsHighContrast[index % colorsHighContrast.length]
}
