import { combineReducers } from 'redux'
import v4 from 'uuid/v4'
import { createSelector } from 'reselect'
import { normalize } from 'normalizr'
import isEmpty from 'lodash/isEmpty'
import * as schema from '../schema'
import { apiConf } from 'commons'
import uniqBy from 'lodash/uniqBy'
import set from 'lodash/fp/set'
import mapValues from 'lodash/mapValues'
import flattenDeep from 'lodash/flattenDeep'
import compact from 'lodash/compact'

import { getEnergy, updateEnergy } from 'store/dateRange'

const { superFetch } = apiConf

const isErrorStatus = (code) => code < 200 || code >= 300
const api = {
  fetchOrganisationSite: (uuid) =>
    superFetch({
      url: `organisation/sites`,
      uuid,
    }),
  fetchPees: (uuid, payload) =>
    superFetch({
      url: `pees/search`,
      method: 'POST',
      body: payload,
      uuid,
    }),
}

// Actions
const FETCH_PEES_REQUEST = 'esite/organisation/FETCH_PEES_REQUEST'
const FETCH_PEES_SUCCESS = 'esite/organisation/FETCH_PEES_SUCCESS'
const FETCH_PEES_FAILURE = 'esite/organisation/FETCH_PEES_FAILURE'
const FETCH_ORGANISATION_REQUEST = 'esite/organisation/FETCH_ORGANISATION_REQUEST'
const FETCH_ORGANISATION_SUCCESS = 'esite/organisation/FETCH_ORGANISATION_SUCCESS'
const FETCH_ORGANISATION_FAILURE = 'esite/organisation/FETCH_ORGANISATION_FAILURE'
const INVALID_ORGANISATION_REQUEST = 'esite/organisation/INVALID_ORGANISATION_REQUEST'
const VALID_ORGANISATION_REQUEST = 'esite/organisation/VALID_ORGANISATION_REQUEST'

// Reducer
const pees = (state = { list: [], isFetching: false, error: false }, action) => {
  switch (action.type) {
    case FETCH_PEES_REQUEST:
      return {
        ...state,
        list: [],
        isFetching: true,
        error: false,
      }
    case FETCH_PEES_SUCCESS:
      return {
        ...state,
        list: action.payload,
        isFetching: false,
        error: false,
      }
    case FETCH_PEES_FAILURE:
      return {
        ...state,
        list: [],
        isFetching: false,
        error: true,
      }
    default:
      return state
  }
}

const organisationUuid = (state = '', action) => {
  switch (action.type) {
    case FETCH_ORGANISATION_REQUEST:
      return action.uuid
    default:
      return state
  }
}

const errorMessageOrganisation = (state = null, action) => {
  switch (action.type) {
    case FETCH_ORGANISATION_FAILURE:
      return action.message
    case FETCH_ORGANISATION_REQUEST:
    case FETCH_ORGANISATION_SUCCESS:
      return null
    default:
      return state
  }
}

const organisationEntities = (state = { uors: {}, sites: {}, pees: {}, rootUor: 0 }, action) => {
  switch (action.type) {
    case FETCH_ORGANISATION_SUCCESS:
      return {
        ...state,
        ...action.response.entities,
        rootUor: action.response.result,
      }
    default:
      return state
  }
}

const valid = (state = false, action) => {
  switch (action.type) {
    case INVALID_ORGANISATION_REQUEST: {
      return false
    }
    case VALID_ORGANISATION_REQUEST: {
      return true
    }
    default:
      return state
  }
}

export default combineReducers({
  pees,
  organisationUuid,
  errorMessageOrganisation,
  organisationEntities,
  valid,
})

// Selectors
export const getPeesList = (state) => state.organisation.pees.list

export const getRootUor = (state) => state.organisation.organisationEntities.rootUor || 0
export const getUor = (state, uorId) => state.organisation.organisationEntities.uors[uorId] || {}
export const getUors = (state) => state.organisation.organisationEntities.uors
export const getUorsIds = (state) =>
  Object.keys(state.organisation.organisationEntities.uors).map(Number)
export const getSite = (state, siteId) => state.organisation.organisationEntities.sites[siteId]
export const getSites = (state) => state.organisation.organisationEntities.sites
export const getSiteIdsFromUor = (state, uorId1, uorId2) =>
  Object.values(state.organisation.organisationEntities.sites).filter(
    (s) => s.parentUor === uorId1 || s.parentUor === uorId2,
  )
export const getSitesIds = (state) =>
  Object.keys(state.organisation.organisationEntities.sites).map(Number)
export const getSitePeesIds = (state, siteId) =>
  state.organisation.organisationEntities.sites[siteId].SitPees
export const getSiteNameByPeeId = (state, peeId) =>
  (state.organisation.organisationEntities.sites[peeId] &&
    state.organisation.organisationEntities.sites[peeId].SphNom) ||
  ''
export const getPee = (state, peeId) => state.organisation.organisationEntities.pees[peeId]
export const getPeeNrj = (state, peeId) =>
  state.organisation.organisationEntities.pees[peeId] &&
  state.organisation.organisationEntities.pees[peeId].PeeNrj
export const getPees = (state) => state.organisation.organisationEntities.pees
export const getPeesIds = (state) =>
  Object.keys(state.organisation.organisationEntities.pees).map(Number)
export const getOrganisationUuid = (state) => state.organisation.organisationUuid
export const getErrorMessageOrganisation = (state) => state.Organisation.errorMessageOrganisation
export const getUorPath = (state, uorId) =>
  state.organisation.organisationEntities.uors[uorId].parentUor !== uorId
    ? getUorPath(state, state.organisation.organisationEntities.uors[uorId].parentUor) +
      '/' +
      state.organisation.organisationEntities.uors[uorId].UorNom
    : state.organisation.organisationEntities.uors[uorId].UorNom
export const getSitePath = (state, siteId) =>
  getUorPath(state, state.organisation.organisationEntities.sites[siteId].parentUor) +
  '/' +
  state.organisation.organisationEntities.sites[siteId].SphNom
export const getPeePath = (state, peeId) =>
  getSitePath(state, state.organisation.organisationEntities.pees[peeId].parentSite) +
  '/' +
  state.organisation.organisationEntities.pees[peeId].PeeClef

export const getUorTree = (state, checkedId) =>
  createSelector(getUors, (uors) => {
    const createNode = (id) => {
      const uor = uors[id]
      if (isEmpty(uor)) return {}
      return {
        label: uor.UorNom,
        value: uor.id,
        checked: uor.id === checkedId,
        ...(!isEmpty(uor.UorEnfants) && {
          children: uor.UorEnfants.map(createNode),
        }),
      }
    }

    const mainUor = Object.values(uors).find((uor) => uor.id === uor.parentUor)
    return !mainUor ? {} : createNode(mainUor.id)
  })(state)

export const getOrganisationRequestValid = (state) => state.organisation.valid

// Actions
export const invalidOrganisationRequest = () => ({
  type: INVALID_ORGANISATION_REQUEST,
})

export const validOrganisationRequest = () => ({
  type: VALID_ORGANISATION_REQUEST,
})

// Thunks
export const fetchPees = (payload) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: FETCH_PEES_REQUEST })
  try {
    const response = await api.fetchPees(uuid, payload)
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)
    if (response.headers.get('X-REQUEST-ID') !== uuid) return

    const result = await response.json()
    dispatch({ type: FETCH_PEES_SUCCESS, payload: result })

    // Si l'énergie sélectionnée ne correspond à aucun PEE, on en sélectionne une autre
    const state = getState()
    const selectedEnergy = getEnergy(state)
    const availableNrj = getAvailableNrj(state).map((n) => n.toString())
    if (availableNrj.length > 0 && !availableNrj.includes(selectedEnergy)) {
      dispatch(updateEnergy(availableNrj[0]))
    }
  } catch (error) {
    console.error(`fetch error: ${error}`)
    dispatch({ type: FETCH_PEES_FAILURE })
  }
}

const getTags = (uor, entities) => {
  return compact(
    flattenDeep([
      uor.UorSites?.map((siteId) => entities.sites[siteId]?.Tags?.split('|')),
      uor.UorEnfants?.map((uorId) => getTags(entities.uors[uorId], entities)),
    ]),
  ).join('|')
}

export const fetchOrganisationSite = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_ORGANISATION_REQUEST,
    uuid,
  })

  return api.fetchOrganisationSite(uuid).then(
    (response) => {
      if (response && response.headers.get('X-REQUEST-ID') !== uuid) return
      if (response.status === 200) {
        response.json().then((response) => {
          const orga = normalize(response, schema.organisation)
          const uorsWithTags = mapValues(orga.entities.uors, (uor) => ({
            ...uor,
            Tags: getTags(uor, orga.entities),
          }))
          const orgaWithTags = set('entities.uors', uorsWithTags, orga)

          dispatch({
            type: FETCH_ORGANISATION_SUCCESS,
            response: orgaWithTags,
          })

          dispatch(fetchPees(getSitesIds(getState())))
          dispatch(validOrganisationRequest())
        })
      } else {
        dispatch({
          type: FETCH_ORGANISATION_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_ORGANISATION_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const getAvailableNrj = createSelector(getPeesList, (pees) => {
  return uniqBy(pees, (pee) => pee.PeeNrjId).map((pee) => pee.PeeNrjId)
})
