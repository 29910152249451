import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, ButtonGroup, Button } from 'reactstrap'
import { compose, withState, withHandlers } from 'recompose'
// import Select from 'react-select'
import classnames from 'classnames'

import styles from './ActionBar.module.scss'
import { ReactComponent as IconCards } from '../../../assets/images/icon-cards.svg'
import { ReactComponent as IconTable } from '../../../assets/images/icon-table.svg'
import {
  setLayout,
  getLayout,
  setSort,
  getSort,
  addAction,
  getFilter,
  setFilter,
} from '../../../store/alerts'
import ActionPopover from '../ActionPopover'

const ActionBar = ({
  layout,
  setLayout,
  sort,
  setSort,
  popoverOpen,
  togglePopover,
  addAction,
  filter,
  setFilter,
}) => {
  const { t } = useTranslation()

  const sorts = [
    { id: 1, name: t('action.bar.commodity') },
    { id: 2, name: t('action.bar.gain') },
    { id: 3, name: t('action.bar.state') },
    { id: 4, name: t('action.bar.type') },
    { id: 5, name: t('action.bar.date') },
  ]

  // const stateFilterOptions = [
  //   { value: 0, label: 'Tous' },
  //   { value: 1, label: 'Nouveau' },
  //   { value: 2, label: 'En cours' },
  //   { value: 3, label: 'Résolu' },
  //   { value: 4, label: 'Abandon' },
  // ]

  return (
    <Row className={classnames('ml-2', 'mr-2', styles.bar)}>
      <Col className={styles.controls}>
        <Button color="primary" id="popover_add" onClick={togglePopover} className={styles.addBtn}>
          + {t('action.bar.addAction')}
        </Button>

        <ActionPopover
          placement="right-start"
          target="popover_add"
          isOpen={popoverOpen}
          toggle={togglePopover}
          onSave={addAction}
        />

        <div className={styles.sortsFilters}>
          <span className={styles.sorts}>
            {/* <span className={styles.label}>Trier</span> */}

            <ButtonGroup>
              {sorts.map(({ name, id }) => (
                <Button
                  key={id}
                  className={classnames(styles.button, {
                    [styles.down]: id === sort,
                    [styles.up]: id === -sort,
                    [styles.active]: id === Math.abs(sort),
                  })}
                  onClick={() => setSort(id === sort ? -id : id)}
                >
                  {name}
                </Button>
              ))}
            </ButtonGroup>
          </span>

          {/* <span className={styles.filters}>
            <span className={styles.label}>Filtrer</span>
            <Select
              value={stateFilterOptions.find(option => option.value === filter)}
              onChange={option => setFilter(option.value)}
              options={stateFilterOptions}
              className="react-select"
              classNamePrefix="react-select"
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: '150px',
                }),
              }}
            />
          </span> */}
        </div>
      </Col>

      <Col className="text-right">
        <ButtonGroup>
          <Button
            className={classnames(styles.button, {
              [styles.active]: layout === 'cards',
            })}
            onClick={() => setLayout('cards')}
          >
            <IconCards className={styles.icon} />
            {t('action.bar.cards')}
          </Button>

          <Button
            className={classnames(styles.button, {
              [styles.active]: layout === 'table',
            })}
            onClick={() => setLayout('table')}
          >
            <IconTable className={styles.icon} />
            {t('action.bar.table')}
          </Button>
        </ButtonGroup>
      </Col>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  layout: getLayout(state),
  sort: getSort(state),
  filter: getFilter(state),
})

const mapDispatchToProps = {
  setLayout,
  setSort,
  addAction,
  setFilter,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('popoverOpen', 'setPopoverOpen', false),
  withHandlers({
    togglePopover:
      ({ popoverOpen, setPopoverOpen }) =>
      () => {
        setPopoverOpen(!popoverOpen)
      },
  }),
)(ActionBar)

export { ActionBar }
