import moment from 'moment'
import isArray from 'lodash/isArray'

import { getContrastedColor, getColor } from 'helpers/colors'
import { noDecimalFormat } from 'helpers/formatters'
import downloadFile from 'helpers/downloadFile'

const pointIntervals = {
  5: 1000 * 60 * 60, // 1 hour
  7: 1000 * 60 * 15, // 15 min
  8: 1000 * 60 * 10, // 10 min
}

export default (graph, unite, hrs, energy, plotBandsLegend, boost, step, uorIdMst) => {
  if (!graph || !graph.series || graph.series.length === 0) return { title: { text: null } }

  const xAxis = {
    type: 'datetime',
  }

  if (graph.xAxis) {
    const { plotBands } = graph.xAxis
    xAxis.plotBands = plotBands.map(({ from, to, id }) => ({
      from,
      to,
      color: getColor(id * 3, 0.2, uorIdMst),
    }))
  }

  const series = graph.series.map((serie, index) => {
    const base = {
      ...serie,
      showInLegend: true,
      id: index,
      color: getContrastedColor(index, uorIdMst),
      connectNulls: false,
    }

    const [a, b] = serie.data
    const pointInterval = b.x - a.x
    const DAY = 86400000

    const data =
      pointInterval < DAY
        ? {
            data: serie.data.map((point) => point.y),
            pointStart: serie.data[0].x,
            pointInterval: pointIntervals[step],
          }
        : {
            data: serie.data.map((p) => [p.x, p.y]),
          }

    return {
      ...base,
      ...data,
    }
  })

  const options = {
    chart: {
      type: 'line',
      height: '500px',
      zoomType: 'xy',
    },
    title: {
      text: null,
    },
    xAxis: {
      ...xAxis,
      labels: {
        formatter: function () {
          return moment(this.value).format('DD/MM')
        },
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      events: {
        setExtremes: function (e) {
          if (['navigator', 'rangeSelectorInput'].includes(e.trigger)) {
            e.preventDefault()
            const min = Number(moment(e.min).startOf('day').format('x'))
            const max = Number(moment(e.max).endOf('day').startOf('hour').format('x'))
            this.setExtremes(min, max)
          }
        },
      },
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    boost: {
      enabled: boost,
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      xDateFormat: '%d/%m/%Y %H:%M',
      pointFormat: `{point.series.name} : <b>{point.y:.2f} ${unite}</b><br/>`,
    },
    plotOptions: {
      series: {
        connectNulls: false,
        animation: false,
        turboThreshold: 1,
        lineWidth: 0.8,
        states: {
          hover: {
            lineWidth: 0.8,
          },
        },
        dataGrouping: {
          enabled: false,
        },
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series,
    exporting: {
      enabled: true,
      sourceWidth: 800,
      sourceHeight: 400,
      buttons: {
        contextButton: {
          menuItems: [
            {
              textKey: 'downloadJPEG',
              onclick: function () {
                this.exportChart({ type: 'image/jpeg' })
              },
            },
            {
              textKey: 'downloadPDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' })
              },
            },
            {
              textKey: 'downloadCSV',
              onclick: function () {
                // const { series } = this.options
                const plotBands = graph.xAxis ? graph.xAxis.plotBands : []
                const { min, max } = this.xAxis[0].getExtremes()
                const showHrs = energy === '2' && !!hrs && plotBands.length > 0

                const head = [
                  '',
                  ...series.map((serie) => serie.name),
                  ...(showHrs ? ['Poste'] : []),
                ].join(';')

                const { data, pointStart, pointInterval } = series[0]
                const body = data.reduce((acc, point, i) => {
                  const x = isArray(point) ? point[0] : pointStart + pointInterval * i

                  if (x < min || x > max) return acc

                  const getPost = () => {
                    const res = plotBands.find(({ from, to }) => x >= from && x <= to)
                    const postId = res ? res.id : '-'
                    const legendRes = plotBandsLegend.find(({ Id }) => postId === Id)
                    const post = legendRes ? legendRes.Nom : '-'
                    return post
                  }

                  const row = [
                    `"${moment(x).format('YYYY-MM-DD HH:mm:ss')}"`,
                    ...series.map(({ data }) => {
                      const point = data[i]
                      const y = isArray(point) ? point[1] : point
                      return `"${noDecimalFormat(y)}"`
                    }),
                    ...(showHrs ? [getPost()] : []),
                  ].join(';')

                  acc.push(row)

                  return acc
                }, [])

                const content = [head, ...body].join('\n')
                downloadFile('chart.csv', content, { type: 'text/csv' })
              },
            },
          ],
        },
      },
    },
  }

  return options
}
