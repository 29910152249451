import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchRegimes: (idPee, uuid) =>
    superFetch({
      url: `pees/${idPee}/regimes`,
      method: 'GET',
      uuid,
    }),
}

// Actions
const FETCH_REGIMES_REQUEST = 'esite/regimes/FETCH_REGIMES_REQUEST'
const FETCH_REGIMES_SUCCESS = 'esite/regimes/FETCH_REGIMES_SUCCESS'
const FETCH_REGIMES_FAILURE = 'esite/regimes/FETCH_REGIMES_FAILURE'

// Reducer
export default (state = [], action) => {
  switch (action.type) {
    case FETCH_REGIMES_REQUEST:
      return {
        uuid: action.uuid,
      }
    case FETCH_REGIMES_SUCCESS:
      return action.payload
    default:
      return state
  }
}

// Selectors
export const getRegimes = (state) => state.regimes
export const getRegimesUuid = (state) => state.regimes.uuid

// Thunks
export const fetchRegimes = (idPee) => async (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_REGIMES_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchRegimes(idPee, uuid)
    if (response && response.headers.get('X-REQUEST-ID') !== getRegimesUuid(getState())) return
    dispatch({
      type: FETCH_REGIMES_SUCCESS,
      payload: await response.json(),
    })
  } catch (error) {
    dispatch({
      type: FETCH_REGIMES_FAILURE,
      message: error.message || 'Something went wrong.',
    })
  }
}
