import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Button } from 'reactstrap'

import { Tags } from './Tags'
import DatesForm from './DatesForm'
import InformationForms from './InformationForms'

const Information = ({ entities, isAdmin }) => {
  const { t } = useTranslation()
  const [edit, setEdit] = React.useState(false)
  const toggleEdit = React.useCallback(() => setEdit(!edit), [edit])

  return (
    <Container className="box">
      <Row className="mb-2">
        <Col>
          <h3>
            {t('site.admin.title')}{' '}
            {isAdmin && (
              <Button onClick={toggleEdit} size="sm" className="ml-3" color="">
                <i className={edit ? 'icon-done' : 'icon-ico-edit'} style={{ color: '#61747f' }} />
              </Button>
            )}
          </h3>
        </Col>
      </Row>

      <DatesForm edit={edit} />
      <InformationForms edit={edit} />

      <Tags />
    </Container>
  )
}

export default Information
