import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Notifications from 'react-notification-system-redux'

export const useNotifyError = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  return useCallback(
    (error) =>
      dispatch(
        Notifications.error({
          title: t('global.shortError'),
          ...(error && { message: error.message }),
          position: 'bl',
          autoDismiss: 3,
        }),
      ),
    [dispatch, t],
  )
}

export const useNotifySuccess = () => {
  const dispatch = useDispatch()
  return useCallback(
    ({ title, message }) =>
      dispatch(
        Notifications.success({
          title,
          message,
          position: 'bl',
          autoDismiss: 3,
        }),
      ),
    [dispatch],
  )
}
