import { getColor } from 'helpers/colors'

const createOptions = (graph, uor) => {
  if (!graph || !graph.graphMensuel || graph.graphMensuel.length === 0)
    return { title: { text: null } }

  const all = {
    title: {
      text: null,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    chart: {
      type: 'column',
    },
    exporting: {
      enabled: true,
      sourceWidth: 800,
      sourceHeight: 400,
    },
    legend: {
      maxHeight: 100,
    },
    tooltip: {
      xDateFormat: '%d/%m/%Y %H:%M',
      pointFormat: `{point.series.name} : <b>{point.y:.2f}<b/><br/>`,
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        pointPadding: 0,
        groupPadding: 0.05,
        borderWidth: 1,
        shadow: false,
      },
    },
    series: graph.graphMensuel.reduce((acc, item, i) => {
      const series = item.graphMensuel.series.map(({ name, data }, j) => ({
        id: `${i}_${j}`,
        name: `${name}`,
        data,
      }))
      return acc.concat(series).map((serie, index) => ({
        ...serie,
        color: getColor(index, 0.4, { uor }),
        borderColor: getColor(index, 1, { uor }),
      }))
    }, []),
  }

  const total = {
    ...all,
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: ['Total'],
    },
    series: all.series.map((serie) => ({
      ...serie,
      data: [serie.data.reduce((acc, { y }) => acc + y, 0)],
    })),
  }

  return { all, total }
}

export default createOptions
