import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { compose, withState, lifecycle, withHandlers } from 'recompose'

import { closeSite, fetchSite } from '../../../store/site'
import { getSite } from '../../../store/organisation'
import { isAdmin as getIsAdmin } from 'store/admin'

import Map from '../Map'
import Information from '../Information'
import Pees from '../Pees'
import Patrimonials from '../Patrimonials'
import Activities from '../Activities'
import { DecretTertiaireSection } from '../DecretTertiaireForm'

import styles from './Site.module.scss'

const Site = (props) => {
  const siteId = Number(props.match.params.id)
  const isAdmin = useSelector(getIsAdmin)
  return (
    <Row className={styles.siteContainer}>
      <div className={props.isCardOpen ? styles.siteCard : styles.siteMap}>
        <div className={styles.map}>
          <Map showList={!props.isCardOpen} />
        </div>
        <Container className={styles.card}>
          {!!props.match.params.id && (
            <>
              <div className={styles.cardHeader}>
                <span className={styles.cardTitle}>{props.site && props.site.SphNom}</span>
                <Link to={'/site'}>
                  <Button onClick={props.closeSite} className={styles.closeBtn} color="">
                    <i className="icon-cross" />
                  </Button>
                </Link>
              </div>
              <div className={styles.cardContent}>
                <Container className={styles.container}>
                  <Row>
                    <Col>
                      <Information isAdmin={isAdmin} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Container className="box">
                        <Row>
                          <Col>
                            <DecretTertiaireSection isAdmin={isAdmin} siteId={siteId} />
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Pees isAdmin={isAdmin} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Patrimonials isAdmin={isAdmin} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Activities isAdmin={isAdmin} />
                    </Col>
                  </Row>
                </Container>
              </div>
            </>
          )}
        </Container>
      </div>
    </Row>
  )
}

const mapStateToProps = (state, ownProps) => ({
  site: getSite(state, ownProps.match.params.id),
})

const mapDispatchToProps = {
  closeSite,
  fetchSite,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('isCardOpen', 'openCard', (props) => !!props.match.params.id),
  withHandlers({
    fetchData: (props) => () => props.fetchSite(props.match.params.id),
  }),
  lifecycle({
    componentDidMount() {
      if (this.props.match.params.id) {
        this.props.fetchData()
      }
    },
    componentDidUpdate(prevProps) {
      if (prevProps.siteId !== this.props.match.params.id) {
        this.props.fetchData()
      }

      const prevId = prevProps.match.params.id
      const currId = this.props.match.params.id

      if (!!currId && !prevId) {
        this.props.openCard(true)
      }

      if (!currId && !!prevId) {
        this.props.openCard(false)
      }
    },
  }),
)(Site)

export { Site }
