import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import flatten from 'lodash/flatten'

import {
  getCurrentSitesIds,
  getCurrentSitesByUor,
  getFilteredPeesIds,
  getFilteredPeesBySite,
} from 'store/currentSelection'

import {
  getVue,
  getSite,
  getPee,
  getShortStartDate,
  getShortEndDate,
  getEnergy,
} from 'store/dateRange'

import {
  getMonotoneGraphUniteId,
  getMonotoneGraphType,
  getMonotoneGraphCompare,
  getMonotoneGraphStep,
  fetchMonotoneGraph,
  setMonotoneGraphUnite,
  setMonotoneGraphType,
  setMonotoneGraphCompare,
  setMonotoneGraphStep,
  getMonotoneGraphIsFetching,
} from 'store/consumption'

import styles from './MonotoneGraph.module.scss'
import Select from 'components/common/Select'
import ButtonGroup from 'components/common/ButtonGroup'

const GroupHeading = (props) => (
  <components.GroupHeading {...props}>
    <span>{props.children}</span>
  </components.GroupHeading>
)

const Option = (props) => (
  <components.Option {...props}>
    {props.data.nrj && (
      <span
        className={
          props.data.nrj === 2
            ? styles.optionIconElec
            : props.data.nrj === 1
            ? styles.optionIconGaz
            : styles.optionIconWater
        }
      >
        <i
          className={
            props.data.nrj === 2 ? 'icon-elec' : props.data.nrj === 1 ? 'icon-gaz' : 'icon-tint'
          }
        />
      </span>
    )}
    {props.label}
  </components.Option>
)

const MonotoneOptions = ({
  isLoading,
  agregation,
  peesBySite,
  entity1,
  entity2,
  setEntity2,
  sitesByUor,
  unite,
  type,
  compare,
  step,
  setMonotoneGraphUnite,
  setMonotoneGraphType,
  toggleCompare,
  setMonotoneGraphStep,
}) => {
  const { t } = useTranslation()

  const entitiesOptions =
    agregation === '0'
      ? peesBySite.map(({ name, pees }) => ({
          label: name,
          options: pees.map(({ id, PeeClef, PeeNrjId }) => ({
            value: id,
            label: PeeClef,
            nrj: PeeNrjId,
          })),
        }))
      : agregation === '1'
      ? sitesByUor.map(({ name, sites }) => ({
          label: name,
          options: sites.map(({ id, SphNom }) => ({
            value: id,
            label: SphNom,
          })),
        }))
      : []

  const flatEntitiesOptions = flatten(entitiesOptions.map((o) => o.options))

  return (
    <>
      {agregation === '0' && (
        <div className={styles.container}>
          <div className={styles.title}>{t('graph.cptCompare')}</div>
          <Select
            placeholder={t('graph.cptPlaceholder')}
            value={flatEntitiesOptions.find((option) => option.value === entity2) || null}
            onChange={(option) => setEntity2(!option ? null : option.value)}
            options={entitiesOptions}
            components={{ GroupHeading, Option }}
            size="xs"
            width={200}
            isDisabled={isLoading || !entity1}
            isClearable={true}
          />
        </div>
      )}

      {agregation === '1' && (
        <div className={styles.container}>
          <div className={styles.title}>{t('graph.siteCompare')}</div>
          <Select
            placeholder={t('graph.sitePlaceholder')}
            value={flatEntitiesOptions.find((option) => option.value === entity2) || null}
            onChange={(option) => setEntity2(!option ? null : option.value)}
            options={entitiesOptions}
            components={{ GroupHeading }}
            size="xs"
            width={200}
            isDisabled={isLoading || !entity1}
            isClearable={true}
          />
        </div>
      )}

      <ButtonGroup
        title={t('graph.displayData')}
        value={type}
        inputs={[
          { label: t('graph.normalise'), value: 'Normalise' },
          { label: t('graph.mesure'), value: 'Mesure' },
        ]}
        onChange={setMonotoneGraphType}
      />

      <ButtonGroup
        title={t('graph.compare')}
        value={compare}
        inputs={[{ label: t('graph.n1'), value: 'MesureN1' }]}
        onChange={toggleCompare}
        isDisabled={isLoading}
      />

      <ButtonGroup
        title={t('graph.unit')}
        value={unite}
        inputs={[
          { label: 'kWh', value: '11' },
          { label: 'MWh', value: '12' },
          { label: 'GWh', value: '13' },
        ]}
        onChange={setMonotoneGraphUnite}
        isDisabled={isLoading}
      />

      <ButtonGroup
        title={t('graph.step')}
        value={step}
        inputs={[
          { label: t('graph.1h'), value: '5' },
          { label: '10 min', value: '7' },
          { label: '15 min', value: '8' },
        ]}
        onChange={setMonotoneGraphStep}
        isDisabled={isLoading}
      />
    </>
  )
}

const mapStateToProps = (state) => {
  const agregation = getVue(state)
  return {
    siteIds: getCurrentSitesIds(state),
    sitesByUor: getCurrentSitesByUor(state),
    peeIds: getFilteredPeesIds(state),
    peesBySite: getFilteredPeesBySite(state),
    startDate: getShortStartDate(state),
    endDate: getShortEndDate(state),
    energy: getEnergy(state),
    unite: getMonotoneGraphUniteId(state),
    type: getMonotoneGraphType(state),
    compare: getMonotoneGraphCompare(state),
    step: getMonotoneGraphStep(state),
    isLoading: getMonotoneGraphIsFetching(state),
    entity1: agregation === '0' ? getPee(state) : getSite(state),
    agregation,
  }
}

const mapDispatchToProps = {
  fetchMonotoneGraph,
  setMonotoneGraphUnite,
  setMonotoneGraphType,
  setMonotoneGraphCompare,
  setMonotoneGraphStep,
}

const createPayload = (props) => ({
  debut: props.startDate,
  fin: props.endDate,
  ids: !!props.entity1 ? (!!props.entity2 ? [props.entity1, props.entity2] : [props.entity1]) : [],
  typeIds: props.agregation === '0' ? 'PEE' : 'SIT',
  nrjs: [props.energy],
  typesDonnees: [props.type, ...props.compare],
  unite: props.unite,
  agregation: props.agregation,
  pas: props.step,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('entity2', 'setEntity2', null),
  withHandlers({
    toggleCompare:
      ({ compare, setMonotoneGraphCompare }) =>
      (s) =>
        setMonotoneGraphCompare(
          compare.includes(s) ? compare.filter((x) => x !== s) : compare.concat(s),
        ),
    fetchGraph: (props) => () => {
      props.fetchMonotoneGraph(createPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      if (createPayload(this.props).ids.length > 0) this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (
        this.props.agregation === '0' &&
        prevProps.peeIds.join(',') !== this.props.peeIds.join(',')
      ) {
        this.props.setEntity2(null)
      }

      if (
        this.props.agregation === '1' &&
        prevProps.siteIds.join(',') !== this.props.siteIds.join(',')
      ) {
        this.props.setEntity2(null)
      }

      if (prevProps.agregation !== this.props.agregation) {
        this.props.setEntity2(null)
      }

      if (
        JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props)) &&
        prevProps.agregation === this.props.agregation &&
        prevProps.energy === this.props.energy &&
        createPayload(this.props).ids.length > 0
      ) {
        this.props.fetchGraph()
      }
    },
  }),
)(MonotoneOptions)
