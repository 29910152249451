import { useQuery, useMutation, useQueryClient } from 'react-query'
import { request } from 'helpers/request'
import { useNotifyError } from 'hooks/notify'
import isNil from 'lodash/isNil'
import range from 'lodash/range'

export enum EnumReference {
  Absolue = 0,
  Relative = 1,
}

export const anneesRef = range(2010, 2021)

export type TypeAnneeRef = typeof anneesRef[number]

export type DecretTertiaire = {
  Id: number
  SitId: number
  AnneeRef: TypeAnneeRef
  Reference: EnumReference
  UseModule: number
}

export const useDecretTertiaireQuery = (siteId: number) => {
  return useQuery({
    queryKey: ['decretTertaire', siteId],
    queryFn: () =>
      request({ url: `sites/${siteId}/decrettertiaire` }) as Promise<DecretTertiaire | null>,
    useErrorBoundary: true,
    keepPreviousData: true,
  })
}

const useMutationOptions = (siteId: number) => {
  const queryCache = useQueryClient()
  const notifyError = useNotifyError()
  return {
    onSuccess: () => {
      queryCache.invalidateQueries(['decretTertaire', siteId])
    },
    onError: notifyError,
  }
}

export const useDecretTertiaireMutation = (siteId: number) => {
  const options = useMutationOptions(siteId)
  return useMutation(
    (payload: Partial<DecretTertiaire>) =>
      request({
        method: isNil(payload.Id) ? 'POST' : 'PUT',
        url: `sites/${siteId}/decrettertiaire`,
        body: payload,
      }) as Promise<DecretTertiaire>,
    options,
  )
}

export const useDeleteDecretTertiaireMutation = (siteId: number) => {
  const options = useMutationOptions(siteId)
  return useMutation(
    () =>
      request({
        method: 'DELETE',
        url: `sites/${siteId}/decrettertiaire`,
      }),
    options,
  )
}
