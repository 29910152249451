import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Datetime from 'react-datetime'
import moment from 'moment'
import { lngIso } from 'commons'

import { dateFormat, dateFormatMonths } from '../../../helpers/formatters'
import { updateLongStartDate, getLongDateRange } from '../../../store/dateRange'

const DateRangeLongStartDate = ({ dispatch, dateRange, location, lng }) => {
  const format = location.pathname === '/market-informations' ? dateFormat() : dateFormatMonths()

  function onChange(date) {
    const isValid = moment(date, format, true).isValid()
    if (!isValid) return
    dispatch(updateLongStartDate(date))
  }

  return (
    <Datetime
      id="startDate"
      locale={lng}
      closeOnSelect
      timeFormat={false}
      dateFormat={format}
      viewMode={location.pathname === '/market-informations' ? 'days' : 'months'}
      value={moment(dateRange.startDate).toDate()}
      onChange={onChange}
    />
  )
}

const mapStateToProps = (state) => ({
  dateRange: getLongDateRange(state),
  lng: lngIso(state),
})

export default withRouter(connect(mapStateToProps)(DateRangeLongStartDate))
