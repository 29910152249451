import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import HeatMapGraph from './HeatMapGraph'
import HeatMapOptions from './HeatMapOptions'

const HeatMap = ({ title }) => {
  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="10" className="graph-container">
          <HeatMapGraph />
        </Col>
        <Col xs="2">
          <HeatMapOptions />
        </Col>
      </Row>
    </Container>
  )
}

export default HeatMap
