import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Container, Row, Col, Button } from 'reactstrap'
import { useHistory } from 'react-router-dom'

import { noDecimalFormat } from 'helpers/formatters'
import styles from './TopFlopCard.module.scss'
import { setToFocus } from 'store/currentSelection'

const TopFlopCard = ({ type, data }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  return !data ? null : (
    <Container
      fluid
      className={classnames({
        [styles.topCard]: type === 'top',
        [styles.flopCard]: type === 'flop',
      })}
    >
      <Row>
        <Col className={styles.header}>
          1. <span>{data.SitNom}</span>
        </Col>
      </Row>

      <Row className="mb-2 align-items-baseline flex-nowrap">
        <Col xs="4">
          <span className={styles.label}>{t('performance.ecart')}&nbsp;:</span>
        </Col>

        <Col xs="8" style={{ textAlign: 'right' }}>
          <span className={styles.gap}>
            {data.EcartPourcentage > 0 && '+'}
            {noDecimalFormat(data.EcartPourcentage)}%
            {data.EcartPourcentage !== 0 && (
              <i className={`icon-bigarrow-${data.EcartPourcentage > 0 ? 'up' : 'down'}`} />
            )}
          </span>
        </Col>
      </Row>

      <Row className="mb-3 align-items-baseline">
        <Col>
          <span className={styles.label}>{data.LibelleValeur}&nbsp;:</span>
          <span className={styles.value}>{noDecimalFormat(data.Valeur)}</span>
        </Col>
      </Row>

      <Row className="mb-2 align-items-baseline">
        <Col>
          <span className={styles.label}>{data.LibelleValeurComparaison}&nbsp;:</span>
          <span className={styles.value}>{noDecimalFormat(data.ValeurComparaison)}</span>
        </Col>
      </Row>

      <Row className="mb-4 align-items-baseline">
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <span className={styles.label}>
              {t('performance.ecart')}&nbsp;-&nbsp;{data.UniteConso}&nbsp;:
            </span>
            <span className={styles.value}>{noDecimalFormat(data.Ecart)}</span>
          </div>

          <div>
            <Button
              size="sm"
              color="link"
              onClick={() => {
                dispatch(setToFocus(data.SitId))
                history.push('/global-consumption')
              }}
              title={t('topNav.globalConsumption')}
              style={{ textDecoration: 'none' }}
            >
              <i className="icon-diag" style={{ fontSize: 16 }} />
            </Button>

            <Button
              size="sm"
              color="link"
              onClick={() => {
                dispatch(setToFocus(data.SitId))
                history.push('/detailed-consumption')
              }}
              title={t('topNav.detailedConsumption')}
              style={{ textDecoration: 'none' }}
            >
              <i className="icon-ccourbes" style={{ fontSize: 16 }} />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default TopFlopCard
export { TopFlopCard }
