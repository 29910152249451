import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Table } from 'reactstrap'
import get from 'lodash/get'
import set from 'lodash/fp/set'

import { getSiteEntites } from 'store/site'
import CustomInput from './CustomInput'
import UorSelect from './UorSelect'

const reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return set(action.path, action.value, state)
    case 'setState':
      return action.payload
    default:
      return state
  }
}

const InfoItem = ({ text }) => {
  return (
    <div
      style={{
        minHeight: 34,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        width: 220,
      }}
    >
      {text}
    </div>
  )
}

const Address = (props) => {
  const { address, compl, code, city, country, email } = props

  const isEmpty = Object.values(props).every((prop) => !prop)

  return (
    <div style={{ marginTop: '1rem', lineHeight: 2 }}>
      {isEmpty ? (
        '-'
      ) : (
        <>
          <span>{address}</span>
          {compl && (
            <span>
              <br />
              {compl}
            </span>
          )}
          <br />
          <span>
            {code} {city}
          </span>
          {country && (
            <span>
              <br />
              {country}
            </span>
          )}
          <br />
          <span>{email}</span>
        </>
      )}
    </div>
  )
}

const InformationForms = ({ data, edit }) => {
  const { t } = useTranslation()
  const [state, dispatch] = React.useReducer(reducer, data)
  const inputProps = { state, dispatch }

  useEffect(() => {
    dispatch({ type: 'setState', payload: data })
  }, [data])

  return (
    <Row>
      <Col>
        <h4>Informations</h4>
        <Table className="simpleTable" size="sm" borderless>
          <tbody>
            <tr>
              <th className="align-middle" scope="row">
                {t('site.admin.legal-entity')}
              </th>
              <td>
                {edit ? (
                  <CustomInput {...inputProps} path="PersonneMorale.RaisonSociale" />
                ) : (
                  <InfoItem text={get(state, 'PersonneMorale.RaisonSociale', '-')} />
                )}
              </td>
            </tr>
            <tr>
              <th className="align-middle" scope="row">
                {t('site.admin.organisation')}
              </th>
              <td>
                {edit ? (
                  <UorSelect id={get(state, 'Organisation.id', '')} />
                ) : (
                  <InfoItem text={get(state, 'Organisation.UorNom', '-')} />
                )}
              </td>
            </tr>
            <tr>
              <th className="align-middle" scope="row">
                {t('site.admin.code')}
              </th>
              <td>
                {edit ? (
                  <CustomInput {...inputProps} path="SitClef" />
                ) : (
                  <InfoItem text={get(state, 'SitClef', '-')} />
                )}
              </td>
            </tr>
            <tr>
              <th className="align-middle" scope="row">
                {t('site.admin.siret')}
              </th>
              <td>
                {edit ? (
                  <CustomInput {...inputProps} path="Siret" />
                ) : (
                  <InfoItem text={get(state, 'Siret', '-')} />
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>

      <Col>
        <h4>{t('site.admin.adress-delivery')}</h4>
        {edit ? (
          <Table className="simpleTable" size="sm" borderless>
            <tbody>
              <tr>
                <td colSpan="2">
                  <CustomInput
                    {...inputProps}
                    path="SphAdresse.AdrAdresse"
                    placeholder={t('site.admin.address')}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <CustomInput
                    {...inputProps}
                    path="SphAdresse.AdrCompl1"
                    placeholder={t('site.admin.addAddress')}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <CustomInput
                    {...inputProps}
                    path="SphAdresse.AdrCodePostal"
                    placeholder={t('site.admin.postalCode')}
                  />
                </td>
                <td>
                  <CustomInput
                    {...inputProps}
                    path="SphAdresse.AdrVille"
                    placeholder={t('site.admin.city')}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <CustomInput
                    {...inputProps}
                    path="SphAdresse.AdrPays"
                    placeholder={t('site.admin.country')}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Address
            address={get(state, 'SphAdresse.AdrAdresse', '')}
            compl={get(state, 'SphAdresse.AdrCompl1', '')}
            code={get(state, 'SphAdresse.AdrCodePostal', '')}
            city={get(state, 'SphAdresse.AdrVille', '')}
            country={get(state, 'SphAdresse.AdrPays', '')}
          />
        )}
      </Col>

      <Col>
        <h4>{t('site.admin.adress-bill')}</h4>
        {edit ? (
          <Table className="simpleTable" size="sm" borderless>
            <tbody>
              <tr>
                <td colSpan="2">
                  <CustomInput
                    {...inputProps}
                    path="SitFacAdresse.AdrAdresse"
                    placeholder={t('site.admin.address')}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <CustomInput
                    {...inputProps}
                    path="SitFacAdresse.AdrCompl1"
                    placeholder={t('site.admin.addAddress')}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <CustomInput
                    {...inputProps}
                    path="SitFacAdresse.AdrCodePostal"
                    placeholder={t('site.admin.postalCode')}
                  />
                </td>
                <td>
                  <CustomInput
                    {...inputProps}
                    path="SitFacAdresse.AdrVille"
                    placeholder={t('site.admin.city')}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <CustomInput
                    {...inputProps}
                    path="SitFacAdresse.AdrPays"
                    placeholder={t('site.admin.country')}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <CustomInput
                    {...inputProps}
                    path="SitFacAdresse.Email"
                    placeholder="Email"
                    type="email"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Address
            address={get(state, 'SitFacAdresse.AdrAdresse', '')}
            compl={get(state, 'SitFacAdresse.AdrCompl1', '')}
            code={get(state, 'SitFacAdresse.AdrCodePostal', '')}
            city={get(state, 'SitFacAdresse.AdrVille', '')}
            country={get(state, 'SitFacAdresse.AdrPays', '')}
            email={get(state, 'SitFacAdresse.Email', '')}
          />
        )}
      </Col>
    </Row>
  )
}

const mapState = (state) => ({
  data: getSiteEntites(state),
})

export default connect(mapState)((props) => {
  return props.data ? <InformationForms {...props} /> : null
})
