import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withState, withHandlers, lifecycle, shouldUpdate } from 'recompose'
import { Container, Row, Col, Form, Button, Popover, Input } from 'reactstrap'
import Select, { components } from 'react-select'
import classnames from 'classnames'
import moment from 'moment'
import flatten from 'lodash/flatten'
import { dateFormat } from '../../../helpers/formatters'

import styles from './ActionPopover.module.scss'
import InputDatepicker from '../../common/InputDatepicker'
import { Loader } from '../../common/Loader'

import { deleteAction, getModels, getAdding, getDeleting, getUpdating } from '../../../store/alerts'
import { getListPee, getListPeeIsLoading, getListPeeError, fetchPees } from '../../../store/pee'
import { getCurrentSitesByUor } from '../../../store/currentSelection'

const GroupHeading = (props) => (
  <components.GroupHeading {...props}>
    <span>{props.children}</span>
  </components.GroupHeading>
)

const Option = (props) => (
  <components.Option {...props}>
    <span
      className={
        props.data.nrj === 2
          ? styles.optionIconElec
          : props.data.nrj === 1
          ? styles.optionIconGaz
          : styles.optionIconWater
      }
    >
      <i
        className={
          props.data.nrj === 2 ? 'icon-elec' : props.data.nrj === 1 ? 'icon-gaz' : 'icon-tint'
        }
      />
    </span>
    {props.label}
  </components.Option>
)

const ActionPopover = (props) => {
  const { t } = useTranslation()

  const modelOptions = props.models.map(({ Id, Titre }) => ({
    value: Id,
    label: Titre,
  }))

  const siteOptions = props.sites.map(({ name, sites }) => ({
    label: name,
    options: sites.map(({ id, SphNom }) => ({
      value: id,
      label: SphNom,
    })),
  }))

  const peeOptions = props.pees.map(({ id, PeeClef, PeeNrjId }) => ({
    value: id,
    label: PeeClef,
    nrj: PeeNrjId,
  }))

  const stateOptions = [
    { value: 1, label: 'Nouveau' },
    { value: 2, label: 'En cours' },
    { value: 3, label: 'Résolu' },
    { value: 4, label: 'Abandon' },
  ]

  const isLoading = props.adding || props.deleting || props.updating

  return (
    <Popover
      className={styles.popover}
      innerClassName={styles.inner}
      placement={props.placement}
      isOpen={props.isOpen}
      target={props.target}
    >
      <Form
        onSubmit={props.save}
        className={classnames({
          [styles.formLoading]: props.adding || props.deleting || props.updating,
        })}
      >
        <Container>
          <Row className="mt-3 mb-3 ml-1 mr-1">
            <Col>
              <label>{t('action.popover.date')}</label>
              <InputDatepicker
                inputProps={{
                  placeholder: dateFormat(),
                  required: true,
                }}
                value={props.date}
                onChange={(date) => props.setDate(moment(date).format(dateFormat()))}
                className={styles.datepicker}
              />
            </Col>
            <Col>
              <label>{t('action.popover.state')}</label>
              <Select
                value={stateOptions.find((option) => option.value === props.state) || null}
                onChange={(option) => props.setState(option.value)}
                options={stateOptions}
                className="react-select"
                classNamePrefix="react-select"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3 ml-1 mr-1">
            <Col>
              <label>{t('action.popover.model')}</label>
              <Select
                placeholder={t('action.popover.modelPlaceholder')}
                value={modelOptions.find((option) => option.value === props.model)}
                onChange={(option) => props.setModel(option.value)}
                options={modelOptions}
                className="react-select"
                classNamePrefix="react-select"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3 ml-1 mr-1">
            <Col>
              <label>{t('action.popover.site')}</label>
              <Select
                placeholder={t('action.popover.sitePlaceholder')}
                value={flatten(siteOptions.map((o) => o.options)).find(
                  (option) => option.value === props.site,
                )}
                onChange={(option) => props.setSite(option.value)}
                options={siteOptions}
                className="react-select"
                classNamePrefix="react-select"
                components={{ GroupHeading }}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3 ml-1 mr-1">
            <Col>
              <label>{t('action.popover.cpt')}</label>
              <Select
                isDisabled={props.site === ''}
                placeholder={t('action.popover.cptPlaceholder')}
                value={peeOptions.find((option) => option.value === props.pdl) || null}
                onChange={(option) => props.setPdl(option.value)}
                options={peeOptions}
                className="react-select"
                classNamePrefix="react-select"
                components={{ Option }}
                isLoading={props.peesIsLoading}
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%',
                  }),
                }}
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3 ml-1 mr-1">
            <Col>
              <label>{t('action.popover.title')}</label>
              <Input
                className={styles.textInput}
                value={props.alert}
                onChange={(e) => props.setAlert(e.target.value)}
                type="text"
                required={true}
              />
            </Col>
          </Row>

          <Row className="mt-2 mb-3 ml-1 mr-1">
            <Col>
              <label>{t('action.popover.comment')}</label>
              <Input
                className={styles.textInput}
                value={props.comment}
                onChange={(e) => props.setComment(e.target.value)}
                type="text"
              />
            </Col>
          </Row>

          <Row className="mt-4 mb-3 ml-1 mr-1">
            <Col className="text-right">
              {props.action && (
                <Button size="sm" color="link" onClick={() => props.delete(props.action.Id)}>
                  {t('action.popover.delete')}
                </Button>
              )}

              <Button
                type="submit"
                size="sm"
                color="primary"
                className={classnames(styles.btnSave)}
                disabled={props.state === '' || props.model === '' || props.pdl === '' || isLoading}
              >
                {t('action.popover.save')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>

      {isLoading && (
        <Loader
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            backgroundColor: 'rgba(255,255,255,0.7)',
            zIndex: 10,
          }}
        />
      )}

      <Button size="sm" className={styles.btnClose} onClick={props.toggle}>
        <i className="icon-cross" />
      </Button>
    </Popover>
  )
}

const mapStateToProps = (state, ownProps) => ({
  pees: getListPee(state, ownProps.site),
  peesIsLoading: getListPeeIsLoading(state, ownProps.site),
  peesError: getListPeeError(state, ownProps.site),
  sites: getCurrentSitesByUor(state),
  models: getModels(state),
  adding: getAdding(state),
  deleting: getDeleting(state),
  updating: getUpdating(state),
})

const mapDispatchToProps = {
  fetchPees,
  deleteAction,
}

export default compose(
  shouldUpdate(
    (props, nextProps) =>
      props.isOpen !== nextProps.isOpen || props.shouldClose !== nextProps.shouldClose,
  ),
  withState('site', 'setSite', ({ action }) => (action && action.SiteId) || ''),
  connect(mapStateToProps, mapDispatchToProps),
  withState(
    'date',
    'setDate',
    ({ action }) =>
      (action &&
        moment(action.DateResolutionAttendue).isValid() &&
        moment(action.DateResolutionAttendue).format('DD/MM/YYYY')) ||
      '',
  ),
  withState('model', 'setModel', ({ action }) => (action && action.ModelId) || ''),
  withState('pdl', 'setPdl', ({ action }) => (action && action.CompteurId) || ''),
  withState('alert', 'setAlert', ({ action }) => (action && action.Titre) || ''),
  withState('comment', 'setComment', ({ action }) => (action && action.Commentaire) || ''),
  withState('state', 'setState', ({ action }) => (action && action.StatutId) || 1),
  withState('shouldClose', 'setShouldClose', false),
  withHandlers({
    close: (props) => () => props.setShouldClose(true),
  }),
  withHandlers({
    delete:
      ({ deleteAction, close }) =>
      (id) => {
        deleteAction(id)
        close()
      },
    save: (props) => (e) => {
      e.preventDefault()
      const payload = {
        ModelId: props.model,
        DateResolutionAttendue: props.date,
        Titre: props.alert,
        Commentaire: props.comment,
        CompteurId: props.pdl,
        StatutId: props.state,
        ...(props.action ? { Gain: props.action.Gain } : {}),
      }

      if (props.action) payload.Id = props.action.Id
      props.onSave(payload)

      props.setDate('')
      props.setModel('')
      props.setSite('')
      props.setPdl('')
      props.setAlert('')
      props.setComment('')
      props.setState(1)

      props.close()
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const {
        site,
        fetchPees,
        toggle,
        setShouldClose,
        shouldClose,
        adding,
        deleting,
        updating,
        pees,
        peesIsLoading,
        peesError,
      } = this.props

      if (!!site && pees.length === 0 && !peesIsLoading && !peesError) {
        fetchPees(site)
      }

      if (shouldClose && !adding && !deleting && !updating) {
        setShouldClose(false)
        toggle()
      }
    },
  }),
)(ActionPopover)

export { ActionPopover }
