import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import styles from './List.module.scss'
import { ReactComponent as MarkerIconBlue } from '../../../assets/images/marker-icon-blue.svg'
import { ReactComponent as MarkerIconBlueNavy } from '../../../assets/images/marker-icon-blueNavy.svg'
import { ReactComponent as MarkerIconGrey } from '../../../assets/images/marker-icon-grey.svg'
import { toggleSiteInSelection } from 'store/currentSelection'
import { getUor } from 'store'

const List = (props) => {
  const { className, dispatch, toggleSiteInSelection, uor } = props
  const { t } = useTranslation()
  const sites = props.sites.filter((site) => props.visibleSites.includes(site.id))

  return (
    <div className={classnames(styles.list, className)}>
      <div className={styles.header}>{t('site.list.title')}</div>
      <ListGroup className={styles.group}>
        {sites.map(({ id, name, address, isSelected }) => (
          <ListGroupItem className={styles.item} key={id}>
            <div className={styles.text}>
              {isSelected ? (
                // uor === '1254' ? (
                uor === '620' ? (
                  <MarkerIconBlueNavy className={styles.marker} />
                ) : (
                  <MarkerIconBlue className={styles.marker} />
                )
              ) : (
                <MarkerIconGrey className={styles.marker} />
              )}
              <div className={styles.name}>{name}</div>
            </div>
            <div className={styles.buttons}>
              <i
                className={`${isSelected ? 'icon-cross' : 'icon-android-add'} ${styles.link}`}
                title={t(isSelected ? 'site.popup.remove' : 'site.popup.add')}
                style={{
                  fontSize: isSelected ? 10 : 16,
                }}
                onClick={() => {
                  dispatch(toggleSiteInSelection(id))
                }}
              />
              <Link to={`/site/${id}`} className={styles.link}>
                <i
                  className={`icon-ico-edit`}
                  style={{
                    fontSize: 11,
                  }}
                />
              </Link>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}

const mapState = (state) => ({
  toggleSiteInSelection: (siteId) => toggleSiteInSelection(state, siteId),
  uor: getUor(state),
})

export default connect(mapState)(List)

export { List }
