import React from 'react'
import classnames from 'classnames'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { Field } from 'components/form/Field'
import { useCalendarForm } from './useCalendarForm'

export const CalendarForm = () => {
  const { t } = useTranslation()
  const { initialValues, validationSchema, submit, isLoading, days } = useCalendarForm()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values) => submit(values)}
    >
      {(props) => {
        const hasError = Object.keys(props.errors).length > 0
        return (
          <Form className={classnames('space-y-6', { isLoading })}>
            <table className="table table-sm table-striped calendar-table border">
              <thead>
                <tr>
                  <th></th>
                  <th>{t('site.calendrier.debut')}</th>
                  <th>{t('site.calendrier.fin')}</th>
                </tr>
              </thead>

              <tbody>
                {days.map((day) => (
                  <tr key={day.id}>
                    <th>{day.name}</th>
                    <td>
                      <Field name={`[${day.id}].DebutOuverture`} placeholder="hh:mm" />
                    </td>
                    <td>
                      <Field name={`[${day.id}].FinOuverture`} placeholder="hh:mm" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <footer className="space-x-4">
              <button
                type="reset"
                disabled={!props.dirty || props.isSubmitting}
                className="btn btn-sm btn-light"
              >
                {t('global.reset')}
              </button>

              <button
                type="submit"
                disabled={!props.dirty || props.isSubmitting || hasError}
                className="btn btn-sm btn-primary"
              >
                {t('global.valider')}
              </button>
            </footer>
          </Form>
        )
      }}
    </Formik>
  )
}
