import React from 'react'

export const FileInput = ({ onChange, disabled = false, children }) => {
  return (
    <label className="btn btn-primary btn-sm" style={{ fontSize: '0.875rem', color: '#fff' }}>
      <input
        style={{ display: 'none' }}
        disabled={disabled}
        type="file"
        accept=".csv"
        onChange={(e) => {
          e.persist()
          onChange(e.target.files[0])
          e.target.value = ''
        }}
      />

      <span>{children}</span>
    </label>
  )
}
