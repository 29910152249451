import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { Table, Button } from 'reactstrap'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'

import { noDecimalFormat, decimalFormat } from '../../../helpers/formatters'
import styles from './TopFlopTableKpi.module.scss'
import { setToFocus } from 'store/currentSelection'

const TopFlopTableKpi = ({ data, history, setToFocus }) => {
  const { t } = useTranslation()

  return (
    <Table striped borderless hover className={classnames('table-fixed', styles.table)}>
      <thead>
        <tr>
          <th>{t('performance.entite')}</th>
          <th style={{ textAlign: 'right' }}>{data[0] && data[0].LibelleValeur}</th>
          <th style={{ textAlign: 'right' }}>{data[0] && data[0].LibelleValeurKpi}</th>
          <th style={{ textAlign: 'right' }}>{data[0] && data[0].LibelleValeurPerf}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.map(({ SitNom, Valeur, ValeurKpi, ValeurPerf, SitId }, index) => (
          <tr key={index}>
            <td>
              {index + 2}. {SitNom}
            </td>
            <td style={{ textAlign: 'right' }}>{noDecimalFormat(Valeur)}</td>
            <td style={{ textAlign: 'right' }}>{noDecimalFormat(ValeurKpi)}</td>
            <td style={{ textAlign: 'right' }}>{decimalFormat(ValeurPerf)}</td>
            <td
              style={{
                textAlign: 'right',
                verticalAlign: 'middle',
                padding: 4,
              }}
            >
              <Button
                size="sm"
                color="link"
                onClick={() => {
                  setToFocus(SitId)
                  history.push('/global-consumption')
                }}
                title={t('topNav.globalConsumption')}
                style={{ textDecoration: 'none' }}
              >
                <i className="icon-diag" style={{ fontSize: 16 }} />
              </Button>

              <Button
                size="sm"
                color="link"
                onClick={() => {
                  setToFocus(SitId)
                  history.push('/detailed-consumption')
                }}
                title={t('topNav.detailedConsumption')}
                style={{ textDecoration: 'none' }}
              >
                <i className="icon-ccourbes" style={{ fontSize: 16 }} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { setToFocus }

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(TopFlopTableKpi)

export { TopFlopTableKpi }
