import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withHandlers, lifecycle, withState } from 'recompose'
import { Container, Row, Col, Table, Form, FormGroup, Label } from 'reactstrap'
import Select from 'react-select'
import classnames from 'classnames'
import moment from 'moment'

import { getPeeDetail, fetchPee, updatePeeField } from '../../../store/pee'
import { fetchAcheminement, getAcheminement } from '../../../store/acheminement'
import { getRegimes, fetchRegimes } from '../../../store/regimes'

import styles from './Pee.module.scss'
import InputDatepicker from '../../common/InputDatepicker'
import { dateFormat } from '../../../helpers/formatters'

const Pee = ({
  details,
  regimes,
  acheminement,
  selectedFta,
  setSelectedFta,
  dateEntree,
  setDateEntree,
  dateSortie,
  setDateSortie,
  updatePeeField,
  isAdmin,
  fetchData,
}) => {
  const { t } = useTranslation()
  const [hrsPte, setHrsPte] = useState(details.PeeLocalHrsPte)
  const [hrsC, setHrsC] = useState(details.PeeLocalHrsC)

  useEffect(() => {
    setHrsPte(details.PeeLocalHrsPte)
    setHrsC(details.PeeLocalHrsC)
  }, [details])

  const networkOptions = [{ value: 0, label: details.NomReseau }]

  const ftaOptions = acheminement
    .sort((a, b) => {
      const d1 = moment(a.AchFtaDateDerniereModification, 'DD/MM/YYYY')
      const d2 = moment(b.AchFtaDateDerniereModification, 'DD/MM/YYYY')
      return d1.isBefore(d2) ? 1 : d1.isAfter(d2) ? -1 : 0
    })
    .map((fta, index) => ({
      value: index,
      label: fta.AchFtaDateDerniereModification,
    }))

  const ftaValue = ftaOptions.find((option) => option.value === selectedFta)

  function updateDates(field, date, setDate) {
    const isValid = moment(date, dateFormat(), true).isValid()
    let action = 'replace'
    if (!isValid) {
      if (date === 'undefined' || date === '') {
        action = 'remove'
      } else return
    }
    if (!isValid) {
      setDate('')
    } else {
      setDate(moment(date).format(dateFormat()))
    }

    updatePeeField(details.id, field, action, date)
  }

  function updateDateEntree(date) {
    updateDates('DateEntree', date, setDateEntree)
  }

  function updateDateSortie(date) {
    updateDates('DateSortie', date, setDateSortie)
  }

  const code = acheminement[0]?.Code
  const isHTA = code === 39
  const isBTSup = code === 40
  const isBTInf = code === 43

  return (
    <Container className="box">
      <Row>
        <Col>
          <h4>{t('site.pee-details.general-informations')}</h4>
        </Col>
      </Row>
      <Row>
        <Col xs="4">
          <Table className="simpleTable" size="sm">
            <tbody>
              <tr>
                <th scope="row">{t('site.pee-details.num')}</th>
                <td>{details.PeeClef}</td>
              </tr>
              <tr>
                <th scope="row">{t('site.pee-details.commodity')}</th>
                <td>{details.PeeNrj}</td>
              </tr>
              {details.PeeSegment ? (
                <tr>
                  <th scope="row">Segment</th>
                  <td>{details.PeeSegment}</td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Col>

        <Col xs="4">
          <Form>
            <FormGroup size="sm">
              <Label for="managerSelect" className={styles.label}>
                {t('site.pee-details.reseau')}
              </Label>
              {isAdmin ? (
                <Select
                  id="manager-select"
                  value={networkOptions[0]}
                  onChange={() => {}}
                  options={networkOptions}
                  className="react-select react-select--small"
                  classNamePrefix="react-select"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      marginTop: '14px',
                      width: '280px',
                    }),
                  }}
                />
              ) : (
                <div style={{ marginTop: '.8rem' }}>{networkOptions[0].label}</div>
              )}
            </FormGroup>
          </Form>
        </Col>

        <Col xs="4">
          <Container>
            <Row>
              <Col xs="5" className="text-right">
                <Label for="fromDatepicker" className={classnames('align-middle', styles.label)}>
                  {t('site.pee-details.active')}
                </Label>
              </Col>
              <Col xs="7">
                {isAdmin ? (
                  <InputDatepicker
                    className={styles.datepicker}
                    value={dateEntree}
                    onChange={updateDateEntree}
                    acceptNull={true}
                  />
                ) : (
                  dateEntree || '-'
                )}
              </Col>
            </Row>
            <Row>
              <Col xs="5" className="text-right">
                <Label for="toDatepicker" className={classnames('align-middle', styles.label)}>
                  {t('site.pee-details.inactive')}
                </Label>
              </Col>
              <Col xs="7">
                {isAdmin ? (
                  <InputDatepicker
                    className={styles.datepicker}
                    value={dateSortie}
                    onChange={updateDateSortie}
                    acceptNull={true}
                  />
                ) : (
                  dateSortie || '-'
                )}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          {acheminement.length > 0 && (
            <>
              <h4>{t('site.pee-details.routing.title')}</h4>
              <Table className="simpleTable" size="sm">
                <tbody>
                  <tr>
                    <th scope="row" className="align-middle">
                      {t('site.pee-details.routing.date')}
                    </th>
                    <td>
                      <Select
                        value={ftaValue}
                        onChange={(option) => setSelectedFta(option.value)}
                        options={ftaOptions}
                        className="react-select react-select--small"
                        classNamePrefix="react-select"
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: '150px',
                          }),
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{t('site.pee-details.routing.formule')}</th>
                    <td>{acheminement[selectedFta].AchFtaLibelle}</td>
                  </tr>
                </tbody>
              </Table>

              {acheminement[selectedFta].AchFtaParametres.length > 0 && (
                <Table striped bordered size="sm" className="stripedTable">
                  <thead>
                    <tr>
                      <th>{t('site.pee-details.routing.option')}</th>
                      <th>{t('site.pee-details.routing.value')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {acheminement[selectedFta].AchFtaParametres.map(
                      ({ FtaParamLibelle, FtaParamValeur }, index) => (
                        <tr key={index}>
                          <th scope="row">{FtaParamLibelle}</th>
                          <td>{FtaParamValeur}</td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </Table>
              )}

              <p css={{ marginTop: '2rem', fontWeight: 'bold' }}>
                {t('site.pee-details.routing.localHrs')}
              </p>

              {(isHTA || isBTSup || isBTInf) && (
                <Table className="simpleTable" size="sm" css={{ td: { border: 0 } }}>
                  <tbody>
                    {isHTA && (
                      <tr>
                        <td className="align-middle">{t('site.pee-details.routing.peak')}</td>
                        <td>
                          {isAdmin ? (
                            <input
                              className="form-control"
                              placeholder="-"
                              css={{ '&::placeholder': { color: '#6c757d66' } }}
                              value={hrsPte ?? ''}
                              onChange={(e) => setHrsPte(e.target.value)}
                            />
                          ) : (
                            hrsPte ?? '-'
                          )}
                        </td>
                        <td className="align-middle">
                          {isAdmin ? (
                            <Button
                              size="sm"
                              color=""
                              onClick={async () => {
                                await updatePeeField(
                                  details.id,
                                  'PeeLocalHrsPte',
                                  'replace',
                                  hrsPte,
                                )
                                fetchData()
                              }}
                              disabled={
                                (hrsPte && hrsPte.trim().length === 0) ||
                                hrsPte === details.PeeLocalHrsPte
                              }
                            >
                              <i className="icon-done" style={{ color: 'rgb(97, 116, 127)' }} />
                            </Button>
                          ) : null}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td className="align-middle">{t('site.pee-details.routing.offPeak')}</td>
                      <td>
                        {isAdmin ? (
                          <input
                            className="form-control"
                            placeholder="-"
                            css={{ '&::placeholder': { color: '#6c757d66' } }}
                            value={hrsC ?? ''}
                            onChange={(e) => setHrsC(e.target.value)}
                          />
                        ) : (
                          hrsC ?? '-'
                        )}
                      </td>
                      <td className="align-middle">
                        {isAdmin ? (
                          <Button
                            size="sm"
                            color=""
                            onClick={async () => {
                              await updatePeeField(details.id, 'PeeLocalHrsC', 'replace', hrsC)
                              fetchData()
                            }}
                            disabled={
                              (hrsC && hrsC.trim().length === 0) || hrsC === details.PeeLocalHrsC
                            }
                          >
                            <i className="icon-done" style={{ color: 'rgb(97, 116, 127)' }} />
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
            </>
          )}
        </Col>
        <Col>
          <h4>{t('site.pee-details.informations-releve')}</h4>
          <Table className="simpleTable" size="sm">
            <tbody>
              <tr>
                <th scope="row">{t('site.pee-details.releve')}</th>
                <td>{details.ModeReleve}</td>
              </tr>
              <tr>
                <th scope="row">{t('site.pee-details.status')}</th>
                <td>{details.EtatReleve}</td>
              </tr>
              <tr>
                <th scope="row">{t('site.pee-details.frequence')}</th>
                <td>{details.FrequenceReleve}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row>
        {regimes && regimes.length > 0 ? (
          <Col xs="6">
            <h4>{t('site.pee-details.regimes-privilegies')}</h4>
            <Table striped bordered size="sm" className="stripedTable">
              <thead>
                <tr>
                  <th>{t('site.pee-details.year')}</th>
                  <th>{t('site.pee-details.exoneration')}</th>
                </tr>
              </thead>
              <tbody>
                {regimes.map(({ Annee, Description }, index) => (
                  <tr key={index}>
                    <th scope="row">
                      <div className={styles.regime}>{Annee}</div>
                    </th>
                    <td>
                      {Description.map((d, i) => (
                        <div key={i} className={styles.regime}>
                          {d}
                        </div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        ) : null}
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const ach = getAcheminement(state)
  const listFta = ach[0] ? ach[0].AchListeFta : []

  return {
    details: getPeeDetail(state),
    dateEnter: getPeeDetail(state),
    dateExit: getPeeDetail(state),
    acheminement: listFta,
    regimes: getRegimes(state),
  }
}

const mapDispatchToProps = {
  fetchPee,
  fetchAcheminement,
  fetchRegimes,
  updatePeeField,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectedFta', 'setSelectedFta', 0),
  withState('dateEntree', 'setDateEntree', null),
  withState('dateSortie', 'setDateSortie', null),
  withHandlers({
    fetchData: (props) => () => {
      props.fetchPee(props.id)
      props.fetchAcheminement([props.id])
      props.fetchRegimes(props.id)
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchData()
    },
    componentDidUpdate(prevProps) {
      if (prevProps.id !== this.props.id) this.props.fetchData()
      if (prevProps.dateEnter !== this.props.dateEnter) {
        const date = this.props.details.DateEntree
        this.props.setDateEntree(!date ? null : moment(date).format(dateFormat()))
      }
      if (prevProps.dateExit !== this.props.dateExit) {
        const date = this.props.details.DateSortie
        this.props.setDateSortie(!date ? null : moment(date).format(dateFormat()))
      }
    },
  }),
)(Pee)

export { Pee }
