import { combineReducers } from 'redux'
import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchSite: (siteId) =>
    superFetch({
      url: `sites/${siteId}`,
    }),

  fetchSitePee: (siteId) =>
    superFetch({
      url: `sites/${siteId}/pees`,
    }),

  updateSiteField: (siteId, field, action, value) => {
    const sitePatch = [{ op: action, path: field }]
    if (action !== 'remove') {
      sitePatch[0].value = value
    }
    return superFetch({
      url: `sites/${siteId}`,
      method: 'PATCH',
      body: sitePatch,
    })
  },
}

// Actions
export const CLOSE_SITE = 'esite/site/CLOSE_SITE'
export const FETCH_SITE_REQUEST = 'FETCH_SITE_REQUEST'
export const FETCH_SITE_SUCCESS = 'esite/site/FETCH_SITE_SUCCESS'
export const FETCH_SITE_FAILURE = 'esite/site/FETCH_SITE_FAILURE'
export const UPDATE_SITE_REQUEST = 'esite/site/UPDATE_SITE_REQUEST'
export const UPDATE_SITE_SUCCESS = 'esite/site/UPDATE_SITE_SUCCESS'
export const UPDATE_SITE_FAILURE = 'esite/site/UPDATE_SITE_FAILURE'
export const FETCH_TEMPLATE_REQUEST = 'esite/site/FETCH_TEMPLATE_REQUEST'
export const FETCH_TEMPLATE_SUCCESS = 'esite/site/FETCH_TEMPLATE_SUCCESS'
export const FETCH_TEMPLATE_FAILURE = 'esite/site/FETCH_TEMPLATE_FAILURE'

// Reducer
const siteUuid = (state = '', action) => {
  switch (action.type) {
    case FETCH_SITE_REQUEST:
      return action.uuid
    case CLOSE_SITE:
      return ''
    default:
      return state
  }
}

const errorMessageSite = (state = null, action) => {
  switch (action.type) {
    case FETCH_SITE_FAILURE:
      return action.message
    case FETCH_SITE_REQUEST:
    case FETCH_SITE_SUCCESS:
      return null
    default:
      return state
  }
}

const siteEntities = (state = '', action) => {
  switch (action.type) {
    case FETCH_SITE_SUCCESS:
      return action.response
    case CLOSE_SITE:
      return ''
    default:
      return state
  }
}

const template = (state = { fetching: false, csv: '', error: '' }, action) => {
  switch (action.type) {
    case FETCH_TEMPLATE_REQUEST:
      return {
        ...state,
        fetching: true,
        csv: '',
        error: '',
      }
    case FETCH_TEMPLATE_FAILURE:
      return {
        ...state,
        fetching: false,
        csv: '',
        error: action.error,
      }
    case FETCH_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetching: false,
        csv: action.csv,
        error: '',
      }
    default:
      return state
  }
}

export default combineReducers({
  siteUuid,
  errorMessageSite,
  siteEntities,
  template,
})

// Selectors
export const getErrorMessageSite = (state) => state.site.errorMessageSite
export const getSiteId = (state) => state.site.siteEntities.id || null
export const getSiteEntites = (state) => state.site.siteEntities

// Action Creators
export const closeSite = () => ({ type: CLOSE_SITE })

// Thunks
export const fetchSite = (siteId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_SITE_REQUEST,
    uuid,
  })

  return api.fetchSite(siteId).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_SITE_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_SITE_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_SITE_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const updateSiteField = (siteId, siteField, action, value) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: UPDATE_SITE_REQUEST,
    uuid,
  })

  return api.updateSiteField(siteId, siteField, action, value).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: UPDATE_SITE_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: UPDATE_SITE_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: UPDATE_SITE_FAILURE,
        message: error.message || 'Erreur lors de la mise à jour du site',
      })
    },
  )
}
