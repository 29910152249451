import React from 'react'
import { TabContent, TabPane } from 'reactstrap'

import SidebarCurrent from './SidebarCurrent'

const SidebarTabs = () => {
  const activeTab = 'SELECTION'
  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId="SELECTION" className="current-selection-tab">
        <SidebarCurrent />
      </TabPane>
    </TabContent>
  )
}

export default SidebarTabs
