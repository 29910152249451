import React from 'react'
import { connect } from 'react-redux'
import { compose, withState, withHandlers } from 'recompose'
import { Input, Button } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'

import { getSiteId } from '../../../store/site'
import { modifyPatrimonial, deletePatrimonial } from '../../../store/patrimonial'
import { withLoader } from '../../common/Loader'
import styles from './Patrimonials.module.scss'
import KpiPatrimonials from './KpiPatrimonials'
import { dateFormat } from 'helpers/formatters'

const formatDate = (value) => {
  const m = moment(value)
  if (m.isValid()) return m.format(dateFormat())
  return '\u2013'
}

const Patrimonial = ({
  edit,
  toggleEdit,
  update,
  select,
  inputValue,
  setSelect,
  updateInputValue,
  deletePat,
  unite,
  referentiel,
  isAdmin,
  date,
  setDate,
}) => {
  return (
    <tr
      className={classnames({
        [styles.edit]: edit,
      })}
    >
      <td className="align-middle">
        <KpiPatrimonials edit={edit} select={select} setSelect={setSelect} />
      </td>
      <td className="align-middle d-flex align-items-center">
        {edit ? (
          <>
            <Input
              type="number"
              className={styles.input}
              value={inputValue}
              onChange={updateInputValue}
            />
            {!!select && referentiel.find((r) => r.Id === select).UniteNom}
          </>
        ) : (
          <span className={styles.value}>
            {inputValue} {unite}
          </span>
        )}
      </td>
      <td className="align-middle">
        {edit ? (
          <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
        ) : (
          formatDate(date)
        )}
      </td>
      <td className="align-middle">
        {isAdmin && (
          <>
            <Button className={styles.btn} color="link" onClick={toggleEdit}>
              <i className={edit ? 'icon-cross' : 'icon-ico-edit'} />
            </Button>
            {edit ? (
              <Button
                className={styles.btn}
                color="link"
                onClick={update}
                disabled={!inputValue || !select}
              >
                <b>
                  <i className="icon-done" />
                </b>
              </Button>
            ) : (
              <Button className={styles.btn} color="link" onClick={deletePat}>
                <i className="icon-ico-trash" />
              </Button>
            )}
          </>
        )}
      </td>
    </tr>
  )
}

const mapStateToProps = (state) => ({
  siteId: getSiteId(state),
})

const mapDispatchToProps = {
  modifyPatrimonial,
  deletePatrimonial,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('select', 'setSelect', (props) => props.TypeIndicateur),
  withState('inputValue', 'setInputValue', (props) => props.Valeur),
  withState('date', 'setDate', (props) => props.Date ?? ''),
  withState('edit', 'setEdit', false),
  withHandlers({
    toggleEdit: (props) => () => props.setEdit(!props.edit),
  }),
  withHandlers({
    update: (props) => () => {
      props.modifyPatrimonial(props.siteId, props.Id, props.inputValue, props.select, props.date)
      props.toggleEdit()
    },
    updateInputValue: (props) => (e) => {
      props.setInputValue(e.target.value)
    },
    deletePat: (props) => () => {
      props.deletePatrimonial(props.siteId, props.Id)
    },
  }),
  withLoader,
)(Patrimonial)

export { Patrimonial }
