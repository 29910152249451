import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { QueryBoundary } from 'components/base/QueryBoundary'
import { DecretTertiaireForm } from './DecretTertiaireForm'
import { DecretTertiaireReadonly } from './DecretTertiaireReadonly'

type DecretTertiaireProps = {
  siteId: number
  isAdmin: boolean
}

export const DecretTertiaireSection = ({ siteId, isAdmin }: DecretTertiaireProps) => {
  const { t } = useTranslation()
  const [edit, setEdit] = useState(false)
  const toggleEdit = () => setEdit((edit) => !edit)

  return (
    <>
      <h3>
        <span>{t('site.decretTertiaire.title')}</span>
        {isAdmin && (
          <button
            type="button"
            onClick={toggleEdit}
            className="btn btn-sm ml-3 position-relative"
            style={{ top: '-0.25rem' }}
          >
            <i className={edit ? 'icon-cross' : 'icon-ico-edit'} style={{ color: '#61747f' }} />
          </button>
        )}
      </h3>

      <QueryBoundary height={100}>
        {edit ? (
          <DecretTertiaireForm siteId={siteId} />
        ) : (
          <DecretTertiaireReadonly siteId={siteId} />
        )}
      </QueryBoundary>
    </>
  )
}
