import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withProps, withState, withHandlers } from 'recompose'
import { Table, Button } from 'reactstrap'
import moment from 'moment'
import classnames from 'classnames'
import { dateFormat, decimalFormat } from '../../../helpers/formatters'

import styles from './ActionTable.module.scss'
import { deleteAction, getActions, updateAction, getSort } from '../../../store/alerts'
import { withLoader } from '../../common/Loader'
import ActionPopover from '../ActionPopover'

const sorts = ['CompteurNrj', 'Gain', 'StatutId', 'TypeRemontee', 'DateResolutionAttendue']

const ActionTable = ({ actions, deleteAction, popoverOpen, togglePopover, updateAction }) => {
  const { t } = useTranslation()
  return (
    <div className={classnames('box', styles.container)} style={{ minHeight: 0 }}>
      <Table striped borderless hover>
        <thead>
          <tr className="align-middle">
            <th>
              <span>{t('action.table.date')}</span>
            </th>

            <th style={{ width: '65px' }}>
              <span>{t('action.table.site')}</span>
            </th>

            <th style={{ width: '65px' }}>
              <span>{t('action.table.cpt')}</span>
            </th>

            <th style={{ width: '110px' }}>
              <span>{t('action.table.energy')}</span>
            </th>

            <th>
              <span>{t('action.table.alert')}</span>
            </th>

            <th style={{ width: '110px' }}>
              <span>{t('action.table.state')}</span>
            </th>

            <th>
              <span>{t('action.table.comment')}</span>
            </th>

            <th style={{ width: '160px' }}>
              <span>{t('action.table.gain')}</span>
            </th>

            <th>
              <span>{t('action.table.type')}</span>
            </th>

            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {actions.map((action) => (
            <tr key={action.Id} className="align-middle">
              <td>
                {moment(action.DateRemontee).isValid()
                  ? moment(action.DateRemontee).format(dateFormat())
                  : ''}
              </td>

              <td>{action.SiteNom}</td>

              <td>{action.CompteurClef}</td>

              <td>
                <div
                  className={classnames(styles.energy, {
                    [styles.gas]: action.CompteurNrj === 1,
                    [styles.elec]: action.CompteurNrj === 2,
                    [styles.water]: action.CompteurNrj === 8,
                  })}
                >
                  {action.CompteurNrj === 1
                    ? t('action.energy.gas')
                    : action.CompteurNrj === 2
                    ? t('action.energy.electricity')
                    : action.CompteurNrj === 8
                    ? t('action.energy.water')
                    : ''}
                </div>
              </td>

              <td className={styles.title}>{action.Titre}</td>

              <td>
                <span
                  className={classnames(styles.state, {
                    'state-item-new': action.StatutId === 1,
                    'state-item-inprogress': action.StatutId === 2,
                    'state-item-resolved': action.StatutId === 3,
                    'state-item-abandoned': action.StatutId === 4,
                  })}
                >
                  {action.StatutId === 1
                    ? t('action.state.new')
                    : action.StatutId === 2
                    ? t('action.state.inProgress')
                    : action.StatutId === 3
                    ? t('action.state.resolved')
                    : action.StatutId === 4
                    ? t('action.state.abandoned')
                    : ''}
                </span>
              </td>

              <td className={styles.comment}>{action.Commentaire}</td>

              <td className={styles.gain}>{decimalFormat(action.Gain)} €</td>

              <td>
                {action.TypeRemontee === 0
                  ? t('action.type.manual')
                  : action.TypeRemontee === 1
                  ? 'ESITE'
                  : ''}
              </td>

              <td className={styles.edit}>
                <Button
                  id={`table_popover_${action.Id}`}
                  className={classnames(styles.btnEdit, {
                    [styles.active]: popoverOpen === action.Id,
                  })}
                  onClick={() => togglePopover(action.Id)}
                >
                  <i className="icon-ico-edit" />
                </Button>

                <ActionPopover
                  placement="left-start"
                  target={`table_popover_${action.Id}`}
                  action={action}
                  isOpen={popoverOpen === action.Id}
                  toggle={() => togglePopover(action.Id)}
                  onSave={updateAction}
                />
              </td>
              <td className={styles.edit}>
                <Button
                  className={classnames(styles.btnEdit, {
                    [styles.active]: popoverOpen === action.Id,
                  })}
                  color="link"
                  onClick={() => deleteAction(action.Id)}
                >
                  <i className="icon-ico-trash" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => {
  const actions = getActions(state)
  const sort = getSort(state)
  const key = sorts[Math.abs(sort) - 1]
  const dir = sort > 0 ? 1 : -1
  const sortedActions = !actions
    ? []
    : actions.slice().sort((a, b) => (a[key] < b[key] ? dir : a[key] > b[key] ? -dir : 0))
  return {
    actions: sortedActions,
  }
}

const mapDispatchToProps = {
  updateAction,
  deleteAction,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProps((props) => ({
    isLoading: !props.actions,
  })),
  withState('popoverOpen', 'setPopoverOpen', ''),
  withHandlers({
    togglePopover:
      ({ popoverOpen, setPopoverOpen }) =>
      (id) => {
        setPopoverOpen(popoverOpen === id ? '' : id)
      },
  }),
  withLoader,
)(ActionTable)

export { ActionTable }
