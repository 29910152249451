import 'react-app-polyfill/ie11'
import 'typeface-muli'
import React from 'react'
import ReactDOM from 'react-dom'

import 'react-dropdown-tree-select/dist/styles.css'
import 'react-table-v6/react-table.css'
import '@reach/dialog/styles.css'
import 'assets/css/index.scss'

import App from './App'
import registerServiceWorker from './registerServiceWorker'

const root = document.getElementById('root')

ReactDOM.render(<App />, root)

registerServiceWorker()
