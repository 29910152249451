import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import Select from 'react-select'

import { getSiteId } from '../../../store/site'
import {
  getPatrimonialsRefrentiel,
  modifyPatrimonial,
  deletePatrimonial,
} from '../../../store/patrimonial'
import { withLoader } from '../../common/Loader'
import styles from './Patrimonials.module.scss'

const KpiPatrimonials = ({ edit, select, setSelect, patrimonialsReferentiel }) => {
  const selectOptions = patrimonialsReferentiel.map(({ Id, Nom }) => ({
    value: Id,
    label: Nom,
  }))

  const selectValue = selectOptions.find((option) => option.value === select)

  return (
    <div>
      {patrimonialsReferentiel.length > 0 && edit ? (
        <Select
          value={selectValue}
          onChange={(option) => setSelect(option.value)}
          options={selectOptions}
          className="react-select"
          classNamePrefix="react-select"
          styles={{
            container: (provided) => ({
              ...provided,
            }),
          }}
        />
      ) : (
        <span className={styles.indicator}>{selectValue && selectValue.label}</span>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  siteId: getSiteId(state),
  patrimonialsReferentiel: getPatrimonialsRefrentiel(state) || [],
})

const mapDispatchToProps = {
  modifyPatrimonial,
  deletePatrimonial,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    toggleEdit: (props) => () => props.setEdit(!props.edit),
  }),
  withHandlers({
    update: (props) => () => {
      props.modifyPatrimonial(props.siteId, props.Id, props.inputValue, props.select)
      props.toggleEdit()
    },
    initValue: (props) => () => {
      if (!props.select && props.patrimonialsReferentiel.length > 0) {
        props.setSelect(props.patrimonialsReferentiel[0].Id)
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.initValue()
    },
    componentDidUpdate(prevProps) {
      this.props.initValue()
    },
  }),
  withLoader,
)(KpiPatrimonials)

export { KpiPatrimonials }
