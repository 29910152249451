import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Error from 'components/common/Error'
import PerformanceLabel from '../PerformanceLabel'

const ClimateLabel = ({ value }) => {
  const { t } = useTranslation()
  const hasError = isNaN(parseFloat(value))

  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{t('labels.climat.title')}</h3>
        </Col>
      </Row>
      <Row>
        <Col className="graph-container">
          {hasError ? (
            <Error />
          ) : (
            <PerformanceLabel
              legends={[t('labels.climat.val'), t('labels.climat.min'), t('labels.climat.max')]}
              arrows={false}
              unit={t('labels.climat.unit')}
              value={value}
              values={[
                ['A', -Infinity, 5, '#e0d9e2', '#000'],
                ['B', 6, 10, '#d1acc4', '#000'],
                ['C', 11, 20, '#bd87ab', '#000'],
                ['D', 21, 35, '#b56797', '#000'],
                ['E', 36, 55, '#b34685', '#000'],
                ['F', 56, 80, '#a3176f', '#000'],
                ['G', 81, 110, '#95016b', '#fff'],
                ['H', 111, 145, '#9c9d9f', '#fff'],
                ['I', 145, Infinity, '#4b4b4d', '#fff'],
              ]}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default ClimateLabel
