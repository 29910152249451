import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'

import styles from './Progress.module.scss'
import { Loader } from '../../common/Loader'
import TopFlopCard from '../TopFlopCard'
import TopFlopTable from '../TopFlopTable'
import CSVButton from '../../common/CSVButton'
import Error from '../../common/Error'
import { noDecimalFormat } from '../../../helpers/formatters'

const ProgressContent = ({ title, data, error }) => {
  const { t } = useTranslation()
  if (error) return <Error />
  if (!data || (data.Top.length === 0 && data.Flop.length === 0)) return t('global.noData')

  const csvData =
    error || !data || data.Top.length === 0
      ? []
      : [
          [
            t('performance.entite'),
            data.Top[0].LibelleValeur,
            data.Top[0].LibelleValeurComparaison,
            `${t('performance.ecart')} - ${data.Top[0].UniteConso}`,
            `${t('performance.ecart')} - %`,
          ],
          ...data.Top.map((item) => [
            item.SitNom,
            noDecimalFormat(item.Valeur),
            noDecimalFormat(item.ValeurComparaison),
            noDecimalFormat(item.Ecart),
            noDecimalFormat(item.EcartPourcentage),
          ]),
          ...data.Flop.map((item) => [
            item.SitNom,
            noDecimalFormat(item.Valeur),
            noDecimalFormat(item.ValeurComparaison),
            noDecimalFormat(item.Ecart),
            noDecimalFormat(item.EcartPourcentage),
          ]).reverse(),
        ]

  return (
    <>
      <Row className="mb-3">
        <Col className="d-flex justify-content-between align-items-end">
          <h3>{t('performance.top')}</h3>
          {!error && data && data.Top.length > 0 && (
            <CSVButton
              data={csvData}
              filename={`topflop-${data.Type}.csv`}
              size="md"
              color="primary"
              style={{ fontSize: 13, marginBottom: 20 }}
            />
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col style={{ maxWidth: '315px' }}>
          <TopFlopCard type="top" data={data.Top[0]} />
        </Col>
        <Col>
          <TopFlopTable type="top" data={data.Top.slice(1)} />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h3>{t('performance.flop')}</h3>
        </Col>
      </Row>
      <Row className="mb-0">
        <Col style={{ maxWidth: '315px' }}>
          <TopFlopCard type="flop" data={data.Flop[0]} />
        </Col>
        <Col>
          <TopFlopTable type="flop" data={data.Flop.slice(1)} />
        </Col>
      </Row>
    </>
  )
}

const Progress = (props) => {
  return (
    <div className={styles.progress}>
      <h3 style={{ marginBottom: 20 }}>{props.title}</h3>
      <Container className="box">
        {props.isLoading ? <Loader style={{ height: '300px' }} /> : <ProgressContent {...props} />}
      </Container>
    </div>
  )
}
export default Progress
