import React from 'react'
import { Alert } from 'reactstrap'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import styles from './FocusAlert.module.scss'
import { getFocusName, unfocus } from 'store/currentSelection'

const FocusAlert = ({ focusName, unfocus }) => {
  const { t } = useTranslation()

  if (focusName === null) return null

  return (
    <Alert className={styles.alert} color="warning" toggle={unfocus}>
      {t('consumption.perimeter')} : {focusName}
    </Alert>
  )
}

const mapState = (state) => ({
  focusName: getFocusName(state),
})

export default connect(mapState, { unfocus })(FocusAlert)
