import React, { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
  updateLongPeriod,
  getLongDateRange,
  DATE_RANGE_LONG_LAST_MONTH,
  DATE_RANGE_LONG_LAST_3_MONTHS,
  DATE_RANGE_LONG_LAST_6_MONTHS,
  DATE_RANGE_LONG_LAST_12_MONTHS,
  DATE_RANGE_LONG_THIS_YEAR,
  DATE_RANGE_LONG_LAST_YEAR,
  DATE_RANGE_LONG_CUSTOM,
} from '../../../store/dateRange'

const DateRangeLongPeriod = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const range = useSelector(getLongDateRange)

  const options = useMemo(
    () => [
      {
        value: DATE_RANGE_LONG_LAST_MONTH,
        label: t('consumption.daterange.lastMonth'),
      },
      {
        value: DATE_RANGE_LONG_LAST_3_MONTHS,
        label: t('consumption.daterange.last3months'),
      },
      {
        value: DATE_RANGE_LONG_LAST_6_MONTHS,
        label: t('consumption.daterange.last6months'),
      },
      {
        value: DATE_RANGE_LONG_LAST_12_MONTHS,
        label: t('consumption.daterange.last12months'),
      },
      {
        value: DATE_RANGE_LONG_THIS_YEAR,
        label: t('consumption.daterange.thisYear'),
      },
      {
        value: DATE_RANGE_LONG_LAST_YEAR,
        label: t('consumption.daterange.lastYear'),
      },
      { value: DATE_RANGE_LONG_CUSTOM, label: t('consumption.daterange.custom') },
    ],
    [t],
  )

  const onChange = useCallback(
    (e) => {
      dispatch(updateLongPeriod(e.target.value))
    },
    [dispatch],
  )

  return (
    <select
      value={range.period}
      onChange={onChange}
      css={{
        border: 0,
        fontSize: 14,
        padding: 0,
        color: 'var(--gar-dark)',
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}

export default DateRangeLongPeriod
