import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import { FaDownload as DownloadIcon } from 'react-icons/fa'

import { useNotifyError } from 'hooks/notify'
import { getCurrentSitesIds } from 'store/currentSelection'
import { requestFile } from 'helpers/downloadFile'

const useDownloadReport = () => {
  const notifyError = useNotifyError()
  return useMutation(
    async ({ date, siteIds }) =>
      requestFile({
        url: `sites/reports/mensuel?date=${date}`,
        method: 'POST',
        body: siteIds,
      }),
    {
      onError: notifyError,
    },
  )
}

const initialMonth = moment().add(-1, 'months').startOf('month').format('yyyy-MM')

export const AdminReporting = () => {
  const { t } = useTranslation()
  const { mutate: downloadReport, isLoading } = useDownloadReport()
  const siteIds = useSelector(getCurrentSitesIds)
  const [month, setMonth] = useState(initialMonth)

  return (
    <div className="section">
      <h3>{t('admin.reporting')}</h3>

      {isEmpty(siteIds) ? (
        <div>{t('global.emptySelection')}</div>
      ) : (
        <div className="d-flex space-x-4">
          <input
            type="month"
            className="form-control form-control-sm"
            style={{ display: 'inline', width: 'auto' }}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          />

          <button
            className="btn btn-primary btn-sm"
            disabled={isLoading}
            onClick={() => {
              const date = moment(month).format('yyyy-MM-DD')
              downloadReport({ date, siteIds })
            }}
          >
            <span>{t('admin.generate')}</span>

            <span className="ml-3">
              {isLoading ? <Spinner size="sm" color="light" /> : <DownloadIcon />}
            </span>
          </button>
        </div>
      )}
    </div>
  )
}
