import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import MonotoneGraph from './MonotoneGraph'
import MonotoneOptions from './MonotoneOptions'

const Monotone = ({ title }) => {
  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="10" className="graph-container">
          <MonotoneGraph />
        </Col>
        <Col xs="2">
          <MonotoneOptions />
        </Col>
      </Row>
    </Container>
  )
}

export default Monotone
