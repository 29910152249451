import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardTitle, CardText } from 'reactstrap'
import { Popup as LeafletPopup } from 'react-leaflet'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { connect } from 'react-redux'

import styles from './Popup.module.scss'
import { toggleSiteInSelection } from 'store/currentSelection'

const Popup = ({ id, name, address, dispatch, toggleSiteInSelection, isSelected }) => {
  const { t } = useTranslation()
  const ref = React.useRef()
  const closePopup = () => {
    ref.current.leafletElement.options.leaflet.map.closePopup()
  }

  return (
    <LeafletPopup className={styles.popup} ref={ref}>
      <Card className={styles.card}>
        <CardTitle className={styles.title}>{name}</CardTitle>
        {address && <CardText>{address}</CardText>}
        <div className={styles.buttons}>
          <Link to={`/site/${id}`} onClick={closePopup}>
            <Button color="primary" size="sm" className={classnames('btn', styles.button)}>
              <i className={`icon-ico-edit ${styles.ico}`} style={{ fontSize: 11 }} />
              {t('site.popup.see')}
            </Button>
          </Link>
          <Button
            color="primary"
            size="sm"
            className={classnames('btn', styles.button)}
            onClick={() => {
              dispatch(toggleSiteInSelection(id))
              closePopup()
            }}
          >
            <i
              className={`${isSelected ? 'icon-cross' : 'icon-android-add'} ${styles.ico}`}
              style={{ fontSize: isSelected ? 10 : 16 }}
            />
            {t(isSelected ? 'site.popup.remove' : 'site.popup.add')}
          </Button>
        </div>
      </Card>
    </LeafletPopup>
  )
}

const mapState = (state) => ({
  toggleSiteInSelection: (siteId) => toggleSiteInSelection(state, siteId),
})

export default connect(mapState)(Popup)

export { Popup }
