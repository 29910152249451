import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import { Highcharts } from 'helpers/highcharts'
import { decimalFormat } from 'helpers/formatters'
import { getColor } from 'helpers/colors'
import { request } from 'helpers/request'
import { getLongStartDate, getLongEndDate } from 'store/dateRange'
import { getPostesUnit } from 'store/consumption'
import { uorIdMst as getUorIdMst, lanId as getLanId } from 'commons'
import { getUor } from 'store'

const createOptions = (data, stacked = false, types = [], uor) => {
  let series = data.series
  if (types.length > 0 && data.series) {
    series = data.series.filter(
      ({ name }) =>
        (types.includes('Facture') && (name.endsWith('Année N') || name.endsWith('Year Y'))) ||
        (types.includes('FactureN1') &&
          (name.endsWith('Année N-1') || name.endsWith('Year Y-1'))) ||
        (types.includes('Budget') && name.endsWith('Budget')),
    )
  }

  if (series) {
    Object.keys(series).map((i) =>
      Object.keys(series[i].data).map(
        (j) => (series[i].data[j].x = parseFloat(series[i].data[j].x)),
      ),
    )

    return {
      ...data,
      series: series.map((s, index) => ({
        ...s,
        color: getColor(index, 0.4, { alt: true, uor }),
        borderColor: getColor(index, 1, { alt: true, uor }),
      })),
      chart: {
        type: 'column',
        animation: false,
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
      plotOptions: {
        column: {
          animation: false,
          stacking: stacked ? 'normal' : '',
        },
      },
      title: {
        text: '',
      },
      tooltip: {
        formatter: function () {
          return stacked
            ? `<span style="color:${this.point.color}">\u25CF</span> ${
                this.series.name
              }: <b>${decimalFormat(this.point.y)}</b><br/>Total: <b>${decimalFormat(
                this.point.stackTotal,
              )}</b>`
            : `<span style="color:${this.point.color}">\u25CF</span> ${
                this.series.name
              }: <b>${decimalFormat(this.point.y)}</b><br/>`
        },
      },
      yAxis: {
        title: '',
      },
      xAxis: {
        type: 'datetime',
      },
    }
  } else {
    return {}
  }
}

const useTemporalGraph = (peeId) => {
  const dateDebut = useSelector(getLongStartDate)
  const dateFin = useSelector(getLongEndDate)
  const types = 'Facture'
  const unite = useSelector(getPostesUnit)
  const uorIdMst = useSelector(getUorIdMst)
  const lanId = useSelector(getLanId)

  const { data } = useQuery({
    queryKey: ['temporalGraph', { peeId, dateDebut, dateFin, types, unite, uorIdMst, lanId }],
    queryFn: () =>
      request({
        url: `graphiquetemporel?date_debut=${dateDebut}&date_fin=${dateFin}&mesures=VolumeHRS&type_donnee=${types}&unite=${unite}&devise=21&pas=3&uorIdMst=${uorIdMst}&lanId=${lanId}`,
        method: 'POST',
        body: peeId,
      }),
  })
  return data
}

export const ColChart = ({ peeId }) => {
  const data = useTemporalGraph(peeId)
  const uor = useSelector(getUor)
  if (!data) return null
  const options = createOptions(data, true, undefined, uor)
  return <HighchartsReact highcharts={Highcharts} options={options} immutable={true} />
}
