import React, { Suspense } from 'react'
import { Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import { ErrorBoundary } from 'commons'
import { Loader } from 'components/common/Loader'
import { PeeTreeSelect } from 'components/common/PeeTreeSelect'
import { Period } from './Period'
import { setPeeId, getPeeId } from 'store/gestion'

export const Options = () => {
  const dispatch = useDispatch()
  const peeId = useSelector(getPeeId)

  return (
    <div
      css={{
        display: 'grid',
        gridGap: '2rem',
        gridTemplateColumns: '550px 400px',
        '>div': {
          display: 'flex',
          alignItems: 'center',
          '>*': {
            flex: 1,
            marginLeft: '1rem',
          },
          '>label': {
            flex: 0,
            margin: 0,
            fontWeight: 700,
            color: '#61747f',
          },
        },
        input: {
          fontSize: 14,
        },
      }}
    >
      <div>
        <Label>Compteur</Label>
        <ErrorBoundary>
          <Suspense
            fallback={<Loader size="sm" style={{ height: 32, justifyContent: 'flex-start' }} />}
          >
            <PeeTreeSelect value={peeId} onChange={(value) => dispatch(setPeeId(value))} />
          </Suspense>
        </ErrorBoundary>
      </div>

      <div>
        <Label>Période</Label>
        <Period />
      </div>
    </div>
  )
}
