import { createSelector } from 'reselect'
import uniq from 'lodash/uniq'
import flatten from 'lodash/flatten'

import { isLongitude, isLatitude } from '../helpers/geo'
import { getSites, getUors, getPeesList, getSiteIdsFromUor } from './organisation'
import { getEnergy } from 'store/dateRange'

// Actions
const TOGGLE_SITE_IN_SELECTION = 'esite/currentSelection/TOGGLE_SITE_IN_SELECTION'
const TOGGLE_UOR_IN_SELECTION = 'esite/currentSelection/TOGGLE_UOR_IN_SELECTION'
const TOGGLE_SITE_OPEN = 'esite/currentSelection/TOGGLE_SITE_OPEN'
const TOGGLE_PEE_VISIBLE = 'esite/currentSelection/TOGGLE_PEE_VISIBLE'
const SELECT_ALL = 'esite/currentSelection/SELECT_ALL'
const EMPTY_SELECTION = 'esite/currentSelection/EMPTY_SELECTION'
const FETCH_ORGANISATION_SUCCESS = 'esite/currentSelection/FETCH_ORGANISATION_SUCCESS'
const REMOVE_PEE_FROM_SELECTION = 'esite/currentSelection/REMOVE_PEE_FROM_SELECTION'
const TOGGLE_PEE_IN_SELECTION = 'esite/currentSelection/TOGGLE_PEE_IN_SELECTION'
const SET_TO_FOCUS = 'esite/currentSelection/SET_TO_FOCUS'
const FOCUS = 'esite/currentSelection/FOCUS'
const UNFOCUS = 'esite/currentSelection/UNFOCUS'

// Reducer
export default (
  state = {
    organisations: {},
    pees: {},
    sites: {},
    uors: {},
    toFocus: null,
    focus: null,
  },
  action,
) => {
  const initialState = {
    organisations: {},
    pees: {},
    sites: {},
    uors: {},
    toFocus: null,
    focus: null,
  }

  const toggleUor = (state, uor, organisation) => {
    let newState = { ...state }
    const uorSites =
      uor.UorSites !== undefined && uor.UorSites !== null
        ? uor.UorSites.filter((siteId) => newState.sites[siteId]).length
        : 0

    const uorSitesIndeterminate =
      uor.UorSites !== undefined && uor.UorSites !== null
        ? uor.UorSites.filter(
            (siteId) =>
              newState.sites[siteId] === undefined || newState.sites[siteId].indeterminate,
          ).length > 0
        : false

    const uorChildren =
      uor.UorEnfants !== undefined && uor.UorEnfants !== null
        ? uor.UorEnfants.filter((uorId) => newState.uors[uorId]).length
        : 0

    const uorChildrenIndeterminate =
      uor.UorEnfants !== undefined && uor.UorEnfants !== null
        ? uor.UorEnfants.filter(
            (uorId) => newState.uors[uorId] === undefined || newState.uors[uorId].indeterminate,
          ).length > 0
        : false

    if (!uorSites && !uorChildren) {
      delete newState.uors[uor.id]
    } else if (uorSitesIndeterminate || uorChildrenIndeterminate) {
      newState.uors[uor.id] = { indeterminate: true }
    } else {
      newState.uors[uor.id] = { indeterminate: false }
    }

    if (uor.parentUor !== uor.id) {
      newState = toggleUor(newState, organisation.uors[uor.parentUor], organisation)
    }

    return newState
  }

  const toggleUorOff = (state, uorId, organisation) => {
    let newState = { ...state }
    const uorSites = organisation.uors[uorId].UorSites || null
    const uorChildren = organisation.uors[uorId].UorEnfants || null
    const uorParent = organisation.uors[organisation.uors[uorId].parentUor]

    if (uorSites !== null) {
      uorSites.map((siteId) => {
        delete newState.sites[siteId]
        return ''
      })
    }

    delete newState.uors[uorId]
    delete newState.organisations[uorId]

    if (uorChildren !== null) {
      uorChildren.map((uorId) => toggleUorOff(newState, uorId, organisation))
    }

    if (uorParent && uorParent.id !== uorId) {
      newState = toggleUor(newState, uorParent, organisation)
    }

    return newState
  }

  const toggleUorOn = (
    state,
    uorId,
    organisation,
    filter = 'all',
    countryFilter = 'all',
    tagFilters,
  ) => {
    let newState = { ...state }
    const uorSites = organisation.uors[uorId].UorSites || null
    const uorChildren = organisation.uors[uorId].UorEnfants || null
    const uorParent = organisation.uors[organisation.uors[uorId].parentUor]

    let anySiteSelected = false
    let allSiteSelected = true

    const filterTagNames = tagFilters.map((tag) => tag.nom)

    if (uorSites !== null) {
      uorSites.forEach((siteId) => {
        const siteTagNames = organisation.sites[siteId].Tags?.split('|') ?? []

        if (
          (countryFilter === 'all' ||
            organisation.sites[siteId].SphAdresse.AdrPaysId === countryFilter) &&
          (tagFilters.length === 0 || siteTagNames.some((s) => filterTagNames.includes(s)))
        ) {
          anySiteSelected = true
          newState.sites[siteId] = { open: true, ...newState.sites[siteId] }
        } else {
          allSiteSelected = false
        }
      })
    }

    if (anySiteSelected) {
      newState.uors[uorId] = { indeterminate: !allSiteSelected }
    }

    if (uorChildren !== null) {
      uorChildren.map((uorId) =>
        toggleUorOn(newState, uorId, organisation, filter, countryFilter, tagFilters),
      )
    }

    if (uorParent && uorParent.id !== uorId) {
      newState = toggleUor(newState, uorParent, organisation)
    }

    return newState
  }

  let newState = {}

  switch (action.type) {
    case TOGGLE_SITE_IN_SELECTION:
      newState = { ...state, focus: null }

      const site = action.organisation.sites[action.siteId]

      if (
        newState.sites[action.siteId] !== undefined &&
        !newState.sites[action.siteId].indeterminate
      ) {
        delete newState.sites[action.siteId]
      } else {
        let indeterminate = false
        newState.sites[action.siteId] = {
          open: true,
          indeterminate: indeterminate,
        }
      }

      newState = toggleUor(newState, action.organisation.uors[site.parentUor], action.organisation)

      return newState
    case TOGGLE_UOR_IN_SELECTION:
      newState = { ...state, focus: null }

      newState =
        newState.uors[action.uorId] && !newState.uors[action.uorId].indeterminate
          ? toggleUorOff(newState, action.uorId, action.organisation)
          : toggleUorOn(
              newState,
              action.uorId,
              action.organisation,
              action.filter,
              action.countryFilter,
              action.tagFilters,
            )

      return newState

    case TOGGLE_SITE_OPEN:
      newState = { ...state, focus: null }
      newState.sites[action.siteId].open = !newState.sites[action.siteId].open
      return newState
    case TOGGLE_PEE_VISIBLE:
      newState = { ...state, focus: null }
      newState.pees[action.peeId].visible = !newState.pees[action.peeId].visible
      return newState
    case SELECT_ALL:
      newState = initialState
      newState = toggleUorOn(
        newState,
        action.rootUor,
        action.organisation,
        action.filter,
        action.countryFilter,
        action.tagFilters,
      )
      return newState
    case EMPTY_SELECTION:
      return initialState
    case FETCH_ORGANISATION_SUCCESS:
      const { pees } = action.response.entities
      return {
        ...state,
        focus: null,
        pees: !pees
          ? {}
          : pees.reduce((acc, peeId) => {
              acc[peeId] = state.pees[peeId]
              return acc
            }, {}),
      }
    case SET_TO_FOCUS:
      return { ...state, toFocus: action.id, focus: null }
    case FOCUS:
      return {
        ...state,
        toFocus: null,
        focus: action.ids[0],
        sites: Object.entries(state.sites).reduce(
          (acc, [id, site]) => ({
            ...acc,
            [id]: { ...site, open: action.ids.includes(Number(id)) },
          }),
          {},
        ),
      }
    case UNFOCUS:
      return {
        ...state,
        focus: null,
        sites: Object.entries(state.sites).reduce(
          (acc, [id, site]) => ({
            ...acc,
            [id]: { ...site, open: true },
          }),
          {},
        ),
      }
    default:
      return state
  }
}

// Selectors
export const getIsCurrentPee = (state, peeId) => state.currentSelection.pees[peeId] !== undefined
export const getIsSiteIndeterminate = (state, siteId) =>
  state.currentSelection.sites[siteId] !== undefined &&
  state.currentSelection.sites[siteId].indeterminate
export const getIsSiteSelected = (state, siteId) =>
  state.currentSelection !== undefined && state.currentSelection.sites[siteId] ? true : false
export const getIsSiteOpen = (state, siteId) =>
  state.currentSelection.sites[siteId] !== undefined && state.currentSelection.sites[siteId].open
export const getIsUorIndeterminate = (state, uorId) =>
  state.currentSelection.uors[uorId] !== undefined &&
  state.currentSelection.uors[uorId].indeterminate
export const getIsUorSelected = (state, uorId) =>
  state.currentSelection !== undefined && state.currentSelection.uors[uorId] ? true : false

export const getCountSelected = (state) =>
  Object.values(state.currentSelection.sites).filter((site) => site.open).length ?? 0

export const getCurrentSitesIds = (state) =>
  (state.currentSelection.sites &&
    Object.keys(state.currentSelection.sites)
      .filter((id) => state.currentSelection.sites[id].open)
      .map(Number)) ||
  []
export const getCurrentSites = (state) =>
  getCurrentSitesIds(state).reduce((currentSites, siteId) => {
    currentSites[siteId] = state.organisation.organisationEntities.sites[siteId]
    return currentSites
  }, {})

export const getAllCurrentSitesIds = (state) =>
  (state.currentSelection.sites && Object.keys(state.currentSelection.sites).map(Number)) || []
export const getAllCurrentSites = (state) =>
  getAllCurrentSitesIds(state).reduce((currentSites, siteId) => {
    currentSites[siteId] = state.organisation.organisationEntities.sites[siteId]
    return currentSites
  }, {})

export const getSiteCurrentPeesIds = (state, siteId) =>
  (state.organisation.organisationEntities.sites[siteId] !== undefined &&
    state.organisation.organisationEntities.sites[siteId].SitPees !== undefined &&
    state.organisation.organisationEntities.sites[siteId].SitPees.filter(
      (peeId) => getCurrentPeesIds(state).map(Number).indexOf(peeId) > -1,
    )) ||
  []
export const getSiteCurrentPees = (state, siteId) =>
  getSiteCurrentPeesIds(state, siteId).reduce((currentSitePees, peeId) => {
    currentSitePees[peeId] = state.organisation.organisationEntities.pees[peeId]
    return currentSitePees
  }, {})

export const getCurrentUorIds = (state) => Object.keys(state.currentSelection.uors)

export const getCurrentUors = createSelector(getUors, getCurrentSites, (uors, currentSites) => {
  const siteList = Object.values(currentSites).filter((site) => !!site)
  const uorList = Object.values(uors)
  const currentUorIds = uniq(flatten(siteList.map((site) => site.parentUor)))
  return uorList.filter((uor) => currentUorIds.includes(uor.id))
})

export const getPositionedSites = createSelector(
  getSites,
  getAllCurrentSites,
  (sites, selection) => {
    const selectionIds = Object.keys(selection)

    return Object.keys(sites).reduce((acc, id) => {
      if (!!sites[id]) {
        const {
          SphNom: name,
          SphAdresse: { Latitude: lat, Longitude: lon },
        } = sites[id]

        if (isLatitude(lat) && isLongitude(lon)) {
          acc.push({
            id,
            name,
            isSelected: selectionIds.includes(id),
            position: [lat, lon],
          })
        }
      }
      return acc
    }, [])
  },
)

export const getCurrentSitesIdsByUor = createSelector(
  getSites,
  getCurrentSites,
  (sites, selection) => {
    const sitesIdsByUor = Object.keys(selection).reduce((acc, siteId) => {
      if (!!sites[siteId]) {
        const {
          Organisation: { id: UorId, UorNom },
        } = sites[siteId]

        if (!acc[UorId]) {
          acc[UorId] = {
            id: UorId,
            name: UorNom,
            sites: [siteId],
          }
        } else {
          acc[UorId].sites.push(siteId)
        }
      }

      return acc
    }, {})

    return Object.values(sitesIdsByUor)
  },
)

export const getAllCurrentSitesIdsByUor = createSelector(
  getSites,
  getAllCurrentSites,
  (sites, selection) => {
    const sitesIdsByUor = Object.keys(selection).reduce((acc, siteId) => {
      if (!!sites[siteId]) {
        const {
          Organisation: { id: UorId, UorNom },
        } = sites[siteId]

        if (!acc[UorId]) {
          acc[UorId] = {
            id: UorId,
            name: UorNom,
            sites: [siteId],
          }
        } else {
          acc[UorId].sites.push(siteId)
        }
      }

      return acc
    }, {})

    return Object.values(sitesIdsByUor)
  },
)

export const getCurrentSitesByUor = createSelector(
  getSites,
  getCurrentSites,
  (sites, selection) => {
    const sitesIdsByUor = Object.keys(selection).reduce((acc, siteId) => {
      const site = sites[siteId]
      if (!!site) {
        const {
          Organisation: { id: UorId, UorNom },
        } = site

        if (!acc[UorId]) {
          acc[UorId] = {
            id: UorId,
            name: UorNom,
            sites: [site],
          }
        } else {
          acc[UorId].sites.push(site)
        }
      }

      return acc
    }, {})

    return Object.values(sitesIdsByUor)
  },
)

export const getCurrentPeesIds = createSelector(getPeesList, getCurrentSites, (pees, sites) => {
  return pees.reduce((acc, pee) => {
    if (sites[pee.PeeSitId]) acc.push(pee.id)
    return acc
  }, [])
})

export const getFilteredPeesIds = createSelector(
  getPeesList,
  getCurrentSites,
  getEnergy,
  (pees, sites, energy) => {
    return pees.reduce((acc, pee) => {
      if (sites[pee.PeeSitId] && pee.PeeNrjId.toString() === energy) acc.push(pee.id)
      return acc
    }, [])
  },
)

export const getCurrentPeesBySite = createSelector(getPeesList, getCurrentSites, (pees, sites) => {
  const peesBySite = pees.reduce((acc, pee) => {
    const siteId = pee.PeeSitId

    if (!sites[siteId]) return acc

    const siteName = sites[siteId].SphNom

    if (!siteName) return acc

    if (!acc[siteId]) {
      acc[siteId] = {
        id: siteId,
        name: siteName,
        pees: [pee],
      }
    } else {
      acc[siteId].pees.push(pee)
    }

    return acc
  }, {})

  return Object.values(peesBySite)
})

export const getFilteredPeesBySite = createSelector(
  getPeesList,
  getCurrentSites,
  getEnergy,
  (pees, sites, energy) => {
    const peesBySite = pees.reduce((acc, pee) => {
      const siteId = pee.PeeSitId

      if (!sites[siteId]) return acc
      if (pee.PeeNrjId.toString() !== energy) return acc

      const siteName = sites[siteId].SphNom

      if (!siteName) return acc

      if (!acc[siteId]) {
        acc[siteId] = {
          id: siteId,
          name: siteName,
          pees: [pee],
        }
      } else {
        acc[siteId].pees.push(pee)
      }

      return acc
    }, {})

    return Object.values(peesBySite)
  },
)

export const getToFocus = (state) => state.currentSelection.toFocus
export const getFocus = (state) => state.currentSelection.focus
export const getFocusName = createSelector(getFocus, getSites, getUors, (focusId, sites, uors) => {
  const site = sites[focusId]
  const uor = uors[focusId]
  return site ? site.SphNom : uor ? uor.UorNom : null
})

// Thunks
export const removePeeFromSelection = (state, peeId) => (dispatch) => {
  dispatch({
    type: REMOVE_PEE_FROM_SELECTION,
    peeId,
    organisation: state.organisation.organisationEntities,
  })
}

export const emptySelection = () => (dispatch) => {
  dispatch({
    type: EMPTY_SELECTION,
  })
}

export const togglePeeInSelection = (state, peeId) => (dispatch) => {
  dispatch({
    type: TOGGLE_PEE_IN_SELECTION,
    peeId,
    organisation: state.organisation.organisationEntities,
  })
}

export const toggleSiteInSelection =
  (state, siteId, filter = 'all', countryFilter = 'all') =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_SITE_IN_SELECTION,
      siteId,
      countryFilter,
      filter,
      organisation: state.organisation.organisationEntities,
    })
  }

export const toggleUorInSelection =
  (state, uorId, filter = 'all', countryFilter = 'all') =>
  (dispatch) => {
    dispatch({
      type: TOGGLE_UOR_IN_SELECTION,
      uorId,
      countryFilter,
      filter,
      organisation: state.organisation.organisationEntities,
      tagFilters: state.searchPanel.tagFilters,
    })
  }

export const toggleSiteOpen = (siteId) => (dispatch) => {
  dispatch({
    type: TOGGLE_SITE_OPEN,
    siteId,
  })
}

export const selectAll = (state, rootUor, filter, countryFilter) => (dispatch) => {
  dispatch({
    type: SELECT_ALL,
    filter,
    countryFilter,
    rootUor,
    organisation: state.organisation.organisationEntities,
    tagFilters: state.searchPanel.tagFilters,
  })
}

export const setToFocus = (id) => ({ type: SET_TO_FOCUS, id })

export const focus = (id) => (dispatch, getState) => {
  const ids = [id, ...getSiteIdsFromUor(getState(), id).map((site) => site.id)]
  dispatch({ type: FOCUS, ids })
}

export const unfocus = () => ({ type: UNFOCUS })
