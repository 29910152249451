import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Label } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'
import { compose, withState, lifecycle } from 'recompose'
import { useRouteMatch } from 'react-router-dom'

import { dateFormat } from 'helpers/formatters'
import InputDatepicker from 'components/common/InputDatepicker'
import { getSiteId, getSiteEntites, updateSiteField } from 'store/site'
import styles from './Information.module.scss'

const DateItem = ({ text }) => {
  return <div style={{ paddingLeft: 10 }}>{text}</div>
}

const DatesForm = ({
  edit,
  entities,
  updateSiteField,
  dateEntree,
  dateSortie,
  setDateEntree,
  setDateSortie,
}) => {
  const { t } = useTranslation()
  const match = useRouteMatch()
  const siteId = match.params.id

  function updateDates(field, date, setDate) {
    const isValid = moment(date, dateFormat(), true).isValid()
    let action = 'replace'
    if (!isValid) {
      if (date === 'undefined' || date === '') {
        action = 'remove'
      } else return
    }
    if (!isValid) {
      setDate('')
    } else {
      setDate(moment(date).format(dateFormat()))
    }

    updateSiteField(siteId, field, action, date ? date.format('YYYY-MM-DD') : '')
  }

  function updateDateEntree(date) {
    updateDates('DateEntree', date, setDateEntree)
  }

  function updateDateSortie(date) {
    updateDates('DateSortie', date, setDateSortie)
  }

  return (
    <Row className="mb-4">
      <Col className="d-flex align-items-center" style={{ height: 35 }}>
        <Label for="fromDatepicker" className={classnames('align-middle', styles.label)}>
          {t('site.admin.dateIn')}
        </Label>
        <div className={styles.dateValue}>
          {edit ? (
            <InputDatepicker
              className={styles.datepicker}
              value={dateEntree}
              onChange={updateDateEntree}
              acceptNull={true}
            />
          ) : (
            <DateItem text={dateEntree || '-'} />
          )}
        </div>
        <Label for="toDatepicker" className={classnames('align-middle', styles.label)}>
          {t('site.admin.dateOut')}
        </Label>
        <div className={styles.dateValue}>
          {edit ? (
            <InputDatepicker
              className={styles.datepicker}
              value={dateSortie}
              onChange={updateDateSortie}
              acceptNull={true}
            />
          ) : (
            <DateItem text={dateSortie || '-'} />
          )}
        </div>
      </Col>
    </Row>
  )
}

const mapState = (state) => ({
  siteId: getSiteId(state),
  siteEntities: getSiteEntites(state),
})

const mapDispatch = {
  updateSiteField,
}

export default compose(
  connect(mapState, mapDispatch),
  withState('entities', 'setEntities', (props) => props.siteEntities),
  withState('dateEntree', 'setDateEntree', null),
  withState('dateSortie', 'setDateSortie', null),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.siteId !== this.props.siteId) {
        this.props.setEntities(this.props.siteEntities)
        this.props.setDateEntree(
          this.props.siteEntities.DateEntree &&
            moment(this.props.siteEntities.DateEntree).format(dateFormat()),
        )
        this.props.setDateSortie(
          this.props.siteEntities.DateSortie &&
            moment(this.props.siteEntities.DateSortie).format(dateFormat()),
        )
      }
    },
  }),
)(DatesForm)
