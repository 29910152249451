export const highchartsFr = {
  lang: {
    loading: 'Chargement...',
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    shortMonths: [
      'Jan',
      'Fév',
      'Mar',
      'Avr',
      'Mai',
      'Juin',
      'Juil',
      'Aout',
      'Sep',
      'Oct',
      'Nov',
      'Déc',
    ],
    weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    decimalPoint: ',',
    numericSymbols: ['k', 'M', 'G', 'T', 'P', 'E'],
    resetZoom: 'Réinitialiser le zoom',
    resetZoomTitle: 'Réinitialiser le zoom au niveau 1:1',
    thousandsSep: ' ',
    rangeSelectorZoom: 'Zoom: ',
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'à',
    downloadJPEG: 'Télécharger en image JPEG',
    downloadPDF: 'Télécharger en document PDF',
    downloadCSV: 'Télécharger en document CSV',
    downloadXLS: 'Télécharger en document XLS',
    contextButtonTitle: 'Exporter le graphique',
  },
  credits: {
    enabled: false,
  },
  rangeSelector: {
    inputDateFormat: '%e %b %Y',
    inputEditDateFormat: '%d/%m/%Y',
    inputDateParser: function (value) {
      value = value.split('/')
      return Date.UTC(parseInt(value[2], 10), parseInt(value[1], 10) - 1, parseInt(value[0], 10))
    },
    buttons: [
      {
        type: 'week',
        count: 1,
        text: '1s',
      },
      {
        type: 'month',
        count: 1,
        text: '1m',
      },
      {
        type: 'month',
        count: 6,
        text: '6m',
      },
      {
        type: 'year',
        count: 1,
        text: '1a',
      },
      {
        type: 'all',
        count: 1,
        text: 'Tout',
      },
    ],
  },
  tooltip: {
    dateTimeLabelFormats: {
      millisecond: '%A %e %b, %H:%M:%S.%L',
      second: '%A %e %b, %H:%M:%S',
      minute: '%A %e %b, %H:%M',
      hour: '%A %e %b, %H:%M',
      day: '%A %e %b %Y',
      week: 'Semaine du %A %e %b %Y',
      month: '%B %Y',
      year: '%Y',
    },
  },
}
