import React from 'react'

import { AdminReferentiels } from './AdminReferentiels'
import { AdminTags } from './AdminTags'
import { AdminActivities } from './AdminActivities'
import { AdminReporting } from './AdminReporting'

export default function Admin() {
  return (
    <div
      css={{
        margin: '30px 15px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '30px',
      }}
    >
      <AdminReferentiels />
      <AdminTags />
      <AdminActivities />
      <AdminReporting />
    </div>
  )
}
