import React, { FC, Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useQueryErrorResetBoundary } from 'react-query'
import { useTranslation } from 'react-i18next'

import { Loader } from 'components/common/Loader'

type QueryBoundaryProps = {
  height?: number
}

export const QueryBoundary: FC<QueryBoundaryProps> = ({ children, height = 100 }) => {
  const { reset } = useQueryErrorResetBoundary()
  const { t } = useTranslation()

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary }) => (
        <div role="alert" style={{ maxWidth: '24rem' }}>
          <div className="text-danger mb-2">{t('global.error')}</div>

          <button className="btn btn-primary btn-sm" onClick={resetErrorBoundary}>
            {t('global.tryAgain')}
          </button>
        </div>
      )}
    >
      <Suspense fallback={<Loader style={{ height }} />}>{children}</Suspense>
    </ErrorBoundary>
  )
}
