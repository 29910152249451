import React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import DateTime from 'react-datetime'
import { InputGroup, Input, InputGroupAddon, Button } from 'reactstrap'
import classnames from 'classnames'

import { lngIso } from 'commons'
import { dateFormat } from '../../../helpers/formatters'
import styles from './InputDatepicker.module.scss'

const RenderInput = (props, openCalendar, className) => {
  const { icon = true, ...inputProps } = props
  return (
    <InputGroup size="sm" className={styles.group}>
      <Input {...inputProps} style={{ height: 'calc(1.5em + .5rem + 6px)' }} />
      {icon && (
        <InputGroupAddon addonType="append">
          <Button className={classnames(styles.button, 'btn-primary')} onClick={openCalendar}>
            <i className="icon-event" />
          </Button>
        </InputGroupAddon>
      )}
    </InputGroup>
  )
}

const InputDatepicker = ({ locale, value, onDateChange, className, inputProps }) => (
  <DateTime
    className={classnames(styles.container, className)}
    inputProps={inputProps}
    renderInput={RenderInput}
    locale={locale}
    closeOnSelect
    dateFormat={dateFormat()}
    timeFormat={false}
    value={value}
    onChange={onDateChange}
  />
)

const mapStateToProps = (state) => ({
  locale: lngIso(state),
})

export default compose(
  connect(mapStateToProps),
  withHandlers({
    onDateChange: (props) => (date) => {
      if (
        (props.acceptNull === true || (!props.acceptNull && typeof date !== 'string')) &&
        typeof props.onChange !== 'undefined'
      ) {
        props.onChange(date)
      }
    },
  }),
)(InputDatepicker)
