import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import styles from './PerformanceKPI.module.scss'
import PerformanceKPIGraph from './PerformanceKPIGraph'
import PerformanceKPIOptions from './PerformanceKPIOptions'

const PerformanceKPI = ({ kpi }) => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <Col className={styles.header}>
          <h3>{t('performance.performanceMensuelle')}</h3>
        </Col>
      </Row>
      <Row>
        <Col xs="10" className="graph-container">
          <PerformanceKPIGraph />
        </Col>
        <Col xs="2">
          <PerformanceKPIOptions kpi={kpi} />
        </Col>
      </Row>
    </>
  )
}

export default PerformanceKPI
