import { combineReducers } from 'redux'
import v4 from 'uuid/v4'
import download from 'downloadjs'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  downloadActivitiesTemplate: (siteId, typeId, startDate, endDate, step, unit) => {
    return superFetch({
      url: `sites/${siteId}/activity/${typeId}/template?debut=${startDate}&fin=${endDate}&pas=${step}&unit=${unit}`,
    })
  },

  uploadActivities: (uuid, siteId, typeId, step, unit, file) => {
    let doc = new FormData()
    doc.append('file', file)

    const url = `sites/${siteId}/activity/${typeId}?pas=${step}&unit=${unit}`

    return superFetch({
      url,
      method: 'POST',
      body: doc,
      stringify: false,
      contentType: null,
      uuid,
    })
  },

  fetchSiteActivities: (siteId, typeId) => {
    return superFetch({
      url: `sites/${siteId}/activity/${typeId}`,
    })
  },
}

// Actions
const FETCH_SITE_ACTIVITIES_REQUEST = 'esite/activities/FETCH_SITE_ACTIVITIES_REQUEST'
const FETCH_SITE_ACTIVITIES_FAILURE = 'esite/activities/FETCH_SITE_ACTIVITIES_FAILURE'
const FETCH_SITE_ACTIVITIES_SUCCESS = 'esite/activities/FETCH_SITE_ACTIVITIES_SUCCESS'
const SITE_DATA_ACTIVITIES_UPLOAD = 'esite/activities/SITE_DATA_ACTIVITIES_UPLOAD'
const SITE_DATA_ACTIVITIES_SUCCESS = 'esite/activities/SITE_DATA_ACTIVITIES_SUCCESS'
const SITE_DATA_ACTIVITIES_FAILURE = 'esite/activities/SITE_DATA_ACTIVITIES_FAILURE'

const activities = (
  state = {
    items: [],
    unit: 0,
    step: 4,
    fetching: false,
    updating: false,
    error: '',
  },
  action,
) => {
  switch (action.type) {
    case FETCH_SITE_ACTIVITIES_REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case FETCH_SITE_ACTIVITIES_FAILURE:
      return {
        ...state,
        fetching: false,
        items: [],
        error: action.error,
      }
    case FETCH_SITE_ACTIVITIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        items: action.activities,
        unit: action.activities.unit,
        step: action.activities.step,
        error: '',
      }
    default:
      return state
  }
}

const uploadStatus = (
  state = {
    isUploading: false,
    isError: false,
    isSuccess: false,
  },
  action,
) => {
  switch (action.type) {
    case SITE_DATA_ACTIVITIES_UPLOAD:
      return {
        isUploading: true,
        isError: false,
        iSuccess: false,
      }
    case SITE_DATA_ACTIVITIES_SUCCESS:
      return {
        isUploading: false,
        isError: false,
        isSuccess: true,
      }
    case SITE_DATA_ACTIVITIES_FAILURE:
      return {
        isUploading: false,
        isError: true,
        isSuccess: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  activities,
  uploadStatus,
})

// Selectors
export const getActivities = (state) => state.activities.activities
export const getUploadStatus = (state) => state.activities.uploadStatus

// Thunks
export const downloadActivitiesTemplate =
  (siteId, typeId, startDate, endDate, step, unit) => async (dispatch, getState) => {
    try {
      const resp = await api.downloadActivitiesTemplate(
        siteId,
        typeId,
        startDate,
        endDate,
        step,
        unit,
      )

      if (resp.status === 200) {
        const disposition = resp.headers.get('content-disposition')
        let filename = ''
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }

        const blob = await resp.blob()
        const contentType = resp.headers.get('Content-Type')
        download(blob, filename, contentType)
      } else {
        console.warn('Unable to download invoices documents')
      }
    } catch (error) {
      console.error('Error downloading invoice', error.message)
    }
  }

export const fetchSiteActivities = (idSite, typeId) => async (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_SITE_ACTIVITIES_REQUEST,
    uuid,
  })

  try {
    const response = await api.fetchSiteActivities(idSite, typeId)

    if (response.status === 200) {
      const data = await response.json()
      dispatch({
        type: FETCH_SITE_ACTIVITIES_SUCCESS,
        site: idSite,
        activities: data,
      })
    } else {
      dispatch({
        type: FETCH_SITE_ACTIVITIES_FAILURE,
      })
    }
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des données d'activité du site " + idSite,
      error.message,
    )
  }
}

export const uploadActivities =
  (siteId, typeId, step, unit, file, cleanUp) => async (dispatch, getState) => {
    const uuid = v4()

    dispatch({
      type: SITE_DATA_ACTIVITIES_UPLOAD,
      uuid,
      siteId,
    })

    try {
      const response = await api.uploadActivities(uuid, siteId, typeId, step, unit, file)
      if (response.status === 200) {
        dispatch({
          type: SITE_DATA_ACTIVITIES_SUCCESS,
          response,
          siteId,
        })
        dispatch(fetchSiteActivities(siteId, typeId))
      } else {
        console.warn('Unable to upload data activities')
        dispatch({
          type: SITE_DATA_ACTIVITIES_FAILURE,
          uuid,
          siteId,
        })
      }
    } catch (error) {
      console.error('Error while uploading data activities', error.message)
      dispatch({
        type: SITE_DATA_ACTIVITIES_FAILURE,
        uuid,
        siteId,
      })
    } finally {
      cleanUp()
    }
  }
