import React from 'react'
import { useField } from 'formik'
import classnames from 'classnames'

type FieldProps = {
  name: string
  placeholder?: string
  type?: 'text' | 'number'
  label?: string
  postfix?: string
}

export const Field = ({ label, postfix, ...props }: FieldProps) => {
  const [field, meta] = useField(props)
  return (
    <div>
      <label className="d-flex flex-column space-y-2">
        {label && <span className="font-weight-bold">{label}</span>}

        <span className="d-flex space-x-2 align-items-center">
          <input
            autoComplete="off"
            className={classnames('form-control form-control-sm', {
              'border-danger': meta.touched && !!meta.error,
            })}
            {...props}
            {...field}
            value={field.value ?? ''}
          />

          {postfix && <span>{postfix}</span>}
        </span>
      </label>
    </div>
  )
}
