import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Spinner } from 'reactstrap'
import moment from 'moment'

import { FaDownload as DownloadIcon } from 'react-icons/fa'
import { FaUpload as UploadIcon } from 'react-icons/fa'
import { FaArrowRight as ArrowIcon } from 'react-icons/fa'

import { superFetch } from 'commons'
import { useNotifyError, useNotifySuccess } from 'hooks/notify'
import { requestFile } from 'helpers/downloadFile'
import { FileInput } from 'components/base/FileInput'

const useDownloadModel = () => {
  const notifyError = useNotifyError()
  return useMutation(
    ({ start, end, step }) =>
      requestFile({
        url: `sites/all/activity/40/template?debut=${start}&fin=${end}&pas=${step}&unit=41`,
        method: 'GET',
      }),
    {
      onError: notifyError,
    },
  )
}

const useUploadData = () => {
  const { t } = useTranslation()
  const notifyError = useNotifyError()
  const notifySuccess = useNotifySuccess()
  return useMutation(
    async ({ step, file }) => {
      const formData = new FormData()
      formData.append('file', file)

      const resp = await superFetch({
        url: `sites/activity/40?pas=${step}&unit=41`,
        method: 'POST',
        body: formData,
        stringify: false,
        contentType: null,
      })

      if (resp.status < 200 || resp.status >= 300) throw new Error(resp.statusText)
    },
    {
      onError: notifyError,
      onSuccess: () => notifySuccess({ title: t('admin.uploadSuccess') }),
    },
  )
}

const initialStart = moment().add(-1, 'month').format('yyyy-MM-DD')
const initialEnd = moment().format('yyyy-MM-DD')

export const AdminActivities = () => {
  const { t } = useTranslation()
  const { mutate: downloadModel, isLoading: isDownloading } = useDownloadModel()
  const { mutate: uploadData, isLoading: isUploading } = useUploadData()

  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)
  const [step, setStep] = useState(3)

  const stepOptions = [
    { value: 1, label: t('site.activities.yearly') },
    { value: 2, label: t('site.activities.quarterly') },
    { value: 3, label: t('site.activities.monthly') },
    { value: 4, label: t('site.activities.daily') },
    { value: 5, label: t('site.activities.hourly') },
    { value: 10, label: t('site.activities.weekly') },
  ]

  return (
    <div className="section">
      <h3>{t('site.activities.title')}</h3>

      <div className="space-y-4">
        <div className="d-flex align-items-center space-x-4">
          <input
            type="date"
            className="form-control form-control-sm"
            style={{ display: 'inline', width: 'auto' }}
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />

          <ArrowIcon />

          <input
            type="date"
            className="form-control form-control-sm"
            style={{ display: 'inline', width: 'auto' }}
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />

          <select
            value={step}
            onChange={(e) => setStep(e.target.value)}
            className="form-control form-control-sm"
            style={{ display: 'inline', width: 'auto' }}
          >
            {stepOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div className="d-flex space-x-4">
          <button
            className="btn btn-primary btn-sm"
            disabled={isDownloading}
            onClick={() => {
              const formattedStart = moment(start).format('DD/MM/yyyy')
              const formattedEnd = moment(end).format('DD/MM/yyyy')
              downloadModel({ start: formattedStart, end: formattedEnd, step })
            }}
          >
            <span>{t('site.activities.downloadModel')}</span>

            <span className="ml-3">
              {isDownloading ? <Spinner size="sm" color="light" /> : <DownloadIcon />}
            </span>
          </button>

          <FileInput
            disabled={isUploading}
            onChange={(file) => {
              uploadData({ step, file })
            }}
          >
            <span>{t('site.activities.uploadData')}</span>

            <span className="ml-3">
              {isUploading ? <Spinner size="sm" color="light" /> : <UploadIcon />}
            </span>
          </FileInput>
        </div>
      </div>
    </div>
  )
}
