import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withHandlers, lifecycle, withState } from 'recompose'
import { components } from 'react-select'
import flatten from 'lodash/flatten'

import {
  fetchKpiGraph,
  getKpiSelection,
  getKpiType,
  getKpiCompare,
  getKpiUnit,
  setKpiSelection,
  setKpiType,
  setKpiCompare,
  setKpiUnit,
  getKpiIsFetching,
} from 'store/performance'

import { getSiteIdsFromUor } from 'store/organisation'

import {
  getCurrentUors,
  getCurrentSitesIds,
  getCurrentSitesByUor,
  getCurrentPeesIds,
  getCurrentPeesBySite,
} from 'store/currentSelection'
import { getLongStartDate, getLongEndDate, getEnergy, getAgregation } from 'store/dateRange'

import Select from 'components/common/Select'
import ButtonGroup from 'components/common/ButtonGroup'
import styles from './PerformanceKPI.module.scss'

const GroupHeading = (props) => (
  <components.GroupHeading {...props}>
    <span>{props.children}</span>
  </components.GroupHeading>
)

const Option = (props) => (
  <components.Option {...props}>
    {props.data.nrj && (
      <span
        className={
          props.data.nrj === 2
            ? styles.optionIconElec
            : props.data.nrj === 1
            ? styles.optionIconGaz
            : styles.optionIconWater
        }
      >
        <i
          className={
            props.data.nrj === 2 ? 'icon-elec' : props.data.nrj === 1 ? 'icon-gaz' : 'icon-tint'
          }
        />
      </span>
    )}
    {props.label}
  </components.Option>
)

const PerformanceKPIOptions = ({
  isLoading,
  uors,
  peesBySite,
  agregation,
  entity1,
  entity2,
  setEntity1,
  setEntity2,
  sitesByUor,
  compare,
  unit,
  setKpiCompare,
  setKpiUnit,
}) => {
  const { t } = useTranslation()

  const entitiesOptions =
    agregation === '0'
      ? peesBySite.map(({ name, pees }) => ({
          label: name,
          options: pees.map(({ id, PeeClef, PeeNrjId }) => ({
            value: id,
            label: PeeClef,
            nrj: PeeNrjId,
          })),
        }))
      : agregation === '1'
      ? sitesByUor.map(({ name, sites }) => ({
          label: name,
          options: sites.map(({ id, SphNom }) => ({
            value: id,
            label: SphNom,
          })),
        }))
      : agregation === '3'
      ? uors.map(({ id, UorNom }) => ({
          label: UorNom,
          value: id,
        }))
      : []

  const flatEntitiesOptions =
    agregation === '3' ? entitiesOptions : flatten(entitiesOptions.map((o) => o.options))

  return (
    <>
      {agregation === '0' && (
        <>
          <div className={styles.container}>
            <div className={styles.title}>{t('graph.cptPrincipal')}</div>
            <Select
              placeholder={t('graph.cptPlaceholder')}
              value={flatEntitiesOptions.find((option) => option.value === entity1)}
              onChange={(option) => setEntity1(!option ? null : option.value)}
              options={entitiesOptions}
              components={{ GroupHeading, Option }}
              size="xs"
              width={200}
              isClearable={true}
              isDisabled={isLoading}
            />
          </div>
          <div className={styles.container}>
            <div className={styles.title}>{t('graph.cptCompare')}</div>
            <Select
              placeholder={t('graph.cptPlaceholder')}
              value={flatEntitiesOptions.find((option) => option.value === entity2)}
              onChange={(option) => setEntity2(!option ? null : option.value)}
              options={entitiesOptions}
              components={{ GroupHeading, Option }}
              size="xs"
              width={200}
              isDisabled={isLoading || !entity1}
              isClearable={true}
            />
          </div>
        </>
      )}

      {agregation === '1' && (
        <>
          <div className={styles.container}>
            <div className={styles.title}>{t('graph.sitePrincipal')}</div>
            <Select
              placeholder={t('graph.sitePlaceholder')}
              value={flatEntitiesOptions.find((option) => option.value === entity1)}
              onChange={(option) => setEntity1(!option ? null : option.value)}
              options={entitiesOptions}
              components={{ GroupHeading }}
              size="xs"
              width={200}
              isClearable={true}
              isDisabled={isLoading}
            />
          </div>
          <div className={styles.container}>
            <div className={styles.title}>{t('graph.siteCompare')}</div>
            <Select
              placeholder={t('graph.sitePlaceholder')}
              value={flatEntitiesOptions.find((option) => option.value === entity2)}
              onChange={(option) => setEntity2(!option ? null : option.value)}
              options={entitiesOptions}
              components={{ GroupHeading }}
              size="xs"
              width={200}
              isDisabled={isLoading || !entity1}
              isClearable={true}
            />
          </div>
        </>
      )}

      {agregation === '3' && (
        <>
          <div className={styles.container}>
            <div className={styles.title}>{t('graph.buPrincipal')}</div>
            <Select
              placeholder={t('graph.buPlaceholder')}
              value={flatEntitiesOptions.find((option) => option.value === entity1)}
              onChange={(option) => setEntity1(!option ? null : option.value)}
              options={entitiesOptions}
              components={{ GroupHeading }}
              size="xs"
              width={200}
              isClearable={true}
              isDisabled={isLoading}
            />
          </div>
          <div className={styles.container}>
            <div className={styles.title}>{t('graph.buCompare')}</div>
            <Select
              placeholder={t('graph.buPlaceholder')}
              value={flatEntitiesOptions.find((option) => option.value === entity2)}
              onChange={(option) => setEntity2(!option ? null : option.value)}
              options={entitiesOptions}
              components={{ GroupHeading }}
              size="xs"
              width={200}
              isDisabled={isLoading || !entity1}
              isClearable={true}
            />
          </div>
        </>
      )}

      <ButtonGroup
        title={t('graph.compare')}
        value={compare}
        inputs={[
          { label: t('graph.n1'), value: 'MesureN1' },
          { label: t('graph.cible'), value: 'Cible' },
          { label: t('graph.budget'), value: 'Budget' },
        ]}
        onChange={setKpiCompare}
        isDisabled={isLoading}
      />
      <ButtonGroup
        title={t('graph.unit')}
        value={unit}
        inputs={[
          { label: 'kWh', value: 11 },
          { label: 'MWh', value: 12 },
          { label: 'GWh', value: 13 },
        ]}
        onChange={setKpiUnit}
        isDisabled={isLoading}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  uors: getCurrentUors(state),
  siteIds: getCurrentSitesIds(state),
  sitesByUor: getCurrentSitesByUor(state),
  peeIds: getCurrentPeesIds(state),
  peesBySite: getCurrentPeesBySite(state),
  startDate: getLongStartDate(state),
  endDate: getLongEndDate(state),
  energy: getEnergy(state),
  agregation: getAgregation(state),
  selection: getKpiSelection(state),
  type: getKpiType(state),
  compare: getKpiCompare(state),
  unit: getKpiUnit(state),
  isLoading: getKpiIsFetching(state),
})

const mapStateToPropsWithEntities = (state, props) => ({
  sitIdsFromEntities: getSiteIdsFromUor(state, props.entity1, props.entity2),
})

const mapDispatchToProps = {
  fetchKpiGraph,
  setKpiSelection,
  setKpiType,
  setKpiCompare,
  setKpiUnit,
}

const createPayload = (props) => {
  const allEntities =
    props.agregation === '0'
      ? props.peeIds
      : props.agregation === '1'
      ? props.siteIds
      : props.agregation === '3'
      ? props.uors.map((x) => x.id)
      : []

  return {
    debut: props.startDate,
    fin: props.endDate,
    ids:
      props.agregation === '3'
        ? props.sitIdsFromEntities
        : !!props.entity1
        ? !!props.entity2
          ? [props.entity1, props.entity2]
          : [props.entity1]
        : allEntities,

    typeIds: props.agregation === '0' ? 'PEE' : 'SIT',
    nrjs: [props.energy],
    unite: props.unit,
    kpi: props.kpi.Id,
    typeKpi: props.kpi.Type,
    agregation: props.agregation,
    typesDonnees: ['Mesure', ...props.compare],
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('entity1', 'setEntity1', (props) =>
    props.agregation === '0' && props.peeIds.length > 0
      ? props.peeIds[0]
      : props.agregation === '1' && props.siteIds.length > 0
      ? props.siteIds[0]
      : props.agregation === '3' && props.uors.map((x) => x.id).length > 0
      ? props.uors.map((x) => x.id)[0]
      : null,
  ),
  withState('entity2', 'setEntity2', null),
  connect(mapStateToPropsWithEntities),
  withHandlers({
    fetchGraph: (props) => () => {
      if (props.kpi === null) return
      props.fetchKpiGraph(createPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (prevProps.agregation !== this.props.agregation) {
        const entity1 =
          this.props.agregation === '0' && this.props.peeIds.length > 0
            ? this.props.peeIds[0]
            : this.props.agregation === '1' && this.props.siteIds.length > 0
            ? this.props.siteIds[0]
            : this.props.agregation === '3' && this.props.uors.map((x) => x.id).length > 0
            ? this.props.uors.map((x) => x.id)[0]
            : null
        this.props.setEntity1(entity1)
        this.props.setEntity2(null)
        return
      }

      if (JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props))) {
        this.props.fetchGraph()
      }
    },
  }),
)(PerformanceKPIOptions)
