import React, { useState } from 'react'
import { Container, Button, FormGroup, Input, Label } from 'reactstrap'

import Security from './Security'
import UserInfos from './UserInfos'

const UserPanel = (props) => {
  const { userPanelOpen, setUserPanelOpen } = props
  const [showSecurityPanel, setShowSecurityPanel] = useState('0')

  return (
    <div>
      <Container className={['user-panel', userPanelOpen ? 'open' : ''].join(' ')} fluid>
        <div className="radio-input-btn panel-choice">
          <FormGroup>
            <Input
              id="showUser"
              type="radio"
              name="userLan"
              checked={showSecurityPanel === '0'}
              value="0"
              onChange={() => setShowSecurityPanel('0')}
            />
            <Label for="showUser" className="btn">
              Infos utilisateurs{' '}
            </Label>
          </FormGroup>

          <FormGroup>
            <Input
              id="showSecurity"
              type="radio"
              name="userLan"
              checked={showSecurityPanel === '1'}
              value="1"
              onChange={() => setShowSecurityPanel('1')}
            />
            <Label for="showSecurity" className="btn">
              Sécurité
            </Label>
          </FormGroup>
        </div>

        <Button
          className="close-panel"
          color="light"
          onClick={() => setUserPanelOpen(!userPanelOpen)}
        >
          <i className="icon-cross" />
        </Button>

        {showSecurityPanel === '0' ? <UserInfos {...props} /> : <Security {...props} />}
      </Container>

      <div className="user-panel-overlay" onClick={() => setUserPanelOpen(!userPanelOpen)} />
    </div>
  )
}

export default UserPanel
