import React, { useMemo, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import flatten from 'lodash/flatten'

import { getPeesList } from 'store/organisation'
import { getCurrentSitesByUor } from 'store/currentSelection'
import { getCurveType, getEnergy, updateCurveType } from 'store/dateRange'
import { getAgregation, updateAgregation } from 'store/dateRange'
import { getSite, updateSite } from 'store/dateRange'
import { getVue, updateVue } from 'store/dateRange'
import { getPee, updatePee } from 'store/dateRange'

import FocusAlert from 'components/common/FocusAlert'
import DateRangeLongPeriod from './DateRangeLongPeriod'
import DateRangeShortPeriod from './DateRangeShortPeriod'
import DateRangeLongStartDate from './DateRangeLongStartDate'
import DateRangeShortStartDate from './DateRangeShortStartDate'
import DateRangeLongEndDate from './DateRangeLongEndDate'
import DateRangeShortEndDate from './DateRangeShortEndDate'
import { NrjSelect } from './NrjSelect'

const agregationOptions = [
  // { value: '0', label: 'Compteur' },
  { value: '1', label: 'Site' },
  // { value: '2', label: 'Total' },
  { value: '3', label: 'BU' },
  // { value: '4', label: 'Région', isDisabled: true },
  // { value: '5', label: 'Cluster', isDisabled: true },
]

const LongPeriod = () => {
  const { t } = useTranslation()
  return (
    <div>
      <label className="dt-label inline">
        <span style={{ marginRight: '1.5rem' }}>{t('consumption.daterange.period')}</span>
        <DateRangeLongPeriod />
      </label>

      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          input: { width: 60 },
        }}
      >
        <DateRangeLongStartDate />
        <i
          className="icon-arrow-date"
          style={{ color: 'var(--gray)', fontSize: 20, margin: '0 0.6rem 2px', lineHeight: 1 }}
        />
        <DateRangeLongEndDate />
      </div>
    </div>
  )
}

const ShortPeriod = () => {
  const { t } = useTranslation()
  return (
    <div>
      <label className="dt-label inline">
        <span style={{ marginRight: '1rem' }}>{t('consumption.daterange.period')}</span>
        <DateRangeShortPeriod />
      </label>

      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          input: { width: 80 },
        }}
      >
        <DateRangeShortStartDate />
        <i
          className="icon-arrow-date"
          style={{ color: 'var(--gray)', fontSize: 20, margin: '0 0.6rem 2px', lineHeight: 1 }}
        />
        <DateRangeShortEndDate />
      </div>
    </div>
  )
}

const CurveTypeSelect = () => {

  const { t } = useTranslation()
  const curveTypeOptions = useMemo( () =>
    [
      { value: '1', label: t('consumption.daterange.soutirage')        },
      { value: '2', label: t('consumption.daterange.injection')        },
      { value: '3', label: t('consumption.daterange.production')       },
      { value: '4', label: t('consumption.daterange.consommation')     },
      { value: '5', label: t('consumption.daterange.autoconsommation') },
    ], [t]
  )
  const dispatch = useDispatch()
  const curveType = useSelector(getCurveType)
  const value = useMemo(
    () => curveTypeOptions.find((option) => option.value === curveType),
    [curveType, curveTypeOptions],
  )

  return (
    <label className="dt-label">
      <span>{t('consumption.daterange.curveType')}</span>
      <Select
        value={value}
        onChange={(option) => {
          dispatch(updateCurveType(option.value))
        }}
        options={curveTypeOptions}
        className="react-select"
        classNamePrefix="react-select"
        styles={{
          container: (provided) => ({
            ...provided,
            width: '160px',
          }),
        }}
      />
    </label>
  )
}

const AgregationSelect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const agregation = useSelector(getAgregation)
  const value = useMemo(
    () => agregationOptions.find((option) => option.value === agregation),
    [agregation],
  )

  return (
    <label className="dt-label">
      <span>{t('consumption.daterange.groupBy')}</span>
      <Select
        value={value}
        onChange={(option) => {
          dispatch(updateAgregation(option.value))
        }}
        options={agregationOptions}
        className="react-select"
        classNamePrefix="react-select"
        styles={{
          container: (provided) => ({
            ...provided,
            width: '140px',
          }),
        }}
      />
    </label>
  )
}
const SiteSelect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const site = useSelector(getSite)
  const sitesByUor = useSelector(getCurrentSitesByUor)

  const options = useMemo(
    () =>
      sitesByUor.map(({ name, sites }) => ({
        label: name,
        options: sites.map(({ id, SphNom }) => ({
          value: id,
          label: SphNom,
        })),
      })),
    [sitesByUor],
  )

  const flatOptions = useMemo(() => flatten(options.map((o) => o.options)), [options])

  const value = useMemo(
    () => flatOptions.find((option) => option.value === site) ?? null,
    [flatOptions, site],
  )

  useEffect(() => {
    if (value === null && flatOptions.length > 0) {
      dispatch(updateSite(flatOptions[0].value))
    } else if (site === null && flatOptions.length === 0) {
      dispatch(updateSite(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatOptions])

  return (
    <label className="dt-label">
      <span>{t('consumption.daterange.site')}</span>
      <Select
        value={value}
        onChange={(option) => {
          dispatch(updateSite(option.value))
        }}
        options={options}
        className="react-select"
        classNamePrefix="react-select"
        styles={{
          container: (provided) => ({
            ...provided,
            width: '200px',
          }),
        }}
      />
    </label>
  )
}

const VueSelect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const vue = useSelector(getVue)

  const vueOptions = useMemo(
    () => [
      { value: '0', label: t('graph.cpt') },
      { value: '1', label: 'Site' },
    ],
    [t],
  )

  const value = useMemo(() => vueOptions.find((option) => option.value === vue), [vue, vueOptions])

  return (
    <label className="dt-label">
      <span>{t('consumption.daterange.vue')}</span>
      <Select
        value={value}
        onChange={(option) => {
          dispatch(updateVue(option.value))
        }}
        options={vueOptions}
        className="react-select"
        classNamePrefix="react-select"
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100px',
          }),
        }}
      />
    </label>
  )
}

const PeeSelect = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const pee = useSelector(getPee)
  const site = useSelector(getSite)
  const nrj = useSelector(getEnergy)
  const pees = useSelector(getPeesList)

  const options = useMemo(
    () =>
      pees
        .filter((pee) => pee.PeeSitId === site && pee.PeeNrjId.toString() === nrj)
        .map((pee) => ({
          value: pee.id,
          label: pee.PeeClef,
          nrj: pee.PeeNrjId,
        })),
    [nrj, pees, site],
  )

  const value = useMemo(
    () => options.find((option) => option.value === pee) ?? null,
    [options, pee],
  )

  useEffect(() => {
    if (value === null && options.length > 0) {
      dispatch(updatePee(options[0].value))
    } else if (pee !== null && options.length === 0) {
      dispatch(updatePee(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <label className="dt-label">
      <span>{t('consumption.daterange.pee')}</span>
      <Select
        value={value}
        onChange={(option) => {
          dispatch(updatePee(option.value))
        }}
        options={options}
        className="react-select"
        classNamePrefix="react-select"
        styles={{
          container: (provided) => ({
            ...provided,
            width: '180px',
          }),
        }}
      />
    </label>
  )
}

const DateRange = ({ short, showAgregation, showEntities, showCurveType, isGlobal }) => {
  const vue = useSelector(getVue)

  return (
    <div className="d-flex justify-content-between align-items-center" style={{ height: '100%' }}>
      <div
        css={{
          display: 'flex',
          border: '1px solid #dee2e6',
          borderRadius: 4,
          '>*': {
            padding: '0.8rem 1.2rem 0.4rem',
            '&:not(:last-child)': {
              borderRight: '1px solid #dee2e6',
            },
          },
          '.dt-label': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '&.inline': {
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            },
            '>span': {
              display: 'block',
              fontSize: 14,
              fontWeight: 'bold',
              color: 'var(--gray)',
            },
          },
          '.react-select': {
            fontSize: 14,
            '.react-select__indicator-separator': {
              display: 'none',
            },
            '.react-select__control': {
              height: 32,
              minHeight: 32,
              border: 0,
              boxShadow: 'none !important',
            },
            '.react-select__value-container': {
              padding: 0,
            },
            '.react-select__indicator': {
              padding: 0,
            },
            '.react-select__menu': {
              marginTop: 0,
              borderRadius: 0,
            },
          },
          '.rdt': {
            input: {
              border: 0,
              height: 32,
              padding: 0,
              fontSize: 14,
            },
          },
        }}
      >
        {showEntities && <SiteSelect />}
        <NrjSelect />
        {showEntities && <VueSelect />}
        {showEntities && vue === '0' && <PeeSelect />}
        {short ? <ShortPeriod /> : <LongPeriod />}
        {showAgregation && <AgregationSelect />}
        {showCurveType && (isGlobal === true || vue !== '0') && <CurveTypeSelect />}
      </div>

      <FocusAlert />
    </div>
  )
}

export default DateRange
