import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers } from 'recompose'
import { useTranslation } from 'react-i18next'

import { getCurrentSitesIds } from 'store/currentSelection'
import { getLongStartDate, getLongEndDate, getEnergy, getCurveType } from 'store/dateRange'

import {
  getConsumptionGraphAgregation,
  getConsumptionGraphUniteId,
  getConsumptionGraphType,
  getConsumptionGraphCompare,
  fetchConsumptionGraph,
  setConsumptionGraphAgregation,
  setConsumptionGraphUnite,
  setConsumptionGraphType,
  setConsumptionGraphCompare,
} from 'store/consumption'

import Select from 'components/common/Select'
import ButtonGroup from 'components/common/ButtonGroup'
import styles from './MonthlyConsumption.module.scss'

const MonthlyConsumptionOptions = ({
  title,
  agregation,
  setConsumptionGraphAgregation,
  unite,
  type,
  compare,
  setConsumptionGraphUnite,
  setConsumptionGraphType,
  toggleCompare,
}) => {
  const { t } = useTranslation()

  const agregationOptions = [
    { value: '2', label: t('graph.overview') },
    { value: '1', label: 'Site' },
    { value: '3', label: 'BU' },
  ]

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>{t('graph.groupBy')}</div>
        <Select
          value={agregationOptions.find((option) => option.value === agregation)}
          onChange={(option) => setConsumptionGraphAgregation(option.value)}
          options={agregationOptions}
          size="xs"
          width={140}
        />
      </div>

      <ButtonGroup
        title={t('graph.displayData')}
        value={type}
        inputs={[
          { label: t('graph.normalise'), value: 'Normalise' },
          { label: t('graph.mesure'), value: 'Mesure' },
        ]}
        onChange={setConsumptionGraphType}
      />

      <ButtonGroup
        title={t('graph.compare')}
        value={compare}
        inputs={[
          { label: t('graph.n1'), value: 'MesureN1' },
          { label: t('graph.budget'), value: 'Budget' },
        ]}
        onChange={toggleCompare}
      />

      <ButtonGroup
        title={t('graph.unit')}
        value={unite}
        inputs={[
          { label: 'kWh', value: '11' },
          { label: 'MWh', value: '12' },
          { label: 'GWh', value: '13' },
        ]}
        onChange={setConsumptionGraphUnite}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  siteIds: getCurrentSitesIds(state),
  startDate: getLongStartDate(state),
  endDate: getLongEndDate(state),
  energy: getEnergy(state),
  agregation: getConsumptionGraphAgregation(state),
  unite: getConsumptionGraphUniteId(state),
  type: getConsumptionGraphType(state),
  compare: getConsumptionGraphCompare(state),
  curveType: getCurveType(state),
})

const mapDispatchToProps = {
  fetchConsumptionGraph,
  setConsumptionGraphUnite,
  setConsumptionGraphType,
  setConsumptionGraphCompare,
  setConsumptionGraphAgregation,
}

const createPayload = (props) => ({
  debut: props.startDate,
  fin: props.endDate,
  ids: props.siteIds,
  typeIds: props.agregation === '0' ? 'PEE' : 'SIT',
  nrjs: [props.energy],
  typesDonnees: [props.type, ...props.compare],
  unite: props.unite,
  agregation: props.agregation,
  flux: props.curveType,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    toggleCompare:
      ({ compare, setConsumptionGraphCompare }) =>
      (s) =>
        setConsumptionGraphCompare(
          compare.includes(s) ? compare.filter((x) => x !== s) : compare.concat(s),
        ),
    fetchGraph: (props) => () => {
      props.fetchConsumptionGraph(createPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props))) {
        this.props.fetchGraph()
      }
    },
  }),
)(MonthlyConsumptionOptions)
