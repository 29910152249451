// CSS
import * as apiConf from './api/apiConf'
import * as userApi from './api/user'
import { init } from './helpers/env'

// API
const { superFetch } = apiConf
export { init, apiConf, userApi, superFetch }

// i18n
export { default as i18n } from './i18n/i18n.js'

// OIDC userManager
export { default as userManager } from './security/userManager'

// Reducers
export { default as apiStatusReducer } from './reducers/apiStatus'
export { default as userReducer } from './reducers/user'

// Redux middlewares
export { default as accessTokenMiddleware } from './middlewares/security'

// Components
export { default as AccessDenied } from './components/login/AccessDenied'
export { default as SilentRedirectPage } from './components/login/SilentRedirectPage'
export { default as Callback } from './components/login/Callback'
export { default as Logout } from './components/login/Logout'
export { default as UserPanel } from './components/userpanel/UserPanel'
export { default as ErrorBoundary } from './components/error/ErrorBoundary'
export { default as Version } from './components/version/Version'

// HOC
export { withLoader } from './helpers/withLoader'

// User selectors
export {
  usrId,
  uorIdMst,
  platform,
  lanId,
  lngIso,
  lngIsoStr,
  isAdmin,
  paysId,
  isLoggedIn,
  userDetail,
  postError,
  postSuccess,
} from './reducers/user'

// User actions
export { logout, changePassword, fetchUserInfo, postUserForm } from './actions/user'
