import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import classnames from 'classnames'

import Error from '../../common/Error'
import styles from './PerformanceLabel.module.scss'
import { withLoader } from '../../common/Loader'
import { getLabelIsFetching, getLabelError } from '../../../store/performance'

const PerformanceLabel = ({ legends, arrows, unit, value, values, error }) => {
  if (error) return <Error />
  return (
    <div>
      <div className={styles.legend}>
        <span>{legends[1]}</span>
        <span>{legends[0]}</span>
      </div>
      <br />

      {values.map(([letter, min, max, backgroundColor, color], index) => (
        <div key={letter} className={styles.line}>
          <div className={styles.barContainer}>
            <div
              className={styles.bar}
              style={{
                backgroundColor,
                color,
                width: 76 + 31 * index,
              }}
            >
              <div className={styles.value}>
                {min === -Infinity
                  ? `≤ ${max}`
                  : max === Infinity
                  ? `> à ${min}`
                  : `${min} à ${max}`}
              </div>
              <div className={styles.letter}>{letter}</div>
            </div>

            {arrows && (
              <div className={styles.rightArrow} style={{ borderLeftColor: backgroundColor }} />
            )}
          </div>

          {value > min && value <= max && (
            <>
              <div className={styles.hr} />
              <div className={styles.barContainer}>
                <div className={styles.leftArrow} />
                <div className={classnames(styles.bar, styles.cursor)}>
                  <div className={styles.value}>{Math.round(value * 100) / 100}</div>
                  <div className={styles.letter}>{letter}</div>
                </div>
                <div className={styles.unit}>{unit}</div>
              </div>
            </>
          )}
        </div>
      ))}

      <br />
      <div className={styles.legend}>
        <span>{legends[2]}</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: getLabelIsFetching(state),
  error: getLabelError(state),
})

export default compose(connect(mapStateToProps), withLoader)(PerformanceLabel)

export { PerformanceLabel }
