let apiUrl = () => ''
let apiUrlInvoice = () => ''
let autorityUrl = ''
let postLoginRedirectUrl = ''
let silentRedirectUri = ''
let OidcLogLevel = 'NONE'
let clientId = ''
let scopes = ''
let reponseType = ''

const subscribers = []

const onReady = (callback) => subscribers.push(callback)

const init = (env) => {
  clientId = env.loginClientId
  scopes = env.scopes
  apiUrl = env.apiUrl
  apiUrlInvoice = env.apiUrlInvoice
  autorityUrl = env.autorityUrl
  postLoginRedirectUrl = env.postLoginRedirectUrl
  silentRedirectUri = env.silentRedirectUri
  reponseType = env.reponseType
  OidcLogLevel = env.OidcLogLevel
  subscribers.forEach((callback) => callback())
}

export {
  clientId,
  scopes,
  reponseType,
  onReady,
  init,
  autorityUrl,
  postLoginRedirectUrl,
  silentRedirectUri,
  OidcLogLevel,
  apiUrl,
  apiUrlInvoice,
}
