import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { Table, Button } from 'reactstrap'
import classnames from 'classnames'
import { withRouter } from 'react-router-dom'

import { noDecimalFormat } from '../../../helpers/formatters'
import styles from './TopFlopTable.module.scss'
import { setToFocus } from 'store/currentSelection'

const TopFlopTable = ({ type, data, history, setToFocus }) => {
  const { t } = useTranslation()

  return (
    <Table striped borderless hover className={classnames('table-fixed', styles.table)}>
      <thead>
        <tr>
          <th>{t('performance.entite')}</th>
          <th style={{ textAlign: 'right' }}>{data[0] && data[0].LibelleValeur}</th>
          <th style={{ textAlign: 'right' }}>{data[0] && data[0].LibelleValeurComparaison}</th>
          <th style={{ textAlign: 'right' }}>
            {t('performance.ecart')} - {data[0] && data[0].UniteConso}
          </th>
          <th style={{ textAlign: 'right' }}>{t('performance.ecart')} - %</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {data.map(
          ({ Ecart, Valeur, ValeurComparaison, SitNom, EcartPourcentage, SitId }, index) => (
            <tr key={index}>
              <td>
                {index + 2}. {SitNom}
              </td>
              <td style={{ textAlign: 'right' }}>{noDecimalFormat(Valeur)}</td>
              <td style={{ textAlign: 'right' }}>{noDecimalFormat(ValeurComparaison)}</td>
              <td style={{ textAlign: 'right' }}>{noDecimalFormat(Ecart)}</td>
              <td style={{ textAlign: 'right' }}>{noDecimalFormat(EcartPourcentage)}</td>
              <td
                style={{
                  textAlign: 'right',
                  verticalAlign: 'middle',
                  padding: 4,
                }}
              >
                <Button
                  size="sm"
                  color="link"
                  onClick={() => {
                    setToFocus(SitId)
                    history.push('/global-consumption')
                  }}
                  title={t('topNav.globalConsumption')}
                  style={{ textDecoration: 'none' }}
                >
                  <i className="icon-diag" style={{ fontSize: 16 }} />
                </Button>

                <Button
                  size="sm"
                  color="link"
                  onClick={() => {
                    setToFocus(SitId)
                    history.push('/detailed-consumption')
                  }}
                  title={t('topNav.detailedConsumption')}
                  style={{ textDecoration: 'none' }}
                >
                  <i className="icon-ccourbes" style={{ fontSize: 16 }} />
                </Button>
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { setToFocus }

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(TopFlopTable)

export { TopFlopTable }
