import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Row, Col, Container, Button } from 'reactstrap'

import { closeSearchPanel, getSearchPanelOpen, getFilterInput } from '../../store/searchPanel'
import { getRootUor } from '../../store/organisation'
import Uor from './Uor'
import ListeFiltree from './ListeFiltree'
import Filtres from './Filtres'
import SelectButtons from './SelectButtons'
import { getTagFilters } from 'store/searchPanel'

const SearchPanel = ({ searchPanelOpen, rootUor, filterInput, closeSearchPanel, dispatch }) => {
  const tagFilters = useSelector(getTagFilters)
  return (
    <div>
      <Container className={['search-panel', searchPanelOpen ? 'open' : ''].join(' ')} fluid>
        {searchPanelOpen ? (
          <span>
            <Button
              className="close-panel"
              color="light"
              onClick={() => {
                dispatch(closeSearchPanel)
              }}
            >
              <i className="icon-cross" />
            </Button>

            <Filtres />

            <Row>
              <Col className="d-flex">
                <SelectButtons rootUor={rootUor} />
              </Col>
            </Row>
            <Row className="panel-flex">
              <Col>
                {filterInput ? (
                  <ListeFiltree tagFilters={tagFilters} />
                ) : (
                  <ul>
                    <Uor uorId={rootUor} tagFilters={tagFilters} />
                  </ul>
                )}
              </Col>
            </Row>
          </span>
        ) : null}
      </Container>
      <div
        className="search-panel-overlay"
        onClick={() => {
          dispatch(closeSearchPanel)
        }}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  searchPanelOpen: getSearchPanelOpen(state),
  rootUor: getRootUor(state),
  filterInput: getFilterInput(state),
  closeSearchPanel: closeSearchPanel(),
})

export default connect(mapStateToProps)(SearchPanel)
