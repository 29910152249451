import React from 'react'
import { ButtonGroup as Group, Button } from 'reactstrap'
import classnames from 'classnames'

import styles from './ButtonGroup.module.scss'

const ButtonGroup = ({
  title,
  value,
  inputs,
  onChange,
  isDisabled,
  vertical = false,
  inline = false,
}) => (
  <div className={inline ? styles.inlineContainer : styles.container}>
    {title && <div className={styles.title}>{title}</div>}
    <Group vertical={vertical}>
      {inputs.map((input) => (
        <Button
          key={input.value}
          className={classnames(styles.button, {
            [styles.active]: Array.isArray(value)
              ? value.includes(input.value)
              : value === input.value,
          })}
          size="sm"
          onClick={() => onChange(input.value)}
          disabled={isDisabled}
        >
          {input.label}
        </Button>
      ))}
    </Group>
  </div>
)

export default ButtonGroup
