import React from 'react'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useOrganisation } from './useOrganisation'
import { getCurrentSitesIds } from 'store/currentSelection'

const usePeeTree = (peeId, filtered) => {
  const orga = useOrganisation()
  const currentSites = useSelector(getCurrentSitesIds)

  const peeTree = React.useMemo(() => {
    const createNode = (item) => {
      if (item.UorNom) {
        return {
          label: item.UorNom || '?',
          disabled: true,
          children: [
            ...(item?.UorEnfants || [])
              .map(createNode)
              .filter((item) => item !== null && item.children.length > 0),
            ...(item?.UorSites || [])
              .map(createNode)
              .filter((item) => item !== null && item.children.length > 0),
          ],
        }
      }

      if (item.SphNom) {
        if (filtered && !currentSites.includes(item.id)) return null
        return {
          label: item.SphNom || '?',
          disabled: true,
          children: (item?.SitPees || [])
            .filter((pee) => pee.PeeNrjId === 2)
            .map(createNode)
            .map((pee) => ({ ...pee, siteLabel: item.SphNom || null })),
        }
      }

      const className = item.PeeNrjId === 2 ? 'elec' : item.PeeNrjId === 1 ? 'gas' : ''

      return {
        label: item.PeeClef || '?',
        disabled: false,
        value: item.id,
        checked: item.id === peeId,
        className,
      }
    }

    return createNode(orga)
  }, [currentSites, filtered, orga, peeId])

  const peesById = React.useMemo(() => {
    const pees = {}

    const getPees = (item) => {
      if (!!item.UorNom) {
        const children = [...(item.UorEnfants || []), ...(item.UorSites || [])]
        children.forEach(getPees)
      } else {
        if (filtered && !currentSites.includes(item.id)) return
        const children = item.SitPees || []
        children.forEach((pee) => {
          pees[pee.id] = {
            ...pee,
            SphNom: item.SphNom,
          }
        })
      }
    }

    getPees(orga)

    return pees
  }, [currentSites, filtered, orga])

  return { peeTree, peesById }
}

const getFirstPee = (node) => (node?.value ? node : getFirstPee(node.children[0]))

export const PeeTreeSelect = ({ value, onChange, filtered = false }) => {
  const { t } = useTranslation()
  const { peeTree, peesById } = usePeeTree(value, filtered)

  React.useEffect(() => {
    if (value === null || !Object.keys(peesById).includes(value.toString())) {
      const pee = getFirstPee(peeTree)
      onChange(pee.value)
    }
  }, [value, peeTree, onChange, peesById])

  const siteName = value && peesById[value] ? peesById[value].SphNom : null

  return (
    <DropdownTreeSelect
      className="pee-tree-select"
      mode="radioSelect"
      texts={{
        placeholder: t('gestion.treeSelect.placeholder'),
        noMatches: t('gestion.treeSelect.noMatches'),
      }}
      data={{
        ...peeTree,
        expanded: true,
      }}
      onChange={(item) => {
        onChange(item.value)
      }}
      keepTreeOnSearch
      keepChildrenOnSearch
      css={{
        '.tag:before': {
          content: siteName ? `"${siteName} - "` : '""',
        },
      }}
    />
  )
}
