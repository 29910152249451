import React from 'react'
import { connect } from 'react-redux'

import {
  getHeatMapGraph,
  getLoadCurveGraphUnite,
  getHeatMapGraphIsFetching,
  getHeatMapGraphError,
} from 'store/consumption'

import Chart from 'components/common/Chart'
import createOptions from './options'

const HeatMapGraph = ({ graph, unite, error, isLoading }) => {
  const options = React.useMemo(() => {
    return createOptions(graph, unite)
  }, [graph, unite])

  return (
    <Chart
      id="heatmap"
      error={error}
      isEmpty={!graph || graph.length === 0}
      isLoading={isLoading}
      options={options}
      immutable={true}
    />
  )
}

const mapStateToProps = (state) => ({
  graph: getHeatMapGraph(state),
  isLoading: getHeatMapGraphIsFetching(state),
  error: getHeatMapGraphError(state),
  unite: getLoadCurveGraphUnite(state),
})

export default connect(mapStateToProps)(HeatMapGraph)
