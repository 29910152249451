import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import isNil from 'lodash/isNil'

import { request } from 'helpers/request'
import { useNotifyError } from 'hooks/notify'
import { useSiteId } from './useSiteId'

type PMax = {
  Id?: number
  SitId: number
  PMaxOuverture: number
  PMaxFermeture: number
}

const usePMaxQuery = (siteId: number) => {
  return useQuery({
    queryKey: ['calendrierpmax', siteId],
    queryFn: () => request({ url: `sites/${siteId}/calendrierpmax` }) as Promise<PMax | null>,
    suspense: true,
    useErrorBoundary: true,
    keepPreviousData: true,
  })
}

const usePMaxMutation = (siteId: number) => {
  const queryClient = useQueryClient()
  const notifyError = useNotifyError()

  return useMutation(
    (pmax: PMax) => {
      return request({
        url: `sites/${siteId}/calendrierpmax`,
        method: isNil(pmax.Id) ? 'POST' : 'PUT',
        body: pmax,
      })
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['calendrierpmax', siteId])
      },
      onError: notifyError,
    },
  )
}

const getDefaultValues = (siteId: number) => ({
  SitId: siteId,
  PMaxOuverture: 0,
  PMaxFermeture: 0,
})

export const usePMaxForm = () => {
  const siteId = useSiteId()
  const { mutateAsync, isLoading } = usePMaxMutation(siteId)
  const { data, isFetching } = usePMaxQuery(siteId)

  const initialValues: PMax = useMemo(
    () => ({
      ...getDefaultValues(siteId),
      ...data,
    }),
    [data, siteId],
  )

  return {
    isLoading: isLoading || isFetching,
    submit: mutateAsync,
    initialValues,
  }
}
