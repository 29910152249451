import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label } from 'reactstrap'
import Highlighter from 'react-highlight-words'

import { getSite } from 'store/organisation'

import {
  getFilterInput,
  getFilterType,
  getCountryFilter,
  getSiteIsOfFilterType,
} from 'store/searchPanel'

import {
  toggleSiteInSelection,
  getIsSiteIndeterminate,
  getIsSiteSelected,
} from 'store/currentSelection'

const Site = ({ siteId, tagFilters }) => {
  const dispatch = useDispatch()

  const site = useSelector((state) => getSite(state, siteId))
  const isSiteIndeterminate = useSelector((state) => getIsSiteIndeterminate(state, siteId))
  const isSiteSelected = useSelector((state) => getIsSiteSelected(state, siteId))

  const toggle = useSelector((state) =>
    toggleSiteInSelection(state, siteId, getFilterType(state), getCountryFilter(state)),
  )

  const siteIsOfFilterType = useSelector((state) => getSiteIsOfFilterType(state, siteId))
  const filterInput = useSelector(getFilterInput)

  const filterTagNames = tagFilters.map((tag) => tag.nom)
  const siteTagNames = site.Tags?.split('|') ?? []

  const shouldDisplay =
    filterTagNames.length === 0 || siteTagNames.some((s) => filterTagNames.includes(s))
  if (!shouldDisplay) return null

  return (
    <span>
      {siteIsOfFilterType ? (
        <li className={!siteIsOfFilterType ? 'hidden-xs-up' : undefined}>
          <FormGroup check>
            <Label
              check
              className={
                (isSiteIndeterminate && 'indeterminate') || (isSiteSelected && 'checked') || ''
              }
            >
              <input
                className="form-check-input"
                type="checkbox"
                value={siteId}
                checked={isSiteSelected}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = isSiteIndeterminate
                  }
                }}
                onChange={() => dispatch(toggle)}
              />
              <span className="left-pad">
                {filterInput ? (
                  <Highlighter
                    highlightClassName="bg-primary"
                    searchWords={[filterInput]}
                    textToHighlight={site.SphNom}
                  />
                ) : (
                  <button className="reset">{site.SphNom}</button>
                )}
              </span>
            </Label>
          </FormGroup>
        </li>
      ) : null}
    </span>
  )
}

export default Site
