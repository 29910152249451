import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

import { fetchLabel, getLabel } from 'store/performance'
import { getSite } from 'store/dateRange'
import { getEnergy } from 'store/dateRange'

import EnergyLabel from './EnergyLabel'
import ClimateLabel from './ClimateLabel'

const Labels = () => {
  const dispatch = useDispatch()
  const siteId = useSelector(getSite)
  const energy = useSelector(getEnergy)
  const labels = useSelector(getLabel)

  useEffect(() => {
    dispatch(
      fetchLabel({
        ids: [siteId],
        typeIds: 'SIT',
        nrjs: [energy],
      }),
    )
  }, [dispatch, energy, siteId])

  if (!labels) return null

  return (
    <Container fluid>
      <Row>
        <Col className="pl-0">
          <EnergyLabel value={!labels ? -1 : labels.EtiquetteEnergetique} />
        </Col>
        <Col className="pr-0">
          <ClimateLabel value={!labels ? -1 : labels.EtiquetteClimat} />
        </Col>
      </Row>
    </Container>
  )
}

export default Labels
