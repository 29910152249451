import React from 'react'
import * as env from '../../helpers/env'

const Logout = () => {
  React.useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = env.autorityUrl
  }, [])

  return null
}

export default Logout
