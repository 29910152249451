import React, { ReactChild } from 'react'

type Value = number | string

type TextFieldProps<T extends Value> = {
  type?: 'text' | 'number'
  label: ReactChild
  postfix?: ReactChild
  value?: T
  onChange: (value: T) => void
}

export const TextField = <T extends Value>({
  type = 'text',
  label,
  postfix,
  value,
  onChange,
}: TextFieldProps<T>) => {
  return (
    <label className="d-flex space-x-4 align-items-center" style={{ height: '1rem' }}>
      <span className="font-weight-bold">{label}</span>
      <input
        className="form-control py-1 px-2"
        style={{ width: '8rem' }}
        type={type}
        value={value ?? ''}
        onChange={(e) => {
          const value = e.target.value
          const parsedValue = type === 'number' && value !== '' ? Number(value) : value
          onChange(parsedValue as T)
        }}
      />
      {postfix && <span>{postfix}</span>}
    </label>
  )
}

type RadioFieldsOption<T extends Value> = {
  label: ReactChild
  value: T
  disabled?: boolean
}

export type RadioFieldsOptions<T extends Value> = RadioFieldsOption<T>[]

type RadioFieldsProps<T extends Value> = {
  label: ReactChild
  options: RadioFieldsOptions<T>
  value?: T
  onChange: (value: T) => void
}

export const RadioFields = <T extends Value>({
  label,
  options,
  value,
  onChange,
}: RadioFieldsProps<T>) => {
  return (
    <span className="d-flex space-x-4 align-items-center">
      <span className="font-weight-bold">{label}</span>

      {options.map((option, index) => (
        <label key={`${option.value}-${index}`} className="d-flex space-x-1 align-items-center">
          <input
            type="radio"
            disabled={option.disabled ?? false}
            checked={value === option.value}
            onChange={() => onChange(option.value)}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </span>
  )
}

type SelectOption<T extends Value> = {
  label: ReactChild
  value: T
  disabled?: boolean
}

export type SelectOptions<T extends Value> = SelectOption<T>[]

type SelectProps<T extends Value> = {
  label: ReactChild
  options: RadioFieldsOptions<T>
  value?: T
  onChange: (value: T) => void
}

export const Select = <T extends Value>({ label, options, value, onChange }: SelectProps<T>) => {
  const found = options.some((option) => option.value === value)

  return (
    <span className="d-flex space-x-4 align-items-center">
      <span className="font-weight-bold">{label}</span>

      <select
        className="form-control"
        style={{ width: '6rem' }}
        value={value}
        onChange={(e) => {
          const value = e.target.value
          const parsedValue =
            typeof options[0].value === 'number' && value !== '' ? Number(value) : value
          onChange(parsedValue as T)
        }}
      >
        {!found && <option value={value}></option>}

        {options.map((option, index) => (
          <option
            key={`${option.value}-${index}`}
            value={option.value}
            disabled={option.disabled ?? false}
          >
            {option.label}
          </option>
        ))}
      </select>
    </span>
  )
}
