import React from 'react'
import HighchartsReact from 'highcharts-react-official'
import { useTranslation } from 'react-i18next'

import { Highcharts } from 'helpers/highcharts'
import { noDecimalFormat } from 'helpers/formatters'
import { getColor } from 'helpers/colors'
import regression from 'regression'

const ProgressKpiGraph = ({ data, xUnit, yUnit, uor }) => {
  const { t } = useTranslation()
  const color = getColor(0, 1, { uor })

  // Regression line coords
  const result = regression.linear(data.map(({ x, y }) => [x, y]))
  const gradient = result.equation[0]
  const yIntercept = result.equation[1]
  const xs = data.map((p) => p.x)
  const x1 = Math.min(...xs)
  const x2 = Math.max(...xs)
  const y1 = gradient * x1 + yIntercept
  const y2 = gradient * x2 + yIntercept

  const options = {
    chart: {
      type: 'scatter',
    },
    exporting: {
      enabled: false,
    },

    title: {
      text: null,
    },

    yAxis: {
      title: {
        text: `${t('graph.consommation')} (${yUnit})`,
      },
    },

    xAxis: {
      gridLineWidth: 1,
      title: {
        text: `${t('graph.indicateur')} (${xUnit})`,
      },
    },

    legend: {
      enabled: false,
    },

    tooltip: {
      headerFormat: '',
      pointFormatter: function () {
        const kpi = noDecimalFormat(this.x)
        const conso = noDecimalFormat(this.y)
        return `<b>${this.name}<b><br/>${t('graph.indicateur')} : <b>${kpi}</b> ${xUnit}<br/>${t(
          'graph.consommation',
        )} : <b>${conso}</b> ${yUnit}`
      },
    },

    plotOptions: {
      scatter: {
        connectNulls: false,
        marker: {
          enabled: true,
          radius: 4,
          lineWidth: 1,
          symbol: 'circle',
        },
      },
    },

    series: [
      {
        data,
        color,
      },
      {
        type: 'line',
        data: [
          [x1, y1],
          [x2, y2],
        ],
        color,
        marker: {
          enabled: false,
        },
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default ProgressKpiGraph
