import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

import { emptySelection } from 'store/currentSelection'

const CurrentSelectionFooter = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div className="d-flex w-100">
      <Button
        color="link"
        onClick={() => {
          dispatch(emptySelection())
        }}
      >
        <span>{t('sidebar.btn.empty')}</span>
      </Button>
    </div>
  )
}

export default CurrentSelectionFooter
