import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

import { ErrorBoundary } from 'commons'

import UserPanel from './UserPanel'
import SearchPanel from '../searchPanel/SearchPanel'
import TopNav from '../topnav/TopNav'
import Views from '../route/Views'
import Notifs from '../notifications/Notifs'
import SideBar from '../sidebar/Sidebar'
import { fetchOrganisationSite } from 'store/organisation'

const Template = () => {
  const dispatch = useDispatch()
  const [userPanelOpen, setUserPanelOpen] = React.useState(false)
  useEffect(() => dispatch(fetchOrganisationSite()), [dispatch])

  return (
    <>
      <Notifs />
      <SearchPanel />
      <UserPanel userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />
      <Container fluid>
        <Row>
          <Col className="sidebar-col" tag="aside">
            <SideBar />
          </Col>
          <Col className="main-col">
            <Row className="main-header sticky">
              <TopNav userPanelOpen={userPanelOpen} setUserPanelOpen={setUserPanelOpen} />
            </Row>
            <ErrorBoundary>
              <Views />
            </ErrorBoundary>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Template
