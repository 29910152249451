import React from 'react'
import { Collapse, Button } from 'reactstrap'
import { connect } from 'react-redux'
import { compose, withStateHandlers } from 'recompose'
import classnames from 'classnames'

import CurrentSite from './CurrentSite'
import { getAllCurrentSites } from 'store/currentSelection'
import { getAllCurrentSitesIdsByUor } from 'store/currentSelection'
import styles from './CurrentSelection.module.scss'

const CurrentSelection = ({ currentSitesIdsByUor, currentSites, toggle, collapsed }) => {
  return (
    <>
      {currentSitesIdsByUor.map(({ id, name, sites }) => (
        <div className={styles.group} key={id}>
          <Button color="link" className={styles.uorBtn} onClick={() => toggle(id)}>
            <span
              title={name}
              style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginRight: '5px',
              }}
            >
              {name}
            </span>
            <i
              className={classnames(
                'icon-smallarrow-up',
                !!collapsed[id] ? styles.arrowUp : styles.arrowDown,
              )}
            />
          </Button>
          <Collapse isOpen={!!collapsed[id]} className={styles.collapse}>
            {sites.map((siteId) => (
              <CurrentSite
                key={siteId}
                site={currentSites[siteId] && currentSites[siteId].SphNom}
                siteId={
                  (currentSites[siteId] && currentSites[siteId].id) || {
                    id: 0,
                  }
                }
              />
            ))}
          </Collapse>
        </div>
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  currentSitesIdsByUor: getAllCurrentSitesIdsByUor(state),
  currentSites: getAllCurrentSites(state),
  isSearchOpened: state.searchPanel.open,
})

export default compose(
  connect(mapStateToProps),
  withStateHandlers(
    { collapsed: {} },
    {
      toggle:
        ({ collapsed }) =>
        (id) => ({
          collapsed: {
            ...collapsed,
            [id]: !collapsed[id],
          },
        }),
    },
  ),
)(CurrentSelection)
