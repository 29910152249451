import React from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'

import { updateSiteField } from 'store/site'

const CustomInput = ({ state, dispatch, path, updateSiteField, ...props }) => {
  const match = useRouteMatch()
  const siteId = match.params.id

  const { t } = useTranslation()
  const [error, setError] = React.useState(null)
  const value = get(state, path, '')
  const initValue = React.useRef(value)

  const debounced = React.useRef(
    debounce((value) => {
      if (value.trim().length > 0 && props.type === 'email' && !value.includes('@')) {
        setError(t('global.emailError'))
      } else {
        updateSiteField(siteId, path, 'replace', value)
      }
    }, 800),
  )

  React.useEffect(() => {
    if (value !== initValue.current) {
      debounced.current(value)
    }
  }, [value])

  return (
    <div>
      <Input
        className="form-control-sm"
        value={value}
        onChange={(e) => {
          setError(null)
          const value = e.target.value
          dispatch({ type: 'set', value, path })
        }}
        {...props}
        style={{
          border: error ? '1px solid var(--danger)' : undefined,
        }}
      />
      {error && <div style={{ marginTop: '.5em', color: 'var(--danger)' }}>{error}</div>}
    </div>
  )
}

const mapDispatch = { updateSiteField }

export default connect(null, mapDispatch)(CustomInput)
