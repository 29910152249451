import React from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'
import sum from 'lodash/sum'

import { useDepenses } from './useGestion'
import { decimalFormat, noDecimalFormat } from 'helpers/formatters'
import { CustomTable } from './CustomTable'
import { CSVButton } from './CSVButton'

const itemProps = [
  'Depassement',
  'Reactif',
  //'Energie', 'Transport', 'Taxes', 'Total', 'Prix',
]

export const TableDepenses = () => {
  const { t } = useTranslation()
  const data = useDepenses()

  const columns = React.useMemo(
    () => [
      t('gestion.tableDepense.depassements'),
      t('gestion.tableDepense.reactif'),
      // t('gestion.tableDepense.energie'),
      // t('gestion.tableDepense.transport'),
      // t('gestion.tableDepense.taxes'),
      // t('gestion.tableDepense.total'),
      // t('gestion.tableDepense.prix'),
    ],
    [t],
  )

  const rows = React.useMemo(() => {
    const mainRows = data.map((item) => [
      upperFirst(moment(item.Date).format('MMM YY')),
      ...itemProps.map((prop, index) =>
        index === 6 // Prix
          ? decimalFormat(item[prop], 2)
          : noDecimalFormat(item[prop]),
      ),
    ])

    const totals = itemProps.map((prop) => sum(data.map((item) => item[prop])))

    const totalRow = [
      'Total',
      ...totals.map((value, index) =>
        index === 6 // Prix
          ? decimalFormat(value, 2)
          : noDecimalFormat(value),
      ),
    ]

    return [...mainRows, totalRow]
  }, [data])

  const getCsvData = React.useCallback(
    () => [['', ...columns], ...rows.map((row) => [`"=""${row[0]}"""`, ...row.slice(1)])],
    [columns, rows],
  )

  return (
    <>
      <CustomTable>
        <thead>
          <tr>
            <th rowSpan="2" style={{ width: 80 }}>
              &nbsp;
            </th>
            <th colSpan="2">{t('gestion.depenseDepReact')}</th>
            {/* <th colSpan="4">{t('gestion.depenses')}</th>
            <th>&nbsp;</th> */}
          </tr>

          <tr>
            {columns.map((c, i) => (
              <th key={i}>{c}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {rows.map((item, j) => (
            <tr
              key={j}
              css={{
                th: { width: 100 },
                td: { width: 150 },
              }}
            >
              <th>{item[0]}</th>

              {item.slice(1).map((value, i) => (
                <td key={i}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </CustomTable>

      <CSVButton filename={`ESITE - ${t('gestion.depenses')}.csv`} getData={getCsvData} />
    </>
  )
}
