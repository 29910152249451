import React from 'react'
import { useSelector } from 'react-redux'
import Notifications from 'react-notification-system-redux'

const Notifs = () => {
  const notifications = useSelector((state) => state.notifications)
  return <Notifications notifications={notifications} />
}

export default Notifs
