import React from 'react'
import { connect } from 'react-redux'
import Datetime from 'react-datetime'
import moment from 'moment'

import { lngIso } from 'commons'
import { dateFormat } from '../../../helpers/formatters'
import { updateShortStartDate, getShortDateRange } from '../../../store/dateRange'

const DateRangeShortStartDate = ({ dispatch, dateRange, lng }) => {
  const format = dateFormat()

  function onChange(date) {
    const isValid = moment(date, format, true).isValid()
    if (!isValid) return
    dispatch(updateShortStartDate(date))
  }

  return (
    <Datetime
      id="startDate"
      locale={lng}
      closeOnSelect
      dateFormat={dateFormat()}
      timeFormat={false}
      value={moment(dateRange.startDate).toDate()}
      onChange={onChange}
    />
  )
}

const mapStateToProps = (state) => ({
  dateRange: getShortDateRange(state),
  lng: lngIso(state),
})

export default connect(mapStateToProps)(DateRangeShortStartDate)
