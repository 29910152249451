import React from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import { lngIso } from 'commons'
import { dateFormatMonths } from 'helpers/formatters'
import { getStartDate, getEndDate, setStartDate, setEndDate } from 'store/gestion'

export const Period = () => {
  const dispatch = useDispatch()
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const locale = useSelector(lngIso)

  const dateFormat = dateFormatMonths()
  const datetimeProps = {
    viewMode: 'months',
    dateFormat,
    closeOnSelect: true,
    locale,
    inputProps: {
      placeholder: dateFormat,
    },
  }

  return (
    <>
      <Datetime
        {...datetimeProps}
        isValidDate={(current) => current.isBefore(moment(endDate), 'month')}
        value={moment(startDate)}
        onChange={(m) => {
          if (typeof m !== 'string') dispatch(setStartDate(m.format()))
        }}
      />

      <i
        className="icon-arrow-date"
        css={{
          flex: '0 !important',
          fontSize: 24,
        }}
      />

      <Datetime
        {...datetimeProps}
        isValidDate={(current) => current.isAfter(moment(startDate), 'month')}
        value={moment(endDate)}
        onChange={(m) => {
          if (typeof m !== 'string') dispatch(setEndDate(m.format()))
        }}
      />
    </>
  )
}
