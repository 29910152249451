import Highcharts from 'highcharts'
import Highstock from 'highcharts/highstock'

import exporting from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'
import heatmap from 'highcharts/modules/heatmap'
import xrange from 'highcharts/modules/xrange'
import boost from 'highcharts/modules/boost'

import { highchartsEn } from 'locales/highcharts-en'
import { highchartsFr } from 'locales/highcharts-fr'

// Init modules
exporting(Highcharts)
exporting(Highstock)
exportData(Highcharts)
exportData(Highstock)
boost(Highcharts)
boost(Highstock)
heatmap(Highcharts)
xrange(Highcharts)

const baseOptions = {
  exporting: {
    csv: {
      itemDelimiter: ';',
    },
    buttons: {
      contextButton: {
        menuItems: [
          {
            textKey: 'downloadJPEG',
            onclick: function () {
              this.exportChart({
                type: 'image/jpeg',
              })
            },
          },
          {
            textKey: 'downloadPDF',
            onclick: function () {
              this.exportChart({
                type: 'application/pdf',
              })
            },
            separator: false,
          },
          {
            textKey: 'downloadCSV',
            onclick: function () {
              this.downloadCSV()
            },
            separator: false,
          },
        ],
      },
    },
  },
}

// Set options
export const setHighchartsOptions = (lanId) => {
  const langOptions = lanId === 1 ? highchartsFr : highchartsEn
  const options = { ...baseOptions, ...langOptions }
  Highcharts.setOptions(options)
  Highstock.setOptions(options)
}

export { Highcharts, Highstock }
