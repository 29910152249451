import moment from 'moment'

// Actions
export const DATE_RANGE_LONG_LAST_MONTH = 'esite/dateRange/DATE_RANGE_LONG_LAST_MONTH'
export const DATE_RANGE_LONG_LAST_3_MONTHS = 'esite/dateRange/DATE_RANGE_LONG_LAST_3_MONTHS'
export const DATE_RANGE_LONG_LAST_6_MONTHS = 'esite/dateRange/DATE_RANGE_LONG_LAST_6_MONTHS'
export const DATE_RANGE_LONG_LAST_12_MONTHS = 'esite/dateRange/DATE_RANGE_LONG_LAST_12_MONTHS'
export const DATE_RANGE_LONG_THIS_YEAR = 'esite/dateRange/DATE_RANGE_LONG_THIS_YEAR'
export const DATE_RANGE_LONG_LAST_YEAR = 'esite/dateRange/DATE_RANGE_LONG_LAST_YEAR'
export const DATE_RANGE_LONG_CUSTOM = 'esite/dateRange/DATE_RANGE_LONG_CUSTOM'
export const DATE_RANGE_SHORT_LAST_WEEK = 'esite/dateRange/DATE_RANGE_SHORT_LAST_WEEK'
export const DATE_RANGE_SHORT_LAST_MONTH = 'esite/dateRange/DATE_RANGE_SHORT_LAST_MONTH'
export const DATE_RANGE_SHORT_LAST_3_MONTHS = 'esite/dateRange/DATE_RANGE_SHORT_LAST_3_MONTHS'
export const DATE_RANGE_SHORT_CUSTOM = 'esite/dateRange/DATE_RANGE_SHORT_CUSTOM'
const DATE_RANGE_UPDATE_LONG_START_DATE = 'esite/dateRange/DATE_RANGE_UPDATE_LONG_START_DATE'
const DATE_RANGE_UPDATE_LONG_END_DATE = 'esite/dateRange/DATE_RANGE_UPDATE_LONG_END_DATE'
const DATE_RANGE_UPDATE_SHORT_START_DATE = 'esite/dateRange/DATE_RANGE_UPDATE_SHORT_START_DATE'
const DATE_RANGE_UPDATE_SHORT_END_DATE = 'esite/dateRange/DATE_RANGE_UPDATE_SHORT_END_DATE'
const DATE_RANGE_UPDATE_ENERGY = 'esite/dateRange/DATE_RANGE_UPDATE_ENERGY'
const DATE_RANGE_UPDATE_AGREGATION = 'esite/dateRange/DATE_RANGE_UPDATE_AGREGATION'
const DATE_RANGE_UPDATE_SITE = 'esite/dateRange/DATE_RANGE_UPDATE_SITE'
const DATE_RANGE_UPDATE_VUE = 'esite/dateRange/DATE_RANGE_UPDATE_VUE'
const DATE_RANGE_UPDATE_PEE = 'esite/dateRange/DATE_RANGE_UPDATE_PEE'

const DATE_RANGE_UPDATE_CURVETYPE = 'esite/dateRange/DATE_RANGE_UPDATE_CURVETYPE'


// Reducer
const initialState = {
  long: {
    period: DATE_RANGE_LONG_LAST_12_MONTHS,
    startDate: moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD'),
    endDate: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
  },
  short: {
    period: DATE_RANGE_SHORT_LAST_MONTH,
    startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  energy: '2',
  agregation: '1',
  site: null,
  vue: '1',
  pee: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case DATE_RANGE_LONG_LAST_MONTH:
      return {
        ...state,
        long: {
          period: DATE_RANGE_LONG_LAST_MONTH,
          startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
          // endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
          endDate: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_LONG_LAST_3_MONTHS:
      return {
        ...state,
        long: {
          period: DATE_RANGE_LONG_LAST_3_MONTHS,
          startDate: moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
          // endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
          endDate: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_LONG_LAST_6_MONTHS:
      return {
        ...state,
        long: {
          period: DATE_RANGE_LONG_LAST_6_MONTHS,
          startDate: moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD'),
          // endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
          endDate: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_LONG_LAST_12_MONTHS:
      return {
        ...state,
        long: {
          period: DATE_RANGE_LONG_LAST_12_MONTHS,
          startDate: moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD'),
          // endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
          endDate: moment().startOf('month').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_LONG_THIS_YEAR:
      return {
        ...state,
        long: {
          period: DATE_RANGE_LONG_THIS_YEAR,
          startDate: moment().startOf('year').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_LONG_LAST_YEAR:
      return {
        ...state,
        long: {
          period: DATE_RANGE_LONG_LAST_YEAR,
          startDate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
          endDate: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_LONG_CUSTOM:
      return {
        ...state,
        long: {
          ...state.long,
          period: DATE_RANGE_LONG_CUSTOM,
        },
      }
    case DATE_RANGE_UPDATE_LONG_START_DATE:
      return action.date.isSameOrAfter(state.long.endDate)
        ? state
        : {
            ...state,
            long: {
              ...state.long,
              period: DATE_RANGE_LONG_CUSTOM,
              startDate: action.date.format('YYYY-MM-DD'),
            },
          }
    case DATE_RANGE_UPDATE_LONG_END_DATE:
      return action.date.isSameOrBefore(state.long.startDate)
        ? state
        : {
            ...state,
            long: {
              ...state.long,
              period: DATE_RANGE_LONG_CUSTOM,
              endDate: action.date.format('YYYY-MM-DD'),
            },
          }

    case DATE_RANGE_SHORT_LAST_WEEK:
      return {
        ...state,
        short: {
          period: DATE_RANGE_SHORT_LAST_WEEK,
          startDate: moment().subtract(1, 'week').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_SHORT_LAST_MONTH:
      return {
        ...state,
        short: {
          period: DATE_RANGE_SHORT_LAST_MONTH,
          startDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_SHORT_LAST_3_MONTHS:
      return {
        ...state,
        short: {
          period: DATE_RANGE_SHORT_LAST_3_MONTHS,
          startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        },
      }
    case DATE_RANGE_SHORT_CUSTOM:
      return {
        ...state,
        short: {
          ...state.short,
          period: DATE_RANGE_SHORT_CUSTOM,
        },
      }
    case DATE_RANGE_UPDATE_SHORT_START_DATE:
      return action.date.isSameOrAfter(state.short.endDate)
        ? state
        : {
            ...state,
            short: {
              ...state.short,
              period: DATE_RANGE_SHORT_CUSTOM,
              startDate: action.date.format('YYYY-MM-DD'),
            },
          }
    case DATE_RANGE_UPDATE_SHORT_END_DATE:
      return action.date.isSameOrBefore(state.short.startDate)
        ? state
        : {
            ...state,
            short: {
              ...state.short,
              period: DATE_RANGE_SHORT_CUSTOM,
              endDate: action.date.format('YYYY-MM-DD'),
            },
          }

    case DATE_RANGE_UPDATE_ENERGY:
      return {
        ...state,
        energy: action.energy,
      }
    case DATE_RANGE_UPDATE_AGREGATION:
      return {
        ...state,
        agregation: action.agregation,
      }
    case DATE_RANGE_UPDATE_CURVETYPE:
      return {
        ...state,
        curveType: action.curveType,
      }
    case DATE_RANGE_UPDATE_SITE:
      return {
        ...state,
        site: action.site,
        pee: null,
      }
    case DATE_RANGE_UPDATE_VUE:
      return {
        ...state,
        vue: action.vue,
      }
    case DATE_RANGE_UPDATE_PEE:
      return {
        ...state,
        pee: action.pee,
      }

    default:
      return state
  }
}

// Selectors
export const getLongDateRange = (state) => state.dateRange.long
export const getLongStartDate = (state) =>
  (state.dateRange && state.dateRange.long && state.dateRange.long.startDate) || '2017-1-1'
export const getLongEndDate = (state) =>
  (state.dateRange && state.dateRange.long && state.dateRange.long.endDate) || '2017-1-1'

export const getShortDateRange = (state) => state.dateRange.short
export const getShortStartDate = (state) =>
  (state.dateRange && state.dateRange.short && state.dateRange.short.startDate) || '2017-1-1'
export const getShortEndDate = (state) =>
  (state.dateRange && state.dateRange.short && state.dateRange.short.endDate) || '2017-1-1'

export const getEnergy = (state) => state.dateRange && state.dateRange.energy
export const getAgregation = (state) => state.dateRange && state.dateRange.agregation
export const getCurveType  = (state) => state.dateRange && state.dateRange.curveType ? state.dateRange.curveType : '1'
export const getSite = (state) => state.dateRange?.site ?? null
export const getVue = (state) => state.dateRange?.vue ?? '1'
export const getPee = (state) => state.dateRange?.pee ?? null

// Action Creators
export const updateLongPeriod = (action) => ({
  type: action,
})

export const updateLongStartDate = (date) => ({
  type: DATE_RANGE_UPDATE_LONG_START_DATE,
  date,
})

export const updateLongEndDate = (date) => ({
  type: DATE_RANGE_UPDATE_LONG_END_DATE,
  date,
})

export const updateShortPeriod = (action) => ({
  type: action,
})

export const updateShortStartDate = (date) => ({
  type: DATE_RANGE_UPDATE_SHORT_START_DATE,
  date,
})

export const updateShortEndDate = (date) => ({
  type: DATE_RANGE_UPDATE_SHORT_END_DATE,
  date,
})

export const updateEnergy = (energy) => ({
  type: DATE_RANGE_UPDATE_ENERGY,
  energy,
})

export const updateAgregation = (agregation) => ({
  type: DATE_RANGE_UPDATE_AGREGATION,
  agregation,
})

export const updateCurveType = (curveType) => ({
  type: DATE_RANGE_UPDATE_CURVETYPE,
  curveType,
})


export const updateSite = (site) => ({
  type: DATE_RANGE_UPDATE_SITE,
  site,
})

export const updateVue = (vue) => ({
  type: DATE_RANGE_UPDATE_VUE,
  vue,
})

export const updatePee = (pee) => ({
  type: DATE_RANGE_UPDATE_PEE,
  pee,
})
