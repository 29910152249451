import { combineReducers } from 'redux'
import { reducer as notifications } from 'react-notification-system-redux'
import { reducer as oidc } from 'redux-oidc'
import { apiStatusReducer, userReducer } from 'commons'
import get from 'lodash/get'

import searchPanel from './searchPanel'
import organisation from './organisation'
import currentSelection from './currentSelection'
import countries from './countries'
import site from './site'
import pee from './pee'
import patrimonial from './patrimonial'
import activity from './activity'
import activities from './siteActivities'
import consumption from './consumption'
import dateRange from './dateRange'
import acheminement from './acheminement'
import regimes from './regimes'
import alerts from './alerts'
import performance from './performance'
import gestion from './gestion'

const app = combineReducers({
  oidc,
  apiStatus: apiStatusReducer,
  user: userReducer,
  regimes,
  acheminement,
  alerts,
  performance,
  consumption,
  dateRange,
  notifications,
  searchPanel,
  currentSelection,
  organisation,
  countries,
  site,
  pee,
  patrimonial,
  activity,
  activities,
  gestion,
})

const rootReducer = (state, action) => {
  if (action.type === 'esite/LOAD_PRESISTED_STATE') {
    state = {
      ...action.persistedState,
      oidc: state.oidc,
    }
  }

  return app(state, action)
}

export default rootReducer

// Selectors

export const getEid = (state) => get(state, ['oidc', 'user', 'profile', 'eid'], false)
export const getName = (state) => get(state, ['oidc', 'user', 'profile', 'name'], false)
export const getUor = (state) => get(state, ['oidc', 'user', 'profile', 'uor'], false)
