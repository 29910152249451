import { useMemo } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import upperFirst from 'lodash/upperFirst'
import groupBy from 'lodash/groupBy'
import isNil from 'lodash/isNil'
import keyBy from 'lodash/keyBy'
import isEmpty from 'lodash/isEmpty'
import range from 'lodash/range'
import * as Yup from 'yup'

import { request } from 'helpers/request'
import { useNotifyError } from 'hooks/notify'
import { useSiteId } from './useSiteId'

type CalItem = {
  Id?: number
  SitId: number
  JourSemaine: number
  DebutOuverture: string
  FinOuverture: string
}

const useCalOuvertureQuery = (siteId: number) => {
  return useQuery({
    queryKey: ['calendrierouverture', siteId],
    queryFn: () =>
      request({ url: `sites/${siteId}/calendrierouverture` }) as Promise<CalItem[] | null>,
    suspense: true,
    useErrorBoundary: true,
    keepPreviousData: true,
    select: (data) => keyBy(data, 'JourSemaine'),
  })
}

const useCalMutation = (siteId: number) => {
  const queryClient = useQueryClient()
  const notifyError = useNotifyError()

  return useMutation(
    (cal: CalItem[]) => {
      const { false: update, true: create } = groupBy(cal, (item) => isNil(item.Id))
      const promises = []

      if (!isEmpty(create))
        promises.push(
          request({
            url: `sites/${siteId}/calendrierouverture`,
            method: 'POST',
            body: create,
          }),
        )

      if (!isEmpty(update))
        promises.push(
          request({
            url: `sites/${siteId}/calendrierouverture`,
            method: 'PUT',
            body: update,
          }),
        )

      return Promise.all(promises)
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(['calendrierouverture', siteId])
      },
      onError: notifyError,
    },
  )
}

const useDays = () => {
  const { i18n } = useTranslation()
  const locale = (i18n as any).language
  const [sunday, ...week] = moment
    .localeData(locale)
    .weekdays()
    .map((name, index) => ({
      name: upperFirst(name),
      id: index,
    }))
  return [...week, sunday]
}

const getDefaultValues = (siteId: number) =>
  keyBy(
    range(0, 7).map((n) => ({
      SitId: siteId,
      JourSemaine: n,
      DebutOuverture: '',
      FinOuverture: '',
    })),
    'JourSemaine',
  )

export const useCalendarForm = () => {
  const siteId = useSiteId()
  const { mutateAsync, isLoading } = useCalMutation(siteId)
  const { data, isFetching } = useCalOuvertureQuery(siteId)
  const days = useDays()

  const validationSchema = useMemo(() => {
    const timeShema = () =>
      Yup.string()
        .nullable()
        .matches(/^([0-1]\d|20|21|22|23):[0-5]\d$/, {
          excludeEmptyString: true,
        })

    return Yup.array().of(
      Yup.object().shape({
        DebutOuverture: timeShema(),
        FinOuverture: timeShema(),
      }),
    )
  }, [])

  const initialValues: CalItem[] = useMemo(
    () =>
      Object.values({
        ...getDefaultValues(siteId),
        ...data,
      }),
    [data, siteId],
  )

  return {
    isLoading: isLoading || isFetching,
    submit: mutateAsync,
    initialValues,
    validationSchema,
    days,
  }
}
