import React from 'react'
import { connect } from 'react-redux'
import { compose, withState, withHandlers, lifecycle } from 'recompose'
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap'
import classnames from 'classnames'

import { getSiteId } from '../../../store/site'
import { getListPee, fetchPees } from '../../../store/pee'

// import styles from './Pees.module.scss';
import Pee from '../Pee'

const Pees = ({ pees, activeTab, toggleTab, isAdmin }) => {
  if (pees.length === 0) return null
  return (
    <>
      <Nav tabs className="simple-nav-tab flex-wrap-reverse">
        {pees.map((pee, index) => (
          <NavItem key={pee.id}>
            <NavLink
              className={classnames({
                active: activeTab === index,
              })}
              onClick={() => toggleTab(index)}
            >
              <i className={pee.PeeNrjId === 1 ? 'icon-gaz' : 'icon-elec'} />
              <span>{pee.PeeClef}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        <Pee id={pees[activeTab].id} isAdmin={isAdmin} />
      </TabContent>
    </>
  )
}

const mapStateToProps = (state) => {
  const siteId = getSiteId(state)
  return {
    pees: getListPee(state, siteId),
    siteId,
  }
}

const mapDispatchToProps = {
  fetchPees,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('activeTab', 'setActiveTab', 0),
  withHandlers({
    fetchData: (props) => () => {
      props.fetchPees(props.siteId)
    },
    toggleTab: (props) => (tab) => {
      if (props.activeTab !== tab) props.setActiveTab(tab)
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.siteId !== this.props.siteId) this.props.fetchData()
    },
  }),
)(Pees)

export { Pees }
