import React from 'react'
import { connect } from 'react-redux'

import { getEnergy } from 'store/dateRange'
import {
  getLoadCurveGraph,
  getLoadCurveGraphUnite,
  getLoadCurveGraphStep,
  getLoadCurveGraphIsFetching,
  getLoadCurveGraphError,
  getReferentielHrs,
  getLoadCurveGraphHrs,
  getLoadCurveGraphBoost,
  getPlotBandsLegend,
} from 'store/consumption'

import Chart from 'components/common/Chart'
import createOptions from './options'
import { getUor } from 'store'

const LoadCurveGraph = ({
  graph,
  isLoading,
  unite,
  error,
  hrs,
  boost,
  energy,
  plotBandsLegend,
  step,
  uor,
}) => {
  const options = React.useMemo(() => {
    return createOptions(graph, unite, hrs, energy, plotBandsLegend, boost, step, uor)
  }, [graph, unite, hrs, energy, plotBandsLegend, boost, step, uor])

  return (
    <Chart
      id="cdc"
      highstock
      error={error}
      isEmpty={!graph || !graph.series || graph.series.length === 0}
      isLoading={isLoading}
      options={options}
    />
  )
}

const mapStateToProps = (state) => ({
  graph: getLoadCurveGraph(state),
  isLoading: getLoadCurveGraphIsFetching(state),
  error: getLoadCurveGraphError(state),
  unite: getLoadCurveGraphUnite(state),
  step: getLoadCurveGraphStep(state),
  energy: getEnergy(state),
  boost: getLoadCurveGraphBoost(state),
  referentielHrs: getReferentielHrs(state).data,
  plotBandsLegend: getPlotBandsLegend(state),
  hrs: getLoadCurveGraphHrs(state),
  uor: getUor(state),
})

export default connect(mapStateToProps)(LoadCurveGraph)
