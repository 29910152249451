import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Notifications from 'react-notification-system-redux'

import {
  UserPanel as Panel,
  postSuccess,
  postError,
  logout,
  changePassword,
  fetchUserInfo,
  postUserForm,
} from 'commons'

const notifySuccess = Notifications.success({
  title: 'Your profile information has been saved successfully !',
  position: 'bl',
  autoDismiss: 4,
})

const notifyError = Notifications.error({
  title: 'Something went wrong !',
  position: 'bl',
  autoDismiss: 4,
})

const UserPanel = ({ userPanelOpen, setUserPanelOpen }) => {
  const dispatch = useDispatch()
  const success = useSelector(postSuccess)
  const error = useSelector(postError)
  const user = useSelector((state) => state.oidc && state.oidc.user)
  const userInfo = useSelector((state) => state.user && state.user.userInfo)

  React.useEffect(() => {
    if (success) dispatch(notifySuccess)
  }, [success, dispatch])

  React.useEffect(() => {
    if (error) dispatch(notifyError)
  }, [error, dispatch])

  return (
    <Panel
      userPanelOpen={userPanelOpen}
      setUserPanelOpen={setUserPanelOpen}
      changePasswordSuccess={success}
      changePasswordError={error}
      userPostSuccess={success}
      userPostError={error}
      user={user}
      userInfo={userInfo}
      logout={() => {
        dispatch(logout(user))
      }}
      changePassword={(payload) => {
        dispatch(changePassword(payload))
      }}
      fetchUserInfo={() => {
        dispatch(fetchUserInfo())
      }}
      postUserForm={(user) => {
        dispatch(postUserForm(user))
      }}
    />
  )
}

export default UserPanel
