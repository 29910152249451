import React, { Suspense, useReducer, useMemo, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from 'react-error-boundary'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import upperFirst from 'lodash/upperFirst'
import regression from 'regression'

import { Highcharts } from 'helpers/highcharts'
import { apiConf } from 'commons'
import { ErrorFallback } from 'components/common/ErrorFallback'
import { Loader } from 'components/common/Loader'
import { getShortStartDate, getShortEndDate } from 'store/dateRange'
import { getColor } from 'helpers/colors'
import { noDecimalFormat } from 'helpers/formatters'
import { getUor } from 'store'

const createRegression = (data) => {
  const result = regression.linear(data.map(({ y }, i) => [i, y]))
  const points = result.points.map(([, y], i) => ({ x: data[i].x, y }))
  return points
}

export const Evolution = ({ siteId }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<Loader style={{ height: 350 }} />}>
        <EvolutionInner siteId={siteId} />
      </Suspense>
    </ErrorBoundary>
  )
}

const formatDate = (x, pas, lang) => {
  const isFr = lang.startsWith('fr')
  const format =
    pas === '1'
      ? 'YYYY'
      : pas === '4'
      ? isFr
        ? 'DD MMMM YYYY'
        : 'YYYY MMMM DD'
      : pas === '5'
      ? isFr
        ? 'DD/MM/YYYY k:mm'
        : 'YYYY-MM-DD h:mm a'
      : isFr
      ? 'MMMM YYYY'
      : 'YYYY MMMM'

  return upperFirst(moment(x).format(format))
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setPas':
      return { ...state, pas: action.payload }
    case 'setOptions': {
      const pasOptions = action.payload
      const pas = pasOptions.some((o) => o.value === state.pas) ? state.pas : 4
      return { pas, pasOptions }
    }
    default:
      return state
  }
}

const EvolutionInner = ({ siteId }) => {
  const { t, i18n } = useTranslation()
  const uor = useSelector(getUor)

  const debut = useSelector(getShortStartDate)
  const fin = useSelector(getShortEndDate)

  const [{ pas, pasOptions }, dispatch] = useReducer(reducer, {
    pas: 4,
    pasOptions: [],
  })

  useEffect(() => {
    const m1 = moment(debut)
    const m2 = moment(fin)

    const options = [
      { value: 5, label: t('graph.hourly') },
      { value: 4, label: t('graph.daily') },
    ]

    if (m1.format('M') !== m2.format('M')) {
      options.push({ value: 3, label: t('graph.monthly') })
    }

    if (m2.diff(m1, 'months') > 2) {
      options.push({ value: 2, label: t('graph.quaterly') })
    }

    if (m1.format('Y') !== m2.format('Y')) {
      options.push({ value: 1, label: t('graph.yearly') })
    }

    dispatch({ type: 'setOptions', payload: options })
  }, [debut, fin, t])

  const { data } = useQuery({
    queryKey: ['temperatures', { siteId, debut, fin, pas }],
    queryFn: async () => {
      const res = await apiConf.superFetch({
        url: 'sites/graph/meteo',
        method: 'POST',
        body: {
          ids: [siteId],
          typeIds: 'SIT',
          agregation: '1',
          debut,
          fin,
          pas,
        },
      })
      if (res.status !== 200) throw new Error(res.status)
      return res.json()
    },
    staleTime: 60 * 60 * 1000,
  })

  const graphOptions = useMemo(() => {
    const length = data?.series?.length ?? 0
    if (length === 0) return null

    const [a, b] = data.series[0].data
    const pointInterval = b.x - a.x
    const HOUR = 60 * 60 * 1000
    const DAY = 24 * HOUR

    const corrected =
      pointInterval <= DAY
        ? {
            data: data.series[0].data.map((point) => point.y),
            pointStart: data.series[0].data[0].x,
            pointInterval: pas === '5' ? HOUR : DAY,
          }
        : {
            data: data.series[0].data.map((point) => [point.x, point.y]),
          }

    const mainSeries = {
      ...data.series[0],
      ...corrected,
      color: getColor(1, 1, { uor }),
    }

    const regressionData = createRegression(data.series[0].data)

    const regressionSeries = {
      name: 'Regression',
      marker: { enabled: false },
      lineWidth: 1,
      color: getColor(2, 1, { uor }),
      ...(pointInterval <= DAY
        ? {
            data: regressionData.map((point) => point.y),
            pointStart: data.series[0].data[0].x,
            pointInterval: pas === '5' ? HOUR : DAY,
          }
        : {
            data: regressionData,
          }),
    }

    return {
      series: [mainSeries, regressionSeries],
      chart: {
        type: 'spline',
        height: '300px',
        zoomType: 'x',
      },
      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: t('meteo.yAxis'),
        },
        labels: {
          format: '{value}° C',
        },
        crosshair: true,
      },
      xAxis: {
        type: 'datetime',
        crosshair: true,
      },
      boost: {
        enabled: true,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        formatter: function () {
          const { x, y } = this.point
          return `<b>${formatDate(x, pas, i18n.language)} \u2013 ${noDecimalFormat(y)}°C</b>`
        },
      },
      plotOptions: {
        series: {
          animation: false,
          turboThreshold: 1,
          lineWidth: 0.8,
          dataGrouping: {
            enabled: false,
          },
          states: {
            hover: {
              enabled: false,
            },
            inactive: {
              opacity: 1,
            },
          },
        },
      },
      time: {
        useUTC: true,
        getTimezoneOffset: (timestamp) => -moment.tz(timestamp, 'Europe/Paris').utcOffset(),
      },
    }
  }, [data, i18n.language, pas, t, uor])

  if (graphOptions === null) return <div>{t('global.noData')}</div>

  return (
    <div>
      <label className="d-inline-flex align-items-center">
        <span className="font-weight-bold text-dark mr-2">{t('meteo.step')}</span>

        <select
          className="form-control form-control-sm"
          value={pas}
          onChange={(e) => dispatch({ type: 'setPas', payload: e.target.value })}
        >
          {pasOptions.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </label>

      <div className="mt-4" style={{ position: 'relative', minHeight: 150 }}>
        <HighchartsReact highcharts={Highcharts} options={graphOptions} immutable={true} />
      </div>
    </div>
  )
}
