import { useQuery, useMutation, useQueryClient } from 'react-query'
import { superFetch } from 'commons'
import { useDispatch } from 'react-redux'
import { invalidOrganisationRequest } from 'store/organisation'

export const useTags = () =>
  useQuery({
    queryKey: ['sites/tags'],
    queryFn: async () => {
      const res = await superFetch({ url: 'sites/tags' })
      if (res.status !== 200) throw new Error(res.status)
      return res.json()
    },
    staleTime: 5 * 60 * 1000,
  })

export const useCreateTag = () => {
  const queryCache = useQueryClient()
  return useMutation(
    (nom) =>
      superFetch({
        url: 'sites/tags',
        method: 'POST',
        body: { nom },
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['sites/tags'], { exact: true })
      },
    },
  )
}

export const useDeleteTag = () => {
  const queryCache = useQueryClient()
  return useMutation(
    (id) =>
      superFetch({
        url: `sites/tags/${id}`,
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        queryCache.refetchQueries(['sites/tags'], { exact: true })
        queryCache.refetchQueries(({ queryKey }) => /sites\/\d+\/tags/.test(queryKey[0]))
      },
    },
  )
}

export const useSiteTags = (siteId) =>
  useQuery({
    queryKey: [`sites/${siteId}/tags`],
    queryFn: async () => {
      const res = await superFetch({ url: `sites/${siteId}/tags` })
      if (res.status !== 200) throw new Error(res.status)
      return res.json()
    },
    enabled: siteId !== null,
    staleTime: 5 * 60 * 1000,
  })

export const useCreateSiteTag = (siteId) => {
  const dispatch = useDispatch()
  const queryCache = useQueryClient()

  return useMutation(
    (tag) =>
      superFetch({
        url: `sites/${siteId}/tags`,
        method: 'POST',
        body: tag,
      }),
    {
      onSuccess: () => {
        dispatch(invalidOrganisationRequest())
        queryCache.refetchQueries([`sites/${siteId}/tags`], { exact: true })
      },
    },
  )
}

export const useDeleteSiteTag = (siteId) => {
  const dispatch = useDispatch()
  const queryCache = useQueryClient()

  return useMutation(
    (id) =>
      superFetch({
        url: `sites/${siteId}/tags/${id}`,
        method: 'DELETE',
      }),
    {
      onSuccess: () => {
        dispatch(invalidOrganisationRequest())
        queryCache.refetchQueries([`sites/${siteId}/tags`], { exact: true })
      },
    },
  )
}
