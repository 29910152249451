import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

import { getKpiGraph, getKpiIsFetching, getKpiError } from 'store/performance'
import Chart from 'components/common/Chart'
import createOptions from './options'
import { getUor } from 'store'

const PerformanceKPIGraph = ({ graph, isLoading, error, uor }) => {
  const options = React.useMemo(() => {
    return createOptions(graph, uor)
  }, [graph, uor])

  const isEmpty = !graph || !graph.graphMensuel || graph.graphMensuel.length === 0

  return (
    <Container>
      <Row>
        <Col xs="9">
          <Chart
            id="perf"
            error={error}
            isEmpty={isEmpty}
            isLoading={isLoading}
            options={options.all}
          />
        </Col>
        <Col xs="3">
          {!error && (
            <Chart
              id="perf-total"
              isEmpty={isEmpty}
              isLoading={isLoading}
              options={options.total}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  graph: getKpiGraph(state),
  isLoading: getKpiIsFetching(state),
  error: getKpiError(state),
  uor: getUor(state),
})

export default connect(mapStateToProps)(PerformanceKPIGraph)
