import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Button } from 'reactstrap'

import CurrentSelection from './CurrentSelection'
import CurrentSelectionFooter from './CurrentSelectionFooter'
import { toggleSearchPanel, getSearchPanelOpen } from 'store/searchPanel'

const PanelButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const searchPanelOpen = useSelector(getSearchPanelOpen)

  return (
    <Button
      className="w-100 toggle-panel glow"
      color="gold"
      onClick={() => dispatch(toggleSearchPanel)}
      active={searchPanelOpen}
    >
      {searchPanelOpen ? t('sidebar.panel.close') : t('sidebar.panel.open')}
    </Button>
  )
}

const SidebarSelectionCurrent = () => {
  return (
    <Container fluid className="current-selection-wrapper p-0">
      <Row noGutters={true} className="current-selection-header p-3">
        <Col>
          <PanelButton />
        </Col>
      </Row>
      <Row noGutters={true} className="current-selection-content">
        <Col>
          <CurrentSelection />
        </Col>
      </Row>
      <Row noGutters={true} className="current-selection-footer">
        <Col>
          <CurrentSelectionFooter />
        </Col>
      </Row>
    </Container>
  )
}

export default SidebarSelectionCurrent
