import { combineReducers } from 'redux'

import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
} from '../actions/'

const status = {
  loading: 'LOADING',
  success: 'SUCCESS',
  error: 'ERROR',
}

const login = (state = null, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        status: status.loading,
      }
    case USER_LOGIN_SUCCESS:
      return {
        status: status.success,
      }
    case USER_LOGIN_FAILURE:
      return {
        status: status.error,
        message: action.message,
      }
    default:
      return state
  }
}

const passwordReset = (state = null, action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      return {
        status: status.loading,
      }
    case PASSWORD_RESET_SUCCESS:
      return {
        status: status.success,
      }
    case PASSWORD_RESET_FAILURE:
      return {
        status: status.error,
        message: action.message,
      }
    default:
      return state
  }
}

export default combineReducers({
  login,
  passwordReset,
})

export const getErrorLogin = (state) =>
  (state.apiStatus.login && state.apiStatus.login.status === status.error) || false
export const getSuccessPasswordReset = (state) =>
  (state.apiStatus.passwordReset && state.apiStatus.passwordReset.status === status.success) ||
  false
export const getErrorPasswordReset = (state) =>
  (state.apiStatus.passwordReset && state.apiStatus.passwordReset.status === status.error) || false
