import React from 'react'
import { useTranslation } from 'react-i18next'
import { cx } from '@emotion/css'

export const Tag = ({
  name,
  active = false,
  disabled,
  deleteDisabled,
  onClick,
  onClose,
  className,
}) => {
  const { t } = useTranslation()

  return (
    <div className={cx('badge badge-light d-flex align-items-center p-0', className)}>
      <button
        disabled={disabled || !onClick}
        onClick={onClick ? onClick : () => {}}
        css={{
          border: 'none',
          padding: '0.4em 0.5em',
          height: '100%',
          color: active ? 'var(--light)' : 'var(--dark)',
          background: active ? 'var(--primary)' : 'transparent',
          borderRadius: '0.123rem',
          '&:focus': {
            outline: 'none',
          },
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: '10rem',
        }}
        title={name}
      >
        {name}
      </button>

      {onClose && (
        <button
          disabled={deleteDisabled}
          onClick={onClose}
          title={t('tags.deleteTitle')}
          css={{
            border: 'none',
            fontSize: 8,
            color: 'var(--dark)',
            height: '100%',
            background: 'transparent',
            padding: '0 1em',
            borderRadius: '0.123rem',
            '&:disabled': {
              color: 'var(--dark)',
            },
            '&:focus, &:hover': {
              outline: 'none',
              background: deleteDisabled ? 'transparent' : 'rgba(185, 82, 90, 0.6)',
              color: deleteDisabled ? 'var(--dark)' : 'var(--light)',
            },
          }}
        >
          <i className="icon-cross" />
        </button>
      )}
    </div>
  )
}
