import React from 'react'
import { Provider } from 'react-redux'
import { OidcProvider } from 'redux-oidc'
import { Route, Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { QueryClient, QueryClientProvider } from 'react-query'
import { I18nextProvider } from 'react-i18next'
import moment from 'moment'

import fr from './locales/fr.json'
import en from './locales/en.json'

import { setHighchartsOptions } from 'helpers/highcharts'
import initMoment from './helpers/moment'
import * as env from './helpers/env'
import configureStore from './configureStore'
import { userManager, i18n, init } from 'commons'
import startAnalytics from './analytics'
import Root from './components/route/Root'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      suspense: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      useErrorBoundary: false,
    },
    mutations: {
      retry: 0,
      useErrorBoundary: false,
    },
  },
})

// Init before configure store
init(env)

i18n.addResourceBundle('fr', 'translation', fr)
i18n.addResourceBundle('en', 'translation', en)

// Configure store
export const store = configureStore()
const getLanId = (state) => state && state.user && state.user.userInfo && state.user.userInfo.LanId
let lanId = getLanId(store.getState())

// Configure Moment & Hichcharts locales
initMoment()
setHighchartsOptions(lanId)
store.subscribe(() => {
  const newLanId = getLanId(store.getState())
  if (newLanId !== lanId) {
    lanId = newLanId
    moment.locale(lanId === 2 ? 'en' : 'fr')
    setHighchartsOptions(lanId)
  }
})

const history = createBrowserHistory()
startAnalytics(history)

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <OidcProvider store={store} userManager={userManager}>
            <Router history={history}>
              <Route path="/" component={Root} />
            </Router>
          </OidcProvider>
        </Provider>
      </QueryClientProvider>
    </I18nextProvider>
  )
}

export default App
