import React from 'react'
import ReactSelect from 'react-select'

const selectColor = 'var(--blue)'
const focusColor = '#3333'

const multiStyles = (width) => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: 'initial',
    borderRadius: '.2rem',
    borderColor: state.isFocused ? 'transparent' : '#d7dee4',
    boxShadow: state.isFocused ? `0 0 0 1px ${selectColor}` : 'unset',
    paddingTop: '1px',
    cursor: 'default',
    ':hover': {
      borderColor: state.isFocused ? 'transparent' : selectColor,
    },
    height: 33,
  }),
  container: (provided) => ({
    ...provided,
    fontSize: '12px',
    // // overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: width || 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    // display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px 8px',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    alignItems: 'center',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 1,
  }),
  group: (provided) => ({
    ...provided,
    padding: '0 0 4px 0',
  }),
  groupHeading: (provided) => ({
    ...provided,
    borderTop: '1px solid #9995',
    paddingTop: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    padding: '6px 8px',
    cursor: 'default',
    ':active': {
      background: selectColor,
      color: '#fff',
    },
    ...(state.isFocused && {
      background: focusColor,
      color: 'unset',
    }),
    ...(state.isSelected && {
      background: selectColor,
      color: '#fff',
    }),
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    left: '220px',
    height: '32px',
    overflow: 'visible',
    width: 'calc(100vw - 820px)',
    flex: 'none',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    ':before': {
      content: `"${state.selectProps.value.length} ${state.selectProps.selectedLabel}"`,
      position: 'absolute',
      left: '-210px',
      flex: 'none',
      color: '#495057',
    },
  }),
})

const xsStyles = (width) => ({
  control: (provided, state) => ({
    ...provided,
    minHeight: 'initial',
    borderRadius: '.2rem',
    borderColor: state.isFocused ? 'transparent' : '#d7dee4',
    boxShadow: state.isFocused ? `0 0 0 1px ${selectColor}` : 'unset',
    paddingTop: '1px',
    cursor: 'default',
    ':hover': {
      borderColor: state.isFocused ? 'transparent' : selectColor,
    },
  }),
  container: (provided) => ({
    ...provided,
    fontSize: '12px',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    // width: width || 'unset',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    // display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '3px 8px',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 1,
  }),
  groupHeading: (provided) => ({
    ...provided,
    backgroundColor: 'rgb(215, 222, 228)',
    color: '#61687f',
    fontWeight: 'bold',
    padding: '6px 8px',
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    padding: '6px 8px',
    cursor: 'default',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ':active': {
      background: selectColor,
      color: '#fff',
    },
    ...(!state.isFocused
      ? {}
      : {
          background: focusColor,
          color: 'unset',
        }),
    ...(!state.isSelected
      ? {}
      : {
          background: selectColor,
          color: '#fff',
        }),
  }),
})

const Select = ({ size, width, ...rest }) => {
  return (
    <ReactSelect
      styles={size === 'xs' ? xsStyles(width) : (size = 'multi' ? multiStyles(width) : {})}
      {...rest}
    />
  )
}

export default Select
