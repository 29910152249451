import { combineReducers } from 'redux'

import {
  USER_INFO_SUCCESS,
  USER_FORM_POST_SUCCESS,
  USER_FORM_POST_REQUEST,
  USER_FORM_POST_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  USER_FORM_UPDATE,
  USER_FORM_RESET,
} from '../actions'

const userInfo = (state = null, action) => {
  switch (action.type) {
    case USER_INFO_SUCCESS:
    case USER_FORM_POST_SUCCESS:
      return action.user
    default:
      return state
  }
}

const userPostSuccess = (state = null, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
    case USER_FORM_UPDATE:
    case USER_INFO_SUCCESS:
    case USER_FORM_RESET:
    case USER_FORM_POST_REQUEST:
    case USER_FORM_POST_FAILURE:
      return false
    case CHANGE_PASSWORD_SUCCESS:
    case USER_FORM_POST_SUCCESS:
      return true
    default:
      return state
  }
}

const userPostError = (state = '', action) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return ''
    case USER_FORM_POST_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      return action.message || 'Something went wrong'
    case USER_FORM_UPDATE:
    case USER_INFO_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
    case USER_FORM_RESET:
    case USER_FORM_POST_REQUEST:
    case USER_FORM_POST_SUCCESS:
      return ''
    default:
      return state
  }
}

export default combineReducers({
  userInfo,
  userPostError,
  userPostSuccess,
})

export const isLoggedIn = (state) => !!(state.oidc && state.oidc.user && !state.oidc.user.expired)
export const usrId = (state) =>
  state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.eid
export const uorIdMst = (state) =>
  state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.uor
export const platform = (state) =>
  state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.pla

export const lanId = (state) => {
  if (state.user && state.user.userInfo && state.user.userInfo.LanId)
    return state.user.userInfo.LanId
  if (state.oidc && state.oidc.user === null) return 1
  return (
    (state.oidc && state.oidc.user && state.oidc.user.profile && state.oidc.user.profile.lan) ||
    state.oidc.user.profile.lan ||
    1
  )
}

export const lngIso = (state) => {
  return lngIsoStr(lanId(state))
}

export const postSuccess = (state) => state.user && state.user.userPostSuccess
export const postError = (state) => state.user && state.user.userPostError
export const userDetail = (state) => state.user && state.user.userInfo

export const lngIsoStr = (lanId) => {
  switch (lanId) {
    case 2:
      return 'en'
    case 1:
    default:
      return 'fr'
  }
}

export const isAdmin = (state) =>
  (state.user && state.user.userInfo && state.user.userInfo.IsAdmin) || false
// Si le code pays utilisateur n'est pas renseigné, on indique France (1)
export const paysId = (state) =>
  (state.user && state.user.userInfo && state.user.userInfo.CodePays) || 1
