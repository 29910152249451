import { combineReducers } from 'redux'
import { apiConf as api } from 'commons'
import v4 from 'uuid/v4'
import { getCurrentSitesIds } from './currentSelection'

const isErrorStatus = (code) => code < 200 || code >= 300

// Actions
const SET_LAYOUT = 'esite/alerts/SET_LAYOUT'
const SET_SORT = 'esite/alerts/SET_SORT'
const SET_FILTER = 'esite/alerts/SET_FILTER'
const FETCH_MODELS_REQUEST = 'esite/alerts/FETCH_MODELS_REQUEST'
const FETCH_MODELS_SUCCESS = 'esite/alerts/FETCH_MODELS_SUCCESS'
const FETCH_MODELS_FAILURE = 'esite/alerts/FETCH_MODELS_FAILURE'
const FETCH_ACTIONS_REQUEST = 'esite/alerts/FETCH_ACTIONS_REQUEST'
const FETCH_ACTIONS_SUCCESS = 'esite/alerts/FETCH_ACTIONS_SUCCESS'
const FETCH_ACTIONS_FAILURE = 'esite/alerts/FETCH_ACTIONS_FAILURE'
const ADD_ACTION_REQUEST = 'esite/alerts/ADD_ACTION_REQUEST'
const ADD_ACTION_SUCCESS = 'esite/alerts/ADD_ACTION_SUCCESS'
const ADD_ACTION_FAILURE = 'esite/alerts/ADD_ACTION_FAILURE'
const DELETE_ACTION_REQUEST = 'esite/alerts/DELETE_ACTION_REQUEST'
const DELETE_ACTION_SUCCESS = 'esite/alerts/DELETE_ACTION_SUCCESS'
const DELETE_ACTION_FAILURE = 'esite/alerts/DELETE_ACTION_FAILURE'
const UPDATE_ACTION_REQUEST = 'esite/alerts/UPDATE_ACTION_REQUEST'
const UPDATE_ACTION_SUCCESS = 'esite/alerts/UPDATE_ACTION_SUCCESS'
const UPDATE_ACTION_FAILURE = 'esite/alerts/UPDATE_ACTION_FAILURE'

// Reducer
const displayReducer = (
  state = {
    layout: 'cards',
    sort: 1,
    filter: 0,
  },
  action,
) => {
  switch (action.type) {
    case SET_LAYOUT:
      return { ...state, layout: action.payload }
    case SET_SORT:
      return { ...state, sort: action.payload }
    case SET_FILTER:
      return { ...state, filter: action.payload }
    default:
      return state
  }
}

const modelsReducer = (
  state = {
    list: [],
    isFetching: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_MODELS_REQUEST:
      return { ...state, isFetching: true }
    case FETCH_MODELS_SUCCESS:
      return { ...state, isFetching: false, list: action.payload }
    case FETCH_MODELS_FAILURE:
      return { ...state, isFetching: false, list: [] }
    default:
      return state
  }
}

const actionsReducer = (
  state = {
    list: [],
    isFetching: false,
    isAdding: false,
    isDeleting: false,
    isUpdating: false,
  },
  action,
) => {
  switch (action.type) {
    case FETCH_ACTIONS_REQUEST:
      return { ...state, isFetching: true }
    case FETCH_ACTIONS_SUCCESS:
      return { ...state, isFetching: false, list: action.payload }
    case FETCH_ACTIONS_FAILURE:
      return { ...state, isFetching: false, list: [] }
    case ADD_ACTION_REQUEST:
      return { ...state, isAdding: true }
    case ADD_ACTION_SUCCESS:
      return { ...state, isAdding: false }
    case ADD_ACTION_FAILURE:
      return { ...state, isAdding: false }
    case DELETE_ACTION_REQUEST:
      return { ...state, isDeleting: true }
    case DELETE_ACTION_SUCCESS:
      return { ...state, isDeleting: false }
    case DELETE_ACTION_FAILURE:
      return { ...state, isDeleting: false }
    case UPDATE_ACTION_REQUEST:
      return { ...state, isUpdating: true }
    case UPDATE_ACTION_SUCCESS:
      return { ...state, isUpdating: false }
    case UPDATE_ACTION_FAILURE:
      return { ...state, isUpdating: false }
    default:
      return state
  }
}

export default combineReducers({
  display: displayReducer,
  models: modelsReducer,
  actions: actionsReducer,
})

// Selectors
export const getLayout = (state) => state.alerts.display.layout
export const getSort = (state) => state.alerts.display.sort
export const getFilter = (state) => state.alerts.display.filter

export const getModels = (state) => state.alerts.models.list
export const getModelsFetching = (state) => state.alerts.models.isFetching

export const getActions = (state) => state.alerts.actions.list
export const getFetching = (state) => state.alerts.actions.isFetching
export const getAdding = (state) => state.alerts.actions.isAdding
export const getDeleting = (state) => state.alerts.actions.isDeleting
export const getUpdating = (state) => state.alerts.actions.isUpdating

// Action Creators
export const setLayout = (payload) => ({ type: SET_LAYOUT, payload })
export const setSort = (payload) => ({ type: SET_SORT, payload })
export const setFilter = (payload) => ({ type: SET_FILTER, payload })

// Thunks
export const fetchModels = () => async (dispatch) => {
  const uuid = v4()
  dispatch({ type: FETCH_MODELS_REQUEST })
  try {
    const response = await api.superFetch({
      url: 'alertes/types?modele=2',
      method: 'GET',
      uuid,
    })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)
    if (response.headers.get('X-REQUEST-ID') !== uuid) return

    const models = await response.json()
    dispatch({ type: FETCH_MODELS_SUCCESS, payload: models })
  } catch (error) {
    console.error(`fetchModels error: ${error.message}`)
    dispatch({ type: FETCH_MODELS_FAILURE })
  }
}

export const fetchActions = (payload) => async (dispatch) => {
  const uuid = v4()
  dispatch({ type: FETCH_ACTIONS_REQUEST })
  try {
    const response = await api.superFetch({
      url: 'actions/search',
      method: 'POST',
      body: payload,
      uuid,
    })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)
    if (response.headers.get('X-REQUEST-ID') !== uuid) return

    const actions = await response.json()
    dispatch({ type: FETCH_ACTIONS_SUCCESS, payload: actions })
  } catch (error) {
    console.error('fetchActions error:', error.message)
    dispatch({ type: FETCH_ACTIONS_FAILURE })
  }
}

export const addAction = (payload) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: ADD_ACTION_REQUEST })
  try {
    const response = await api.superFetch({
      url: 'actions',
      method: 'POST',
      body: payload,
      uuid,
    })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)
    if (response.headers.get('X-REQUEST-ID') !== uuid) return
    dispatch({ type: ADD_ACTION_SUCCESS })

    const siteIds = getCurrentSitesIds(getState())
    dispatch(fetchActions(siteIds))
  } catch (error) {
    console.error('addAction error:', error.message)
    dispatch({ type: ADD_ACTION_FAILURE })
  }
}

export const deleteAction = (id) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: DELETE_ACTION_REQUEST })
  try {
    const response = await api.superFetch({
      url: `actions/${id}`,
      method: 'DELETE',
      uuid,
    })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)
    if (response.headers.get('X-REQUEST-ID') !== uuid) return
    dispatch({ type: DELETE_ACTION_SUCCESS })

    const siteIds = getCurrentSitesIds(getState())
    dispatch(fetchActions(siteIds))
  } catch (error) {
    console.error('deleteAction error:', error.message)
    dispatch({ type: DELETE_ACTION_FAILURE })
  }
}

export const updateAction = (payload) => async (dispatch, getState) => {
  const uuid = v4()
  dispatch({ type: UPDATE_ACTION_REQUEST })
  try {
    const response = await api.superFetch({
      url: `actions/${payload.Id}`,
      method: 'PUT',
      body: payload,
      uuid,
    })
    if (isErrorStatus(response.status)) throw new Error(`status ${response.status}`)
    if (response.headers.get('X-REQUEST-ID') !== uuid) return
    dispatch({ type: UPDATE_ACTION_SUCCESS })

    const siteIds = getCurrentSitesIds(getState())
    dispatch(fetchActions(siteIds))
  } catch (error) {
    console.error('updateAction error:', error.message)
    dispatch({ type: UPDATE_ACTION_FAILURE })
  }
}
