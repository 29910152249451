import React from 'react'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import { getEnergy } from 'store/dateRange'

import {
  fetchReferentielHrs,
  setLoadCurveGraphUnite,
  setLoadCurveGraphType,
  setLoadCurveGraphCompare,
  setLoadCurveGraphStep,
  setLoadCurveGraphHrs,
  setLoadCurveGraphBoost,
  getLoadCurveGraphIsFetching,
  getReferentielHrs,
  getLoadCurveGraphHrs,
  getLoadCurveGraphUniteId,
  getLoadCurveGraphType,
  getLoadCurveGraphCompare,
  getLoadCurveGraphStep,
  getLoadCurveGraphBoost,
} from 'store/consumption'

import ButtonGroup from 'components/common/ButtonGroup'
import Select from 'components/common/Select'
import styles from './LoadCurve.module.scss'

const LoadCurveOptions = ({
  isLoading,
  referentielHrs,
  type,
  unite,
  step,
  hrs,
  energy,
  compare,
  boost,
  setLoadCurveGraphUnite,
  setLoadCurveGraphType,
  setLoadCurveGraphCompare,
  setLoadCurveGraphStep,
  setLoadCurveGraphHrs,
  setLoadCurveGraphBoost,
  fetchReferentielHrs,
}) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    if (!referentielHrs.data && !referentielHrs.isFetching) {
      fetchReferentielHrs()
    }
  }, [fetchReferentielHrs, referentielHrs])

  const toggleCompare = React.useCallback(
    (s) => {
      setLoadCurveGraphCompare(
        compare.includes(s) ? compare.filter((x) => x !== s) : compare.concat(s),
      )
    },
    [compare, setLoadCurveGraphCompare],
  )

  const hrsOptions = !referentielHrs.data
    ? []
    : [
        {
          label: t('consumption.none'),
          value: 0,
        },
      ].concat(
        referentielHrs.data.map(({ Id, Libelle, Commentaire }) => ({
          label: Libelle,
          value: Id,
          comment: Commentaire,
        })),
      )

  const compareOptions = [
    { label: t('graph.s1'), value: 'MesureS1' },
    { label: t('graph.n1'), value: 'MesureN1' },
    { label: t('graph.normalise'), value: 'Normalise' },
    { label: t('graph.ps'), value: 'PS' },
    { label: t('graph.dps'), value: 'DepassementsPS' },
    { label: t('graph.talonPuissance'), value: 'Talon' },
  ]

  const uniteOptions = [
    { label: 'kW', value: '1' },
    { label: 'MW', value: '2' },
    { label: 'GW', value: '3' },
    { label: 'kWh', value: '11' },
    { label: 'MWh', value: '12' },
    { label: 'GWh', value: '13' },
  ]

  const stepOptions = [
    { label: t('graph.yearly'), value: '1' },
    { label: t('graph.quaterly'), value: '2' },
    { label: t('graph.monthly'), value: '3' },
    { label: t('graph.daily'), value: '4' },
    { label: t('graph.1h'), value: '5' },
    { label: '15 min', value: '7' },
    { label: '10 min', value: '8' },
  ]

  return (
    <>
      <ButtonGroup
        title={t('graph.displayData')}
        value={type}
        inputs={[
          { label: t('graph.normalise'), value: 'Normalise' },
          { label: t('graph.mesure'), value: 'Mesure' },
        ]}
        onChange={setLoadCurveGraphType}
        isDisabled={isLoading}
      />

      <div className={styles.container}>
        <div className={styles.title}>{t('graph.compare')}</div>
        {compareOptions.map((opt) => (
          <div className={styles.checkbox} key={opt.value}>
            <label className={compare.includes(opt.value) ? styles.checked : ''}>
              <Input type="checkbox" onChange={() => toggleCompare(opt.value)} />
              <span>{opt.label}</span>
            </label>
          </div>
        ))}
      </div>

      <div className={styles.container}>
        <div className={styles.title}>{t('graph.unit')}</div>
        <Select
          value={uniteOptions.find((option) => option.value === unite)}
          onChange={(option) => setLoadCurveGraphUnite(option.value)}
          options={uniteOptions}
          size="xs"
          width={200}
        />
      </div>

      <div className={styles.container}>
        <div className={styles.title}>{t('graph.step')}</div>
        <Select
          value={stepOptions.find((option) => option.value === step)}
          onChange={(option) => setLoadCurveGraphStep(option.value)}
          options={stepOptions}
          size="xs"
          width={200}
        />
      </div>

      {energy === '2' && (
        <div className={styles.container}>
          <div className={styles.title}>{t('graph.hrs')}</div>
          <Select
            value={hrsOptions.find((option) => option.value === hrs) || hrsOptions[0]}
            onChange={(option) => setLoadCurveGraphHrs(option.value)}
            options={hrsOptions}
            size="xs"
            width={200}
            isLoading={referentielHrs.isFetching}
            isDisabled={isLoading}
            placeholder={t('graph.placeholder')}
          />
        </div>
      )}

      {/* <div className={styles.container}>
        <div className={styles.title}>Options</div>
        <div className={styles.checkbox}>
          <label
            className={boost ? styles.checked : ''}
          >
            <Input
              type="checkbox"
              onChange={() => setLoadCurveGraphBoost(!boost)}
            />
            <span>Boost</span>
          </label>
        </div>
      </div> */}
    </>
  )
}

const mapState = (state) => ({
  isLoading: getLoadCurveGraphIsFetching(state),
  referentielHrs: getReferentielHrs(state),
  hrs: getLoadCurveGraphHrs(state),
  energy: getEnergy(state),
  unite: getLoadCurveGraphUniteId(state),
  type: getLoadCurveGraphType(state),
  compare: getLoadCurveGraphCompare(state),
  step: getLoadCurveGraphStep(state),
  boost: getLoadCurveGraphBoost(state),
})

const mapDispatch = {
  fetchReferentielHrs,
  setLoadCurveGraphUnite,
  setLoadCurveGraphType,
  setLoadCurveGraphCompare,
  setLoadCurveGraphStep,
  setLoadCurveGraphHrs,
  setLoadCurveGraphBoost,
}

export default connect(mapState, mapDispatch)(LoadCurveOptions)
