import React from 'react'
import { Navbar, Nav, NavItem, Button, Badge } from 'reactstrap'
import { withRouter, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { getSiteId } from '../../store/site'
import { isAdmin } from '../../store/admin'

const TopNav = ({ location, setUserPanelOpen, userPanelOpen, siteId, isAdmin }) => {
  const { t } = useTranslation()
  return (
    <Navbar color="secondary" expand className="topNav">
      <Nav navbar className="leftNav">
        {paths.map((i, index) => (
          <NavItem key={index}>
            <NavLink
              to={siteId === null ? `${i.dir}${i.link}` : `${i.dir}${i.link}/${siteId}`}
              activeClassName="active"
              className="nav-link"
              isActive={() =>
                (i.dir && location.pathname.match('^' + i.dir)) ||
                location.pathname.match('^' + i.link)
              }
            >
              <span>
                {i.icon && <i className={i.icon} />}
                {t(i.text)}
                {i.nb && <Badge>{i.nb}</Badge>}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Nav className="ml-auto rightNav" navbar>
        {isAdmin &&
          pathsAdmin.map((i, index) => (
            <NavItem key={index}>
              <NavLink
                to={i.dir + i.link}
                activeClassName="active"
                className="nav-link align-bottom"
                isActive={() =>
                  (i.dir && location.pathname.match('^' + i.dir)) ||
                  location.pathname.match('^' + i.link)
                }
              >
                <span>
                  <i className={i.icon} /> {t(i.text)} {i.nb && <Badge>{i.nb}</Badge>}
                </span>
              </NavLink>
            </NavItem>
          ))}
        <NavItem className="userNavItem">
          <Button color="link" title="" onClick={() => setUserPanelOpen(!userPanelOpen)}>
            <i className="icon-user" />
            <i className="icon-smallarrow-down" />
          </Button>
        </NavItem>
      </Nav>
    </Navbar>
  )
}

const mapState = (state) => ({
  siteId: getSiteId(state),
  isAdmin: isAdmin(state),
})

export default compose(withRouter, connect(mapState))(TopNav)

export const paths = [
  {
    text: 'topNav.site',
    icon: 'icon-pin-map',
    dir: '',
    link: '/site',
  },
  {
    text: 'topNav.globalConsumption',
    icon: 'icon-diag',
    dir: '',
    link: '/global-consumption',
  },
  {
    text: 'topNav.detailedConsumption',
    icon: 'icon-ccourbes',
    dir: '',
    link: '/detailed-consumption',
  },
  // {
  //   text: 'topNav.actionPlan',
  //   icon: 'icon-ico-alarm',
  //   dir: '',
  //   link: '/action-plan',
  // },
  {
    text: 'topNav.performance',
    icon: 'icon-ico-menu-dashboard',
    dir: '',
    link: '/performance',
  },
  {
    text: 'topNav.gestion',
    icon: 'icon-ic-settings',
    dir: '',
    link: '/gestion',
  },
]

export const pathsAdmin = [
  {
    text: 'topNav.administration',
    icon: 'icon-ico-menu-parameters',
    dir: '',
    link: '/admin',
  },
]
