import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import { compose, withProps, lifecycle, withHandlers, withState } from 'recompose'
import classnames from 'classnames'
import throttle from 'lodash/throttle'

import styles from './Performance.module.scss'
import DateRange from '../../common/DateRange'
import Progress from '../Progress'
import ProgressKpi from '../ProgressKpi'

import { getCurrentSitesIds, getFocus, unfocus } from 'store/currentSelection'

import {
  getLongStartDate,
  getLongEndDate,
  getEnergy,
  getAgregation,
} from '../../../store/dateRange'
import {
  fetchTopFlop,
  fetchTopFlopKpi,
  getTopFlopData,
  getTopFlopIsFetching,
  getTopFlopError,
  getTopFlopKpiData,
  getTopFlopKpiIsFetching,
  getTopFlopKpiError,
  getActiveTab,
  setActiveTab,
} from '../../../store/performance'
import { fetchPatrimonialsReferentiel, getPatrimonialsRefrentiel } from '../../../store/patrimonial'
import { fetchActivityReferentiel, getActivityRefrentiel } from '../../../store/activity'
import { getKpiReferentiel } from '../../../store/performance'
import withEmpty from 'components/common/Empty'

let isUserScroll = true

const scrollToRef = (ref) => {
  isUserScroll = false

  window.scrollTo({
    top:
      ref && ref.current
        ? ref.current.getBoundingClientRect().top + window.scrollY - 120 - 120
        : 76,
    behavior: 'smooth',
  })

  setTimeout(() => {
    isUserScroll = true
  }, 1000)
}

const TopFlopN1 = ({ topFlop, topFlopIsLoading, topFlopError }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col className="ml-3 mr-3">
        <Progress
          title={t('performance.consoVsN1')}
          data={topFlop && topFlop.find((x) => x.Type === 'N1')}
          isLoading={topFlopIsLoading}
          error={topFlopError}
        />
      </Col>
    </Row>
  )
}

const TopFlopBudget = ({ topFlop, topFlopIsLoading, topFlopError }) => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col className="ml-3 mr-3">
        <Progress
          title={t('performance.consoVsBudget')}
          data={topFlop && topFlop.find((x) => x.Type === 'Budget')}
          isLoading={topFlopIsLoading}
          error={topFlopError}
        />
      </Col>
    </Row>
  )
}

const KPIs = ({
  kpi,
  setKpi,
  kpiReferentiel,
  topFlopKpi,
  topFlopKpiIsLoading,
  topFlopKpiError,
}) => {
  const { t } = useTranslation()
  return (
    <Row>
      <Col className="ml-3 mr-3">
        <div className={styles.title}>
          <h3>{t('performance.performance')}</h3>
        </div>
        <Nav tabs className="simple-nav-tab">
          {kpiReferentiel.map((k) => (
            <NavItem key={k.Id}>
              <NavLink
                className={classnames({
                  active: kpi && kpi.Id === k.Id,
                })}
                onClick={() => {
                  setKpi(k)
                }}
              >
                <span>
                  {k.Nom} (MWh/{k.UniteNom})
                </span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <ProgressKpi
          name={kpi ? kpi.Nom : ''}
          data={topFlopKpi && topFlopKpi.find((x) => x.Type === 'Kpi')}
          isLoading={topFlopKpiIsLoading}
          error={topFlopKpiError}
          kpi={kpi}
        />
      </Col>
    </Row>
  )
}

const Performance = (props) => {
  const { t } = useTranslation()
  const { tabs, activeTab, setActiveTab, focus, unfocus } = props

  React.useEffect(() => {
    if (focus !== null) {
      unfocus()
    }
  }, [focus, unfocus])

  if (focus !== null) return null

  return (
    <>
      <Row className={styles.header}>
        <Col>
          <Row className={classnames('ml-2', 'mr-2', styles.periodSelector)}>
            <Col>
              <DateRange short={false} showAgregation={true} />
            </Col>
          </Row>
          <Row>
            <Col className="p-0">
              <Nav className="sub-nav-tab">
                {tabs.map(({ title, ref }, index) => (
                  <NavItem key={index}>
                    <NavLink
                      className={classnames({
                        active: activeTab === index,
                      })}
                      onClick={() => {
                        setActiveTab(index)
                        scrollToRef(ref)
                      }}
                    >
                      <span>{t(title)}</span>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
          </Row>
        </Col>
      </Row>
      {tabs.map(({ Component, ref }, index) => (
        <Fragment key={index}>
          <div ref={ref} />
          <Component {...props} />
        </Fragment>
      ))}
    </>
  )
}

const mapStateToProps = (state) => ({
  topFlop: getTopFlopData(state),
  topFlopIsLoading: getTopFlopIsFetching(state),
  topFlopError: getTopFlopError(state),
  topFlopKpi: getTopFlopKpiData(state),
  topFlopKpiIsLoading: getTopFlopKpiIsFetching(state),
  topFlopKpiError: getTopFlopKpiError(state),
  activeTab: getActiveTab(state),
  siteIds: getCurrentSitesIds(state),
  startDate: getLongStartDate(state),
  endDate: getLongEndDate(state),
  energy: getEnergy(state),
  agregation: getAgregation(state),
  kpiReferentiel: getKpiReferentiel(state),
  kpiPatrimonial: getPatrimonialsRefrentiel(state),
  kpiActivity: getActivityRefrentiel(state),
  focus: getFocus(state),
})

const mapDispatchToProps = {
  setActiveTab,
  fetchTopFlop,
  fetchTopFlopKpi,
  fetchPatrimonialsReferentiel,
  fetchActivityReferentiel,
  unfocus,
}

const createTopFlopPayload = (props) => ({
  ids: props.siteIds,
  typeIds: props.agregation === '0' ? 'PEE' : 'SIT',
  nrjs: [props.energy],
  debut: props.startDate,
  fin: props.endDate,
  agregation: props.agregation,
})

const createTopFlopKpiPayload = (props) => ({
  ids: props.siteIds,
  typeIds: props.agregation === '0' ? 'PEE' : 'SIT',
  nrjs: [props.energy],
  debut: props.startDate,
  fin: props.endDate,
  agregation: props.agregation,
  kpi: props.kpi && props.kpi.Id,
  typeKpi: props.kpi && props.kpi.Type,
})

export default compose(
  withEmpty,
  connect(mapStateToProps, mapDispatchToProps),
  withState('kpi', 'setKpi', (props) =>
    props.kpiReferentiel.length > 0 ? props.kpiReferentiel[0] : null,
  ),
  withProps(({ siteIds, kpiReferentiel }) => {
    const [first, ...rest] = [
      {
        title: 'performance.tabs.n1',
        Component: TopFlopN1,
        ref: React.createRef(),
      },
      {
        title: 'performance.tabs.budget',
        Component: TopFlopBudget,
        ref: React.createRef(),
      },
      ...(kpiReferentiel.length === 0
        ? []
        : [
            {
              title: 'performance.tabs.performance',
              Component: KPIs,
              ref: React.createRef(),
            },
          ]),
    ]

    return {
      tabs: siteIds && siteIds.length > 2 ? [first, ...rest] : [...rest],
    }
  }),
  withHandlers({
    onScroll: (props) =>
      throttle(() => {
        if (!isUserScroll) return false
        const index = props.tabs
          .map(({ ref }) =>
            ref && ref.current ? ref.current.getBoundingClientRect().top + 50 + window.scrollY : 76,
          )
          .findIndex((top) => top > window.scrollY)
        props.setActiveTab(index)
      }, 400),
    fetchData: (props) => () => {
      props.fetchTopFlop(createTopFlopPayload(props))
    },
    fetchDataKpi: (props) => () => {
      if (!props.kpi) return
      props.fetchTopFlopKpi(createTopFlopKpiPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      window.addEventListener('scroll', this.props.onScroll)
      this.props.fetchPatrimonialsReferentiel()
      this.props.fetchActivityReferentiel()
      this.props.fetchData()
      this.props.fetchDataKpi()
    },
    componentDidUpdate(prevProps) {
      if (prevProps.kpiReferentiel.length === 0 && this.props.kpiReferentiel.length > 0) {
        const kpi = this.props.kpiReferentiel[0]
        this.props.setKpi(kpi)
      }
      if (
        JSON.stringify(createTopFlopPayload(prevProps)) !==
        JSON.stringify(createTopFlopPayload(this.props))
      ) {
        this.props.fetchData()
      }

      if (
        JSON.stringify(createTopFlopKpiPayload(prevProps)) !==
        JSON.stringify(createTopFlopKpiPayload(this.props))
      ) {
        this.props.fetchDataKpi()
      }
    },
    componentWillUnmount() {
      window.removeEventListener('scroll', this.props.onScroll)
    },
  }),
)(Performance)

export { Performance }
