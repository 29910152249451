import { combineReducers } from 'redux'
import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchPatrimonialsReferentielAll: () =>
    superFetch({
      url: `referentiel/kpi/patrimonial`,
    }),

  fetchPatrimonialsReferentiel: () =>
    superFetch({
      url: 'referentiel/kpi/patrimonial/my',
    }),

  updatePatrimonialsReferentiel: (patrimonials) =>
    superFetch({
      url: 'referentiel/kpi/patrimonial/my',
      method: 'POST',
      body: patrimonials,
    }),

  fetchPatrimonials: (siteId) =>
    superFetch({
      url: `sites/` + siteId + `/patrimonials/filtered`,
    }),

  addPatrimonial: (siteId, patrimonial) =>
    superFetch({
      url: `sites/` + siteId + `/patrimonials`,
      method: 'POST',
      body: patrimonial,
    }),

  modifyPatrimonial: (siteId, patrimonialId, patrimonial) =>
    superFetch({
      url: `sites/` + siteId + `/patrimonials/` + patrimonialId,
      method: 'PUT',
      body: patrimonial,
    }),

  deletePatrimonial: (siteId, patrimonialId) =>
    superFetch({
      url: `sites/` + siteId + `/patrimonials/` + patrimonialId,
      method: 'DELETE',
    }),
}

// Actions
const FETCH_PATRIMONIALS_REQUEST = 'esite/patrimonial/FETCH_PATRIMONIALS_REQUEST'
const FETCH_PATRIMONIALS_SUCCESS = 'esite/patrimonial/FETCH_PATRIMONIALS_SUCCESS'
const FETCH_PATRIMONIALS_FAILURE = 'esite/patrimonial/FETCH_PATRIMONIALS_FAILURE'
const ADD_PATRIMONIAL_REQUEST = 'esite/patrimonial/ADD_PATRIMONIAL_REQUEST'
const ADD_PATRIMONIAL_SUCCESS = 'esite/patrimonial/ADD_PATRIMONIAL_SUCCESS'
const ADD_PATRIMONIAL_FAILURE = 'esite/patrimonial/ADD_PATRIMONIAL_FAILURE'
const DELETE_PATRIMONIAL_REQUEST = 'esite/patrimonial/DELETE_PATRIMONIAL_REQUEST'
const DELETE_PATRIMONIAL_SUCCESS = 'esite/patrimonial/DELETE_PATRIMONIAL_SUCCESS'
const DELETE_PATRIMONIAL_FAILURE = 'esite/patrimonial/DELETE_PATRIMONIAL_FAILURE'
const MODIFY_PATRIMONIAL_REQUEST = 'esite/patrimonial/MODIFY_PATRIMONIAL_REQUEST'
const MODIFY_PATRIMONIAL_SUCCESS = 'esite/patrimonial/MODIFY_PATRIMONIAL_SUCCESS'
const MODIFY_PATRIMONIAL_FAILURE = 'esite/patrimonial/MODIFY_PATRIMONIAL_FAILURE'
const FETCH_PATRIMONIALS_REFERENTIEL_REQUEST =
  'esite/patrimonial/FETCH_PATRIMONIALS_REFERENTIEL_REQUEST'
const FETCH_PATRIMONIALS_REFERENTIEL_SUCCESS =
  'esite/patrimonial/FETCH_PATRIMONIALS_REFERENTIEL_SUCCESS'
const FETCH_PATRIMONIALS_REFERENTIEL_FAILURE =
  'esite/patrimonial/FETCH_PATRIMONIALS_REFERENTIEL_FAILURE'
const UPDATE_PATRIMONIALS_REFERENTIEL_REQUEST =
  'esite/patrimonial/UPDATE_PATRIMONIALS_REFERENTIEL_REQUEST'
const UPDATE_PATRIMONIALS_REFERENTIEL_SUCCESS =
  'esite/patrimonial/UPDATE_PATRIMONIALS_REFERENTIEL_SUCCESS'
const UPDATE_PATRIMONIALS_REFERENTIEL_FAILURE =
  'esite/patrimonial/UPDATE_PATRIMONIALS_REFERENTIEL_FAILURE'
const FETCH_PATRIMONIALS_REFERENTIEL_ALL_REQUEST =
  'esite/patrimonial/FETCH_PATRIMONIALS_REFERENTIEL_ALL_REQUEST'
const FETCH_PATRIMONIALS_REFERENTIEL_ALL_SUCCESS =
  'esite/patrimonial/FETCH_PATRIMONIALS_REFERENTIEL_ALL_SUCCESS'
const FETCH_PATRIMONIALS_REFERENTIEL_ALL_FAILURE =
  'esite/patrimonial/FETCH_PATRIMONIALS_REFERENTIEL_ALL_FAILURE'

// Reducer
const patrimonials = (
  state = { items: [], fetching: false, updating: false, error: '' },
  action,
) => {
  switch (action.type) {
    case FETCH_PATRIMONIALS_REQUEST:
      return {
        ...state,
        fetching: true,
      }
    case FETCH_PATRIMONIALS_FAILURE:
      return {
        ...state,
        fetching: false,
        items: [],
        error: action.error,
      }
    case FETCH_PATRIMONIALS_SUCCESS:
      return {
        ...state,
        fetching: false,
        items: action.response,
        error: '',
      }
    case MODIFY_PATRIMONIAL_REQUEST:
      return {
        ...state,
        updating: true,
      }
    case MODIFY_PATRIMONIAL_SUCCESS:
      return {
        ...state,
        updating: false,
      }
    case MODIFY_PATRIMONIAL_FAILURE:
      return {
        ...state,
        updating: false,
      }
    default:
      return state
  }
}

const patrimonialReferentiel = (state = [], action) => {
  switch (action.type) {
    case FETCH_PATRIMONIALS_REFERENTIEL_SUCCESS:
      return action.response
    default:
      return state
  }
}

const referentielUpdateStatus = (
  state = {
    isUpdating: false,
    isSuccess: false,
    isError: false,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_PATRIMONIALS_REFERENTIEL_REQUEST:
      return {
        isUpdating: true,
        isSuccess: false,
        isError: false,
      }
    case UPDATE_PATRIMONIALS_REFERENTIEL_SUCCESS:
      return {
        isUpdating: false,
        isSuccess: true,
        isError: false,
      }
    case UPDATE_PATRIMONIALS_REFERENTIEL_FAILURE:
      return {
        isUpdating: false,
        isSuccess: false,
        isError: true,
      }
    default:
      return state
  }
}

const patrimonialReferentielAll = (state = [], action) => {
  switch (action.type) {
    case FETCH_PATRIMONIALS_REFERENTIEL_ALL_SUCCESS:
      return action.response
    default:
      return state
  }
}

export default combineReducers({
  patrimonials,
  patrimonialReferentiel,
  patrimonialReferentielAll,
  referentielUpdateStatus,
})

// Selectors
export const getPatrimonials = (state) => state.patrimonial.patrimonials
export const getPatrimonialsRefrentiel = (state) => state.patrimonial.patrimonialReferentiel
export const getPatrimonialsRefrentielAll = (state) => state.patrimonial.patrimonialReferentielAll
export const getReferentielUpdateStatus = (state) => state.patrimonial.referentielUpdateStatus

// Thunks
export const fetchPatrimonialsReferentiel = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_PATRIMONIALS_REFERENTIEL_REQUEST,
    uuid,
  })

  return api.fetchPatrimonialsReferentiel().then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_PATRIMONIALS_REFERENTIEL_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_PATRIMONIALS_REFERENTIEL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_PATRIMONIALS_REFERENTIEL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const updatePatrimonialsReferentiel = (patrimonials) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: UPDATE_PATRIMONIALS_REFERENTIEL_REQUEST,
    uuid,
  })

  return api.updatePatrimonialsReferentiel(patrimonials).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: UPDATE_PATRIMONIALS_REFERENTIEL_SUCCESS,
          })
          dispatch({
            type: FETCH_PATRIMONIALS_REFERENTIEL_SUCCESS,
            response,
          })
        })
      } else {
        dispatch({
          type: UPDATE_PATRIMONIALS_REFERENTIEL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: UPDATE_PATRIMONIALS_REFERENTIEL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const fetchPatrimonialsReferentielAll = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_PATRIMONIALS_REFERENTIEL_ALL_REQUEST,
    uuid,
  })

  return api.fetchPatrimonialsReferentielAll().then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_PATRIMONIALS_REFERENTIEL_ALL_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_PATRIMONIALS_REFERENTIEL_ALL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_PATRIMONIALS_REFERENTIEL_ALL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const fetchPatrimonials = (siteId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_PATRIMONIALS_REQUEST,
    uuid,
  })

  return api.fetchPatrimonials(siteId).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_PATRIMONIALS_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_PATRIMONIALS_FAILURE,
          error: response.status,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_PATRIMONIALS_FAILURE,
        error: error.message || 'Something went wrong.',
      })
    },
  )
}

export const addPatrimonial = (siteId, type, indicator, date) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: ADD_PATRIMONIAL_REQUEST,
    uuid,
  })

  const patrimonial = {}
  patrimonial.Propriete = indicator
  patrimonial.Valeur = indicator
  patrimonial.TypeIndicateur = type
  patrimonial.Date = date

  return api.addPatrimonial(siteId, patrimonial).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: ADD_PATRIMONIAL_SUCCESS,
            response: response,
          })
          //refresh tags
          dispatch(fetchPatrimonials(siteId))
        })
      } else {
        dispatch({
          type: ADD_PATRIMONIAL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: ADD_PATRIMONIAL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const modifyPatrimonial = (siteId, id, type, indicator, date) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: MODIFY_PATRIMONIAL_REQUEST,
    uuid,
  })

  const patrimonial = {
    Valeur: type,
    TypeIndicateur: indicator,
    Date: date,
  }

  return api.modifyPatrimonial(siteId, id, patrimonial).then(
    (response) => {
      if (response.status === 200) {
        dispatch({
          type: MODIFY_PATRIMONIAL_SUCCESS,
          response: response,
        })
        dispatch(fetchPatrimonials(siteId))
      } else {
        dispatch({
          type: MODIFY_PATRIMONIAL_FAILURE,
          error: response.status,
        })
      }
    },
    (error) => {
      dispatch({
        type: MODIFY_PATRIMONIAL_FAILURE,
        error: error.message || 'Something went wrong.',
      })
    },
  )
}

export const deletePatrimonial = (siteId, tagId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: DELETE_PATRIMONIAL_REQUEST,
    uuid,
  })

  return api.deletePatrimonial(siteId, tagId).then(
    (response) => {
      if (response.status === 200) {
        dispatch({
          type: DELETE_PATRIMONIAL_SUCCESS,
          response: response,
        })
        //refresh tags
        dispatch(fetchPatrimonials(siteId))
      } else {
        dispatch({
          type: DELETE_PATRIMONIAL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: DELETE_PATRIMONIAL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}
