import { getColor } from 'helpers/colors'
import { noDecimalFormat } from 'helpers/formatters'
import moment from 'moment'

const getPeriod = (series) => {
  const { data } = series
  return moment(data[data.length - 1].x).diff(moment(data[0].x), 'months')
}

export default (graph, unite, uor) => {
  if (!graph || !graph.graphMensuel || graph.graphMensuel.length === 0)
    return { title: { text: null } }

  const data = graph.graphMensuel

  const series = data.reduce((acc, { id, nomEntite, graphMensuel }) => {
    const series = graphMensuel.series
      .filter(({ data }) => data.length > 0)
      .map(({ name, data }, index) => ({
        id: `${id}_${index}`,
        name: `${nomEntite} - ${name}`,
        data,
      }))
    return acc.concat(series).map((serie, index) => ({
      ...serie,
      color: getColor(index, 0.4, { uor }),
      borderColor: getColor(index, 1, { uor }),
      dataLabels: {
        color: getColor(index, 1, { uor }),
      },
    }))
  }, [])

  const all = {
    animation: false,
    title: {
      text: null,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    chart: {
      type: 'column',
    },
    tooltip: {
      xDateFormat: '%d/%m/%Y %H:%M',
      pointFormat: `{point.series.name} : <b>{point.y:.2f} ${unite}<b/><br/>`,
    },
    plotOptions: {
      series: {
        animation: false,
        dataLabels: {
          animation: false,
          enabled: series.length === 1 && getPeriod(series[0]) < 13,
          formatter: function () {
            return this.y < 100_000 ? noDecimalFormat(this.y) : ''
          },
        },
      },
      column: {
        pointPadding: 0,
        groupPadding: 0.05,
        borderWidth: 1,
        shadow: false,
      },
    },
    series,
  }

  const total = {
    ...all,
    xAxis: {
      categories: ['Total'],
    },
    series: all.series.map((serie) => ({
      ...serie,
      data: [serie.data.reduce((acc, { y }) => acc + y, 0)],
    })),
  }

  return { all, total }
}
