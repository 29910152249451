import { combineReducers } from 'redux'
import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchPees: (siteId) =>
    superFetch({
      url: `sites/` + siteId + `/pees`,
    }),
  fetchPee: (peeId) =>
    superFetch({
      url: `pees/` + peeId,
    }),
  updatePeeField: (peeId, field, action, value) => {
    const patch = [{ op: action, path: field }]
    if (action !== 'remove') {
      patch[0].value = value.format?.('YYYY-MM-DD') ?? value
    }
    return superFetch({
      url: `pees/${peeId}`,
      method: 'PATCH',
      body: patch,
    })
  },
}

// Actions
const FETCH_PEES_REQUEST = 'esite/pee/FETCH_PEES_REQUEST'
const FETCH_PEES_SUCCESS = 'esite/pee/FETCH_PEES_SUCCESS'
const FETCH_PEES_FAILURE = 'esite/pee/FETCH_PEES_FAILURE'
const FETCH_PEE_REQUEST = 'esite/pee/FETCH_PEE_REQUEST'
const FETCH_PEE_SUCCESS = 'esite/pee/FETCH_PEE_SUCCESS'
const FETCH_PEE_FAILURE = 'esite/pee/FETCH_PEE_FAILURE'
const UPDATE_PEE_REQUEST = 'esite/site/UPDATE_PEE_REQUEST'
const UPDATE_PEE_SUCCESS = 'esite/site/UPDATE_PEE_SUCCESS'
const UPDATE_PEE_FAILURE = 'esite/site/UPDATE_PEE_FAILURE'

// Reducer
const bySite = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PEES_REQUEST:
      return {
        ...state,
        [action.siteId]: {
          list: [],
          isLoading: true,
          error: false,
        },
      }
    case FETCH_PEES_SUCCESS:
      return {
        ...state,
        [action.siteId]: {
          list: action.response,
          isLoading: false,
          error: false,
        },
      }
    case FETCH_PEES_FAILURE:
      return {
        ...state,
        [action.siteId]: {
          list: [],
          isLoading: false,
          error: true,
        },
      }
    default:
      return state
  }
}

const detail = (state = null, action) => {
  switch (action.type) {
    case FETCH_PEE_SUCCESS:
      return action.response
    default:
      return state
  }
}

export default combineReducers({
  bySite,
  detail,
})

// Selectors
export const getListPee = (state, id) => {
  return state.pee.bySite[id] ? state.pee.bySite[id].list : []
}

export const getListPeeIsLoading = (state, id) =>
  state.pee.bySite[id] ? state.pee.bySite[id].isLoading : false
export const getListPeeError = (state, id) =>
  state.pee.bySite[id] ? state.pee.bySite[id].error : false
export const getPeeDetail = (state) => state.pee.detail || {}

// Thunks
export const fetchPees = (siteId) => (dispatch, getState) => {
  if (getState().pee.bySite[siteId]) return

  const uuid = v4()

  dispatch({
    type: FETCH_PEES_REQUEST,
    siteId,
    uuid,
  })

  return api.fetchPees(siteId).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_PEES_SUCCESS,
            siteId,
            response: response,
            firstPeeId: response[0] ? response[0].id : null,
          })
        })
      } else {
        dispatch({
          siteId,
          type: FETCH_PEES_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        siteId,
        type: FETCH_PEES_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const fetchPee = (siteId) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_PEE_REQUEST,
    uuid,
  })

  return api.fetchPee(siteId).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_PEE_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_PEE_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_PEE_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const updatePeeField = (peeId, field, action, value) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: UPDATE_PEE_REQUEST,
    uuid,
  })

  return api.updatePeeField(peeId, field, action, value).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: UPDATE_PEE_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: UPDATE_PEE_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: UPDATE_PEE_FAILURE,
        message: error.message || 'Erreur lors de la mise à jour du compteur',
      })
    },
  )
}
