import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import flatten from 'lodash/flatten'
import { components } from 'react-select'

import { getCurrentSitesByUor, getFilteredPeesBySite } from 'store/currentSelection'
import { getLoadCurveGraphIsFetching, setLoadCurveGraphAgregation } from 'store/consumption'

import Select from 'components/common/Select'
import styles from './LoadCurve.module.scss'

const GroupHeading = (props) => (
  <components.GroupHeading {...props}>
    <span>{props.children}</span>
  </components.GroupHeading>
)

const PeeOption = (props) => (
  <components.Option {...props}>
    {props.data.nrj && (
      <span
        className={
          props.data.nrj === 2
            ? styles.optionIconElec
            : props.data.nrj === 1
            ? styles.optionIconGaz
            : styles.optionIconWater
        }
      >
        <i
          className={
            props.data.nrj === 2 ? 'icon-elec' : props.data.nrj === 1 ? 'icon-gaz' : 'icon-tint'
          }
        />
      </span>
    )}
    {props.label}
  </components.Option>
)

const LoadCurveEntitites = ({
  agregation,
  isLoading,
  sitesByUor,
  peesBySite,
  entities,
  setEntities,
  label,
}) => {
  const { t } = useTranslation()

  const entitiesOptions = useMemo(
    () =>
      agregation === '1'
        ? sitesByUor.map(({ name, sites }) => ({
            label: name,
            options: sites.map(({ id, SphNom }) => ({
              value: id,
              label: SphNom,
            })),
          }))
        : peesBySite.map(({ name, pees }) => ({
            label: name,
            options: pees.map(({ id, PeeClef, PeeNrjId }) => ({
              value: id,
              label: PeeClef,
              nrj: PeeNrjId,
            })),
          })),
    [agregation, peesBySite, sitesByUor],
  )

  const flatEntitiesOptions = useMemo(
    () => flatten(entitiesOptions.map((o) => o.options)),
    [entitiesOptions],
  )

  return (
    <>
      {['0', '2'].includes(agregation) && (
        <>
          <div className={styles.container}>
            <div className={styles.title}>{label}</div>
            <Select
              placeholder={t('graph.cptPlaceholder')}
              value={
                entities === null
                  ? []
                  : flatEntitiesOptions.filter((option) => entities.includes(option.value))
              }
              onChange={(options) => {
                setEntities(options === null ? [] : options.map((o) => o.value))
              }}
              options={entitiesOptions}
              components={{ GroupHeading, PeeOption }}
              size="multi"
              width={210}
              isDisabled={isLoading}
              isClearable={false}
              isSearchable={false}
              isMulti={true}
              selectedLabel={t('graph.multiSelectPlaceholder')}
            />
          </div>
        </>
      )}

      {agregation === '1' && (
        <>
          <div className={styles.container}>
            <div className={styles.title}>{t('graph.compareToSites')}</div>
            <Select
              placeholder={t('graph.sitePlaceholder')}
              value={
                entities === null
                  ? []
                  : flatEntitiesOptions.filter((option) => entities.includes(option.value))
              }
              onChange={(options) => {
                setEntities(options === null ? [] : options.map((o) => o.value))
              }}
              options={entitiesOptions}
              components={{ GroupHeading }}
              size="multi"
              width={210}
              isDisabled={isLoading}
              isClearable={false}
              isSearchable={false}
              isMulti={true}
              selectedLabel={t('graph.multiSelectPlaceholder')}
            />
          </div>
        </>
      )}
    </>
  )
}

const mapState = (state) => ({
  sitesByUor: getCurrentSitesByUor(state),
  peesBySite: getFilteredPeesBySite(state),
  isLoading: getLoadCurveGraphIsFetching(state),
})

const mapDispatch = {
  setLoadCurveGraphAgregation,
}

export default connect(mapState, mapDispatch)(LoadCurveEntitites)
