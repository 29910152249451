import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { useTranslation } from 'react-i18next'

import { getCurrentSitesIds, getCurrentPeesIds } from 'store/currentSelection'
import {
  getVue,
  getSite,
  getPee,
  getShortStartDate,
  getShortEndDate,
  getLongStartDate,
  getLongEndDate,
  getEnergy,
  getCurveType,
} from 'store/dateRange'

import {
  getLoadCurveGraphUniteId,
  getLoadCurveGraphType,
  getLoadCurveGraphCompare,
  getLoadCurveGraphStep,
  fetchLoadCurveGraph,
  getLoadCurveGraphHrs,
} from 'store/consumption'

import styles from './LoadCurve.module.scss'
import LoadCurveEntitites from './LoadCurveEntities'
import LoadCurveOptions from './LoadCurveOptions'
import LoadCurveLegend from './LoadCurveHrsLegend'
import LoadCurveGraph from './LoadCurveGraph'

const LoadCurveInner = ({ entities, setEntities, title, agregation }) => {
  const { t } = useTranslation()
  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{title}</h3>
        </Col>
      </Row>
      {agregation !== '2' && (
        <Row>
          <Col className={styles.header}>
            <LoadCurveEntitites
              entities={entities}
              setEntities={setEntities}
              label={agregation === '1' ? t('graph.compareToSites') : t('graph.compareToCpts')}
              agregation={agregation}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs="10">
          <Row>
            <Col>
              <LoadCurveGraph />
              <LoadCurveLegend />
            </Col>
          </Row>
        </Col>
        <Col xs="2">
          <LoadCurveOptions />
        </Col>
      </Row>
    </Container>
  )
}

const createPayload = (props) => ({
  debut: props.startDate,
  fin: props.endDate,
  ids: props.agregation === '2' ? props.siteIds : [props.entity1, ...props.entities],
  typeIds: ['1', '2'].includes(props.agregation) ? 'SIT' : 'PEE',
  nrjs: [props.energy],
  typesDonnees: [props.type, ...props.compare],
  unite: props.unite,
  agregation: props.agregation,
  pas: props.step,
  hrs: props.hrs,
  flux: props.curveType,
})

const LoadCurve = compose(
  connect(
    (state) => {
      const agregation = getVue(state)
      return {
        siteIds: getCurrentSitesIds(state),
        peeIds: getCurrentPeesIds(state),
        startDate: getShortStartDate(state),
        endDate: getShortEndDate(state),
        energy: getEnergy(state),
        unite: getLoadCurveGraphUniteId(state),
        type: getLoadCurveGraphType(state),
        compare: getLoadCurveGraphCompare(state),
        step: getLoadCurveGraphStep(state),
        hrs: getLoadCurveGraphHrs(state),
        entity1: agregation === '0' ? getPee(state) : getSite(state),
        agregation,
        curveType: agregation === '0' ? undefined : getCurveType(state),
      }
    },
    {
      fetchLoadCurveGraph,
    },
  ),
  withState('entities', 'setEntities', []),
  withHandlers({
    toggleCompare:
      ({ compare, setLoadCurveGraphCompare }) =>
      (s) =>
        setLoadCurveGraphCompare(
          compare.includes(s) ? compare.filter((x) => x !== s) : compare.concat(s),
        ),
    fetchGraph: (props) => () => {
      props.fetchLoadCurveGraph(createPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (
        ['0', '2'].includes(this.props.agregation) &&
        prevProps.peeIds.join(',') !== this.props.peeIds.join(',')
      ) {
        this.props.setEntities([])
      }

      if (
        this.props.agregation === '1' &&
        prevProps.siteIds.join(',') !== this.props.siteIds.join(',')
      ) {
        this.props.setEntities([])
      }

      if (prevProps.agregation !== this.props.agregation) {
        this.props.setEntities([])
      }

      if (JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props))) {
        this.props.fetchGraph()
      }
    },
  }),
)(LoadCurveInner)



const AggregatedLoadCurve = compose(
  connect(
    (state) => {
      return {
        siteIds: getCurrentSitesIds(state),
        peeIds: getCurrentPeesIds(state),
        startDate: getLongStartDate(state),
        endDate: getLongEndDate(state),
        energy: getEnergy(state),
        unite: getLoadCurveGraphUniteId(state),
        type: getLoadCurveGraphType(state),
        compare: getLoadCurveGraphCompare(state),
        step: getLoadCurveGraphStep(state),
        hrs: getLoadCurveGraphHrs(state),
        agregation: '2',
        curveType: getCurveType(state),
      }
    },
    {
      fetchLoadCurveGraph,
    },
  ),
  withState('entities', 'setEntities', []),
  withHandlers({
    toggleCompare:
      ({ compare, setLoadCurveGraphCompare }) =>
      (s) =>
        setLoadCurveGraphCompare(
          compare.includes(s) ? compare.filter((x) => x !== s) : compare.concat(s),
        ),
    fetchGraph: (props) => () => {
      props.fetchLoadCurveGraph(createPayload(props))
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchGraph()
    },
    componentDidUpdate(prevProps) {
      if (prevProps.peeIds.join(',') !== this.props.peeIds.join(',')) {
        this.props.setEntities([])
      }

      if (JSON.stringify(createPayload(prevProps)) !== JSON.stringify(createPayload(this.props))) {
        this.props.fetchGraph()
      }
    },
  }),
)(LoadCurveInner)

export { LoadCurve, AggregatedLoadCurve }
