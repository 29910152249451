import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'

import { request } from 'helpers/request'
import { getPeeId, getStartDate, getEndDate } from 'store/gestion'

export const useDepenses = (id) => {
  const peeId = useSelector(getPeeId)
  const debut = useSelector(getStartDate)
  const fin = useSelector(getEndDate)

  const { data } = useQuery({
    queryKey: ['depenses', { peeId, debut, fin }],
    queryFn: () =>
      request({
        url: `pees/${peeId}/feuillet/depenses`,
        method: 'POST',
        body: { debut, fin },
      }),
  })

  return data
}

export const usePuissancesTotal = (id) => {
  const peeId = useSelector(getPeeId)
  const debut = useSelector(getStartDate)
  const fin = useSelector(getEndDate)

  const { data } = useQuery({
    queryKey: ['puissances-total', { peeId, debut, fin }],
    queryFn: () =>
      request({
        url: `pees/${peeId}/feuillet/puissances/total`,
        method: 'POST',
        body: { debut, fin },
      }),
  })

  return data
}

export const usePuissancesMensuel = (id) => {
  const peeId = useSelector(getPeeId)
  const debut = useSelector(getStartDate)
  const fin = useSelector(getEndDate)

  const { data } = useQuery({
    queryKey: ['puissances-mensuel', { peeId, debut, fin }],
    queryFn: () =>
      request({
        url: `pees/${peeId}/feuillet/puissances/mensuel`,
        method: 'POST',
        body: { debut, fin },
      }),
  })

  return data
}
