import React, { ReactChild } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import { EnumReference, useDecretTertiaireQuery } from 'hooks/decretTertiaire'

type DecretTertiaireReadonlyProps = {
  siteId: number
}

export const DecretTertiaireReadonly = ({ siteId }: DecretTertiaireReadonlyProps) => {
  const { t } = useTranslation()
  const { data, isFetching } = useDecretTertiaireQuery(siteId)
  const isAbsolute = data?.Reference === EnumReference.Absolue

  return (
    <div className={classnames('mt-4 d-flex flex-column space-y-6', { isFetching })}>
      <Item label={t('site.decretTertiaire.anneeRef')} value={data?.AnneeRef ?? '\u2013'} />
      <Item label={t('site.decretTertiaire.reference')} value={data?.Reference ?? '\u2013'} />
      {isAbsolute && <Item label="Use Modulé" value={`${data?.UseModule ?? '\u2013'} kWh/m2/an`} />}
    </div>
  )
}

type ItemProps = {
  label: ReactChild
  value: ReactChild
}

const Item = ({ label, value }: ItemProps) => {
  return (
    <span className="d-flex space-x-4 align-items-center">
      <span className="font-weight-bold">{label}</span>
      <span>{value}</span>
    </span>
  )
}
