import ReactGA from 'react-ga'

const ID = 'UA-112610371-4'

ReactGA.initialize(ID, {
  // debug: true,
})

const pagesToSkip = ['/', '/callback']

const sendPageToAnalytics = (location) => {
  const page = location.pathname
  if (pagesToSkip.includes(page)) return
  ReactGA.pageview(page)
}

export default (history) => {
  sendPageToAnalytics(history.location)
  history.listen(sendPageToAnalytics)
}
