import sortBy from 'lodash/sortBy'
import moment from 'moment'

import { getColor } from 'helpers/colors'
import downloadFile from 'helpers/downloadFile'
import { formatCSV } from 'helpers/csv'
const upperCaseFirst = (s) => s.charAt(0).toUpperCase() + s.slice(1)

const createOptions = (graph, uor) => {
  if (!graph || graph.length === 0) return { title: { text: null } }

  const { serie } = graph[0]

  const data = sortBy(serie, ['x']).reduce((acc, { x, y, name }) => {
    if (!!x && !!y) {
      const date = moment(name, 'DD/MM/YYYY')
      const dayName = date.isValid() ? upperCaseFirst(date.format('dddd')) : null
      acc.push({ x, y, name, dayName })
    }
    return acc
  }, [])

  const daySeries = moment
    .weekdays()
    .map(upperCaseFirst)
    .map((dayName, index) => ({
      name: dayName,
      data: data.filter((item) => item.dayName === dayName),
      color: getColor(index, 1, { uor }),
      marker: {
        fillColor: getColor(index, 0.3, { uor }),
        lineColor: getColor(index, 1, { uor }),
      },
    }))

  return {
    chart: {
      type: 'scatter',
    },
    exporting: {
      enabled: true,
      sourceWidth: 800,
      sourceHeight: 400,
      buttons: {
        contextButton: {
          menuItems: [
            {
              textKey: 'downloadJPEG',
              onclick: function () {
                this.exportChart({ type: 'image/jpeg' })
              },
            },
            {
              textKey: 'downloadPDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' })
              },
            },
            {
              textKey: 'downloadCSV',
              onclick: function () {
                downloadFile('heatmap.csv', createCSV(data), {
                  type: 'text/csv',
                })
              },
            },
          ],
        },
      },
    },

    title: {
      text: null,
    },

    yAxis: {
      title: {
        text: 'Consommation journalière (MWh)',
      },
    },

    xAxis: {
      gridLineWidth: 1,
      title: {
        text: 'Température (°C)',
      },
    },

    tooltip: {
      headerFormat: `<b>{series.name}<b><br/>`,
      pointFormat: `{point.name}<br/>Température : <b>{point.x:.2f}</b> °C<br/>Consommation : <b>{point.y:.2f}</b> MWh`,
    },

    plotOptions: {
      scatter: {
        connectNulls: false,
        marker: {
          enabled: true,
          radius: 4,
          lineWidth: 1,
          symbol: 'circle',
        },
      },
    },

    series: daySeries,
  }
}

const createCSV = (data) => {
  const header = ['Date', 'Jour', 'Température (°C)', 'Consommation journalière (MWh)']
  const body = data.map(({ x, y, name, dayName }) => [name, dayName, x, y])
  return formatCSV([header, ...body])
}

export default createOptions
