import { superFetch } from 'commons'

const downloadFile = (filename, content, { type = 'text/plain' } = {}) => {
  // Excel hack - See https://stackoverflow.com/a/17879474
  const BOM = '\ufeff'
  const parts = type === 'text/csv' ? [BOM, content] : [content]
  const blob = new Blob(parts, { type })

  // IE11
  const isIE11 = navigator && navigator.msSaveOrOpenBlob
  if (isIE11) return navigator.msSaveOrOpenBlob(blob, filename)

  // Non-IE11
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = filename
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export const requestFile = async (options, defaultFilename) => {
  const resp = await superFetch(options)

  if (resp.status !== 200) throw new Error(resp.statusText)
  const contentType = resp.headers.get('Content-Type')
  const filename =
    resp.headers.get('Content-Disposition')?.match(/filename=([^;]*);/)?.[1] ?? defaultFilename

  const data = await resp.blob()

  downloadFile(filename, data, contentType)
}

export default downloadFile
