import * as React from 'react'
import { connect } from 'react-redux'
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap'

import Activity from './Activity'

import { getActivityRefrentiel, fetchActivityReferentiel } from 'store/activity'
import { OpeningCalendar } from 'components/site/OpeningCalendar'

const Activities = ({ activities, fetchData, isAdmin }) => {
  const [activeTab, setActiveTab] = React.useState(0)

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  if (activities.length === 0) return null

  const { Id: typeId, Unite: unitId, UniteNom: unitName } = activities[activeTab]

  return (
    <>
      <Nav tabs className="simple-nav-tab flex-wrap-reverse">
        {activities.map(({ Id, Nom }, index) => (
          <NavItem key={Id}>
            <NavLink
              className={activeTab === index ? 'active' : ''}
              onClick={() => setActiveTab(index)}
            >
              {Nom}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <TabContent activeTab={activeTab}>
        {typeId === 44 ? (
          <OpeningCalendar />
        ) : (
          <Activity typeId={typeId} unitId={unitId} unitName={unitName} isAdmin={isAdmin} />
        )}
      </TabContent>
    </>
  )
}

const mapState = (state) => ({
  activities: getActivityRefrentiel(state),
})

const mapDispatch = {
  fetchData: fetchActivityReferentiel,
}

export default connect(mapState, mapDispatch)(Activities)
