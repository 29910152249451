import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withState, lifecycle, withHandlers } from 'recompose'
import { Button, FormGroup, CustomInput, Spinner } from 'reactstrap'

import styles from './Admin.module.scss'

import {
  getPatrimonialsRefrentiel,
  getPatrimonialsRefrentielAll,
  fetchPatrimonialsReferentiel,
  fetchPatrimonialsReferentielAll,
  updatePatrimonialsReferentiel,
  getReferentielUpdateStatus,
} from 'store/patrimonial'

import {
  getActivityRefrentiel,
  getActivityRefrentielAll,
  fetchActivityReferentiel,
  fetchActivityReferentielAll,
  updateActivityReferentiel,
} from 'store/activity'

const AdminReferentielsContent = ({
  patrimonialsAll,
  activityAll,
  checkedPatrimonials,
  checkedActivity,
  setPatrimonial,
  setActivity,
  isValidDisabled,
  postChanges,
  referentielUpdateStatus,
}) => {
  const { t } = useTranslation()

  if (patrimonialsAll.length === 0 && activityAll.length === 0) return null

  const { isUpdating } = referentielUpdateStatus

  return (
    <div className="section">
      <FormGroup>
        <h3>{t('admin.titlePatrimonial')}</h3>
        <div>
          {patrimonialsAll.map(({ Id, Nom }) => (
            <CustomInput
              key={Id}
              type="switch"
              id={`patrimonial-${Id}`}
              className={styles.switch}
              label={Nom}
              checked={checkedPatrimonials[Id] || false}
              disabled={isUpdating}
              onChange={(e) => setPatrimonial(Id, e.target.checked)}
            />
          ))}
        </div>
        <br />
        <h3>{t('admin.titleActivity')}</h3>
        <div>
          {activityAll.map(({ Id, Nom }) => (
            <CustomInput
              key={Id}
              type="switch"
              id={`activity-${Id}`}
              className={styles.switch}
              label={Nom}
              checked={checkedActivity[Id] || false}
              disabled={isUpdating}
              onChange={(e) => setActivity(Id, e.target.checked)}
            />
          ))}
        </div>
      </FormGroup>

      <Button
        color="primary"
        size="sm"
        disabled={isValidDisabled || isUpdating}
        className="mt-4"
        onClick={postChanges}
      >
        {isUpdating ? <Spinner className={styles.spinner} /> : <span>{t('admin.validate')}</span>}
      </Button>
    </div>
  )
}

const mapStateToProps = (state) => ({
  patrimonialsIds: getPatrimonialsRefrentiel(state).map((p) => p.Id),
  patrimonialsAll: getPatrimonialsRefrentielAll(state),
  activityIds: getActivityRefrentiel(state).map((p) => p.Id),
  activityAll: getActivityRefrentielAll(state),
  referentielUpdateStatus: getReferentielUpdateStatus(state),
})

const mapDispatchToProps = {
  fetchPatrimonialsReferentiel,
  fetchPatrimonialsReferentielAll,
  fetchActivityReferentiel,
  fetchActivityReferentielAll,
  updatePatrimonialsReferentiel,
  updateActivityReferentiel,
}

export const AdminReferentiels = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('checkedPatrimonials', 'setCheckedPatrimonials', {}),
  withState('checkedActivity', 'setCheckedActivity', {}),
  withState('isValidDisabled', 'setIsValidDisabled', true),
  withHandlers({
    fetchData: (props) => () => {
      props.fetchPatrimonialsReferentiel()
      props.fetchPatrimonialsReferentielAll()
      props.fetchActivityReferentiel()
      props.fetchActivityReferentielAll()
    },
    setPatrimonial:
      ({ checkedPatrimonials, setCheckedPatrimonials, setIsValidDisabled }) =>
      (Id, value) => {
        setCheckedPatrimonials({
          ...checkedPatrimonials,
          [Id]: value,
        })
        setIsValidDisabled(false)
      },
    setActivity:
      ({ checkedActivity, setCheckedActivity, setIsValidDisabled }) =>
      (Id, value) => {
        setCheckedActivity({
          ...checkedActivity,
          [Id]: value,
        })
        setIsValidDisabled(false)
      },
    initCheckedPatrimonials:
      ({ setCheckedPatrimonials, patrimonialsAll, patrimonialsIds }) =>
      () => {
        const checkedPatrimonials = patrimonialsAll.reduce(
          (acc, { Id }) => ({
            ...acc,
            [Id]: patrimonialsIds.includes(Id),
          }),
          {},
        )
        setCheckedPatrimonials(checkedPatrimonials)
      },
    initCheckedActivity:
      ({ setCheckedActivity, activityAll, activityIds }) =>
      () => {
        const checkedActivity = activityAll.reduce(
          (acc, { Id }) => ({
            ...acc,
            [Id]: activityIds.includes(Id),
          }),
          {},
        )
        setCheckedActivity(checkedActivity)
      },
    postChanges:
      ({
        updatePatrimonialsReferentiel,
        checkedPatrimonials,
        updateActivityReferentiel,
        checkedActivity,
      }) =>
      () => {
        const patrimonials = Object.entries(checkedPatrimonials)
          .filter((p) => p[1])
          .map((p) => p[0])
        updatePatrimonialsReferentiel(patrimonials)
        const activity = Object.entries(checkedActivity)
          .filter((p) => p[1])
          .map((p) => p[0])
        updateActivityReferentiel(activity)
      },
  }),
  lifecycle({
    componentDidMount() {
      const {
        patrimonialsAll,
        activityAll,
        initCheckedPatrimonials,
        initCheckedActivity,
        fetchData,
      } = this.props

      if (patrimonialsAll.length > 0) {
        initCheckedPatrimonials()
      }
      if (activityAll.length > 0) {
        initCheckedActivity()
      }
      if (patrimonialsAll.length === 0 || activityAll.length === 0) {
        fetchData()
      }
    },
    componentDidUpdate(prevProps) {
      const {
        patrimonialsIds,
        patrimonialsAll,
        activityAll,
        activityIds,
        initCheckedPatrimonials,
        initCheckedActivity,
      } = this.props
      if (
        prevProps.patrimonialsIds.length !== patrimonialsIds.length ||
        prevProps.patrimonialsAll.length !== patrimonialsAll.length
      ) {
        initCheckedPatrimonials()
      }
      if (
        prevProps.activityIds.length !== activityIds.length ||
        prevProps.activityAll.length !== activityAll.length
      ) {
        initCheckedActivity()
      }
    },
  }),
)(AdminReferentielsContent)
