import React from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import {
  TextField,
  RadioFields,
  RadioFieldsOptions,
  Select,
  SelectOptions,
} from 'components/base/Fields'
import { EnumReference, anneesRef } from 'hooks/decretTertiaire'
import { useDecretTertiaireForm } from './useDecretTertiaireForm'

const anneeRefOptions: SelectOptions<number> = anneesRef.map((anneeRef) => ({
  label: anneeRef,
  value: anneeRef,
}))

export const DecretTertiaireForm = ({ siteId }: { siteId: number }) => {
  const { t } = useTranslation()

  const { values, setValues, onSubmit, isLoading, disabled } = useDecretTertiaireForm(siteId)

  const isAbsolute = values?.Reference === EnumReference.Absolue

  const referenceOptions: RadioFieldsOptions<EnumReference> = [
    { label: t('site.decretTertiaire.absolue'), value: EnumReference.Absolue },
    { label: t('site.decretTertiaire.relative'), value: EnumReference.Relative },
  ]

  return (
    <form
      onSubmit={onSubmit}
      className={classnames('mt-4 d-flex flex-column space-y-6', { isLoading })}
    >
      <Select<number>
        label={t('site.decretTertiaire.anneeRef')}
        options={anneeRefOptions}
        value={values?.AnneeRef}
        onChange={(value) => setValues({ ...values, AnneeRef: value })}
      />

      <RadioFields<EnumReference>
        label={t('site.decretTertiaire.reference')}
        options={referenceOptions}
        value={values?.Reference}
        onChange={(value) => setValues({ ...values, Reference: value })}
      />

      {isAbsolute && (
        <TextField<number>
          type="number"
          label="Use Modulé"
          postfix="kWh/m2/an"
          value={values?.UseModule}
          onChange={(value) => setValues({ ...values, UseModule: value })}
        />
      )}

      <footer>
        <button type="submit" className="btn btn-primary btn-sm" disabled={disabled}>
          {t('admin.validate')}
        </button>
      </footer>
    </form>
  )
}
