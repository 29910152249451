import React, { createRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import throttle from 'lodash/throttle'

import { getDetailedActiveTab, setDetailedActiveTab } from 'store/consumption'
import { getToFocus, focus } from 'store/currentSelection'
import { getVue, getPee } from 'store/dateRange'

import DateRange from 'components/common/DateRange'
import withEmpty from 'components/common/Empty'
import { LoadCurve } from '../LoadCurve'
import HeatMap from '../HeatMap'
import Monotone from '../Monotone'
import EnergySignature from '../EnergySignature'
import Temperatures from '../Temperatures'
import Labels from '../Labels'

import styles from './DetailedConsumption.module.scss'

let isUserScroll = true

const scrollToRef = (ref) => {
  isUserScroll = false

  window.scrollTo({
    top: ref.current.getBoundingClientRect().top + window.scrollY - 76 - 100 - 48 - 32,
    behavior: 'smooth',
  })

  setTimeout(() => {
    isUserScroll = true
  }, 1000)
}

const NavBar = ({ activeTab, toggleTab, tabs }) => (
  <Nav className="sub-nav-tab">
    {tabs.map(({ title, ref }, index) => (
      <NavItem key={index}>
        <NavLink
          className={classnames({
            active: activeTab === index,
          })}
          onClick={() => {
            if (toggleTab) toggleTab(index)
            scrollToRef(ref)
          }}
        >
          <span>{title}</span>
        </NavLink>
      </NavItem>
    ))}
  </Nav>
)

const DetailedConsumption = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const activeTab3 = useSelector(getDetailedActiveTab)
  const toFocus = useSelector(getToFocus)
  const vue = useSelector(getVue)
  const pee = useSelector(getPee)

  const tabs3 = React.useMemo(
    () => [
      {
        title: t('consumption.title.loadCurve'),
        Component: LoadCurve,
        ref: createRef(),
      },
      {
        title: t('consumption.title.monotone'),
        Component: Monotone,
        ref: createRef(),
      },
      {
        title: t('consumption.title.heatmap'),
        Component: HeatMap,
        ref: createRef(),
      },
      {
        title: t('consumption.title.signature'),
        Component: EnergySignature,
        ref: createRef(),
      },
      {
        title: t('consumption.title.temperatures'),
        Component: Temperatures,
        ref: createRef(),
      },
      {
        title: t('consumption.title.label'),
        Component: Labels,
        ref: createRef(),
      },
    ],
    [t],
  )

  useEffect(() => {
    const onScroll = throttle(() => {
      if (!isUserScroll) return
      const index = tabs3
        .map(({ ref }) =>
          ref && ref.current ? ref.current.getBoundingClientRect().top + window.scrollY : 76,
        )
        .findIndex((top) => top > window.scrollY)
      dispatch(setDetailedActiveTab(index))
    }, 400)

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [dispatch, tabs3])

  useEffect(() => {
    if (toFocus !== null) {
      dispatch(focus(toFocus))
    }
  }, [toFocus, dispatch])

  if (toFocus !== null) return null

  const hasSelection = vue !== '0' || pee !== null

  return (
    <>
      <Row className={styles.header2}>
        <Col>
          <Row className={styles.periodSelector}>
            <Col className="ml-3 mr-3">
              <DateRange short={true} showAgregation={false} showEntities={true} showCurveType={true} />
            </Col>
          </Row>
          {hasSelection && (
            <Row>
              <Col className="p-0">
                <NavBar tabs={tabs3} activeTab={activeTab3} toggleTab={setDetailedActiveTab} />
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {hasSelection ? (
        tabs3.map(({ Component, ref, title }, index) => (
          <Row key={index}>
            <Col className="ml-3 mr-3">
              <div ref={ref} />
              <Component title={title} />
            </Col>
          </Row>
        ))
      ) : (
        <Row>
          <Col className="mt-5 text-center">{t('global.emptyPeeSelection')}</Col>
        </Row>
      )}
    </>
  )
}

export default withEmpty(DetailedConsumption)
