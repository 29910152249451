import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import HighchartsReact from 'highcharts-react-official'
import { compose, withProps } from 'recompose'

import { Highcharts } from 'helpers/highcharts'
import { withLoader } from 'commons'

const createConfig = (data, unite) => {
  return {
    title: {
      text: null,
    },
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    chart: {
      type: 'column',
      // height: '400px'
    },
    tooltip: {
      xDateFormat: '%d/%m/%Y %H:%M',
      pointFormat: `{point.series.name} : <b>{point.y:.2f} ${unite}<b/><br/>`,
      // crosshairs: true,
      // shared: true,
    },
    plotOptions: {
      series: {
        animation: false,
      },
      column: {
        pointPadding: 0,
        groupPadding: 0.05,
        borderWidth: 1,
        shadow: false,
      },
    },
    series: [
      {
        name: 'Activité de production',
        data: data.data,
      },
    ],
  }
}

const ActivitiesGraph = ({ data, unite }) => {
  const config = createConfig(data, unite)
  return (
    <Container>
      <Row>
        <Col>
          <HighchartsReact highcharts={Highcharts} options={config} />
        </Col>
      </Row>
    </Container>
  )
}

export default compose(
  withProps((props) => ({
    isLoading: !props.data,
  })),
  withLoader,
)(ActivitiesGraph)

export { ActivitiesGraph }
