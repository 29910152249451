import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withState, lifecycle } from 'recompose'
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Label,
  Input,
  UncontrolledAlert,
  Spinner,
} from 'reactstrap'
import Select from 'react-select'
import moment from 'moment'
import classnames from 'classnames'

// ACTIONS
import {
  downloadActivitiesTemplate,
  uploadActivities,
  fetchSiteActivities,
  getActivities,
  getUploadStatus,
} from '../../../store/siteActivities'

// COMPONENTS
import ActivitiesGraph from './ActivityGraph'
import { ReactComponent as UploadIcon } from '../../../assets/images/upload.svg'
import { ReactComponent as DownloadIcon } from '../../../assets/images/download.svg'

// REDUCERS
import { getSiteId } from '../../../store/site'

// CSS
import styles from './Activities.module.scss'

// HELPERS
import InputDatepicker from '../../common/InputDatepicker'

const Activity = (props) => {
  const { siteId, uploadStatus, isAdmin } = props
  const { t } = useTranslation()
  const stepOptions = [
    { value: 1, label: t('site.activities.yearly') },
    { value: 2, label: t('site.activities.quarterly') },
    { value: 3, label: t('site.activities.monthly') },
    { value: 4, label: t('site.activities.daily') },
    { value: 5, label: t('site.activities.hourly') },
    { value: 10, label: t('site.activities.weekly') },
  ]

  const uploadActivityData = (e) => {
    e.persist()
    const cleanUp = () => (e.target.value = '')
    props.uploadActivityData(siteId, props.typeId, e.target.files[0], cleanUp)
  }

  return (
    <Container className="box" style={{ minHeight: '380px' }}>
      <Row>
        <Col>
          <h4>{t('site.activities.title')}</h4>
        </Col>
      </Row>
      {isAdmin && (
        <>
          <Row>
            <Col>
              <Form className={styles.form}>
                <label>{t('site.activities.start')}</label>
                <InputDatepicker
                  inputProps={{
                    placeholder: 'DD/MM/YYYY',
                    pattern: '\\d{1,2}/\\d{1,2}/\\d{4}',
                    required: true,
                    width: 10,
                  }}
                  value={props.startDate}
                  onChange={(date) => props.setStartDate(moment(date).format('DD/MM/YYYY'))}
                  className={styles.datepicker}
                />

                <label>{t('site.activities.end')}</label>
                <InputDatepicker
                  inputProps={{
                    placeholder: 'DD/MM/YYYY',
                    pattern: '\\d{1,2}/\\d{1,2}/\\d{4}',
                    required: true,
                  }}
                  value={props.endDate}
                  onChange={(date) => props.setEndDate(moment(date).format('DD/MM/YYYY'))}
                  className={styles.datepicker}
                />

                <label>{t('site.activities.step')}</label>
                <Select
                  value={stepOptions.find((option) => option.value === props.step)}
                  onChange={(option) => props.setStep(option.value)}
                  options={stepOptions}
                  className="react-select"
                  classNamePrefix="react-select"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: '150px',
                    }),
                  }}
                />

                <Button
                  size="sm"
                  color="primary"
                  className={styles.btnSubmit}
                  onClick={props.downloadActivityModel(siteId, props.typeId)}
                >
                  {t('site.activities.downloadModel')}
                  <DownloadIcon className={styles.downloadIcon} />
                </Button>
              </Form>
            </Col>
          </Row>
          <Row className="mt-4 mb-5">
            <Col>
              <Form className={styles.uploadForm}>
                <Label
                  className="custom-file"
                  style={{ width: 'unset' }}
                  onClick={(e) => {
                    if (uploadStatus.isUploading) e.preventDefault()
                  }}
                >
                  <Input
                    id="activityFile"
                    type="file"
                    className="custom-file-input"
                    style={{ display: 'none' }}
                    accept=".csv"
                    onChange={(e) => uploadActivityData(e)}
                  />
                  <div
                    size="sm"
                    color="primary"
                    className={classnames('btn', 'btn-primary', 'btn-sm', styles.uploadButton)}
                  >
                    <span className={styles.uploadLabel}>{t('site.activities.uploadData')}</span>

                    {uploadStatus.isUploading ? (
                      <Spinner className={styles.uploadLoader} />
                    ) : (
                      <UploadIcon className={styles.uploadIcon} />
                    )}
                  </div>
                </Label>
                {uploadStatus.isError && (
                  <UncontrolledAlert color="danger" className={styles.alert}>
                    {t('site.activities.uploadError')}
                  </UncontrolledAlert>
                )}

                {uploadStatus.isSuccess && (
                  <UncontrolledAlert color="success" className={styles.alert}>
                    {t('site.activities.uploadSuccess')}
                  </UncontrolledAlert>
                )}
              </Form>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col className="col-11">
          <ActivitiesGraph data={props.siteActivities.items} unite={props.unitName} />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  siteId: getSiteId(state),
  siteActivities: getActivities(state),
  user: state.oidc.user,
  uploadStatus: getUploadStatus(state),
})

const mapDispatchToProps = (dispatch, { startDate, endDate, step, unitId }) => ({
  fetchSiteActivities: (siteId, typeId) => {
    if (!siteId) return
    dispatch(fetchSiteActivities(siteId, typeId))
  },
  downloadActivityModel: (siteId, typeId) => () => {
    dispatch(downloadActivitiesTemplate(siteId, typeId, startDate, endDate, step, unitId))
  },
  uploadActivityData: (siteId, typeId, file, cleanUp) => {
    dispatch(uploadActivities(siteId, typeId, step, unitId, file, cleanUp))
  },
})

export default compose(
  withState('startDate', 'setStartDate', moment(Date.now()).add(-1, 'month').format('DD/MM/YYYY')),
  withState('endDate', 'setEndDate', moment(Date.now()).format('DD/MM/YYYY')),
  withState('step', 'setStep', 4),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchSiteActivities(this.props.siteId, this.props.typeId)
    },
    componentDidUpdate(prevProps) {
      if (prevProps.siteId !== this.props.siteId || prevProps.typeId !== this.props.typeId) {
        this.props.fetchSiteActivities(this.props.siteId, this.props.typeId)
      }

      if (prevProps.siteActivities !== this.props.siteActivities) {
        const { step } = this.props.siteActivities
        this.props.setStep(!step ? 4 : step)
      }
    },
  }),
)(Activity)
