import React from 'react'
import { Button } from 'reactstrap'
import { CallbackComponent } from 'redux-oidc'
import { useTranslation } from 'react-i18next'

import userManager from '../../security/userManager'
import Loader from '../widgets/Loader'
import './Callback.scss'

const Callback = () => {
  const { t } = useTranslation()

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={() => {
        window.location.href = '/'
      }}
      errorCallback={() => {}}
    >
      <div className="callback-container">
        <Loader center={false} />

        <div className="callback-refresh">
          <Button
            color="link"
            onClick={() => {
              window.location.href = '/'
            }}
          >
            {t('access.redirection')}
          </Button>
        </div>
      </div>
    </CallbackComponent>
  )
}

export default Callback
