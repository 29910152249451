import React, { useEffect } from 'react'
import { processSilentRenew } from 'redux-oidc'

const SilentRedirectPage = () => {
  useEffect(() => {
    processSilentRenew()
  }, [])

  return <div></div>
}

export default SilentRedirectPage
export { SilentRedirectPage }
