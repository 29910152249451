import { useState, useEffect, useMemo, FormEvent } from 'react'
import isEqual from 'lodash/isEqual'

import {
  useDecretTertiaireQuery,
  useDecretTertiaireMutation,
  DecretTertiaire,
} from 'hooks/decretTertiaire'

type FormData = Partial<DecretTertiaire>

const defaultValues: FormData = {
  AnneeRef: undefined,
  Reference: undefined,
  UseModule: undefined,
}

export const useDecretTertiaireForm = (siteId: number) => {
  const { data, ...query } = useDecretTertiaireQuery(siteId)
  const { mutate, ...mutation } = useDecretTertiaireMutation(siteId)

  const [values, setValues] = useState<FormData>()

  const initialValues: FormData = useMemo(
    () => ({
      ...defaultValues,
      ...data,
    }),
    [data],
  )

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues])

  const isLoading = query.isFetching || mutation.isLoading
  const isDirty = !isEqual(values, initialValues)
  const disabled = isLoading || !isDirty

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    mutate({
      SitId: siteId,
      ...values,
    })
  }

  return { values, setValues, onSubmit, isLoading, disabled }
}
