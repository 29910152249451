import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'

import { usrId as getUsrId, uorIdMst as getUorIdMst, lanId as getLanId } from 'commons'
import { request } from 'helpers/request'

export const useOrganisation = () => {
  const usrId = useSelector(getUsrId)
  const uorIdMst = useSelector(getUorIdMst)
  const lanId = useSelector(getLanId)

  const { data } = useQuery({
    queryey: ['organisation', { usrId, uorIdMst, lanId }],
    queryFn: () =>
      request({ url: `organisation?usrid=${usrId}&uoridmst=${uorIdMst}&lanId=${lanId}` }),
    staleTime: Infinity,
  })

  return data
}
