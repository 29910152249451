import React from 'react'
import { connect } from 'react-redux'
import DropdownTreeSelect from 'react-dropdown-tree-select'

import { updateSiteField } from 'store/site'
import { getUorTree } from 'store/organisation'

const UorSelect = ({ id, uorTree, updateSiteField }) => {
  const data = {
    ...uorTree,
    expanded: true,
  }

  const onChange = React.useCallback(({ label, value }) => {
    // updateSiteField()
  }, [])

  return (
    <DropdownTreeSelect className="uor-select" mode="radioSelect" data={data} onChange={onChange} />
  )
}

const mapState = (state, { id }) => ({
  uorTree: getUorTree(state, id),
})

const mapDispatch = { updateSiteField }

export default connect(mapState, mapDispatch)(UorSelect)
