import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose, withHandlers, withState, lifecycle } from 'recompose'
import { Container, Row, Col, Input, Button, Table } from 'reactstrap'
import classnames from 'classnames'

import { getSiteId } from '../../../store/site'
import {
  getPatrimonials,
  fetchPatrimonials,
  fetchPatrimonialsReferentiel,
  addPatrimonial,
  getPatrimonialsRefrentiel,
} from '../../../store/patrimonial'

import styles from './Patrimonials.module.scss'
import Patrimonial from './Patrimonial'
import KpiPatrimonials from './KpiPatrimonials'

const Patrimonials = (props) => {
  const { t } = useTranslation()

  const {
    isAdmin,
    patrimonialsReferentiel,
    updating,
    selectValue,
    setSelectValue,
    inputValue,
    updateInputValue,
    adding,
    toggleAdding,
    savePatrimonial,
    date,
    setDate,
  } = props

  if (patrimonialsReferentiel.length === 0) return null

  const patrimonials = props.patrimonials.map((p) => {
    const ref = patrimonialsReferentiel.find((r) => r.Id === p.TypeIndicateur)
    return {
      ...p,
      unite: !!ref ? ref.UniteNom : '',
    }
  })

  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{t('site.patrimonials.title')}</h3>
        </Col>
      </Row>
      {(adding || patrimonials.length > 0) && (
        <Row>
          <Col>
            <Table className={classnames('simpleTable', styles.table)}>
              <thead>
                <tr>
                  <th style={{ width: '280px' }}>{t('site.patrimonials.kpi')}</th>
                  <th style={{ width: '200px' }}>{t('site.patrimonials.value')}</th>
                  <th style={{ width: '200px' }}>Date</th>
                  <th style={{ width: '150px' }} />
                </tr>
              </thead>
              <tbody>
                {patrimonials.map((props) => (
                  <Patrimonial
                    key={props.Id}
                    updating={updating}
                    {...props}
                    referentiel={patrimonialsReferentiel}
                    isAdmin={isAdmin}
                  />
                ))}

                {adding && (
                  <tr>
                    <td>
                      <KpiPatrimonials
                        edit={true}
                        select={selectValue}
                        setSelect={setSelectValue}
                      />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <Input
                          className={styles.input}
                          type="number"
                          id="value"
                          value={inputValue}
                          onChange={updateInputValue}
                        />
                        <span>
                          {!!selectValue &&
                            patrimonialsReferentiel.find((r) => r.Id === selectValue).UniteNom}
                        </span>
                      </div>
                    </td>
                    <td className="d-flex align-items-center">
                      <Input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    </td>
                    <td>
                      <Button className={styles.btn} color="link" onClick={toggleAdding}>
                        <i className="icon-cross" />
                      </Button>
                      <Button
                        className={styles.btn}
                        color="link"
                        onClick={savePatrimonial}
                        disabled={!inputValue || !selectValue}
                      >
                        <i className="icon-done" />
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}
      {isAdmin && !adding && (
        <Row>
          <Col>
            <Button size="sm" color="primary" onClick={toggleAdding} className={styles.addBtn}>
              + {t('site.patrimonials.addKpi')}
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  )
}

const mapStateToProps = (state) => {
  const { items, fetching, updating } = getPatrimonials(state)

  return {
    siteId: getSiteId(state),
    fetching,
    updating,
    patrimonials: items.slice().sort((a, b) => a.Id - b.Id),
    patrimonialsReferentiel: getPatrimonialsRefrentiel(state) || [],
  }
}

const mapDispatchToProps = {
  fetchPatrimonials,
  fetchPatrimonialsReferentiel,
  addPatrimonial,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withState('selectValue', 'setSelectValue', ''),
  withState('inputValue', 'setInputValue', ''),
  withState('date', 'setDate', ''),
  withState('adding', 'setAdding', false),
  withHandlers({
    toggleAdding: (props) => () => {
      props.setAdding(!props.adding)
    },
  }),
  withHandlers({
    updateInputValue: (props) => (e) => {
      props.setInputValue(e.target.value)
    },
    savePatrimonial: (props) => () => {
      if (props.selectValue === '' || props.selectValue === '') return

      props.addPatrimonial(props.siteId, props.selectValue, props.inputValue, props.date)

      props.toggleAdding()
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchPatrimonialsReferentiel()
    },
    componentDidUpdate(prevProps) {
      if (prevProps.siteId !== this.props.siteId) {
        this.props.fetchPatrimonials(this.props.siteId)
      }
    },
  }),
)(Patrimonials)

export { Patrimonials }
