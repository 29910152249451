import React, { Suspense, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'
import formatcoords from 'formatcoords'

import { apiConf } from 'commons'
import { ErrorFallback } from 'components/common/ErrorFallback'
import { Loader } from 'components/common/Loader'

export const StationMeteo = (props) => {
  return (
    <div css={{ display: 'flex', justifyContent: 'center' }}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<Loader style={{ height: 150 }} />}>
          <StationMeteoInner {...props} />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

const StationMeteoInner = ({ siteId }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { data } = useQuery({
    queryKey: ['stationmeteo', { siteId }],
    queryFn: async () => {
      const res = await apiConf.superFetch({ url: `sites/${siteId}/stationmeteo` })
      if (res.status !== 200) return null
      return res.json()
    },
    staleTime: 60 * 60 * 1000,
  })

  const coords = useMemo(() => {
    if (!data || !data.Latitude || !data.Longitude) return null
    try {
      return formatcoords(data.Latitude, data.Longitude)
        .format('FFf', { latLonSeparator: ',', decimalPlaces: 0 })
        .split(',')
        .map((coord) => (language === 'fr' ? coord.replace('W', 'O') : coord))
    } catch (error) {
      console.error(error)
    }
  }, [data, language])

  if (!data) return null

  return (
    <div className="shadow-sm border p-4">
      <table
        css={{
          tr: { height: '2.5rem' },
          th: { width: '10rem' },
        }}
      >
        <tbody>
          {data.Nom && (
            <tr>
              <th>{t('meteo.station')}</th>
              <td>{data.Nom}</td>
            </tr>
          )}
          {data.Altitude && (
            <tr>
              <th>Altitude</th>
              <td>
                {data.Altitude} {t('meteo.meter', { count: data.Altitude })}
              </td>
            </tr>
          )}
          {coords && (
            <>
              <tr>
                <th>Latitude</th>
                <td>{coords[0]}</td>
              </tr>
              <tr>
                <th>Longitude</th>
                <td>{coords[1]}</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}
