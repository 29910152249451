import React from 'react'
import { useTranslation } from 'react-i18next'
import flatMap from 'lodash/flatMap'
import uniq from 'lodash/uniq'

import { useAcheminement } from './useAcheminement'
import { CustomTable } from './CustomTable'

export const PeeFta = () => {
  const { t } = useTranslation()
  const data = useAcheminement()
  const rowLabels = uniq(
    flatMap(data, (fta) => fta.AchFtaParametres.map((param) => param.FtaParamLibelle)),
  )
  const rowDatas = data.map((fta) =>
    fta.AchFtaParametres.reduce(
      (acc, param) => ({
        ...acc,
        [param.FtaParamLibelle]: param.FtaParamValeur,
      }),
      {},
    ),
  )

  return (
    <div
      css={{
        display: 'grid',
        gridGap: '1rem',
        overflowX: 'auto',
        '>label': {
          display: 'grid',
          gridGap: '1rem',
          gridTemplateColumns: '150px 1fr',
          alignItems: 'start',
          fontSize: 14,
          color: '#61747f',
          '>div:first-of-type': {
            fontWeight: 700,
          },
        },
        table: {
          minWidth: '100%',
          tableLayout: 'auto',
        },
        'td, th': {
          minWidth: 220,
          width: `calc(100% / ${rowDatas.length + 1})`,
        },
      }}
    >
      <CustomTable>
        <thead>
          <tr>
            <th>{t('site.pee-details.routing.option')}</th>
            {data.map((item, i) => (
              <th key={i}>{item.AchFtaDateDerniereModification}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{t('site.pee-details.routing.formule')}</th>
            {data.map((item, i) => (
              <td key={i} style={{ whiteSpace: 'normal', textAlign: 'left' }}>
                {item.AchFtaLibelle}
              </td>
            ))}
          </tr>
          {rowLabels.map((label, rowIndex) => (
            <tr key={rowIndex}>
              <th scope="row">{label}</th>
              {rowDatas.map((params, colIndex) => (
                <td key={colIndex} style={{ textAlign: 'left' }}>
                  {params[label] ?? '\u2013'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </CustomTable>
    </div>
  )
}
