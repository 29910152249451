import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { getIsSiteOpen, toggleSiteOpen, toggleSiteInSelection } from 'store/currentSelection'
import { getSiteId } from 'store/site'

import styles from './CurrentSite.module.scss'

const CurrentSite = ({ siteId, site }) => {
  const [path, setPath] = useState('')
  const dispatch = useDispatch()

  const isSiteOpen = useSelector((state) => getIsSiteOpen(state, siteId))
  const openedSiteId = useSelector(getSiteId)

  const toggleSite = useSelector((state) => toggleSiteInSelection(state, siteId))

  return (
    <div className="current-site">
      <Button
        className={classnames(
          'd-flex',
          'align-items-center',
          'w-100',
          styles.item,
          openedSiteId === siteId ? `${styles.selectedBtn} selectedBtn` : styles.btn,
        )}
        color="link"
      >
        <span className="w-100" style={{ opacity: isSiteOpen ? 1 : 0.3 }}>
          {site}
        </span>

        <Link to={'/site/' + siteId}>
          <i
            className={classnames('icon-ico-edit sidebar-ico', styles.ico, {
              [styles.selectedIco]: path === '/site/',
              selectedIco: path === '/site/',
            })}
            onClick={() => setPath('/site/')}
            style={{
              fontSize: 11,
            }}
          />
        </Link>

        <div
          onClick={() => {
            dispatch(toggleSiteOpen(siteId))
          }}
        >
          <i
            className={classnames('icon-eye sidebar-ico', styles.ico)}
            style={{
              fontSize: 13,
              ...(!isSiteOpen && { opacity: 1 }),
            }}
          />
        </div>

        <div
          onClick={() => {
            dispatch(toggleSite)
          }}
        >
          <i
            className={classnames('icon-cross sidebar-ico', styles.ico)}
            style={{
              fontSize: 11,
            }}
          />
        </div>
      </Button>
    </div>
  )
}

export default CurrentSite
