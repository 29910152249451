import React from 'react'
import { useTranslation } from 'react-i18next'
import sumBy from 'lodash/sumBy'

import { usePuissancesTotal } from './useGestion'
import { noDecimalFormat } from 'helpers/formatters'
import { CustomTable } from './CustomTable'
import { CSVButton } from './CSVButton'

const defaultData = ['HP', 'HPH', 'HCH', 'HPE', 'HCE'].map((Poste) => ({
  Poste,
  PuissanceSouscrite: '-',
  PMin: '-',
  PMax: '-',
  ConsoActive: '-',
}))

export const TableTotal = () => {
  const { t } = useTranslation()
  const pTotal = usePuissancesTotal()

  const data = pTotal.length > 0 ? pTotal : defaultData

  const getCsvData = React.useCallback(
    () => [
      ['', ...data.map((item) => item.Poste)],
      [t('gestion.pSouscrite'), ...data.map((x) => noDecimalFormat(x.PuissanceSouscrite))],
      [t('gestion.pMin'), ...data.map((x) => noDecimalFormat(x.PMin))],
      [t('gestion.pMax'), ...data.map((x) => noDecimalFormat(x.PMax))],
      [t('gestion.consoActive'), ...data.map((x) => noDecimalFormat(x.ConsoActive))],
    ],
    [data, t],
  )

  const consoTotale = noDecimalFormat(sumBy(data, 'ConsoActive'))

  return (
    <div>
      <CustomTable>
        <thead>
          <tr>
            <th style={{ width: 270 }}>&nbsp;</th>
            {data.map((x, i) => (
              <th key={i}>{x.Poste}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          <tr>
            <th>{t('gestion.pSouscrite')}</th>
            {data.map((x, i) => (
              <td key={i}>{noDecimalFormat(x.PuissanceSouscrite)}</td>
            ))}
          </tr>
          <tr>
            <th>{t('gestion.pMin')}</th>
            {data.map((x, i) => (
              <td key={i}>{noDecimalFormat(x.PMin)}</td>
            ))}
          </tr>
          <tr>
            <th>{t('gestion.pMax')}</th>
            {data.map((x, i) => (
              <td key={i}>{noDecimalFormat(x.PMax)}</td>
            ))}
          </tr>
          <tr>
            <th>{t('gestion.consoActive')}</th>
            {data.map((x, i) => (
              <td key={i}>{noDecimalFormat(x.ConsoActive)}</td>
            ))}
          </tr>
        </tbody>
      </CustomTable>

      <CSVButton filename={`ESITE - ${t('gestion.pTotal')}.csv`} getData={getCsvData} />

      <p className="lead mt-3">
        {t('gestion.consoTotale')} : {consoTotale} kWh
      </p>
    </div>
  )
}
