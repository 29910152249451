import React from 'react'
import { Alert } from 'reactstrap'
import { useTranslation } from 'react-i18next'

const Error = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Alert color="danger" style={{ display: 'inline-block' }}>
        {t('global.error')}
      </Alert>
    </div>
  )
}
export default Error
