import { combineReducers } from 'redux'
import v4 from 'uuid/v4'

import { apiConf } from 'commons'
const { superFetch } = apiConf

const api = {
  fetchActivityReferentielAll: () =>
    superFetch({
      url: `referentiel/kpi/activity`,
    }),

  fetchActivityReferentiel: () =>
    superFetch({
      url: 'referentiel/kpi/activity/my',
    }),

  updateActivityReferentiel: (activity) =>
    superFetch({
      url: 'referentiel/kpi/activity/my',
      method: 'POST',
      body: activity,
    }),
}

// Actions
const FETCH_ACTIVITY_REFERENTIEL_REQUEST = 'esite/activity/FETCH_ACTIVITY_REFERENTIEL_REQUEST'
const FETCH_ACTIVITY_REFERENTIEL_SUCCESS = 'esite/activity/FETCH_ACTIVITY_REFERENTIEL_SUCCESS'
const FETCH_ACTIVITY_REFERENTIEL_FAILURE = 'esite/activity/FETCH_ACTIVITY_REFERENTIEL_FAILURE'
const UPDATE_ACTIVITY_REFERENTIEL_REQUEST = 'esite/activity/UPDATE_ACTIVITY_REFERENTIEL_REQUEST'
const UPDATE_ACTIVITY_REFERENTIEL_SUCCESS = 'esite/activity/UPDATE_ACTIVITY_REFERENTIEL_SUCCESS'
const UPDATE_ACTIVITY_REFERENTIEL_FAILURE = 'esite/activity/UPDATE_ACTIVITY_REFERENTIEL_FAILURE'
const FETCH_ACTIVITY_REFERENTIEL_ALL_REQUEST =
  'esite/activity/FETCH_ACTIVITY_REFERENTIEL_ALL_REQUEST'
const FETCH_ACTIVITY_REFERENTIEL_ALL_SUCCESS =
  'esite/activity/FETCH_ACTIVITY_REFERENTIEL_ALL_SUCCESS'
const FETCH_ACTIVITY_REFERENTIEL_ALL_FAILURE =
  'esite/activity/FETCH_ACTIVITY_REFERENTIEL_ALL_FAILURE'

// Reducer
// const activity = (state = { items: [], fetching: false, updating: false, error: '' }, action) => {
//   switch (action.type) {
//     case FETCH_ACTIVITY_REQUEST:
//       return {
//         ...state,
//         fetching: true,
//       }
//     case FETCH_ACTIVITY_FAILURE:
//       return {
//         ...state,
//         fetching: false,
//         items: [],
//         error: action.error,
//       }
//     case FETCH_ACTIVITY_SUCCESS:
//       return {
//         ...state,
//         fetching: false,
//         items: action.response,
//         error: '',
//       }
//     case MODIFY_PATRIMONIAL_REQUEST:
//       return {
//         ...state,
//         updating: true,
//       }
//     case MODIFY_PATRIMONIAL_SUCCESS:
//       return {
//         ...state,
//         updating: false,
//       }
//     case MODIFY_PATRIMONIAL_FAILURE:
//       return {
//         ...state,
//         updating: false,
//       }
//     default:
//       return state;
//   }
// };

const activityReferentiel = (state = [], action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_REFERENTIEL_SUCCESS:
      return action.response
    default:
      return state
  }
}

const referentielUpdateStatus = (
  state = {
    isUpdating: false,
    isSuccess: false,
    isError: false,
  },
  action,
) => {
  switch (action.type) {
    case UPDATE_ACTIVITY_REFERENTIEL_REQUEST:
      return {
        isUpdating: true,
        isSuccess: false,
        isError: false,
      }
    case UPDATE_ACTIVITY_REFERENTIEL_SUCCESS:
      return {
        isUpdating: false,
        isSuccess: true,
        isError: false,
      }
    case UPDATE_ACTIVITY_REFERENTIEL_FAILURE:
      return {
        isUpdating: false,
        isSuccess: false,
        isError: true,
      }
    default:
      return state
  }
}

const activityReferentielAll = (state = [], action) => {
  switch (action.type) {
    case FETCH_ACTIVITY_REFERENTIEL_ALL_SUCCESS:
      return action.response
    default:
      return state
  }
}

export default combineReducers({
  //activity,
  activityReferentiel,
  activityReferentielAll,
  referentielUpdateStatus,
})

// Selectors
export const getActivity = (state) => state.activity.activity
export const getActivityRefrentiel = (state) => state.activity.activityReferentiel
export const getActivityRefrentielAll = (state) => state.activity.activityReferentielAll
export const getReferentielUpdateStatus = (state) => state.activity.referentielUpdateStatus

// Thunks
export const fetchActivityReferentiel = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_ACTIVITY_REFERENTIEL_REQUEST,
    uuid,
  })

  return api.fetchActivityReferentiel().then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_ACTIVITY_REFERENTIEL_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_ACTIVITY_REFERENTIEL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_ACTIVITY_REFERENTIEL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const updateActivityReferentiel = (activity) => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: UPDATE_ACTIVITY_REFERENTIEL_REQUEST,
    uuid,
  })

  return api.updateActivityReferentiel(activity).then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: UPDATE_ACTIVITY_REFERENTIEL_SUCCESS,
          })
          dispatch({
            type: FETCH_ACTIVITY_REFERENTIEL_SUCCESS,
            response,
          })
        })
      } else {
        dispatch({
          type: UPDATE_ACTIVITY_REFERENTIEL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: UPDATE_ACTIVITY_REFERENTIEL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}

export const fetchActivityReferentielAll = () => (dispatch, getState) => {
  const uuid = v4()

  dispatch({
    type: FETCH_ACTIVITY_REFERENTIEL_ALL_REQUEST,
    uuid,
  })

  return api.fetchActivityReferentielAll().then(
    (response) => {
      if (response.status === 200) {
        response.json().then((response) => {
          dispatch({
            type: FETCH_ACTIVITY_REFERENTIEL_ALL_SUCCESS,
            response: response,
          })
        })
      } else {
        dispatch({
          type: FETCH_ACTIVITY_REFERENTIEL_ALL_FAILURE,
        })
      }
    },
    (error) => {
      dispatch({
        type: FETCH_ACTIVITY_REFERENTIEL_ALL_FAILURE,
        message: error.message || 'Something went wrong.',
      })
    },
  )
}
