import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { getFilteredPees, getFilteredSites, getFilteredUors } from '../../store/searchPanel'
import Pee from './Pee'
import Site from './Site'
import Uor from './Uor'

const ListeFiltree = ({ filteredPees, filteredSites, filteredUors, tagFilters }) => {
  const { t } = useTranslation()
  return (
    <div className="liste-filtree">
      {filteredUors.length > 0 && (
        <div>
          <h5>{t('panel.uors')}:</h5>
          <ul>
            {filteredUors.map((uorId) => (
              <Uor key={uorId} uorId={uorId} tagFilters={tagFilters} />
            ))}
          </ul>
        </div>
      )}

      {filteredSites.length > 0 && (
        <div>
          <h5>{t('panel.sites')}:</h5>
          <ul>
            {filteredSites.map((siteId) => (
              <Site key={siteId} siteId={siteId} tagFilters={tagFilters} />
            ))}
          </ul>
        </div>
      )}

      {filteredPees.length > 0 && (
        <div>
          <h5>{t('panel.pees')}:</h5>
          <ul>
            {filteredPees.map((peeId) => (
              <Pee key={peeId} peeId={peeId} />
            ))}
          </ul>
        </div>
      )}

      {filteredPees.length === 0 && filteredSites.length === 0 && filteredUors.length === 0 && (
        <p>Aucun élément correspondant à votre recherche.</p>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  filteredPees: getFilteredPees(state),
  filteredSites: getFilteredSites(state),
  filteredUors: getFilteredUors(state),
})

export default connect(mapStateToProps)(ListeFiltree)
