import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Error from 'components/common/Error'
import PerformanceLabel from '../PerformanceLabel'

const EnergyLabel = ({ value }) => {
  const { t } = useTranslation()
  const hasError = isNaN(parseFloat(value))

  return (
    <Container className="box">
      <Row>
        <Col>
          <h3>{t('labels.energy.title')}</h3>
        </Col>
      </Row>
      <Row>
        <Col className="graph-container">
          {hasError ? (
            <Error />
          ) : (
            <PerformanceLabel
              legends={[t('labels.energy.val'), t('labels.energy.min'), t('labels.energy.max')]}
              arrows={true}
              unit={t('labels.energy.unit')}
              value={value}
              values={[
                ['A', -Infinity, 50, '#029037', '#000'],
                ['B', 51, 90, '#58ab27', '#000'],
                ['C', 91, 150, '#c8d200', '#000'],
                ['D', 151, 230, '#ffec02', '#000'],
                ['E', 231, 330, '#faba00', '#000'],
                ['F', 331, 450, '#eb690a', '#000'],
                ['G', 451, 590, '#e20019', '#fff'],
                ['H', 591, 750, '#9c9d9f', '#fff'],
                ['I', 750, Infinity, '#4b4b4d', '#fff'],
              ]}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default EnergyLabel
