import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'reactstrap'

import {
  getConsumptionGraph,
  getConsumptionGraphUnite,
  getConsumptionGraphIsFetching,
  getConsumptionGraphError,
} from 'store/consumption'

import Chart from 'components/common/Chart'
import createConsoOptions from './options'
import { getUor } from 'store'

const MonthlyConsumptionGraph = ({ graph, unite, error, isLoading, uor }) => {
  const options = React.useMemo(() => {
    return createConsoOptions(graph, unite, uor)
  }, [graph, unite, uor])

  const isEmpty = !graph || !graph.graphMensuel || graph.graphMensuel.length === 0

  return (
    <Container>
      <Row>
        <Col xs="9">
          <Chart
            id="conso"
            error={error}
            isEmpty={isEmpty}
            isLoading={isLoading}
            options={options.all}
          />
        </Col>
        <Col xs="3">
          {!error && (
            <Chart
              id="conso-total"
              isEmpty={isEmpty}
              isLoading={isLoading}
              options={options.total}
            />
          )}
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  graph: getConsumptionGraph(state),
  unite: getConsumptionGraphUnite(state),
  isLoading: getConsumptionGraphIsFetching(state),
  error: getConsumptionGraphError(state),
  uor: getUor(state),
})

export default connect(mapStateToProps)(MonthlyConsumptionGraph)
