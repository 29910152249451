import { getColor } from 'helpers/colors'
import { decimalFormat } from 'helpers/formatters'

const createOptions = (graph, uor) => {
  if (!graph || !graph.series || graph.series.length === 0) return { title: { text: null } }

  const { length } = graph.series[0].data

  const options = {
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    xAxis: {
      labels: {
        format: '{value} %',
      },
    },
    chart: {
      type: 'line',
    },
    exporting: {
      enabled: true,
      sourceWidth: 800,
      sourceHeight: 400,
    },
    tooltip: {
      formatter: function () {
        const { y, name, series } = this.point
        if (y == null) return false
        return `${series.name}<br/>${name}<br/><b>${decimalFormat(y)}</b><br/>`
      },
    },
    plotOptions: {
      series: {
        animation: false,
        turboThreshold: 0,
      },
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    series: graph.series.map((serie, index) => ({
      ...serie,
      data: serie.data.map((item) => ({ ...item, x: (item.x * 100) / length })),
      id: index,
      color: getColor(index, 1, { uor }),
    })),
  }

  return options
}

export default createOptions
