import React, { useCallback } from 'react'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import downloadFile from '../../../helpers/downloadFile'
import { formatCSV } from '../../../helpers/csv'

const CSVButton = ({ filename, data, className, size = 'sm', color = 'secondary', style = {} }) => {
  const { t } = useTranslation()

  const handleClick = useCallback(
    () => downloadFile(filename, formatCSV(data), { type: 'text/csv' }),
    [filename, data],
  )

  return (
    <Button
      color={color}
      size={size}
      className={className}
      onClick={handleClick}
      style={{
        fontSize: '11px',
        ...style,
      }}
    >
      {t('global.exportCsv')}
    </Button>
  )
}

export default CSVButton
