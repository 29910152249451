import React from 'react'
import { connect } from 'react-redux'

import { getSignatureGraph, getSignatureIsFetching, getSignatureError } from 'store/performance'

import Chart from 'components/common/Chart'
import createOptions from './options'
import { getUor } from 'store'

const EnergySignatureGraph = ({ graph, error, isLoading, uor }) => {
  const options = React.useMemo(() => {
    return createOptions(graph, uor)
  }, [graph, uor])

  return (
    <Chart
      id="signature"
      error={error}
      isEmpty={!graph || graph.length === 0}
      isLoading={isLoading}
      options={options}
    />
  )
}

const mapStateToProps = (state) => ({
  graph: getSignatureGraph(state),
  isLoading: getSignatureIsFetching(state),
  error: getSignatureError(state),
  uor: getUor(state),
})

export default connect(mapStateToProps)(EnergySignatureGraph)
