import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { compose } from 'recompose'
import classnames from 'classnames'
import { Container, Row, Col, Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'

import { noDecimalFormat } from '../../../helpers/formatters'
import styles from './TopFlopCardKpi.module.scss'
import { setToFocus } from 'store/currentSelection'

const TopFlopCardKpi = ({ type, data, history, setToFocus }) => {
  const { t } = useTranslation()
  return !data ? null : (
    <Container
      fluid
      className={classnames({
        [styles.topCard]: type === 'top',
        [styles.flopCard]: type === 'flop',
      })}
    >
      <Row>
        <Col className={styles.header}>
          1. <span>{data.SitNom}</span>
        </Col>
      </Row>
      <Row className="mb-2 align-items-baseline flex-nowrap">
        <Col xs="4">
          <span className={styles.label}>{data.LibelleValeurPerf}&nbsp;:</span>
        </Col>
        <Col xs="8" style={{ textAlign: 'right' }}>
          <span className={styles.gap}>{noDecimalFormat(data.ValeurPerf)}</span>
        </Col>
      </Row>
      <Row className="mb-2 align-items-baseline">
        <Col>
          <span className={styles.label}>{data.LibelleValeur}&nbsp;:</span>
          <span className={styles.value}>{noDecimalFormat(data.Valeur)}</span>
        </Col>
      </Row>
      <Row className="mb-4 align-items-baseline">
        <Col className="d-flex justify-content-between align-items-center">
          <div>
            <span className={styles.label}>{data.LibelleValeurKpi}&nbsp;:</span>
            <span className={styles.value}>{noDecimalFormat(data.ValeurKpi)}</span>
          </div>
          <div>
            <Button
              size="sm"
              color="link"
              onClick={() => {
                setToFocus(data.SitId)
                history.push('/global-consumption')
              }}
              title={t('topNav.globalConsumption')}
              style={{ textDecoration: 'none' }}
            >
              <i className="icon-diag" style={{ fontSize: 16 }} />
            </Button>

            <Button
              size="sm"
              color="link"
              onClick={() => {
                setToFocus(data.SitId)
                history.push('/detailed-consumption')
              }}
              title={t('topNav.detailedConsumption')}
              style={{ textDecoration: 'none' }}
            >
              <i className="icon-ccourbes" style={{ fontSize: 16 }} />
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { setToFocus }

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(TopFlopCardKpi)

export { TopFlopCardKpi }
