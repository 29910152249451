import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

const UserInfosInput = ({ id, label, onChange, value, type, required }) => (
  <FormGroup className="text-input">
    <Input
      className={value === '' ? '' : 'has-value'}
      id={id}
      defaultValue={value}
      onChange={onChange}
      required={required}
      type={type}
    />
    <Label for={id}>
      {label}
      {required ? '*' : ''}
    </Label>
  </FormGroup>
)

export default UserInfosInput
