import React from 'react'
import classnames from 'classnames'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { Field } from 'components/form/Field'
import { usePMaxForm } from './usePMaxForm'

export const PMaxForm = () => {
  const { t } = useTranslation()
  const { initialValues, submit, isLoading } = usePMaxForm()

  return (
    <div>
      <h4 className="mb-4">{t('site.calendrier.puissanceMax')}</h4>

      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values) => submit(values)}
      >
        {(props) => {
          return (
            <Form className={classnames('space-y-6', { isLoading })}>
              <div className="space-y-4">
                <Field
                  name="PMaxOuverture"
                  type="number"
                  label={t('site.calendrier.ouverture')}
                  postfix="kW"
                />
                <Field
                  name="PMaxFermeture"
                  type="number"
                  label={t('site.calendrier.fermeture')}
                  postfix="kW"
                />
              </div>

              <footer className="space-x-4">
                <button
                  type="reset"
                  disabled={!props.dirty || props.isSubmitting}
                  className="btn btn-sm btn-light"
                >
                  {t('global.reset')}
                </button>

                <button
                  type="submit"
                  disabled={!props.dirty || props.isSubmitting}
                  className="btn btn-sm btn-primary"
                >
                  {t('global.valider')}
                </button>
              </footer>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
